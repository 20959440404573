export const VerticalDashedLine = ({
  stroke = "black",
  height,
  dashArray,
  width = "1",
  strokeWidth = "1",
}) => {
  return (
    <svg width={width} height={height} xmlns="http://www.w3.org/2000/svg">
      <line
        x1="0"
        y1="0"
        x2="0"
        y2={height}
        stroke={stroke}
        strokeWidth={strokeWidth}
        strokeDasharray={dashArray}
      />
    </svg>
  );
};
