import { useSnapshot } from "valtio"
import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import deleteIcon from "../assets/images/delete.svg";
import { taskStore } from "./task-store";
import { Agmongofile } from "../components/AgFile";
import Api from "../state/Api";
import { AgmodalStore, EntityV2Store, configStore, loginStore } from "../state/CommonState";
import { CUSTOMERROLES, ROLES } from "../util/constants";
import { Modal } from "reactstrap";
import { DocumentViewer } from "../pages/DocumentViewer";
import { FaDownload } from "react-icons/fa";

export default function CustomsKycRequirements(props) {
    useSnapshot(AgmodalStore)
    let task = taskStore.current;
    // task.pendingWith = "CUSTOMER"
    const [selectedFiles, setSelectedFiles] = useState({});
    const [fileObjects, setFileObjects] = useState({});
    const [documentDetails, setDocumentDetails] = useState({});
    const [submitClicked, setSubmitClicked] = useState(false)
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [anyDocumentAccepted, setAnyDocumentAccepted] = useState(false);
    const [viewOnly, setViewOnly] = useState(true)
    const [res, setRes] = useState({})
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [fileUrl, setFileUrl] = useState('');
    const [fileName, setFileName] = useState('')

    const openModal = (url, title) => {
        setFileUrl(url);
        setFileName(title)
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setModalIsOpen(false);
        setFileUrl('');
    };

    const handleFileChange = (e, id) => {
        const file = e.target.files[0];
        if (file) {
            setFileObjects((prev) => ({
                ...prev,
                [id]: file,
            }));
            setSelectedFiles((prev) => ({
                ...prev,
                [id]: file.name,
            }));
        }
    };

    const handleStatusChange = (e, id) => {
        const { value } = e.target;
        setDocumentDetails((prev) => ({
            ...prev,
            [id]: {
                ...prev[id],
                status: value,
                remarks: value !== 'Reject' ? '' : prev[id]?.remarks,
            },
        }));
    };

    const downloadFile = async (filename, entityId) => {
        const url = `${process.env.REACT_APP_FILE_SERVER}/api/v1/filestore/getfile/${entityId}/${filename}`;
      
        try {
          // Fetch the file as a blob
          const response = await fetch(url);
          
          // Check if the response is OK (status 200)
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
      
          // Convert the response into a Blob
          const blob = await response.blob();
      
          // Create a temporary link element
          const link = document.createElement('a');
          
          // Create a URL for the blob
          const blobUrl = URL.createObjectURL(blob);
      
          // Set the download attribute with the filename
          link.href = blobUrl;
          link.download = filename;
      
          // Append the link to the document body
          document.body.appendChild(link);
      
          // Programmatically click the link to trigger the download
          link.click();
      
          // Remove the link from the document
          document.body.removeChild(link);
      
          // Release the blob URL
          URL.revokeObjectURL(blobUrl);
        } catch (error) {
          console.error('Error downloading the file:', error);
        }
      };

    const handleRemarkChange = (e, id) => {
        const { value } = e.target;
        setDocumentDetails((prev) => ({
            ...prev,
            [id]: {
                ...prev[id],
                remarks: value,
            },
        }));
    };

    useEffect(() => {
        if(props.entityId){task.entityId = props.entityId}
        const fetchData = async () => {
            const initialSelectedFiles = {};
            const initialDocumentDetails = {};

            try {
                if (props.entityView) {
                    const data = await new Promise((resolve, reject) => {
                        Api.getCustomClearenceFiles(EntityV2Store.EntityRec.entityId, resolve);
                    });

                    if (data && data.kycDetails) {
                        task.uploads = data.kycDetails;
                    }else if (data && data.kycDetails === undefined){
                        task.uploads = [];
                    }
                }
                task.uploads.forEach((upload, index) => {
                    initialSelectedFiles[index] = upload.filename;
                    initialDocumentDetails[index] = {
                        status: upload.status ? upload.status : "",
                        remarks: upload.remarks || ''
                    };
                });

                setAnyDocumentAccepted(task.uploads.some(upload => upload.status === "Accept"));
                setDocumentDetails(initialDocumentDetails);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
        setViewOnly(task?.customerStatus === "COMPLETED" && task?.pendingWith === "AGRAGA" && loginStore.isClient())
    }, [viewOnly, task, props.entityView, props.entityId]);

    const handleSave = async () => {
        const formDataArray = task?.uploads?.map((item, index) => ({
            key: item.key,
            file: fileObjects[index],
            status: documentDetails[index]?.status,
            remarks: documentDetails[index]?.remarks,
            label: item.label,
            type: item.type,
            inputFields: item.inputFields,
            mandate: item.mandate
        }));
        const uploadPromises = formDataArray.map((item, i) => {
            if (!fileObjects[i]) {
                fileObjects[i] = task.uploads[i].filename;
            }
            return item.type === "file" && item.file && uploadFile(item.file, item.key);
        });



        const uploadResults = await Promise.all(uploadPromises);

        const resultsWithIndex = task?.uploads?.map((item, index) => {
            const uploadResult = uploadResults.find(uploadResult => uploadResult?.label === item.key);

            return {
                ...item,
                filename: uploadResult ? uploadResult.key?.filename : item?.filename,
                status: uploadResult?.message === "Success" ? "Success" : documentDetails[index]?.status || "",
                remarks: documentDetails[index]?.remarks || ""
            };
        });
        const req = {
            bookingNum: task.bookingNum,
            origin: task.origin,
            destination: task.destination,
            uploadResults: resultsWithIndex,
            pendingWith: "CUSTOMER",
            actionName: task.actionName,
            num: task._id.num,
            status: "PENDING",
            customerStatus:"PENDING",
            entityId: task.entityId,
            entityName: task.entityName
        }
        Api.uploadCustomClearenceFiles(req);
        if (taskStore.previous) {
            taskStore.current = taskStore.previous
            taskStore.previous = null
        }
        taskStore.myActionVisible = false
        taskStore.isViewAll = false
            AgmodalStore.pageVisible = false
            AgmodalStore.apiCall = true
            taskStore.triggerActionVisible = false;
    }

    const handleSubmit = async () => {
        const res = await submitConfirm()
        setRes(res)
        if (res?.mandatoryMet) {
            const nonMandatoryFieldsIncomplete = task.uploads.some((item, index) => {
                if (item.type === "file" && item.mandate === false && item.status !== "Accept" && !fileObjects[index]) {
                    return true;
                }
                return false;
            });
            if (nonMandatoryFieldsIncomplete && task.pendingWith === "CUSTOMER") {
                setShowConfirmation(true)
                return;
            } else {

                Api.uploadCustomClearenceFiles(res.req);

                if (taskStore.previous) {
                    taskStore.current = taskStore.previous
                    taskStore.previous = null
                }
                taskStore.myActionVisible = false
                taskStore.isViewAll = false
                    AgmodalStore.pageVisible = false
                    AgmodalStore.apiCall = true
                    taskStore.triggerActionVisible = false;
            }
        }
        
    }

    const handleConfirmation = (confirmed) => {
        setShowConfirmation(false);
        if (confirmed) {
            Api.uploadCustomClearenceFiles(res.req);

            if (taskStore.previous) {
                taskStore.current = taskStore.previous
                taskStore.previous = null
            }
            taskStore.myActionVisible = false
            taskStore.isViewAll = false
                AgmodalStore.pageVisible = false
                AgmodalStore.apiCall = true
                taskStore.triggerActionVisible = false;
        }
    };
    // ConfirmationPopup component definition
    const ConfirmationPopup = ({ onConfirm }) => {
        return (
            <div className="modal is-active" style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
                <div className="modal-background"></div>
                <div className="modal-content">
                    <div className="box" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <h1 className="has-text-weight-bold">Proceed to Confirm</h1>
                        <p>Are you sure you want to proceed with submission?</p>
                        <p>Some Non-Mandatory documents are pending.</p>
                        <div className="buttons" style={{ display: 'flex', justifyContent: 'space-between', width: '100%', marginTop: '20px' }}>
                            <button className="button is-danger" onClick={() => onConfirm(false)}>Cancel</button>
                            <button className="button is-success" onClick={() => onConfirm(true)}>Submit</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const submitConfirm = async () => {
        setSubmitClicked(true)
        let mandatoryMet = true

        const formDataArray = task?.uploads?.map((item, index) => ({
            key: item.key,
            file: fileObjects[index],
            status: documentDetails[index]?.status,
            remarks: documentDetails[index]?.remarks,
            label: item.label,
            type: item.type,
            inputFields: item.inputFields,
            mandate: item.mandate
        }));

        try {
            let req
            if (task.pendingWith === "CUSTOMER") {
                const uploadPromises = formDataArray
                    .filter((item, i) => {
                        if (item.status !== "Accept") {
                            if (!fileObjects[i]) { fileObjects[i] = task.uploads[i].filename }
                            if (item.type === "file" && item.mandate === true && !fileObjects[i]) {
                                console.log("Mandatory file not found");
                                mandatoryMet = false
                            } else if (item.type === "input" && item.mandate === true) {
                                const mandatoryFields = item.inputFields.some(field => field.value !== undefined && field.value !== '');
                                if (!mandatoryFields) {
                                    console.log("Mandatory field not filled");
                                    mandatoryMet = false
                                }
                            }
                            return true;
                        }
                        return false;
                    })
                    .map(item => (item.type === "file" && item.file?.size > 0 && uploadFile(item.file, item.key)));


                const uploadResults = await Promise.all(uploadPromises);

                const resultsWithIndex = task?.uploads?.map((item, index) => {
                    if (documentDetails[index]?.status === "Accept") {
                        const existingUpload = task.uploads.find(upload => upload.key === item.key);
                        return {
                            ...item,
                            filename: existingUpload ? existingUpload?.filename : null,
                            status: "Accept",
                            remarks: documentDetails[index]?.remarks || existingUpload?.remarks || ""
                        };
                    } else {
                        const uploadResult = uploadResults.find(uploadResult => uploadResult.label === item.key);
                        return {
                            ...item,
                            filename: uploadResult ? uploadResult.key?.filename : item.filename,
                            status: uploadResult?.message === "Success" && "",
                            remarks: documentDetails[index]?.remarks || ""
                        };
                    }
                });
                req = {
                    bookingNum: task.bookingNum,
                    origin: task.origin,
                    destination: task.destination,
                    uploadResults: resultsWithIndex,
                    pendingWith: "AGRAGA",
                    actionName: task.actionName,
                    num: task._id.num,
                    customerStatus: "COMPLETED",
                    status: "PENDING",
                    entityId: task.entityId,
                    entityName: task.entityName
                }
            } else if (task.pendingWith === "AGRAGA") {
                const updatedUploads = task?.uploads?.map((upload, index) => ({
                    ...upload,
                    status: documentDetails[index]?.status || upload.status,
                    remarks: documentDetails[index]?.remarks || upload.remarks
                }));
                const isAnyStatusNotSelected = updatedUploads.some(upload => !upload.status);
                if (isAnyStatusNotSelected) {
                    console.log('Please select status for all documents');
                    return;
                }
                const hasReject = updatedUploads.some(upload => upload.status === "Reject");
                const allAccepted = updatedUploads.every(upload => upload.status === "Accept");
                req = {
                    bookingNum: task.bookingNum,
                    uploadResults: updatedUploads,
                    pendingWith: hasReject ? "CUSTOMER" : "AGRAGA",
                    actionName: task.actionName,
                    status: allAccepted ? "COMPLETED" : "PENDING",
                    num: task._id.num,
                    entityId: task.entityId,
                    entityName: task.entityName,
                    customerStatus: allAccepted ? "COMPLETED" : "PENDING",
                    origin: task.origin,
                    destination: task.destination,
                }

            }
            return { req: req, mandatoryMet: mandatoryMet }
        } catch (error) {
            console.error('Error during file uploads:', error);
            // Handle the error, e.g., show an error message
        }
    };

    const handleFields = (uploadIndex, fieldIndex, newValue, label) => {
        const updatedUploads = [...task.uploads];
        const upload = updatedUploads[uploadIndex];
        const field = upload.inputFields[fieldIndex];

        if (label === "Account Number") {
            newValue = newValue.slice(0, 20).replace(/\D/g, '');
        } else if (label === "AD Code") {
            newValue = newValue.slice(0, 14);
        } else {
            newValue = newValue.slice(0, 100);
        }

        field.value = newValue;
        task.uploads = updatedUploads;
    }

    const uploadFile = (file, key) => {
        if (!file) {
            // Handle the case where the file is not found by returning an empty string
            return Promise.resolve({ label: key, message: 'File not found', filename: "NA" });
        }

        const url = `${process.env.REACT_APP_FILE_SERVER}/api/v1/filestore/storefile`;
        const formData = new FormData();
        formData.append('myFile', file);
        formData.append('label', key);
        formData.append('key', task.entityId);

        return fetch(url, {
            method: 'POST',
            headers: Api.H1(),
            body: formData
        })
            .then(response => response.json())
            .then(data => {
                if (data.error && data.error !== 'No Error' && data.error !== 'Invalid Token') {
                    throw new Error(data.error);
                }
                data.label = key;
                return data;
            })
            .catch(error => {
                console.error('File upload error:', error);
                throw error;
            });
    };

    const handleFileReset = (id) => {
        setSelectedFiles((prev) => {
            const newFiles = { ...prev };
            delete newFiles[id];
            return newFiles;
        });
        setFileObjects((prev) => {
            const newFileObjects = { ...prev };
            delete newFileObjects[id];
            return newFileObjects;
        });
    };

    return (
        <div className="box">
            {modalIsOpen && <DocumentViewer
                documentTitle={fileName}
                open={modalIsOpen}
                onClose={closeModal}
                documentUrl={fileUrl}
            />}
            {props.entityView && props.entityView && <div style={{ display: 'flex', flexDirection: "row", justifyContent: "space-between" }}>
                <h1 className="title">Customs KYC Documents</h1>
                <button
                    onClick={async (e) => {
                        e.preventDefault()
                        props.fn(false)
                    }}
                    className="delete is-large is-info"
                    style={{ backgroundColor: "#485fc7" }}
                ></button></div>}
            {props.entityView && !anyDocumentAccepted && <p>No Documents to show</p>}
            {task.uploads?.map((e, i) => (
                <Box key={e.key}>
                    {((task.uploads[i].status === "Accept" && props.entityView) || viewOnly) && <div style={{ paddingTop: "20px" }}> 
                     <h1 className="has-text-weight-bold" ><span>{e.label}</span><span>{e.mandate && " *"}</span></h1> 
                     {e.type === "file" && task.uploads[i].filename ? <div> <a style={{ fontSize: "15px", textDecoration: "underline", paddingRight:'10px' }} className="is-link" href="#"
                                                    onClick={() => openModal(`${process.env.REACT_APP_FILE_SERVER}/api/v1/filestore/getfile/${task.entityId}/${task.uploads[i].filename}`, task.uploads[i].filename)} >{task.uploads[i].filename}</a><a className="is-link"
                                                    onClick={() => downloadFile(task.uploads[i].filename, task.entityId)}><FaDownload/></a></div> : <div>
                        {e.inputFields?.map((input, fieldIndex) => (
                            <div
                                key={input.label}
                                style={{
                                    display: 'inline-grid',
                                    marginRight: '10px',
                                    width: "40%"
                                }}
                            >
                                <div style={{ display: 'flex', alignItems: 'center', marginLeft: '10px' }}>
                                    <span style={{ width: "100%", display: "inline-block" }}>{input.label}:</span>
                                    <span style={{ marginLeft: "10px" }}>{input.value}</span>
                                </div>
                            </div>
                        ))}
                    </div>}</div>}
                    {(task.uploads[i].status !== "Accept" || task.status === "COMPLETED") && !props.entityView && !viewOnly && (
                        <tr>
                            <td style={{ paddingBottom: "50px" }}>
                                <h1 className="has-text-weight-bold"><span>{e.label}</span><span>{e.mandate && " *"}</span></h1>
                                {task.pendingWith === 'CUSTOMER' ? (
                                    e.type === 'file' ? (
                                        <div>
                                            <div style={{ display: 'flex', alignItems: 'center', marginLeft: '10px' }}>
                                                {e.template && (
                                                    <div style={{ display: 'flex' }}>
                                                        <p>Download template from the given link</p>
                                                        <a
                                                            href={e.key === "kycForm" ? "KYC-Company.pdf" : "Authorisation Letter from forwarder.docx"}
                                                            download={task.uploads[i].filename} 
                                                            style={{ textDecoration: 'none', color: 'blue', cursor: 'pointer', paddingLeft: "10px" }}
                                                        >
                                                            {e.key === "kycForm" ? "KYC-Company.pdf" : "Authorisation Letter from forwarder.docx"}
                                                        </a>
                                                    </div>
                                                )}
                                            </div>
                                            <div style={{ display: 'flex', alignItems: 'center', marginLeft: '10px', paddingTop: "10px" }}>
                                                {task.uploads[i].status === "Reject" && <div style={{ display: 'flex', alignItems: 'center', marginRight: '10px' }}>Re upload file </div>}
                                                {!selectedFiles[i] ? (
                                                    <input
                                                        type="file"
                                                        name={e.label}
                                                        accept=".pdf,.docx,.jpg,.jpeg,.png"
                                                        onChange={(event) => handleFileChange(event, i)}
                                                        id={`file-input-${i}`}
                                                        style={{ border: submitClicked && (e.mandate && !selectedFiles[i] && !task.uploads[i].filename) ? '1px solid red' : 'none' }}
                                                    />
                                                ) : (
                                                    <span style={{ marginRight: '10px' }}>{selectedFiles[i]}</span>
                                                )}
                                                {selectedFiles[i] && (
                                                    <img
                                                        src={deleteIcon}
                                                        alt="Delete"
                                                        style={{ cursor: 'pointer' }}
                                                        onClick={() => handleFileReset(i)}
                                                    />
                                                )}
                                                {task.uploads[i].filename && <div> <p>Previously uploaded</p> <a style={{ fontSize: "15px", textDecoration: "underline" }} className="is-link" href="#"
                                                    onClick={() => openModal(`${process.env.REACT_APP_FILE_SERVER}/api/v1/filestore/getfile/${task.entityId}/${task.uploads[i].filename}`, task.uploads[i].filename)} >{task.uploads[i].filename}</a></div>}
                                            </div>
                                            {e.remarks && <div style={{ display: 'flex', alignItems: 'center', marginLeft: '10px' }}>Rejection Remarks: {e.remarks}</div>}
                                        </div>
                                    ) : (
                                        <div>
                                            {e.inputFields?.map((input, fieldIndex) => (
                                                <div
                                                    key={input.label}
                                                    style={{
                                                        display: 'inline-grid',
                                                        marginRight: '10px',
                                                        width: "40%"
                                                    }}
                                                >
                                                    <div style={{ display: 'flex', alignItems: 'center', marginLeft: '10px' }}>{input.label}</div>
                                                    <input
                                                        className="input is-small"
                                                        type={input.label === "Account Number" ? "number" : "text"}
                                                        value={input.value}
                                                        onChange={(e) => handleFields(i, fieldIndex, e.target.value, input.label)}
                                                        placeholder={input.placeholder}
                                                        style={{ display: 'inline-block', border: submitClicked && e.mandate && !input?.value && '1px solid red' }}
                                                    />
                                                </div>
                                            ))}
                                            {e.remarks && <div style={{ display: 'flex', alignItems: 'center', marginLeft: '10px' }}>Rejection Remarks: {e.remarks}</div>}
                                        </div>
                                    )
                                ) : (
                                    <div>
                                        <div>
                                            {e.type === "file" ? (
                                                task.uploads[i].filename ?<div> <a style={{ fontSize: "15px", textDecoration: "underline", paddingRight:"10px" }} className="is-link" href="#"
                                                onClick={() => openModal(`${process.env.REACT_APP_FILE_SERVER}/api/v1/filestore/getfile/${task.entityId}/${task.uploads[i].filename}`, task.uploads[i].filename)} >{task.uploads[i].filename}</a><a className="is-link"
                                                onClick={() => downloadFile(task.uploads[i].filename, task.entityId)}><FaDownload/></a></div> : <p>Not Available</p>
                                            ) : <div>
                                                {e.inputFields?.map((input, fieldIndex) => (
                                                    <div
                                                        key={input.label}
                                                        style={{
                                                            display: 'inline-grid',
                                                            marginRight: '10px',
                                                            width: "40%"
                                                        }}
                                                    >
                                                        <div style={{ display: 'flex', alignItems: 'center', marginLeft: '10px' }}>
                                                            <span style={{ width: "100%", display: "inline-block" }}>{input.label}:</span>
                                                            <span style={{ marginLeft: "10px" }}>{input.value}</span>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                            }
                                        </div>
                                        {task.status !== "COMPLETED" && !props.entityView &&
                                            <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
                                                <div className="select is-small" style={{ marginRight: '10px' }}>
                                                    <select defaultValue={""} style={{ border: submitClicked && (documentDetails[i]?.status !== "Accept" && documentDetails[i]?.status !== "Reject") ? '1px solid red' : 'none' }} value={documentDetails[i]?.status} onChange={(event) => handleStatusChange(event, i)}>
                                                        <option value="" >Select Status</option>
                                                        <option value="Accept">Accept</option>
                                                        <option value="Reject">Reject</option>
                                                    </select>
                                                </div>
                                                {documentDetails[i]?.status === 'Reject' && task.pendingWith === "AGRAGA" && (
                                                    <input
                                                        className="input is-small"
                                                        type="text"
                                                        placeholder="Enter remarks"
                                                        value={documentDetails[i]?.remarks || ''}
                                                        onChange={(event) => handleRemarkChange(event, i)}
                                                    />
                                                )}
                                            </div>}
                                    </div>
                                )}
                            </td>
                        </tr>
                    )}
                </Box>
            ))}
            {showConfirmation && (
                <ConfirmationPopup
                    onConfirm={handleConfirmation}
                />
            )}
            {task.status !== "COMPLETED" && !viewOnly && !props.entityView && [
                ROLES.CENTRAL.AGRAGA_ADMIN,
                ROLES.CENTRAL.PRODUCT_ADOPTION,
                ROLES.CB.BRANCH_HEAD,
                ROLES.CB.CUSTOMER_SUCCESS,
                ROLES.CB.RELATIONSHIP_MANAGER,
                ROLES.CB.ADMIN,
                CUSTOMERROLES.Client_admin,
                CUSTOMERROLES.BRANCH_USER,
                ROLES.CENTRAL.CENTRAL_OPERATIONS
            ].some(role => loginStore.userRec.aguserRec.role.includes(role)) &&
                <div style={{ display: "flex", alignItems: "center", alignContent: "space-between", width: "100%" }}>
                    {task.status === "PENDING" && task.customerStatus === 'PENDING' &&
                        <button className="button is-warning" style={{ flexGrow: 1, margin: "0 100px" }} onClick={handleSave}>Save</button>
                    }
                    {<button className="button is-link" style={{ flexGrow: 1, margin: "0 100px" }} onClick={handleSubmit}>Submit</button>}
                </div>
            }
        </div>
    );
}
