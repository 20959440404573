import { useSnapshot } from "valtio"
import { bookingsStore } from "../state/CommonState"
import { useEffect } from "react"
import { rtpStore } from "../pricing/pricing-store"
import BookingListv3 from "./booking-list-v3"
import ViewEnquiryv3 from "../enquiryv3/view-enquiry-v3"

export default function ListBookingsMainv3(props) {
    useSnapshot(bookingsStore)
    useSnapshot(rtpStore)
    useEffect(()=>{
        rtpStore.pricingSourceScreen = 'booking'
      return ()=>{
        bookingsStore.currentBookingsListComponent = "list"
      }
    },[])

    switch (bookingsStore.currentBookingsListComponent) {
        case 'list':
            //BookingsApi.getAllBookings()
            return <BookingListv3 />
        case 'view':
            return (
                <>
                    <ViewEnquiryv3 viewonly='1' showbooking='1' />
                </>
            )
        default: return <h1>Not Found</h1>
    }
}