import { CargoTypes } from "./AgCargoTypes";
import { MultiContainerDetails } from "./AgMultiContainerDetails";
import containerdetail from "../assets/images/containerdetailsicon.svg";
import { useEffect, useState } from "react";
import Utils from "../util/Utils";

export function ContainerDetails({form,viewonly}){
    const { getValues,watch} = form;
    const outGuage = watch("showCargoDetailsOutGuage")
 const mode = watch("mode");
 const Scope = getValues("scope")
 const stuffing = watch("stuffingType")
 const deStuffing = watch("destuffingType")
 const containerDetails = watch("containerDetails")
 let showCargoDetail = false;
 const [total,setTotal] = useState({ 
   totTotalWeight : 0})
 if(mode.toLowerCase()==="fcl"){
   let origin = Scope.split("-")[0];
      let destination = Scope.split("-")[2];
      if((origin?.toLowerCase()==="door" && stuffing.toLowerCase() === "dock" ) || (destination?.toLowerCase()==="door" && deStuffing.toLowerCase() === "dock" )){
          showCargoDetail = true;
      }else{
        showCargoDetail = false
      }
 }
 useEffect(()=>{

      if(containerDetails!=undefined){
         let totals = 0
         for (let j = 0; j < containerDetails.length; j++) {
            totals = totals+(Number(containerDetails[j]['numContainers'])*Number(Utils.toUnit(containerDetails[j]['wtPerContainer'], containerDetails[0]['unit'])))
         }
         if(JSON.stringify({totTotalWeight : totals})!=JSON.stringify(total)){
            setTotal({totTotalWeight : totals})
         }
       }
   
 },[JSON.stringify(containerDetails)])
    
   return <div className="flex flex-col w-full rounded-md bg-white shadow-custom p-[18px] gap-[18px]">
   <div className="flex flex-row justify-between items-center">
   <div className="flex flex-row gap-[10px]">
        <img src={containerdetail}/>
        <p className="text-black font-bold">Container Details</p>
     </div>
     </div>
     <CargoTypes form={form} viewonly={viewonly} />
     {/* {multidest && multidest.map((m,i)=>{
        return <MultiContainerDetails form={form} viewonly={viewonly} ind={i} />
     })} */}
     <MultiContainerDetails form={form} viewonly={viewonly} ind={0} />
    {(showCargoDetail||outGuage) && <><hr style={{margin:"0px"}}/><div className="flex flex-row justify-between items-center w-full">
        <div className="flex flex-row gap-2.5"><p className="text-blue-dark font-bold font-barlow">Grand Total Weight:</p><p className="text-yellow font-bold font-barlow">{isNaN(total.totTotalWeight)?0:total.totTotalWeight} Kgs</p></div>
        </div></>}
   </div>
}