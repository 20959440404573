import { useSnapshot } from "valtio";
import { DtStore } from "./DtStore";
import DtApi from "../state/dt-api";
import { useGlobalState } from "../GlobalState";
import { dtactionStore } from "../dtactions/dtaction-store";
let server = process.env.REACT_APP_FILE_SERVER;
export function DtInitiateAdvancemodal() {
  const { changeCurrentMenu } = useGlobalState()
  useSnapshot(DtStore);
  useSnapshot(dtactionStore)
  let dtStore = DtStore.DTBooking;
  const close = () => {
    DtStore.initiateAdvancemodal = false;
    changeCurrentMenu('Domestic Transport Actions')
  };
  const Update = async () => {
    dtStore.initiateAdvance = "Yes";
    DtApi.postEditDtBooking(dtStore, close);
  };
  let modalClass = "modal";
  if (DtStore.initiateAdvancemodal) modalClass += " is-active";
  return (
    <>
      {DtStore.initiateAdvancemodal ? (
        <div
          className={modalClass}
          onClick={() => {
            DtStore.initiateAdvancemodal = false;
          }}
        >
          <div className="modal-background"></div>
          <div
            className="modal-card"
            onClick={(e) => {
              e.stopPropagation();
            }}
            style={{ borderRadius: "10px",width:"80%" }}
          >
            <section className="modal-card-body">
              <div class="columns m-1 is-vcentered is-multiline">
                <div class="column is-12 pb-0 mb-0">
                  <b>
                    <h3 className="title is-5">Initiate Advance</h3>
                  </b>
                </div>
                <div class="column is-5">Vendor Name</div>
                <div class="column is-1">:</div>
                <div class="column is-6">{dtStore.vendor_assigned}</div>
                <div class="column is-5">Vendor Cost</div>
                <div class="column is-1">:</div>
                <div class="column is-6">{dtStore.vendor_agreed_cost} (INR)</div>
                {dtStore.vehicle_no && dtStore.vehicle_no.length > 0 ? (
                  <>
                    <div class="column is-5">Vehicle No</div>
                    <div class="column is-1">:</div>
                    <div class="column is-6">{dtStore.vehicle_no}</div>
                  </>
                ) : (
                  ""
                )}
                {dtStore.drivername && dtStore.drivername.length > 0 ? (
                  <>
                    <div class="column is-5">Driver Name</div>
                    <div class="column is-1">:</div>
                    <div class="column is-6">{dtStore.drivername}</div>
                  </>
                ) : (
                  ""
                )}
                {dtStore.drivernumber && dtStore.drivernumber.length > 0 ? (
                  <>
                    <div class="column is-5">Driver Number</div>
                    <div class="column is-1">:</div>
                    <div class="column is-6">{dtStore.drivernumber}</div>
                  </>
                ) : (
                  ""
                )}
                <div class="column is-12">
                  <table className="table is-bordered">
                    <thead>
                      <th>Destination</th>
                      <th>Cargo Value</th>
                      <th>E-Way Bill / Commercial Invoice</th>
                      <th>E-Way Bill No</th>
                      <th>Valid Upto</th>
                      <th>Lorry Receipt</th>
                      <th>Lorry Reciept No</th>
                    </thead>
                    <tbody>
                      {dtStore.details[0].delivery.map((e, i) => {
                        return (
                          <tr>
                            <td>{e.address}</td>
                            <td>{e.cargo_Value}</td>
                            <td>
                              {e.ewaybill.length > 0 ? (
                                <>
                                  <a
                                    target="_blank"
                                    href={
                                      server +
                                      "/api/v1/filestore/getfile/" +
                                      dtStore.details[0].contract_id +
                                      "/" +
                                      e.ewaybill
                                    }
                                  >
                                    E-Way Bill / Commercial Invoice - {i + 1}
                                  </a>
                                </>
                              ) : (
                                ""
                              )}
                            </td>
                            <td>{e.ewaybillno}</td>
                            <td>{e.ewaybilldate}</td>
                            <td>
                              {e.lorryreceipt.length > 0 ? (
                                <>
                                  <a
                                    target="_blank"
                                    href={
                                      server +
                                      "/api/v1/filestore/getfile/" +
                                      dtStore.details[0].contract_id +
                                      "/" +
                                      e.lorryreceipt
                                    }
                                  >
                                    Lorry Receipt - {i + 1}
                                  </a>
                                </>
                              ) : (
                                <></>
                              )}
                            </td>
                            <td>{e.lorryrecieptNo}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
                <div class="column is-5">Gross Profit Amount</div>
                <div class="column is-1">:</div>
                <div class="column is-6">{Number(dtStore.bookingamount)-Number(dtStore.vendor_agreed_cost)} (INR)</div>
                <div class="column is-5">GP %</div>
                <div class="column is-1">:</div>
                <div class="column is-6">{(((Number(dtStore.bookingamount)-Number(dtStore.vendor_agreed_cost))/Number(dtStore.vendor_agreed_cost))*100).toFixed(2) }</div>
              </div>
              <button
                class="button is-warning"
                onClick={() => (DtStore.initiateAdvancemodal = false)}
              >
                cancel
              </button>
              <button
                class="button is-danger"
                style={{ float: "right" }}
                onClick={Update}
              >
                Proceed
              </button>
            </section>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
}
