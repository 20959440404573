import { useEffect, useState } from "react"
import { useSnapshot } from "valtio"
import { AgFile2withloader } from "../components/agfile2"
import { myactionsstore } from "../my-actions/store-myactions"
import { exportToExcel } from 'react-json-to-excel';
import { loader } from "../util/loader";
import RoleUtils from "../util/RoleUtils"
import { PERMISSIONS } from "../util/constants"
import MonApi from "../state/mongo-api";

export default function Transporter() {
    useSnapshot(loader)
    const store = useSnapshot(myactionsstore)
    const [error, setError] = useState(false)
    const [download, setDownload] = useState(false)
    const [edata, setEdata] = useState([])
    const [data, setData] = useState({})
    const [data1, setData1] = useState({})
    const [data2, setData2] = useState({})
    let errors = data.validationErrors

    const check = async () => {
        try {
            setDownload(false)
            setError(false)
            if (data['PIN Master'] && data['Transporter Tariff']) {
                let d1 = data['PIN Master']
                let d2 = []
                let d3 = data['Transporter Tariff']
                try {
                    let url = `${process.env.REACT_APP_PRICINGAPI_SERVER}/api/v1/pricing/getcharges/pinunloc`
                    let ret = await MonApi.aget(url)
                    if(ret && ret.length>0){
                        d2 = ret
                    }
                } catch(error) {
                    console.log(error)
                }
                let a = {}
                let b = {}
                let s = []
                let e = []
                for (let i = 0; i < d1.length; i++) {
                    if (a[d1[i]['StateName'].toLowerCase().trim()] == undefined) {
                        a[d1[i]['StateName'].toLowerCase().trim()] = []
                    }
                    a[d1[i]['StateName'].toLowerCase().trim()].push(d1[i])
                }
                console.log(a)
                for (let i = 0; i < d2.length; i++) {
                    if (b[d2[i]['unloc'].toLowerCase().trim()] == undefined) {
                        b[d2[i]['unloc'].toLowerCase().trim()] = []
                    }
                    b[d2[i]['unloc'].toLowerCase().trim()].push(d2[i])
                }
                console.log(b)
                for (let i = 0; i < d3.length; i++) {
                    let o = []
                    let p = []
                    let p1 = []
                    let p2 = []
                    let p3 = []
                    let d = []
                    if (d3[i]['State'] != undefined && a[d3[i]['State'].toLowerCase().trim()] != undefined && d3[i]['State'].toLowerCase().trim().length > 0) {
                        if (a[d3[i]['State'].toLowerCase().trim()][0]["Type"] == "Union Territory" && d3[i]['Origin'] != undefined && d3[i]['Origin'] == d3[i]['State']) {
                            let l = a[d3[i]['State'].toLowerCase().trim()]
                            for (let j = 0; j < l.length; j++) {
                                if (p.indexOf(l[j]['Pincode']) == -1) {
                                    o.push({
                                        location: l[j]['Office Name'],
                                        pincode: l[j]['Pincode']
                                    })
                                    p.push(l[j]['Pincode'])
                                }

                            }
                        } else {
                            let l = a[d3[i]['State'].toLowerCase().trim()]
                            let zy = []
                            let zx = []
                            for (let j = 0; j < l.length; j++) {
                                if (d3[i]['Origin'] != undefined) {
                                    if ((l[j]['District'].toLowerCase().trim() + ' ').indexOf(' ' + (d3[i]['Origin'].toLowerCase().trim()) + ' ') > 0 || (l[j]['District'].toLowerCase().trim() + ' ').indexOf((d3[i]['Origin'].toLowerCase().trim()) + ' ') == 0) {
                                        zx.push(l[j])
                                        if ((l[j]['Division Name'].toLowerCase().trim() + ' ').indexOf(' ' + (d3[i]['Origin'].toLowerCase().trim()) + ' ') > 0 || (l[j]['Division Name'].toLowerCase().trim() + ' ').indexOf((d3[i]['Origin'].toLowerCase().trim()) + ' ') == 0) {
                                            if ((l[j]['Region Name'].toLowerCase().trim() + ' ').indexOf(' ' + (d3[i]['Origin'].toLowerCase().trim()) + ' ') > 0 || (l[j]['Region Name'].toLowerCase().trim() + ' ').indexOf((d3[i]['Origin'].toLowerCase().trim()) + ' ') == 0) {
                                                zy.push(l[j])
                                                if (p.indexOf(l[j]['Pincode']) == -1) {
                                                    o.push({
                                                        location: l[j]['Office Name'],
                                                        pincode: l[j]['Pincode']
                                                    })
                                                    p.push(l[j]['Pincode'])
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            if(zy.length==0){
                                if(zx.length!=0){
                                    for (let j = 0; j < zx.length; j++) {
                                        if (p.indexOf(zx[j]['Pincode']) == -1) {
                                            o.push({
                                                location: zx[j]['Office Name'],
                                                pincode: zx[j]['Pincode']
                                            })
                                            p.push(zx[j]['Pincode'])
                                        }
                                    }
                                }else{
                                    for (let j = 0; j < l.length; j++) {
                                        if (d3[i]['Origin'] != undefined) {
                                            if ((l[j]['Office Name'].toLowerCase().trim() + ' ').indexOf(' ' + (d3[i]['Origin'].toLowerCase().trim()) + ' ') > 0 || (l[j]['Office Name'].toLowerCase().trim() + ' ').indexOf((d3[i]['Origin'].toLowerCase().trim()) + ' ') == 0) {
                                                if (p.indexOf(l[j]['Pincode']) == -1) {
                                                    o.push({
                                                        location: l[j]['Office Name'],
                                                        pincode: l[j]['Pincode']
                                                    })
                                                    p.push(l[j]['Pincode'])
                                                }
                                            }
                                        }
                                    }        
                                }
                            }
                        }

                    }
                    if (d3[i]['Origin'] != undefined && b[d3[i]['Origin'].toLowerCase().trim()] != undefined && d3[i]['Origin'].toLowerCase().trim().length > 0) {
                        let l = b[d3[i]['Origin'].toLowerCase().trim()]
                        for (let j = 0; j < l.length; j++) {
                            if (l[j]['unloc'].toLowerCase().trim().indexOf(d3[i]['Origin'].toLowerCase().trim()) != -1) {
                                if (p1.indexOf(l[j]['pinCode']) == -1) {
                                    o.push({
                                        location: l[j]['unloc'],
                                        pincode: l[j]['pinCode']
                                    })
                                    p1.push(l[j]['pinCode'])
                                }
                            }
                        }
                    }
                    if (d3[i]['State_1'] != undefined && a[d3[i]['State_1'].toLowerCase().trim()] != undefined && d3[i]['State_1'].toLowerCase().trim().length > 0) {
                        if (a[d3[i]['State_1'].toLowerCase().trim()][0]["Type"] == "Union Territory" && d3[i]['Destination'] != undefined && d3[i]['State_1'] == d3[i]['Destination']) {
                            let l = a[d3[i]['State_1'].toLowerCase().trim()]
                            for (let j = 0; j < l.length; j++) {
                                if (p2.indexOf(l[j]['Pincode']) == -1) {
                                    d.push({
                                        location: l[j]['Office Name'],
                                        pincode: l[j]['Pincode']
                                    })
                                    p2.push(l[j]['Pincode'])
                                }
                            }
                        } else {
                            let l = a[d3[i]['State_1'].toLowerCase().trim()]
                            let zy = []
                            let zx = []
                            for (let j = 0; j < l.length; j++) {
                                if (d3[i]['Destination'] != undefined) {
                                    if ((l[j]['District'].toLowerCase().trim() + ' ').indexOf(' ' + (d3[i]['Destination'].toLowerCase().trim()) + ' ') > 0 || (l[j]['District'].toLowerCase().trim() + ' ').indexOf((d3[i]['Destination'].toLowerCase().trim()) + ' ') == 0) {
                                        zx.push(l[j])
                                        if ((l[j]['Division Name'].toLowerCase().trim() + ' ').indexOf(' ' + (d3[i]['Destination'].toLowerCase().trim()) + ' ') > 0 || (l[j]['Division Name'].toLowerCase().trim() + ' ').indexOf((d3[i]['Destination'].toLowerCase().trim()) + ' ') == 0) {
                                            if ((l[j]['Region Name'].toLowerCase().trim() + ' ').indexOf(' ' + (d3[i]['Destination'].toLowerCase().trim()) + ' ') > 0 || (l[j]['Region Name'].toLowerCase().trim() + ' ').indexOf((d3[i]['Destination'].toLowerCase().trim()) + ' ') == 0) {
                                                zy.push(l[j])
                                                if (p2.indexOf(l[j]['Pincode']) == -1) {
                                                    d.push({
                                                        location: l[j]['Office Name'],
                                                        pincode: l[j]['Pincode']
                                                    })
                                                    p2.push(l[j]['Pincode'])
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            if(zy.length==0){
                                if(zx.length!=0){
                                    for (let j = 0; j < zx.length; j++) {
                                        if (p2.indexOf(zx[j]['Pincode']) == -1) {
                                            d.push({
                                                location: zx[j]['Office Name'],
                                                pincode: zx[j]['Pincode']
                                            })
                                            p2.push(zx[j]['Pincode'])
                                        }
                                    }
                                }else{
                                    for (let j = 0; j < l.length; j++) {
                                        if (d3[i]['Destination'] != undefined) {
                                            if ((l[j]['Office Name'].toLowerCase().trim() + ' ').indexOf(' ' + (d3[i]['Destination'].toLowerCase().trim()) + ' ') > 0 || (l[j]['Office Name'].toLowerCase().trim() + ' ').indexOf((d3[i]['Destination'].toLowerCase().trim()) + ' ') == 0) {
                                                if (p2.indexOf(l[j]['Pincode']) == -1) {
                                                    d.push({
                                                        location: l[j]['Office Name'],
                                                        pincode: l[j]['Pincode']
                                                    })
                                                    p2.push(l[j]['Pincode'])
                                                }
                                            }
                                        }
                                    }
                                }
        
                            }
                        }
                    }
                    if (d3[i]['Destination'] != undefined && b[d3[i]['Destination'].toLowerCase().trim()] != undefined && d3[i]['Destination'].toLowerCase().trim().length > 0) {
                        let l = b[d3[i]['Destination'].toLowerCase().trim()]
                        for (let j = 0; j < l.length; j++) {
                            console.log(l[j]['unloc'].toLowerCase().trim(),d3[i]['Destination'].toLowerCase().trim())
                            if (l[j]['unloc'].toLowerCase().trim().indexOf(d3[i]['Destination'].toLowerCase().trim()) != -1) {
                                console.log(l[j]['unloc'].toLowerCase().trim(),d3[i]['Destination'].toLowerCase().trim(),p3.indexOf(l[j]['pinCode']),p3)
                                if (p3.indexOf(l[j]['pinCode']) == -1) {
                                    d.push({
                                        location: l[j]['unloc'],
                                        pincode: l[j]['pinCode']
                                    })
                                    p3.push(l[j]['pinCode'])
                                }
                            }
                        }
                    }



                    
console.log(o,d)
                    if (o.length && d.length > 0) {

                        for (let i1 = 0; i1 < o.length; i1++) {
                            for (let j = 0; j < d.length; j++) {
                                let k = {}
                                k["Origin PIN"] = o[i1]['pincode']
                                k["Destination PIN"] = d[j]['pincode']
                                let l1 = Object.keys(d3[i])
                                for (let z = 0; z < l1.length; z++) {
                                    if (["Origin",
                                        "State",
                                        "Destination",
                                        "State_1"].indexOf(l1[z]) == -1) {
                                        k[l1[z]] = d3[i][l1[z]]
                                    }
                                }
                                s.push(k)
                            }

                        }
                    } else {
                        if (o.length == 0 && d.length > 0) {
                            d3[i]['Reason'] = 'Look-up failed for Origin'
                            e.push(d3[i])
                        }
                        if (o.length > 0 && d.length == 0) {
                            {
                                d3[i]['Reason'] = 'Look-up failed for Destination'
                                e.push(d3[i])
                            }
                            if (o.length == 0 && d.length == 0) {
                                d3[i]['Reason'] = 'Look-up failed for Origin,Destination'
                                e.push(d3[i])
                            }
                        }

                    }
                }
                    if (s.length > 0 || e.length > 0) {
                    

                        var clean = s.filter((arr, index, self) =>
                            index === self.findIndex((t) => (t['Origin PIN'] === arr['Origin PIN'] && t['Destination PIN'] === arr['Destination PIN'])))

                        s = clean
                        setEdata(
                            [{
                                sheetName: "success",
                                details: s
                            }, {
                                sheetName: "failure",
                                details: e
                            }]
                        )
                        setDownload(true)
                    } else {
                        setError(true)
                    }
                    console.log(s)
                    console.log(e)
                }else {
                    setError(true)
                }
            } catch (error) {
                setError(true)
            }
            loader.hide()
        }

    const check1 = async () => {
        try {
            setDownload(false)
            setError(false)
            if (data1['PIN Master'] && data1['Transporter Tariff']) {

                let d1 = data1['PIN Master']
                let d2 = []
                let d3 = data1['Transporter Tariff']
                try {
                    let url = `${process.env.REACT_APP_PRICINGAPI_SERVER}/api/v1/fclpricing/getcharges/fcl_pinunloc`
                    let ret = await MonApi.aget(url)
                    if(ret && ret.length>0){
                        d2 = ret
                    }
                } catch(error) {
                    console.log(error)
                }
                let a = {}
                let b = {}
                let s = []
                let e = []
                for (let i = 0; i < d1.length; i++) {
                    if (a[d1[i]['StateName'].toLowerCase().trim()] == undefined) {
                        a[d1[i]['StateName'].toLowerCase().trim()] = []
                    }
                    a[d1[i]['StateName'].toLowerCase().trim()].push(d1[i])
                }
                console.log(a)
                for (let i = 0; i < d2.length; i++) {
                    if (b[d2[i]['unloc'].toLowerCase().trim()] == undefined) {
                        b[d2[i]['unloc'].toLowerCase().trim()] = []
                    }
                    b[d2[i]['unloc'].toLowerCase().trim()].push(d2[i])
                }
                console.log(b)
                for (let i = 0; i < d3.length; i++) {
                    let o = []
                    let p = []
                    let p1 = []
                    let p2 = []
                    let p3 = []
                    let p4 = []
                    let p5 = []
                    let d = []
                    let u2 = false
                    if (d3[i]['State'] != undefined && a[d3[i]['State'].toLowerCase().trim()] != undefined && d3[i]['State'].toLowerCase().trim().length > 0) {
                        if (a[d3[i]['State'].toLowerCase().trim()][0]["Type"] == "Union Territory" && d3[i]['Origin'] != undefined && d3[i]['Origin'] == d3[i]['State']) {
                            let l = a[d3[i]['State'].toLowerCase().trim()]
                            for (let j = 0; j < l.length; j++) {
                                if (p.indexOf(l[j]['Pincode']) == -1) {
                                    if(!u2) u2 = true
                                    o.push({
                                        location: l[j]['Office Name'],
                                        pincode: l[j]['Pincode']
                                    })
                                    p.push(l[j]['Pincode'])
                                }

                            }
                        } else {
                            let l = a[d3[i]['State'].toLowerCase().trim()]
                            let zy = []
                            let zx = []
                            for (let j = 0; j < l.length; j++) {
                                if (d3[i]['Origin'] != undefined) {
                                    if ((l[j]['District'].toLowerCase().trim() + ' ').indexOf(' ' + (d3[i]['Origin'].toLowerCase().trim()) + ' ') > 0 || (l[j]['District'].toLowerCase().trim() + ' ').indexOf((d3[i]['Origin'].toLowerCase().trim()) + ' ') == 0) {
                                        zx.push(l[j])
                                        if ((l[j]['Division Name'].toLowerCase().trim() + ' ').indexOf(' ' + (d3[i]['Origin'].toLowerCase().trim()) + ' ') > 0 || (l[j]['Division Name'].toLowerCase().trim() + ' ').indexOf((d3[i]['Origin'].toLowerCase().trim()) + ' ') == 0) {
                                            if ((l[j]['Region Name'].toLowerCase().trim() + ' ').indexOf(' ' + (d3[i]['Origin'].toLowerCase().trim()) + ' ') > 0 || (l[j]['Region Name'].toLowerCase().trim() + ' ').indexOf((d3[i]['Origin'].toLowerCase().trim()) + ' ') == 0) {
                                                zy.push(l[j])
                                                if (p.indexOf(l[j]['Pincode']) == -1) {
                                                    if(!u2) u2 = true
                                                    o.push({
                                                        location: l[j]['Office Name'],
                                                        pincode: l[j]['Pincode']
                                                    })
                                                    p.push(l[j]['Pincode'])
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            if(zy.length==0){
                                if(zx.length!=0){
                                    for (let j = 0; j < zx.length; j++) {
                                        if (p.indexOf(zx[j]['Pincode']) == -1) {
                                            if(!u2) u2 = true
                                            o.push({
                                                location: zx[j]['Office Name'],
                                                pincode: zx[j]['Pincode']
                                            })
                                            p.push(zx[j]['Pincode'])
                                        }
                                    }
                                }else{
                                    for (let j = 0; j < l.length; j++) {
                                        if (d3[i]['Origin'] != undefined) {
                                            if ((l[j]['Office Name'].toLowerCase().trim() + ' ').indexOf(' ' + (d3[i]['Origin'].toLowerCase().trim()) + ' ') > 0 || (l[j]['Office Name'].toLowerCase().trim() + ' ').indexOf((d3[i]['Origin'].toLowerCase().trim()) + ' ') == 0) {
                                                if (p.indexOf(l[j]['Pincode']) == -1) {
                                                    if(!u2) u2 = true
                                                    o.push({
                                                        location: l[j]['Office Name'],
                                                        pincode: l[j]['Pincode']
                                                    })
                                                    p.push(l[j]['Pincode'])
                                                }
                                            }
                                        }
                                    }        
                                }
                            }
                        }

                    }
                    if (d3[i]['Origin'] != undefined && b[d3[i]['Origin'].toLowerCase().trim()] != undefined && d3[i]['Origin'].toLowerCase().trim().length > 0) {
                        let l = b[d3[i]['Origin'].toLowerCase().trim()]
                        for (let j = 0; j < l.length; j++) {
                            if (l[j]['unloc'].toLowerCase().trim().indexOf(d3[i]['Origin'].toLowerCase().trim()) != -1) {
                                if (p1.indexOf(l[j]['pinCode']) == -1) {
                                    if(!u2) u2 = true
                                    o.push({
                                        location: l[j]['unloc'],
                                        pincode: l[j]['unloc']
                                    })
                                    p1.push(l[j]['pinCode'])
                                }
                            }
                        }
                    }
                    // if(!u2 && d3[i]['Origin'] != undefined && d4.indexOf(d3[i]['Origin'].toLowerCase().trim()) != -1){
                    //     if (p4.indexOf(d3[i]['Origin'].toLowerCase()) == -1) {
                    //         if(!u2) u2 = true
                    //         o.push({
                    //             location: d3[i]['Origin'],
                    //             pincode: d3[i]['Origin']
                    //         })
                    //         p4.push(d3[i]['Origin'].toLowerCase())
                    //     }
                    // }
                    u2 = false
                    if (d3[i]['State_1'] != undefined && a[d3[i]['State_1'].toLowerCase().trim()] != undefined && d3[i]['State_1'].toLowerCase().trim().length > 0) {
                        if (a[d3[i]['State_1'].toLowerCase().trim()][0]["Type"] == "Union Territory" && d3[i]['Destination'] != undefined && d3[i]['State_1'] == d3[i]['Destination']) {
                            let l = a[d3[i]['State_1'].toLowerCase().trim()]
                            for (let j = 0; j < l.length; j++) {
                                if (p2.indexOf(l[j]['Pincode']) == -1) {
                                    if(!u2) u2 = true
                                    d.push({
                                        location: l[j]['Office Name'],
                                        pincode: l[j]['Pincode']
                                    })
                                    p2.push(l[j]['Pincode'])
                                }
                            }
                        } else {
                            let l = a[d3[i]['State_1'].toLowerCase().trim()]
                            let zy = []
                            let zx = []
                            for (let j = 0; j < l.length; j++) {
                                if (d3[i]['Destination'] != undefined) {
                                    if ((l[j]['District'].toLowerCase().trim() + ' ').indexOf(' ' + (d3[i]['Destination'].toLowerCase().trim()) + ' ') > 0 || (l[j]['District'].toLowerCase().trim() + ' ').indexOf((d3[i]['Destination'].toLowerCase().trim()) + ' ') == 0) {
                                        zx.push(l[j])
                                        if ((l[j]['Division Name'].toLowerCase().trim() + ' ').indexOf(' ' + (d3[i]['Destination'].toLowerCase().trim()) + ' ') > 0 || (l[j]['Division Name'].toLowerCase().trim() + ' ').indexOf((d3[i]['Destination'].toLowerCase().trim()) + ' ') == 0) {
                                            if ((l[j]['Region Name'].toLowerCase().trim() + ' ').indexOf(' ' + (d3[i]['Destination'].toLowerCase().trim()) + ' ') > 0 || (l[j]['Region Name'].toLowerCase().trim() + ' ').indexOf((d3[i]['Destination'].toLowerCase().trim()) + ' ') == 0) {
                                                zy.push(l[j])
                                                if (p2.indexOf(l[j]['Pincode']) == -1) {
                                                    if(!u2) u2 = true
                                                    d.push({
                                                        location: l[j]['Office Name'],
                                                        pincode: l[j]['Pincode']
                                                    })
                                                    p2.push(l[j]['Pincode'])
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            if(zy.length==0){
                                if(zx.length!=0){
                                    for (let j = 0; j < zx.length; j++) {
                                        if (p2.indexOf(zx[j]['Pincode']) == -1) {
                                            if(!u2) u2 = true
                                            d.push({
                                                location: zx[j]['Office Name'],
                                                pincode: zx[j]['Pincode']
                                            })
                                            p2.push(zx[j]['Pincode'])
                                        }
                                    }
                                }else{
                                    for (let j = 0; j < l.length; j++) {
                                        if (d3[i]['Destination'] != undefined) {
                                            if ((l[j]['Office Name'].toLowerCase().trim() + ' ').indexOf(' ' + (d3[i]['Destination'].toLowerCase().trim()) + ' ') > 0 || (l[j]['Office Name'].toLowerCase().trim() + ' ').indexOf((d3[i]['Destination'].toLowerCase().trim()) + ' ') == 0) {
                                                if (p2.indexOf(l[j]['Pincode']) == -1) {
                                                    if(!u2) u2 = true
                                                    d.push({
                                                        location: l[j]['Office Name'],
                                                        pincode: l[j]['Pincode']
                                                    })
                                                    p2.push(l[j]['Pincode'])
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }

                    }
                    if (d3[i]['Destination'] != undefined && b[d3[i]['Destination'].toLowerCase().trim()] != undefined && d3[i]['Destination'].toLowerCase().trim().length > 0) {
                        let l = b[d3[i]['Destination'].toLowerCase().trim()]
                        for (let j = 0; j < l.length; j++) {
                            console.log(l[j]['unloc'].toLowerCase().trim(),d3[i]['Destination'].toLowerCase().trim())
                            if (l[j]['unloc'].toLowerCase().trim().indexOf(d3[i]['Destination'].toLowerCase().trim()) != -1) {
                                console.log(l[j]['unloc'].toLowerCase().trim(),d3[i]['Destination'].toLowerCase().trim(),p3.indexOf(l[j]['pinCode']),p3)
                                if (p3.indexOf(l[j]['pinCode']) == -1) {
                                    if(!u2) u2 = true
                                    d.push({
                                        location: l[j]['unloc'],
                                        pincode: l[j]['unloc']
                                    })
                                    p3.push(l[j]['pinCode'])
                                }
                            }
                        }
                    }
                    
                    // if(!u2 && d3[i]['Destination'] != undefined && d4.indexOf(d3[i]['Destination'].toLowerCase().trim()) != -1){
                    //     if (p5.indexOf(d3[i]['Destination'].toLowerCase()) == -1) {
                    //         if(!u2) u2 = true
                    //         d.push({
                    //             location: d3[i]['Destination'],
                    //             pincode: d3[i]['Destination']
                    //         })
                    //         p5.push(d3[i]['Destination'].toLowerCase())
                    //     }
                    // }



                    
console.log(o,d)
                    if (o.length && d.length > 0) {

                        for (let i1 = 0; i1 < o.length; i1++) {
                            for (let j = 0; j < d.length; j++) {
                                let k = {}
                                k["Pick-up UNLOC/ PIN"] = o[i1]['pincode']
                                k["Delivery UNLOC/ PIN"] = d[j]['pincode']
                                let l1 = Object.keys(d3[i])
                                for (let z = 0; z < l1.length; z++) {
                                    if (["Origin",
                                        "State",
                                        "Destination",
                                        "State_1"].indexOf(l1[z]) == -1) {
                                        k[l1[z]] = d3[i][l1[z]]
                                    }
                                }
                                s.push(k)
                            }
                        }
                    } else {
                        if (o.length == 0 && d.length > 0) {
                            d3[i]['Reason'] = 'Look-up failed for Origin'
                            e.push(d3[i])
                        }
                        if (o.length > 0 && d.length == 0) {
                            {
                                d3[i]['Reason'] = 'Look-up failed for Destination'
                                e.push(d3[i])
                            }
                            if (o.length == 0 && d.length == 0) {
                                d3[i]['Reason'] = 'Look-up failed for Origin,Destination'
                                e.push(d3[i])
                            }
                        }

                    }
                }
                    if (s.length > 0 || e.length > 0) {
                    

                        var clean = s.filter((arr, index, self) =>
                            index === self.findIndex((t) => (t['Pick-up UNLOC/ PIN'] === arr['Pick-up UNLOC/ PIN'] && t['Delivery UNLOC/ PIN'] === arr['Delivery UNLOC/ PIN'])))

                        s = clean
                        let kz = []
                        if(s.length>0){
                            for (let ij = 0; ij < s.length; ij++) {
                                let keys = Object.keys(s[ij])
                            let ak = []
                            let bk = []
                            keys.map(e=>{
                                if(e.indexOf('(') != -1 && e.indexOf('to') != -1 && e.indexOf(')') != -1){
                                    ak.push(e)
                                }else{
                                    bk.push(e)
                                }
                                return e
                            })
                            ak.map(e=>{
                                let by = {}
                                bk.map(e1=>{
                                    by[e1] = s[ij][e1]
                                })
                                by['Unit Price Per Container'] = s[ij][e]
                                let al = e.trim().split('(')
                                if(al.length==2){
                                    by['Container Type'] = al[0].trim()
                                    let am = al[1].trim().split('to')
                                    if(am.length==2){
                                        by['Min Weight'] = am[0].trim()
                                        let an = am[1].trim().split(')')
                                        if(an.length==2){
                                            by['Max Weight'] = an[0].trim()
                                            kz.push(by)
                                        }
                                    }
                                }
                            })
                            }
                        }
                        let kn = []
                        for (let ik = 0; ik < kz.length; ik++) {
                            let zk = ["Vendor ID",
                            "Transport Vendor Name",
                            "Country Code",
                            "Pick-up UNLOC/ PIN",
                            "Delivery UNLOC/ PIN",
                            "Container Type",
                            "Min Weight",
                            "Max Weight",
                            "Currency",
                            "Unit Price Per Container",
                            "Valid From",
                            "Valid To",
                            "Free Time",
                            "Halting Charges",
                            "Remarks"]
                            let ly = {}
                            zk.map(e=>{
                                if(kz[ik][e]!=undefined){
                                    ly[e]=kz[ik][e]
                                }else{
                                    ly[e]=""
                                }
                            })
                            kn.push(ly)
                        }
                        s = kn
                        setEdata(
                            [{
                                sheetName: "success",
                                details: s
                            }, {
                                sheetName: "failure",
                                details: e
                            }]
                        )
                        setDownload(true)
                    } else {
                        setError(true)
                    }
                    console.log(s)
                    console.log(e)
                }else {
                    setError(true)
                }
            } catch (error) {
                setError(true)
            }
            loader.hide()
        }
        const check2 = async () => {
            try {
                setDownload(false)
                setError(false)
                if (data2['PIN Master'] && data2['Transporter Tariff']) {
    
                    let d1 = data2['PIN Master']
                    let d2 = []
                    let d3 = data2['Transporter Tariff']
                    try {
                        let url = `${process.env.REACT_APP_PRICINGAPI_SERVER}/api/v1/fclpricing/getcharges/fcl_pinunloc`
                        let ret = await MonApi.aget(url)
                        if(ret && ret.length>0){
                            d2 = ret
                        }
                    } catch(error) {
                        console.log(error)
                    }
                    let a = {}
                    let b = {}
                    let s = []
                    let e = []
                    for (let i = 0; i < d1.length; i++) {
                        if (a[d1[i]['StateName'].toLowerCase().trim()] == undefined) {
                            a[d1[i]['StateName'].toLowerCase().trim()] = []
                        }
                        a[d1[i]['StateName'].toLowerCase().trim()].push(d1[i])
                    }
                    console.log(a)
                    for (let i = 0; i < d2.length; i++) {
                        if (b[d2[i]['unloc'].toLowerCase().trim()] == undefined) {
                            b[d2[i]['unloc'].toLowerCase().trim()] = []
                        }
                        b[d2[i]['unloc'].toLowerCase().trim()].push(d2[i])
                    }
                    console.log(b)
                    for (let i = 0; i < d3.length; i++) {
                        let o = []
                        let p = []
                        let p1 = []
                        let p2 = []
                        let p3 = []
                        let p4 = []
                        let p5 = []
                        let d = []
                        let u2 = false
                        if (d3[i]['State'] != undefined && a[d3[i]['State'].toLowerCase().trim()] != undefined && d3[i]['State'].toLowerCase().trim().length > 0) {
                            if (a[d3[i]['State'].toLowerCase().trim()][0]["Type"] == "Union Territory" && d3[i]['Origin'] != undefined && d3[i]['Origin'] == d3[i]['State']) {
                                let l = a[d3[i]['State'].toLowerCase().trim()]
                                for (let j = 0; j < l.length; j++) {
                                    if (p.indexOf(l[j]['Pincode']) == -1) {
                                        if(!u2) u2 = true
                                        o.push({
                                            location: l[j]['Office Name'],
                                            pincode: l[j]['Pincode']
                                        })
                                        p.push(l[j]['Pincode'])
                                    }
    
                                }
                            } else {
                                let l = a[d3[i]['State'].toLowerCase().trim()]
                                let zy = []
                                let zx = []
                                for (let j = 0; j < l.length; j++) {
                                    if (d3[i]['Origin'] != undefined) {
                                        if ((l[j]['District'].toLowerCase().trim() + ' ').indexOf(' ' + (d3[i]['Origin'].toLowerCase().trim()) + ' ') > 0 || (l[j]['District'].toLowerCase().trim() + ' ').indexOf((d3[i]['Origin'].toLowerCase().trim()) + ' ') == 0) {
                                            zx.push(l[j])
                                            if ((l[j]['Division Name'].toLowerCase().trim() + ' ').indexOf(' ' + (d3[i]['Origin'].toLowerCase().trim()) + ' ') > 0 || (l[j]['Division Name'].toLowerCase().trim() + ' ').indexOf((d3[i]['Origin'].toLowerCase().trim()) + ' ') == 0) {
                                                if ((l[j]['Region Name'].toLowerCase().trim() + ' ').indexOf(' ' + (d3[i]['Origin'].toLowerCase().trim()) + ' ') > 0 || (l[j]['Region Name'].toLowerCase().trim() + ' ').indexOf((d3[i]['Origin'].toLowerCase().trim()) + ' ') == 0) {
                                                    zy.push(l[j])
                                                    if (p.indexOf(l[j]['Pincode']) == -1) {
                                                        if(!u2) u2 = true
                                                        o.push({
                                                            location: l[j]['Office Name'],
                                                            pincode: l[j]['Pincode']
                                                        })
                                                        p.push(l[j]['Pincode'])
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                                if(zy.length==0){
                                    if(zx.length!=0){
                                        for (let j = 0; j < zx.length; j++) {
                                            if (p.indexOf(zx[j]['Pincode']) == -1) {
                                                if(!u2) u2 = true
                                                o.push({
                                                    location: zx[j]['Office Name'],
                                                    pincode: zx[j]['Pincode']
                                                })
                                                p.push(zx[j]['Pincode'])
                                            }
                                        }
                                    }else{
                                        for (let j = 0; j < l.length; j++) {
                                            if (d3[i]['Origin'] != undefined) {
                                                if ((l[j]['Office Name'].toLowerCase().trim() + ' ').indexOf(' ' + (d3[i]['Origin'].toLowerCase().trim()) + ' ') > 0 || (l[j]['Office Name'].toLowerCase().trim() + ' ').indexOf((d3[i]['Origin'].toLowerCase().trim()) + ' ') == 0) {
                                                    if (p.indexOf(l[j]['Pincode']) == -1) {
                                                        if(!u2) u2 = true
                                                        o.push({
                                                            location: l[j]['Office Name'],
                                                            pincode: l[j]['Pincode']
                                                        })
                                                        p.push(l[j]['Pincode'])
                                                    }
                                                }
                                            }
                                        }        
                                    }
                                }
                            }
    
                        }
                        if (d3[i]['Origin'] != undefined && b[d3[i]['Origin'].toLowerCase().trim()] != undefined && d3[i]['Origin'].toLowerCase().trim().length > 0) {
                            let l = b[d3[i]['Origin'].toLowerCase().trim()]
                            for (let j = 0; j < l.length; j++) {
                                if (l[j]['unloc'].toLowerCase().trim().indexOf(d3[i]['Origin'].toLowerCase().trim()) != -1) {
                                    if (p1.indexOf(l[j]['pinCode']) == -1) {
                                        if(!u2) u2 = true
                                        o.push({
                                            location: l[j]['unloc'],
                                            pincode: l[j]['unloc']
                                        })
                                        p1.push(l[j]['pinCode'])
                                    }
                                }
                            }
                        }
                        // if(!u2 && d3[i]['Origin'] != undefined && d4.indexOf(d3[i]['Origin'].toLowerCase().trim()) != -1){
                        //     if (p4.indexOf(d3[i]['Origin'].toLowerCase()) == -1) {
                        //         if(!u2) u2 = true
                        //         o.push({
                        //             location: d3[i]['Origin'],
                        //             pincode: d3[i]['Origin']
                        //         })
                        //         p4.push(d3[i]['Origin'].toLowerCase())
                        //     }
                        // }
                        u2 = false
                        if (d3[i]['State_1'] != undefined && a[d3[i]['State_1'].toLowerCase().trim()] != undefined && d3[i]['State_1'].toLowerCase().trim().length > 0) {
                            if (a[d3[i]['State_1'].toLowerCase().trim()][0]["Type"] == "Union Territory" && d3[i]['Destination'] != undefined && d3[i]['State_1'] == d3[i]['Destination']) {
                                let l = a[d3[i]['State_1'].toLowerCase().trim()]
                                for (let j = 0; j < l.length; j++) {
                                    if (p2.indexOf(l[j]['Pincode']) == -1) {
                                        if(!u2) u2 = true
                                        d.push({
                                            location: l[j]['Office Name'],
                                            pincode: l[j]['Pincode']
                                        })
                                        p2.push(l[j]['Pincode'])
                                    }
                                }
                            } else {
                                let l = a[d3[i]['State_1'].toLowerCase().trim()]
                                let zy = []
                                let zx = []
                                for (let j = 0; j < l.length; j++) {
                                    if (d3[i]['Destination'] != undefined) {
                                        if ((l[j]['District'].toLowerCase().trim() + ' ').indexOf(' ' + (d3[i]['Destination'].toLowerCase().trim()) + ' ') > 0 || (l[j]['District'].toLowerCase().trim() + ' ').indexOf((d3[i]['Destination'].toLowerCase().trim()) + ' ') == 0) {
                                            zx.push(l[j])
                                            if ((l[j]['Division Name'].toLowerCase().trim() + ' ').indexOf(' ' + (d3[i]['Destination'].toLowerCase().trim()) + ' ') > 0 || (l[j]['Division Name'].toLowerCase().trim() + ' ').indexOf((d3[i]['Destination'].toLowerCase().trim()) + ' ') == 0) {
                                                if ((l[j]['Region Name'].toLowerCase().trim() + ' ').indexOf(' ' + (d3[i]['Destination'].toLowerCase().trim()) + ' ') > 0 || (l[j]['Region Name'].toLowerCase().trim() + ' ').indexOf((d3[i]['Destination'].toLowerCase().trim()) + ' ') == 0) {
                                                    zy.push(l[j])
                                                    if (p2.indexOf(l[j]['Pincode']) == -1) {
                                                        if(!u2) u2 = true
                                                        d.push({
                                                            location: l[j]['Office Name'],
                                                            pincode: l[j]['Pincode']
                                                        })
                                                        p2.push(l[j]['Pincode'])
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                                if(zy.length==0){
                                    if(zx.length!=0){
                                        for (let j = 0; j < zx.length; j++) {
                                            if (p2.indexOf(zx[j]['Pincode']) == -1) {
                                                if(!u2) u2 = true
                                                d.push({
                                                    location: zx[j]['Office Name'],
                                                    pincode: zx[j]['Pincode']
                                                })
                                                p2.push(zx[j]['Pincode'])
                                            }
                                        }
                                    }else{
                                        for (let j = 0; j < l.length; j++) {
                                            if (d3[i]['Destination'] != undefined) {
                                                if ((l[j]['Office Name'].toLowerCase().trim() + ' ').indexOf(' ' + (d3[i]['Destination'].toLowerCase().trim()) + ' ') > 0 || (l[j]['Office Name'].toLowerCase().trim() + ' ').indexOf((d3[i]['Destination'].toLowerCase().trim()) + ' ') == 0) {
                                                    if (p2.indexOf(l[j]['Pincode']) == -1) {
                                                        if(!u2) u2 = true
                                                        d.push({
                                                            location: l[j]['Office Name'],
                                                            pincode: l[j]['Pincode']
                                                        })
                                                        p2.push(l[j]['Pincode'])
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
    
                        }
                        if (d3[i]['Destination'] != undefined && b[d3[i]['Destination'].toLowerCase().trim()] != undefined && d3[i]['Destination'].toLowerCase().trim().length > 0) {
                            let l = b[d3[i]['Destination'].toLowerCase().trim()]
                            for (let j = 0; j < l.length; j++) {
                                console.log(l[j]['unloc'].toLowerCase().trim(),d3[i]['Destination'].toLowerCase().trim())
                                if (l[j]['unloc'].toLowerCase().trim().indexOf(d3[i]['Destination'].toLowerCase().trim()) != -1) {
                                    console.log(l[j]['unloc'].toLowerCase().trim(),d3[i]['Destination'].toLowerCase().trim(),p3.indexOf(l[j]['pinCode']),p3)
                                    if (p3.indexOf(l[j]['pinCode']) == -1) {
                                        if(!u2) u2 = true
                                        d.push({
                                            location: l[j]['unloc'],
                                            pincode: l[j]['unloc']
                                        })
                                        p3.push(l[j]['pinCode'])
                                    }
                                }
                            }
                        }
                        
                        // if(!u2 && d3[i]['Destination'] != undefined && d4.indexOf(d3[i]['Destination'].toLowerCase().trim()) != -1){
                        //     if (p5.indexOf(d3[i]['Destination'].toLowerCase()) == -1) {
                        //         if(!u2) u2 = true
                        //         d.push({
                        //             location: d3[i]['Destination'],
                        //             pincode: d3[i]['Destination']
                        //         })
                        //         p5.push(d3[i]['Destination'].toLowerCase())
                        //     }
                        // }
    
    
    
                        
    console.log(o,d)
                        if (o.length && d.length > 0) {
    
                            for (let i1 = 0; i1 < o.length; i1++) {
                                for (let j = 0; j < d.length; j++) {
                                    let k = {}
                                    k["Pick-up UNLOC/ PIN"] = o[i1]['pincode']
                                    k["Delivery UNLOC/ PIN"] = d[j]['pincode']
                                    let l1 = Object.keys(d3[i])
                                    for (let z = 0; z < l1.length; z++) {
                                        if (["Origin",
                                            "State",
                                            "Destination",
                                            "State_1"].indexOf(l1[z]) == -1) {
                                            k[l1[z]] = d3[i][l1[z]]
                                        }
                                    }
                                    s.push(k)
                                }
                            }
                        } else {
                            if (o.length == 0 && d.length > 0) {
                                d3[i]['Reason'] = 'Look-up failed for Origin'
                                e.push(d3[i])
                            }
                            if (o.length > 0 && d.length == 0) {
                                {
                                    d3[i]['Reason'] = 'Look-up failed for Destination'
                                    e.push(d3[i])
                                }
                                if (o.length == 0 && d.length == 0) {
                                    d3[i]['Reason'] = 'Look-up failed for Origin,Destination'
                                    e.push(d3[i])
                                }
                            }
    
                        }
                    }
                        if (s.length > 0 || e.length > 0) {
                        var clean = s.filter((arr, index, self) =>
                                index === self.findIndex((t) => (t['Pick-up UNLOC/ PIN'] === arr['Pick-up UNLOC/ PIN'] && t['Delivery UNLOC/ PIN'] === arr['Delivery UNLOC/ PIN'])))
    
                            s = clean
                            setEdata(
                                [{
                                    sheetName: "success",
                                    details: s
                                }, {
                                    sheetName: "failure",
                                    details: e
                                }]
                            )
                            setDownload(true)
                        } else {
                            setError(true)
                        }
                        console.log(s)
                        console.log(e)
                    }else {
                        setError(true)
                    }
                } catch (error) {
                    setError(true)
                }
                loader.hide()
            }
    const startdownload = () => {
            exportToExcel(edata, 'Transporter_Tariff', true)
        }

        useEffect(() => {
            if (Object.keys(data).length > 0) {
                check()
            }
        }, [data])

        useEffect(() => {
            if (Object.keys(data1).length > 0) {
                check1()
            }
        }, [data1])
        useEffect(() => {
            if (Object.keys(data2).length > 0) {
                check2()
            }
        }, [data2])
        return (
            <>
                <div>
                    <table className="table is-bordered is-striped is-fullwidth is-hoverable ">
                        <tbody>
                            <tr id="mf1">
                                <td>
                                    <div className="title is-6">LCL Transporter Tariff</div>
                                </td>
                            {RoleUtils.isUserAuthorized(PERMISSIONS.EDIT.EDIT_UPLOADS) &&
                                <td>
                                    <AgFile2withloader callback={setData} form_data={{ shipmentType: 'LCL' }} parent="mf1"
                                        url='/api/v1/common/get_transporter_tariff' filetypes='.csv,.xlsx' />
                                </td>
                            }
                                <td><a style={{ fontSize: "12px", textDecoration: "underline" }} className="is-link" href="LCLTTUpload.xlsx" download="LCLTTUpload.xlsx">Download LCL TT Template</a></td>
                            </tr>
                            <tr id="mf2">
                                <td>
                                    <div className="title is-6">FCL Transporter Tariff</div>
                                </td>
                            {RoleUtils.isUserAuthorized(PERMISSIONS.EDIT.EDIT_UPLOADS) &&
                                <td>
                                    <AgFile2withloader callback={setData1} form_data={{ shipmentType: 'fcl' }} parent="mf2"
                                        url='/api/v1/common/get_transporter_tariff' filetypes='.csv,.xlsx' />
                                </td>
                            }
                                <td><a style={{ fontSize: "12px", textDecoration: "underline" }} className="is-link" href="FCLTTUpload.xlsx" download="FCLTTUpload.xlsx">Download FCL TT Template</a></td>
                            </tr>
                            <tr id="mf1">
                                <td>
                                    <div className="title is-6">PTL Transporter Tariff</div>
                                </td>
                            {RoleUtils.isUserAuthorized(PERMISSIONS.EDIT.EDIT_UPLOADS) &&
                                <td>
                                    <AgFile2withloader callback={setData2} form_data={{ shipmentType: 'LCL' }} parent="mf1"
                                        url='/api/v1/common/get_transporter_tariff' filetypes='.csv,.xlsx' />
                                </td>
                            }
                                <td><a style={{ fontSize: "12px", textDecoration: "underline" }} className="is-link" href="PTLTTUpload.xlsx" download="PTLTTUpload.xlsx">Download PTL TT Template</a></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                {
                    (download) ?
                        <div>
                            <br />
                            <button className="button is-success is-centered" onClick={startdownload}>Download</button>
                        </div>
                        : ""
                }
                {
                    (error) ? <h4 className="title is-6">Please Check File</h4> : ""
                }

            </>
        )
    }