import { useEffect, useState } from "react"
import { useSnapshot } from "valtio"
import { AgSelect } from "../components/AgComponents"
import { EntityV2Store, bookingsStore, configStore, entityStore } from "../state/CommonState"
import Utils from "../util/Utils"
import Otheraction from "./act-otheraction"
import TaskContainer from "./task-container"
import TaskDatetime from "./task-datetime"
import Tasklistsmall from "./task-list-small"
import { taskStore } from "./task-store"
import Taskapi, { Task } from "./tasks-api"
import { getAction, getfilenames, getActionList, getActionListNames, getActionsForShipmentType, getPayload, getTriggercomp } from "./tasks-static-data"
import BranchApi from "../state/branch-api"

export default function TaskTrigger(props) {
    useSnapshot(taskStore)
    useSnapshot(bookingsStore)
    useSnapshot(entityStore)
    useSnapshot(EntityV2Store)
    const [oldActions, setOldActions] = useState([])
    const [otherActions, setOtherActions] = useState([])
    const [currentActionlastdate, setCurrentActionlastdate] = useState("")
    const [openPending,setOpenPending] = useState(true)
    const [entities,setEntities] = useState([])
    let active = 'none'
    if (taskStore.triggerActionVisible) active = 'block'
    let b = bookingsStore.current
    let task = taskStore.current
    let action = getAction(taskStore.current.actionName)
    let Comp = action?.triggercomp
    function setaction(name){
        let current = oldActions.filter(e => e.actionName === name)
        if(current.length>0){
            let date = Utils.formatToDateTime(current[current.length-1]['completedOn'])
            if(current[current.length-1]['actionName']=="Carrier Release Order"){
                taskStore.current.actionName = ""
                configStore.setModalMessage("This action has been previously completed on: "+date)
            }else{
                configStore.setModalMyacticecompleted("This action has been previously completed on: "+date)
            }
            setCurrentActionlastdate(date)
        }else{
            setCurrentActionlastdate("")
        }
        
    }
    const showdest = (key) =>{
        console.log(key)
        if(b?.contract[key]!=undefined && b?.contract[key].length>0){
            return <>
            <hr style={{margin:"0px"}}/>
            {b?.contract[key]}
            </>
        }
    }
    useEffect(() => {
        let f = async () => {
            let ret = await Taskapi.getTasklist4Booking(b._id)
            // if(ret.status === 400){
            //     return configStore.setModalMessage(ret.message)
            // }else {
                let old = ret?.filter(e => e.status === 'COMPLETED')
                if(JSON.stringify(old)!=JSON.stringify(oldActions)){
                    setOldActions(old)
                }
                ret = ret?.filter(e => e.status === 'PENDING')
                console.log('RESULT OF BOOKINGS ACTIONS=', ret)
                if(JSON.stringify(ret)!=JSON.stringify(otherActions)){
                    setOtherActions(ret)
                }
            // }
        }
        f()
    }, [])
    if (taskStore.myActionVisible) return <TaskContainer />
    return (
        <div  style={{ display: active, width: '100%' }}>
            <div>
                <div style={{ height: '70vh' }}>
                    <div className="columns">
                        <div className="column is-7">
                            <h5 className="title is-4" style={{ color: '#2c358a', fontFamily: 'product_sans_bold' }}>
                                Action
                            </h5>
                            {
                                (currentActionlastdate.length>0?
                                <>
                                <p className="title is-6" style={{ color: '#2c358a', fontFamily: 'product_sans_bold' }}>
                                This action has been previously completed on: {currentActionlastdate}
                            </p>
                                </>:<></>)
                            }
                            <div className="box">
                            <tr>
                                <td><strong>Booking ID</strong></td>
                                <td style={{ width: '20px' }}></td>
                                <td>{b?._id}</td>
                            </tr>
                            <tr>
                                <td>{b?.contract?.origin}</td>
                                <td style={{ fontSize: '1.2rem', fontWeight: 'bold' }}> &#8594; </td>
                                <td>{b?.contract?.destination}
                                {showdest("destination2")}
                                        {showdest("destination3")}
                                        {showdest("destination4")}
                                        {showdest("destination5")}
                                </td>
                            </tr>
                            <tr>
                                <td>{b?.contract?.shipmentType}</td>
                                <td style={{ width: '20px' }}></td>
                                <td>{b?.contract?.shipmentScope}</td>
                            </tr>
                            </div>
                        </div>
                        <div className="column is-5" style={{ fontSize: '0.80rem' }}>
                            <button style={{ float: 'right' }} className="button is-small islink"
                                onClick={async (e) => {
                                    taskStore.triggerActionVisible = false
                                }}>
                                <span className="icon ">
                                    <i className="fas fa-xmark"></i>
                                </span>
                            </button>
                            <div>
                            <button className="button is-link"  style={{backgroundColor:"#2c358a"}} onClick={()=> {
    setOpenPending(!openPending)
}} >
                                Pendings
                            </button>
                            </div>
                            <hr />
                            {
                            (openPending?<Tasklistsmall actionList={otherActions} />: <></>)
                        }
                        </div>
                    </div>
                    <div className="columns">
                        <div className="column is-12">
                            <p></p><hr /><p></p>
                            <p>
                                <AgSelect label='Select an Action to trigger' record={taskStore.current} 
                                    name='actionName'
                                    dataList={getActionsForShipmentType(b, EntityV2Store.EntityRec, otherActions)}
                                    callback={(val) => {
                                        Object.assign(taskStore.current, getPayload(taskStore.current.actionName))
                                        console.log("+==",taskStore.current)
                                        if(taskStore.current && taskStore.current.files!=undefined){
                                            for (let i = 0; i < taskStore.current.files.length; i++) {
                                                taskStore.current.files[i]['filename'] = ""
                                                taskStore.current.files[i]['approved'] = ""
                                                taskStore.current.files[i]['rejectReason'] = ""
                                            }
                                        }
                                        if(taskStore.current.actionName!='DO Release (Air)'){
                                            taskStore.current.original_file = getfilenames(taskStore.current.files)
                                        }
                                        if((taskStore.current.actionName=='Pick-up Appointment' || taskStore.current.actionName=='Delivery Appointment' ) && b?.contract?.shipmentType.toLowerCase().includes("fcl")){
                                            let c = []
                                            for (let i = 0; i < b.contract.containerList.length; i++) {
                                                for (let j = 0; j < Number(b.contract.containerList[i]['numContainers']); j++) {
                                                    c.push({
                                                        container:(j+1)+". "+b.contract.containerList[i].containerType,
                                                        pickupDate: '',
                                                        pickupTimeFrom: '',
                                                        pickupTimeTo: ''
                                                    })
                                                }
                                                
                                                
                                            }
                                            taskStore.current.containersList = c

                                        }
                                        setaction(taskStore.current.actionName)
                                        
                                    }} />
                            </p>
                            <p>
                                {
                                    (Comp) ? <Comp action={action} /> : <></>
                                }
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}