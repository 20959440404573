import { useSnapshot } from "valtio"
import ListEnquiryV3 from "./list-enquiry-v3"
import AgEnquiryv3 from "./AgEnquiry-v3"
import { enquiryStore } from "../enquiry/enquiry-data"

export default function ListEnquiryMainv3(props) {
    useSnapshot(enquiryStore)
    console.log('VALUE OF LIST ENQUIRY =', enquiryStore.currentEnquiryListComponent)
    switch(enquiryStore.currentEnquiryListComponent) {
        case 'list': return <ListEnquiryV3 />
        case 'new' : return <AgEnquiryv3/>
        default: return <h1>Not Found</h1>
    }
}