import { configStore } from "../state/CommonState"
import Quotev3 from './quote-v3'
import QuoteViewv3 from './quote-view-v3'
import { useSnapshot } from "valtio"
import QuoteListv3 from "./quote-list-v3"

export default function QuoteMainv3() {
    const a = useSnapshot(configStore)

    switch(a.quoteCurrentView) {
        case 'list': return <QuoteListv3/>
        case 'view': return <QuoteViewv3 view={"view"}/>
        case 'pureView': return <QuoteViewv3 view={"pureView"}/>
        case 'edit': return <Quotev3 />
    }
}