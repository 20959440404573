import { Box, Tooltip, Typography } from "@mui/material";
import { CenteredBox } from "./CentereedBox";
import { useSnapshot } from "valtio";
import { enquiryState } from "../state/CommonState";
import { OptionBox } from "./OptionBox";
import { ShipmentScopeTag } from "./ShipmentScope";
import calender from "../assets/images/calender.svg";
import styled from "@emotion/styled";
import { enquiryStore } from "../enquiry/enquiry-data";


const Divider = styled(Box)(({theme})=>({
    width : "1px",
    borderRadius : "4px",
    height : "80%",
    backgroundColor : "#AAAAAA"
}))


export function EnquiryDetailsView({options}){


    return   <Box sx={{width:"100%",backgroundColor:'white',height:"60px",borderRadius:"8px",display:"flex",flexDirection:"row",padding:"8px",justifyContent: options ? "space-around" : "center",alignItems:"center"}}>
   {options ? <>
      {options.map((option,index)=><>
        <CenteredBox sx={{width:"max-content"}}><OptionBox title={option.title} content={<Tooltip title={option.toolTipValue ? option.toolTipValue : option.value} componentsProps={{
    tooltip: {
      sx: {
        fontSize:"16px",
        bgcolor: "white",
        color: "black",
        boxShadow:"4px 4px 20px 0px #00000033"

      }
    }
  }}><Typography sx={{ color: "#555555", fontSize: "16px", fontFamily: "Figtree",width:option.width, overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap"}}>{option.value}</Typography></Tooltip>} /></CenteredBox>
   { index!==options.length-1 && <Divider/>}
      </>)}
   </> : <>
    <CenteredBox sx={{width:"10%"}}><OptionBox title="Mode" content={<span><ShipmentScopeTag fontSize="16px" fontWeight="normal" width="38%" scope={enquiryStore.current.shipmentType} iconPlacement="start" /></span>} /></CenteredBox>
    <Divider/>
    <CenteredBox sx={{width:"12%"}}><OptionBox title="Scope" content={<Typography sx={{ color: "#555555", fontSize: "16px", fontFamily: "Figtree" }}>{enquiryStore.current.shipmentScope}</Typography>} /></CenteredBox>
    <Divider/>
    <CenteredBox sx={{width:"23%"}}><OptionBox title="Origin" content={<Typography sx={{ color: "#555555", fontSize: "16px", fontFamily: "Figtree", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", width: "200px" }}>{enquiryStore.current.enqRoute.origin}</Typography>} /></CenteredBox>
    <Divider/>
    <CenteredBox sx={{width:"23%"}}><OptionBox title="Destination" content={<Typography sx={{ color: "#555555", fontSize: "16px", fontFamily: "Figtree", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", width: "200px" }}>{enquiryStore.current.enqRoute.destination}</Typography>} /></CenteredBox>
    <Divider/>
    <CenteredBox sx={{width:"20%"}}><OptionBox title="Cargo Readiness Date" content={<span style={{ display: "flex", flexDirection: "row", gap: 1, height: "100%", width: "100%", alignItems: "center", justifyContent: "space-between" }}><img src={calender} alt="calender" /><Typography sx={{ color: "#555555", fontSize: "16px", fontFamily: "Figtree", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>{enquiryStore.current.enqRoute.cargoReadinessDate}</Typography></span>} /></CenteredBox>
    </>
   }
 </Box>
}