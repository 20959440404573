import { useEffect, useState } from "react";
import { AntSwitch } from "../mui-components/AntSwitch";
import Utils from "../util/Utils";
import { CargoTypes } from "./AgCargoTypes";
import { ControlledDetailSwitch } from "./AgControlledDetailSwitch";
import { MultiCargoDetails } from "./AgMultiCargoDetails";
import cargoDetail from "../assets/images/worldwide-shipping.svg";

export function CargoDetails({form,viewonly}){
    const { control, watch,getValues,setValue } = form;
    const Mode = watch("mode")
    let multidest = getValues("multidest")?getValues("multidest"):watch("multidest")
    let containerDetails = getValues("containerDetails")?getValues("containerDetails"):watch("containerDetails")
    const mode = Mode.toLowerCase();
    const Scope = watch("scope")
    const stuffingType = watch("stuffingType")
    const destuffingType = watch("destuffingType");
    const isFBA = watch("isFba")
    const dimensionsUnit = watch("cargoDimensionUnit");
    const [total,setTotal] = useState({ totNumPackages : 0,
        totTotalWeight : 0,
        totTotalVolume : 0,
        totChargeableWeight : 0})
    const obj = {
        "stuffingType":stuffingType,
        "destuffingType":destuffingType,
        "shipmentType":mode,
        "shipmentScope":Scope
    }
    const [showAdvancedCargoDetails,setAdvancedCargoDetails] = useState(false);
    const [changeToDimension,setChangeToDimension] = useState(false);
    let isDimension = false;
    if ((mode === "lcl" && Scope.includes("Door")) || mode === "air" || mode === "fcl" || changeToDimension ) {
        isDimension = true;
    }
    useEffect(()=>{
        if(mode==="lcl" && Scope==="Port-to-Port"){
            setAdvancedCargoDetails(true);
        }else{
            setAdvancedCargoDetails(false);
        }
    },[Mode,Scope])
    useEffect(()=>{
        let totals = Utils.calcCargoDetailsGrand(multidest,isDimension,dimensionsUnit,mode);
        if(JSON.stringify(totals)!=JSON.stringify(total)){
            setTotal({...totals});
         }  
    },[JSON.stringify(multidest),dimensionsUnit,isDimension,mode])
    useEffect(()=>{
        if(isFBA==true){
            if(multidest!=undefined){
                for (let i = 0; i < multidest.length; i++) {
                    if(multidest[i]['cargoDetails']!=undefined){
                        for (let j = 0; j < multidest[i]['cargoDetails'].length; j++) {
                            let pk = multidest[i]['cargoDetails'][j]['packageType']
                            if(pk!=undefined && pk!="" && ["Pallet","Loose Cartons"].indexOf(pk) == -1){
                                setValue(`multidest.${i}.cargoDetails.${j}.packageType`,"")
                            }
                        }
                    }
                }
            }
        }
        
    },[isFBA])
    let nonstackable = watch("nonStackable");
    const outGuage = watch("showCargoDetailsOutGuage");
    const showStackForFcl = ()=>{
        if(Mode=="FCL"){
            let origin = Scope.split("-")[0];
           
            let destination = Scope.split("-")[2];
            
                if((origin?.toLowerCase()==="door" && stuffingType.toLowerCase() === "dock" ) || (destination?.toLowerCase()==="door" && destuffingType.toLowerCase() === "dock" ) || (Scope!=="Port-to-Port" && stuffingType.toLowerCase() === "dock" && destuffingType.toLowerCase() === "dock") || outGuage){
                   return true
                }
                return false
        }else{
            return false
        }
        
    }
    const generateId = (id,i)=> {
        if(id==undefined){
            let a = i + Date.now() + '-' + Math.floor(Math.random() * 1000)
            multidest[i].id = a
            return a
        }else{
            return id
        }
    }
    return  <div className="flex flex-col w-full rounded-md bg-white shadow-custom p-[18px] gap-[18px]">
    <div className="flex flex-row justify-between items-center">
    <div className="flex flex-row gap-[10px]">
         <img src={cargoDetail}/>
         <p className="text-black font-bold">{Utils.labelForOutGauge1(obj,containerDetails)?"Cargo Details for Out-Gauge Containers":"Cargo Details"}</p>
      </div>
     {/* {showAdvancedCargoDetails && <div className="flex flex-row gap-1 items-center">
        <p className="text-black font-roboto">Advanced cargo details</p>
        <AntSwitch disabled={viewonly} checked={changeToDimension} onChange={(e)=>{
                setChangeToDimension(e.target.checked);
        }} />
      </div>} */}
      </div>
      {showStackForFcl() && <ControlledDetailSwitch disable={viewonly} control={control} isChecked={nonstackable} formKey={"nonStackable"} title={"Non-Stackable"}/> }
     { mode.toLowerCase() !== "fcl" && <CargoTypes form={form} viewonly={viewonly} />}
     {multidest && multidest.map((m,i)=>{
        return <MultiCargoDetails key={generateId(m.id,i)} form={form} viewonly={viewonly} ind={i} changeToDimension={changeToDimension}/>
     })}
     {
        multidest.length>1 && <><hr style={{margin:"0px"}}/><div className="flex flex-row justify-between items-center w-full">
        <div className="flex flex-row gap-2.5"><p className="text-blue-dark font-bold font-barlow">Grand Total Weight:</p><p className="text-yellow font-bold font-barlow">{isNaN(total.totTotalWeight)?0:total.totTotalWeight} Kgs</p></div>
         {/* { !(mode==="air") &&  */}
         <div className="flex flex-row gap-2.5"><p className="text-blue-dark font-bold font-barlow">Grand Total Volume:</p><p className="text-yellow font-bold font-barlow">{isNaN(total.totTotalVolume)?0:total.totTotalVolume} CBM</p></div>
         {/* }
         { mode==="air" && <div className="flex flex-row gap-2.5"><p className="text-blue-dark font-bold font-barlow">Grand Chargable Weight:</p><p className="text-yellow font-bold font-barlow">{isNaN(total.totTotalVolume)?0:total.totTotalVolume} Kgs</p></div>} */}
        </div></>
     }
     
    </div>
}