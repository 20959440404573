import { useSnapshot } from "valtio";
import Utils from "../util/Utils";
import { enquiryStore } from "../enquiry/enquiry-data";
import { AttributeDetails } from "./AttributeValueBox";
import { Box, Typography } from "@mui/material";
import { TableView } from "./TableView";
import { TableHeading } from "../v3-componets/AgTableHeading";

export function ViewCargoDetails(){
    const enquiry = useSnapshot(enquiryStore);
    let calculatedCargo; 
    let cargoDetails = enquiry.current.cargoDetails;
    let isAir = enquiry.current.shipmentType.toLowerCase()==="air";
    let isFcl = enquiry.current.shipmentType.includes("FCL");

    

    if(isAir){
        calculatedCargo = Utils.AirCargoCalculation(cargoDetails);
    }else{
        calculatedCargo =  Utils.CalculateCargo(cargoDetails,enquiry.current.shipmentScope.includes("Door")); 
    }
    console.log("the calculated cargo is ",calculatedCargo)
    let finalCargoDetails = [
        {title:"Total Quantity",value:calculatedCargo.quantity.toString()},
        {title:"Total Weight", value:`${calculatedCargo.totalWeight} ${ isAir ? enquiry.current.cargoDetails[0].weight.unit : enquiry.current.cargoDetails[0]?.totalWeight?.unit}`},        
        {title: "Total Volume",value:`${calculatedCargo.totalVolume} ${enquiry.current.cargoDetails[0]?.totalVolume?.unit}`},
    ];

    if(enquiry.current.shipmentScope.includes("Door") || isAir){
        finalCargoDetails.push({title:"Dimensions",value:`${calculatedCargo?.dimensions?.totalLength} x ${calculatedCargo?.dimensions?.totalWidth} x ${calculatedCargo?.dimensions?.totalHeight} ${enquiry.current.cargoDetails[0]?.dimensions?.unit}`})
    }

    finalCargoDetails.push({title:"Hazardous",value:enquiry.current.hazardous},)
    finalCargoDetails.push({title:"Non-Stackable",value:enquiry.current.nonStackable})

    if(isFcl||isAir){
        finalCargoDetails.push({title:"Temp. Controlled",value:enquiry.current.temperatureControlled})
    }
    if(isFcl){
        finalCargoDetails.push({title:"Stuffing Type",value:enquiry.current.stuffingType})
        finalCargoDetails.push({title:"De-Stuffing Type",value:enquiry.current.destuffingType})
    }
    console.log("the final cargo detail is ",finalCargoDetails)
    return <Box sx={{paddingX:"10px"}}> <AttributeDetails details={finalCargoDetails} /></Box>
}

const destinationVehicles = [
    {label :"Vehicle Number",key:"vehicle",type:"string"},
    {label :"Loaded at CFS",key:"loaded_cfs",type:"date"},
    {label :"Cargo Picked-up for Delivery",key:"atdfrompod",type:"date"},
    {label :"Estimated Delivery Date",key:"estimate_delivery_date_to_consignee",type:"date"},
    {label :"Actual Delivery Date",key:"actual_delivery_date",type:"date"},
]

export function ENquiryViewContainerDetail(){
    const enquiry = enquiryStore;
    let containerDetail = enquiry.current.containerDetails;

    return containerDetail.map((detail)=>{
        let containerDetails = [];
        containerDetails.push({label:"Number of Containers",value:detail.numContainers})
        containerDetails.push({label:"Container Type",value:detail.containerType})
        containerDetails.push({label:"Weight per Containers",value:detail.wtPerContainer})
        return <Box sx={{display:"flex",flexDirection:"column",gap:0.5,paddingX:"10px"}}>
        <Typography sx={{fontFamily:'Figtree',fontSize:"16px",color:"#555555",fontWeight:"bold"}}>{detail.packageType}</Typography>
            <AttributeDetails details={containerDetails.map((cargoDetail)=>({title:cargoDetail.label,value:cargoDetail.value}))} />
    </Box>
    })
}

export function ViewCargoDetails3({cargoDetails}){
   const enquiry = enquiryStore;
   let isAir = enquiry.current.shipmentType.toLowerCase()==="air";
   let isLcl = enquiry.current.shipmentType.toLowerCase()==="lcl";
   let isFcl = enquiry.current.shipmentType.includes("FCL");
   let displayCargo4fcl = Utils.outGaugeContainerCheck(enquiry.current.containerDetails)
   let isDimension = isAir || (isFcl && enquiry.current.shipmentType.split("/")[0].includes("Dock") && enquiry.current.stuffingType==="Dock") || ( isFcl && enquiry.current.shipmentType.split("/")[1].includes("Dock") && enquiry.current.destuffingType==="Dock") || (isLcl && enquiry.current.shipmentScope.includes("Door") ||displayCargo4fcl)

   return (cargoDetails.map((detail)=>{
    let cargoDetailsView = [];
    cargoDetailsView.push({label:"Package Type",value:detail.packageType})
    cargoDetailsView.push({label:"Quantity",value:detail.quantity})
   if(isLcl){
    cargoDetailsView.push({label:"Total Weight",value:detail.totalWeight.value+ " " + detail.totalWeight.unit}) 
    cargoDetailsView.push({label:"Total Volume",value:detail.totalVolume.value?.toFixed(3)+" "+  detail.totalVolume.unit})
   }

   
    if(isDimension){
        cargoDetailsView.push({label:"Weight per Package",value:detail.weight.value+ " "+ detail.weight.unit})
        cargoDetailsView.push({label:"Dimensions",value:`${detail.dimensions.length}x${detail.dimensions.width}x${detail.dimensions.height}`+ ` ` + enquiry.current.cargoDimensionUnit})
    }
return <Box sx={{display:"flex",flexDirection:"column",marginBottom:"10px",paddingX:"10px"}}>
{/* <Typography sx={{fontFamily:'Figtree',fontSize:"16px",color:"#555555",fontWeight:"bold"}}>{detail.packageType}</Typography> */}
    {/* <AttributeDetails details={cargoDetailsView.map((cargoDetail)=>({title:cargoDetail.label,value:cargoDetail.value}))} /> */}
    <TableView rows={cargoDetailsView.map((cargoDetail)=>({title:cargoDetail.label,value:cargoDetail.value}))} />
</Box>}))
}

export function MultiDestCargoView({multiDest}){
    return <div className="flex flex-col w-full gap-2">
        {multiDest.map((destination,i)=><div className="flex flex-col">
            <div className="flex flex-row mx-[10px] h-[35px] items-center border-[0.5px] border-solid px-[12px] border-[#D4D4D4] border-b-0">
            <p className=" text-blue-dark font-bold font-barlow">{`Destination ${i+1} : `}</p>
                <p>{destination.destination}</p>
            </div>
            <ViewCargoDetails3 cargoDetails={destination.cargodetails} />
        </div>)}
    </div>
}

export function ViewCargoDetails2(){
    const enquiry = enquiryStore;
    let cargoDetails = enquiry.current.cargoDetails;
    let isAir = enquiry.current.shipmentType.toLowerCase()==="air";
    let isLcl = enquiry.current.shipmentType.toLowerCase()==="lcl";
    let isFcl = enquiry.current.shipmentType.includes("FCL");
    let displayCargo4fcl = Utils.outGaugeContainerCheck(enquiry.current.containerDetails)
    let isDimension = isAir || (isFcl && enquiry.current.shipmentType.split("/")[0].includes("Dock") && enquiry.current.stuffingType==="Dock") || ( isFcl && enquiry.current.shipmentType.split("/")[1].includes("Dock") && enquiry.current.destuffingType==="Dock") || (isLcl && enquiry.current.shipmentScope.includes("Door") ||displayCargo4fcl)
    return (cargoDetails.map((detail)=>{
        let cargoDetailsView = [];
        cargoDetailsView.push({label:"Package Type",value:detail.packageType})
        cargoDetailsView.push({label:"Quantity",value:detail.quantity})
       if(isLcl){
        cargoDetailsView.push({label:"Total Weight",value:detail.totalWeight.value+ " " + detail.totalWeight.unit}) 
        cargoDetailsView.push({label:"Total Volume",value:detail.totalVolume.value?.toFixed(3)+" "+  detail.totalVolume.unit})
       }

       
        if(isDimension){
            cargoDetailsView.push({label:"Weight per Package",value:detail.weight.value+ " "+ detail.weight.unit})
            cargoDetailsView.push({label:"Dimensions",value:`${detail.dimensions.length}x${detail.dimensions.width}x${detail.dimensions.height}`+ ` ` + detail.dimensions.unit})
        }
    return <Box sx={{display:"flex",flexDirection:"column",marginY:"10px",paddingX:"10px"}}>
    <Typography sx={{fontFamily:'Figtree',fontSize:"16px",color:"#555555",fontWeight:"bold"}}>{detail.packageType}</Typography> 
        <AttributeDetails details={cargoDetailsView.map((cargoDetail)=>({title:cargoDetail.label,value:cargoDetail.value}))} />
        
</Box>}))
}