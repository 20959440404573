import { Box, Button, Checkbox, Collapse, Dialog, DialogActions, DialogContent, Typography } from "@mui/material";
import { EnquiryDetailsView } from "../mui-components/EnquiryDetailsView";
import { PricingDetailsCard } from "../mui-components/PricingDetailsCard";
import { AntSwitch } from "../mui-components/AntSwitch";
import { bookingsStore, breadCrumbStore, configStore, contractsStore, enquiryState, EntityV2Store, filtersState, loginStore, newDashboardState } from "../state/CommonState";
import { useEffect, useRef, useState } from "react";
import arrowdown from "../assets/images/arrowdown.svg";
import routeIcon from "../assets/images/route.svg"
import closeIcon from "../assets/images/close.svg";
import { CustomStyledTextField } from "../mui-components/ColoredTextfield";
import Utils from "../util/Utils";
import { useSnapshot } from "valtio";
import { enquiryStore } from "../enquiry/enquiry-data";
import { BookingContainerDetail } from "./BookingContainerDetail";
import { ENquiryViewContainerDetail, ViewCargoDetails2 } from "../mui-components/ViewCargoDetails";
import { VehicleDetail, VehicleDetailsViewForQuoation } from "./VehicleDetails";
import { LCLBookingPricingDetail } from "./LCLBookingPricingDetail";
import { AIRBookingPricingDetail } from "./AIRBookingPricingDetail";
import { FCLBookingPricingDetail } from "./FCLBookingPricingDetail";
import Api from "../state/Api";
import { LoaderButton } from "../mui-components/LoaderButton";
import { MuiPopup } from "../mui-components/MuiPopUp";
import EntityV2Api from "../state/entities-api";
import AguserApi from "../state/AguserApi";
import { rtpStore } from "../pricing/pricing-store";
import { ENTITY_STATUS } from "../util/constants";
import { CopyText } from "../mui-components/CopyText";
import { loader } from "../util/loader";
import { AgLoader } from "../components/ag-loader/ag-loader";
import { TruckLoader, truckLoader } from "../mui-components/TruckLoader";
import { ScheduleGraph } from "../mui-components/ScheduleGraph";
import { ScheduleCard, Transit } from "../mui-components/ScheduleCard";
import { ScheduleGraph2 } from "../mui-components/ScheduleGraph2";
import schLogoMap from "../enquiry/carrier-logo";
import BookingsApi from "../state/bookings-api";
import EntityCreWarnPopup from "../mui-components/EntityCreWarnPopup";
import EntityApi from "../state/entities-api";
import { HorizontalDashedLine } from "../mui-components/HorizontalDashedLine";
import { VerticalDashedLine } from "../mui-components/VerticalDashedLine";
import destinationIcon from "../assets/images/destinationicon.svg"; 
import ship from "../assets/images/ship-1.svg";
import { commodityList } from "../StaticData";
import { commodityType } from "../util/commodityType";

function CargoAttributeBox({ title, value }) {
    return <Box sx={{ display: "flex", flexDirection: "column", gap: 0.5, width: "150px" }}>
        <Typography sx={{ fontFamily: "Figtree", fontSize: "12px", fontWeight: "bold", color: "#2A6ED4" }}>{title}</Typography>
        <Typography sx={{ fontFamily: "Figtree", fontSize: "12px", color: "#555555" }}>{value}</Typography>
    </Box>
}

function ServiceSwitch({ title, checked = false }) {
    return <span style={{ display: "flex", flexDirection: "row", gap: 8, alignItems: "center" }}><Typography sx={{ fontFamily: "Figtree", color: "#555555", fontSize: "14px" }}>{title}</Typography><AntSwitch disabled={true} checked={checked} style={{ scale: "0.8" }} /></span>
}

const PriceDetailsFCL = ["Your Account", "Shipper's Account"];

function FclPricingDetails() {
    const [selected, setSelect] = useState(0);
    return <Box sx={{ display: "flex", flexDirection: "column", width: "40%", height: "100%", gap: 1, overflowY: "auto" }}>
        {PriceDetailsFCL.map((priceDetail, i) => <Box sx={{ display: "flex", flexDirection: "column", borderRadius: "5px", backgroundColor: 'white' }}>
            <PricingDetailsCard title={priceDetail} isCollapsable={true} isOpen={selected === i} onClick={() => {
                if (selected === PriceDetailsFCL.length - 1) {
                    setSelect(0)
                } else {
                    setSelect(1)
                }
            }} />
        </Box>
        )}
    </Box>
}

function BookingConfirmationDialog({ open, handleClose }) {
    return <Dialog open={open}>
        <DialogContent sx={{ backgroundColor: "#F5F5F5" }}>
            <Box sx={{ display: "flex", flexDirection: 'column' }}>
                <Box onClick={() => {
                    handleClose();
                }} sx={{
                    display: "flex", alignSelf: "end", justifyContent: "center", alignItems: "center", backgroundColor: "#2B6ED4", borderRadius: "5px", width: "max-content", padding: "8px", "&:hover": {
                        cursor: "pointer"
                    }
                }}>
                    <img style={{ width: "18px", height: "18px" }} src={closeIcon} />
                </Box>
                <Box sx={{ display: "flex", flexDirection: 'column', gap: 2, padding: "10px 20px" }}>
                    <Box sx={{ display: "flex", flexDirection: 'column', alignItems: "center", gap: 1 }}>
                        <Typography sx={{ fontWeight: "bold", fontFamily: "Figtree", color: "black" }}>Your Booking has been placed successfully</Typography>
                        <Typography sx={{ fontWeight: "bold", fontFamily: "Figtree", color: "black" }}>Booking Id</Typography>
                    </Box>
                    <Box sx={{ display: "flex", flexDirection: 'column', alignItems: "start", gap: 1 }}>
                        <Typography sx={{ fontFamily: "Figtree", color: "#555555" }}>Enter Customer Reference</Typography>
                        <CustomStyledTextField size="small" fullWidth />
                    </Box>
                </Box>
            </Box>
        </DialogContent>
        <DialogActions sx={{ backgroundColor: "#F5F5F5" }}>
            <Button onClick={() => {
                handleClose();
            }} variant="contained" sx={{
                color: "#2B6ED4", textTransform: "capitalize",
                fontFamily: "Figtree", backgroundColor: "white", "&:hover": {
                    backgroundColor: "white"
                }
            }}>Add Later</Button>
            <Button variant="contained" sx={{
                textTransform: "capitalize",
                fontFamily: "Figtree"
            }}>Save</Button>
        </DialogActions>
    </Dialog>
}

function CalculateContainer(containerdetails) {
    const containerSummary = { containers: [], wtPerContainer: 0 }

    containerdetails.forEach((container) => {
        containerSummary.containers.push(`${container.numContainers} x ${container.containerType}`)
        containerSummary.wtPerContainer += parseInt(container.wtPerContainer)
    })

    containerSummary.wtPerContainer = Utils.R(
        containerSummary.wtPerContainer
    );
    return containerSummary;
}



const bookingDetailView = {
    "Container Details": <ENquiryViewContainerDetail />,
    "Cargo Details": <ViewCargoDetails2 />,
    "Vehicle Details": <VehicleDetailsViewForQuoation />
}



export function TermsConditionPopup({ tc, open, setClose }) {
    return <MuiPopup width="80%" height={600} open={open} >
        <Box sx={{ display: "flex", height: "100%", width: "100%", flexDirection: "column", alignItems: "end", gap: 2 }}>
            <Box sx={{
                display: "flex", flex: 1, flexDirection: "column", overflow: "auto", gap: 1, paddingTop: "20px", paddingRight: "10px", "&::-webkit-scrollbar-thumb": {
                    backgroundColor: "#2A6ED4 !important"
                }
            }}>
                {tc.map((terms, i) => <Typography sx={{ fontFamliy: "Figtree", color: "black" }}>{i + 1}. {terms}</Typography>)}
            </Box>
            <Button sx={{ fontFamily: "Figtree", textTransform: "capitalize" }} variant="contained" onClick={() => {
                setClose();
            }}>Close</Button>
        </Box>
    </MuiPopup>
}

// export function PricingDetailsPage(){
//     useSnapshot(breadCrumbStore);
// //  useSnapshot(truckLoader)
//     const {selectedSchedule} = breadCrumbStore.state
//     const [open,setOpen] = useState(false);
//     const [checked,setChecked] = useState(false)
//     const [openTerms,setOpenTerms] = useState(false);
//     const [disabled,setDisabled] = useState(false)
//     const [openContract,setOpenContract] = useState(false);
//     const [disableBookNow,setDisableBookNow] = useState(false);
//     const enquiry = useSnapshot(enquiryStore);
//     const contractNumber = useRef()
//     let mode = enquiry.current.shipmentType.toLowerCase();
//     let calculatedCargo; 
//     useEffect(()=>{
//         if(disabled||disableBookNow){
//            truckLoader.open = true
//         }else{
//            truckLoader.open = false
//         }
//     },[disabled,disableBookNow])
//     if(mode==="air"){
//         calculatedCargo = Utils.AirCargoCalculation(enquiry.current.cargoDetails);
//     }else{
//         calculatedCargo =  Utils.CalculateCargo(enquiry.current.cargoDetails); 
//     }
//     const vehicles = Utils.VehicleAggregation(enquiry.current.vehicles);
//     const calculatedContainers = CalculateContainer(enquiry.current.containerDetails);
//     const requiredDetailsForTheGivenBookingScope = Utils.getRequiredDetailsForGivenShipmentScope(); 
//     return <Box sx={{ height: "100%", width: "100%", display: "flex", flexDirection: "column", gap: 1 }}>
//        <TruckLoader/>
//    <EnquiryDetailsView options={[{ title: "Mode", value: enquiryStore.current.shipmentType }, { title: "Scope", value: enquiryStore.current.shipmentScope }, { title: "Origin", value: enquiryStore.current.enqRoute.origin, width:"320px" }, { title: "Destination", value: enquiryStore.current.enqRoute.destination, width:"320px" }, { title: "Cargo Readiness", value: Utils.formatDateBooking(enquiryStore.current.enqRoute.cargoReadinessDate) }]} />
//     <Box sx={{display:'flex',flexDirection:"row",width:"100%",gap:1,height:"80%"}}>
//         <Box sx={{width:"60%",height:"100%",display:"flex",flexDirection:"column",gap:1}}>  
//             <Box sx={{backgroundColor:"white",borderRadius:"10px",height:"75%",overflow:"auto",width:"100%",display:"flex",flexDirection:"column",justifyContent:"start",padding:"14px", "&::-webkit-scrollbar-thumb": {
//             backgroundColor: "#2A6ED4 !important"
//         }}}>
//                 <>
//                 {requiredDetailsForTheGivenBookingScope.map((booking)=>{
//                     return <Box sx={{display:"flex",flexDirection:"column",justifyContent:'start'}}>
//                         <Typography sx={{fontFamily :"Figtree",fontWeight:"bold",color:"black"}}>{booking}</Typography>
//                         <span style={{padding:"8px",gap:1.5,display:"flex",flexDirection:"column"}}>{bookingDetailView[booking]}</span>
//                     </Box>;
//                 })}
//                 </>
//             {/* { enquiryState.mode.toLowerCase() === "fcl" && <Box sx={{display:"flex",flexDirection:"column",gap:1}}>
//             <Typography sx={{fontFamily:"Figtree",fontWeight:"bold",fontSize:"14px",color:"black"}}>Container Details</Typography>
//             <Box sx={{display:"flex",flexDirection:"row",width:"100%",justifyContent:"start",flexWrap:"wrap",rowGap:1}}>
//             <CargoAttributeBox title="Container Type" value={calculatedContainers.containers.join(", ")} />
//                         <CargoAttributeBox title="Total Volume" value={`${calculatedContainers.wtPerContainer} ${enquiry.current.containerDetails[0]?.unit}`} />
//             </Box>
//             </Box> }
//                 <Box sx={{display:"flex",flexDirection:"column",gap:1}}>
//                     <Typography sx={{fontFamily:"Figtree",fontWeight:"bold",fontSize:"14px",color:"black"}}>Cargo Details</Typography>
//                     <Box sx={{display:"flex",flexDirection:"row",width:"100%",justifyContent:"space-between",flexWrap:"wrap",rowGap:0.8}}>
//                         { mode === "air" && <CargoAttributeBox title="Package Type" value={calculatedCargo?.productType?.join(", ")} />}
//                         <CargoAttributeBox title="Total Quantity" value={calculatedCargo.quantity} />
//                         <CargoAttributeBox title="Total Weight" value={`${calculatedCargo.totalWeight} ${ mode === "air" ? enquiry.current.cargoDetails[0].weight.unit : enquiry.current.cargoDetails[0]?.totalWeight?.unit}`} />
//                        { mode === "air" ? <CargoAttributeBox title="Dimensions" value={`${calculatedCargo?.dimensions?.totalLength} x ${calculatedCargo?.dimensions?.totalWidth} x ${calculatedCargo?.dimensions?.totalHeight} ${enquiry.current.cargoDetails[0]?.dimensions?.unit}`} /> :  <CargoAttributeBox title="Total Volume" value={`${calculatedCargo.totalVolume} ${enquiry.current.cargoDetails[0]?.totalVolume?.unit}`} />}
//                         <CargoAttributeBox title="Hazardous" value={enquiry.current.hazardous} />
//                         <CargoAttributeBox title="Non-Stackable" value={enquiry.current.nonStackable} />
//                         { (mode === "fcl" || mode === "air" ) && <>
//                         <CargoAttributeBox title="Temp. Controlled" value={enquiry.current.temperatureControlled} />
//                         </>
//                         }
//                        {  mode === "fcl" && <><CargoAttributeBox title="Stuffing Type" value={enquiry.current.stuffingType} />
//                         <CargoAttributeBox title="De-Stuffing Type" value={enquiry.current.destuffingType} />
//                         </>}
//                     </Box>
//                 </Box>
//                 <Box sx={{display:"flex",flexDirection:"column",gap:1}}>
//                     <Typography sx={{fontFamily:"Figtree",fontWeight:"bold",fontSize:"14px",color:"black"}}>Vehicle Details</Typography>
//                     <Box sx={{display:"flex",flexDirection:"row",flexWrap:"wrap",justifyContent:"start"}}>
//                         {vehicles.map((vehicle)=> <Typography sx={{fontFamily:"Figtree",color:"#555555",flexBasis:"150px",fontSize:"12px"}}>{vehicle}</Typography>)}
//                         </Box> 
//                 </Box> */}
//             </Box>
//             <Box sx={{backgroundColor:"white",borderRadius:"10px",height:"25%",width:"100%",flexDirection:"column",display:"flex",justifyContent:"space-evenly",paddingX:"12px"}}>
//               <Typography sx={{fontFamily:"Figtree",fontWeight:"bold",fontSize:"14px",color:"black"}}>Added Services</Typography>
//               <Box sx={{display:"flex",flexDirection:"row",justifyContent:"space-around",alignItems:"center"}}>
//                 <ServiceSwitch checked={true} title="Marine Finance" />
//                 <ServiceSwitch title="Trade Finance" />
//                 <ServiceSwitch title="Ship-Now-Pay-Later" />
//               </Box>
//             </Box>
//         </Box>
//         <Box sx={{minWidth:"40%",maxWidth:"40%",display:"flex",flexDirection:"column"}}>
//         { enquiryStore.current.shipmentType.indexOf("LCL")>=0 && <LCLBookingPricingDetail contract={selectedSchedule.dummyContract} isAccordionView={true}/>}
//         { enquiryStore.current.shipmentType.indexOf("Air")>=0 && <AIRBookingPricingDetail contract={selectedSchedule.dummyContract} isAccordionView={true}/>}
//         { enquiryStore.current.shipmentType.indexOf("FCL")>=0 && <FCLBookingPricingDetail contract={selectedSchedule.dummyContract} isAccordionView={true}/>}
//         </Box>

//         {/* { enquiryState.mode.toLowerCase() === "fcl" ?  <FclPricingDetails/> : <Box sx={{width:"40%",height:"100%",borderRadius:"10px",padding:"8px",backgroundColor:"white"}}>
//       <PricingDetailsCard/>
//     </Box>} */}
//     </Box>
//     <Box sx={{display:"flex",flexDirection:'row',width:"100%",justifyContent:"space-between",flex:1,alignItems:"center"}}>
//       <Box  sx={{display:"flex",flexDirection:"row",gap:1,alignItems:"center"}}><Checkbox checked={checked} onChange={(e)=>{
//           setChecked(e.target.checked)
//       }} sx={{padding:0}} /><span onClick={()=>{
//         setOpenTerms(true)
//   }} style={{fontSize:"14px",cursor:"pointer"}}>I Accept Terms & Conditions</span></Box>
//       <Box sx={{display:"flex",flexDirection:"row",gap:1}}>
//         <LoaderButton disableColor="white" progressColor="#1565c0" progressSize={20} fullWidth={false} enableLoader={disabled} buttonContent="Save Contract" onClick={async () => {
//                                    if(checked){
//                                     try {
//                                         setDisabled(true)
//                                         let currentSchedule = {...breadCrumbStore.state.selectedSchedule};
//                                     currentSchedule.quote.quoteSummary.entityId = configStore.currentSelectedEntity.entityId;
//                                     currentSchedule.quote.quoteData.entityId = configStore.currentSelectedEntity.entityId;
//                                     breadCrumbStore.state.selectedSchedule.dummyContract.entityId = configStore.currentSelectedEntity.entityId;
//                                     await Api.saveQuoteAndContract(currentSchedule,currentSchedule?.dummyContract?.schedules?.length === 0,(contractId)=>{
//                                         setOpenContract(true);
//                                         contractNumber.current = contractId
//                                     } )
//                                     setDisabled(false)
//                                     } catch (error) {
//                                         configStore.setModalMessage("Something went wrong")
//                                         setDisabled(false)
//                                     }
//                                    }else{
//                                     configStore.setModalMessage("Accept terms and conditions to proceed further")
//                                    }
//                                 }} variant="contained" size="small" sx={{backgroundColor:"white",color:"#2B6ED4",textTransform:"capitalize",width:"130px",fontSize:"12px","&:hover":{
//                                     backgroundColor:"white"
//                                 }}}/>
//         <Box><LoaderButton buttonContent="Book now" progressSize={20}  enableLoader={disableBookNow} 
//         onClick={async()=>{
//             // setOpen(true);
//             const rec = {...selectedSchedule.dummyContract};
//             rec.entityId = configStore.currentSelectedEntity.entityId;
//             breadCrumbStore.state.selectedSchedule.dummyContract.entityId = rec.entityId;
//             setDisableBookNow(true)
//             EntityV2Api.viewEntity(rec.entityId, (data) => {
//                 if (data.status === ENTITY_STATUS.DEACTIVATED) {
//                     configStore.setModalMessage(`Entity ${data.entityName} is Deactivated , please Activate it for Procced to booking `);

//                     return;
//                 }
//                 else if (data.entityCountry.toLowerCase() === 'india' && (data.branch[0].gst.length < 1 || data.branch[0].branchAddresses[0].address < 1)) {
//                     if (loginStore.isClient()) {
//                         let dataObject = {
//                             cb: data?.customer?.crossBorder?.relationshipManager,
//                             dt: data?.customer?.domesticTransport?.relationshipManager
//                         }

//                         AguserApi.getProfile(dataObject, (data) => {
//                             let message = "Please update your KYC before proceeding for the Booking. Please reach out to your Relationship Manager if you face any challenges "

//                             if (data?.CB_User || data?.DT_User) {
//                                 let user = data?.CB_User || data?.DT_User
//                                 message += `${user?.firstName} on ${user?.phonePrefix} ${user?.phone} or ${user?.email}`
//                             } else if (data?.PAT_User) {
//                                 message += data?.PAT_User?.email
//                             }

//                             configStore.setModalMessage(message)
//                             return;
//                         })
//                     } else {
//                         configStore.setModalMessage('Please Update at least one GST and address to place the Booking')
//                     }
//                 }
//                 else if (data.entityCountry.toLowerCase() !== 'india' && data.tinNum.length < 1) {
//                     configStore.setModalMessage('Please Update at least tin Number to place the Booking')
//                 }
//                 else {
//                     rtpStore.routeSubscript = breadCrumbStore.state.subscript
//                     bookingsStore.initialize()
//                     bookingsStore.current.schedule = breadCrumbStore.state.theSchedule
//                     bookingsStore.current.graphNodes = breadCrumbStore.state.graphNodes
//                     let rec1 = { ...rec }
//                     //rec1.tc.push(...rec.temptc)
//                     bookingsStore.current.contract = { ...rec }
//                     //bookingsStore.current.contract.temptc = []
//                     let filteredCharges = bookingsStore.current.contract.chargesList
//                     /*if (rec.oih && rtpStore.shipperpda == ' ') {
//                         filteredCharges = filteredCharges.filter(e => e.chargeDescription != 'Origin Inland Haulage')
//                         bookingsStore.current.contract.quoteValueDeduction = rec.oih
//                         bookingsStore.current.contract.oih = 0
//                     }*/
//                     if (rec.oih && rtpStore.shipperpda == ' ') {
//                         bookingsStore.current.contract.oih = rec.oih
//                     } else {
//                         bookingsStore.current.contract.oih = 0
//                     }
//                     bookingsStore.current.contract.chargesList = [...filteredCharges]
//                     bookingsStore.current.contract.quotationNum = 'SPOT'
//                     bookingsStore.current.contractNum = 'SPOT'
//                     bookingsStore.current.contractRefNum = 'SPOT'
//                     bookingsStore.current.hasToShowDummySchedule = false
//                     bookingsStore.current.emailid = loginStore.email
//                     bookingsStore.current.createdFromPricing = true
//                     console.log('\n\n***TC length=', bookingsStore.current.contract.tc.length, '***\n')
//                     if (rec.schedules?.length===0) bookingsStore.current.hasToShowDummySchedule = true

//                     contractsStore.current = rec
//                     // hideme(true)
//                     // setptbVisible(false)
//                     configStore.scheduleOrCarrierform = 'carrierform'
//                     breadCrumbStore.addCrumb("Booking Details")
//                 }
//                 setDisableBookNow(false)
//             })

//         }} variant="contained" size="small" sx={{textTransform:"capitalize",fontSize:"12px",width:"110px"}}/></Box>
//       </Box>
//     </Box>
//     { openContract && <MuiPopup height={200} open={openContract}>
//             <Box sx={{width:"100%",height:"100%",display:"flex",flexDirection:"column",alignItems:"end"}}>
//                 <Box sx={{display:"flex",flex:1,width:"100%",flexDirection:"column",justifyContent:"center",alignItems:"center",gap:2}}>
//                 <Typography sx={{fontFamily:"Figtree",fontWeight:"bold",ccolor:"black"}}>Your contract has been successfully saved.</Typography>
//                 <CopyText text={contractNumber.current} />
//                     {/* <Typography sx={{fontFamily:"Figtree",fontWeight:"bold"}}>Your contract has been successfully saved.</Typography> */}
//                 </Box>
//                 <Button sx={{width:"max-content"}} variant="contained" onClick={()=>{
//                     setOpenContract(false);
//                     breadCrumbStore.back();
//                 }}>Ok</Button>
//             </Box>
//     </MuiPopup>}
//     <TermsConditionPopup tc={selectedSchedule.dummyContract.temptc?.length>0 ? selectedSchedule.dummyContract.temptc : selectedSchedule.dummyContract.tc } open={openTerms} setClose={()=>{
//        setOpenTerms(false)
//     }} />
//     <BookingConfirmationDialog open={open} handleClose={()=>{
//         setOpen(false);
//     }} />
//     </Box>
// }

export function ReviewServicesDetailsPage({callback}) {
    const enquiry = useSnapshot(enquiryStore);
    const [entityData, SetEntityData] = useState(null);
    const [amount, setAmount] = useState(12000);
    const [premium, setPremium] = useState(0);
    const [error, setError] = useState(false); // State to track validation error
    const { selectedSchedule, subscript, graphNodes } = breadCrumbStore.state

    let enq = enquiry.current;
    const getEntitydata = async () => {
        let entityData = await EntityApi.viewEntity(enq?.entityId);
    
        console.log("entityData", entityData);
        ///console.log("entityData", task.entityId);
    
        return entityData;
    };
    useEffect(() => {
        getEntitydata().then((data1) => {
          SetEntityData(data1);
        });
    }, []);
    const getPremium = async () => {
            //loader.show()
            let policyType;
            let orginCountry = Utils.extractCountry(enq.enqRoute.origin);
            let destinationCountry = Utils.extractCountry(enq.enqRoute.destination);
            if (entityData?.entityCountry == orginCountry) {
              policyType = "Export";
            }
            if (entityData?.entityCountry == destinationCountry) {
              policyType = "Import";
            }
            if(enquiryStore.current.commodityType==""){
                setError(true)
            } else{
                setError(false)
            }
            await Api.CoiPremium(
            {
                orgLinkName: "API-Agraga-Bajaj-Specific-Marine-PG",
                Transit_Type: policyType,
                CommodityId: commodityType[enquiryStore.current.commodityType],
                Invoice_Value: currency =="USD"?amount * configStore.getExchangeRate("USD"):amount,
                Transit_From_Country: orginCountry,
                Transit_To_Country: destinationCountry,
              },
              async (data) => {
                console.log("XDc",data)
                enquiryStore.current.premium=data.premium
                enquiryStore.current.sum_insured=data.sum_insured
                enquiryStore.current.insurance_Amount=amount
                 if(data.status=="success"){
                    loader.hide()
                    const chargesList = selectedSchedule.dummyContract.chargesList;

                    // Remove existing Marine Insurance charge if it exists
                    const updatedChargesList = chargesList.filter(e => e.chargeDescription !== "Marine Insurance");
        
                    // Add the updated Marine Insurance charge
                    updatedChargesList.push({
                        chargeDescription: "Marine Insurance",
                        currency: "INR",
                        isIncluded: "Yes",
                        perShipment: 0,
                        total: ((data.premium - 500)).toFixed(2),
                    });
                    console.log(updatedChargesList)
                    selectedSchedule.dummyContract.chargesList = updatedChargesList;
        
                    callback(parseInt((data.premium - 500).toFixed(2)));
                    setPremium(data.premium);
        
                }}
            );
    
        };
        useEffect(() => {
            if (amount > 12000  && currency=="USD" || (amount>1020360 && currency=="INR") ) {
            getPremium();
            } else{
                setPremium(0)
                setError(false)
                if(amount<1200 && currency=="USD" || (amount<=1020360 && currency=="INR") )
                {
                    enquiryStore.current.premium=500
                    enquiryStore.current.sum_insured=1122396
    
                    const chargesList = selectedSchedule.dummyContract.chargesList;
                    const updatedChargesList = chargesList.filter(e => e.chargeDescription !== "Marine Insurance");
                    updatedChargesList.push({
                        chargeDescription: "Marine Insurance",
                        currency: "INR",
                        isIncluded: "Yes",
                        perShipment: 0,
                        total: "500",
                    });
                    
                    selectedSchedule.dummyContract.chargesList = updatedChargesList;
                } 
                        
                    
                
                

                
                callback(-1);
            }
        }, [amount]);
     
        const obj = [
            // 'Door Delivery',
            // 'Door Pickup',
            // 'Origin Clearance',
            // 'Destination Clearance',
            'Marine Insurance'
        ]
        const [switchStates, setSwitchStates] = useState({});
        const [currency, setCurrency] = useState('USD'); // default to USD

   
    useEffect(()=>{
        if(enquiryStore.current.commodityType!=""){
            enquiryStore.current.premium=500
            enquiryStore.current.sum_insured=1122396
            const chargesList = selectedSchedule.dummyContract.chargesList;
            const updatedChargesList = chargesList.filter(e => e.chargeDescription !== "Marine Insurance");
            updatedChargesList.push({
                chargeDescription: "Marine Insurance",
                currency: "INR",
                isIncluded: "Yes",
                perShipment: 0,
                total: "500",
            });
            selectedSchedule.dummyContract.chargesList = updatedChargesList;
            callback(-2)

        }
        
    },[enquiryStore.current.commodityType])

    useEffect(()=>{
        if(currency=="INR"){
            enquiryStore.current.insurance_Amount_currency_type=currency
        }
    },[currency])
    const handleToggle = (index) => {
        setSwitchStates((prev) => ({
          ...prev,
          [index]: !prev[index], // Toggle the state of the switch at the given index
        }));
        setAmount(12000)
        setPremium(0)
        enquiryStore.current.commodityType=''
        setError(false)
        if(!switchStates[index]==true){
            enquiryStore.current.marineInsuranceEnabled='Yes'

        } else{
            enquiryStore.current.marineInsuranceEnabled='No'

        }
        if(!switchStates[index]==false){
            const chargesList = selectedSchedule.dummyContract.chargesList;
            const updatedChargesList = chargesList.filter(e => e.chargeDescription !== "Marine Insurance");
            selectedSchedule.dummyContract.chargesList = updatedChargesList;
            callback(0)
        } 
      };
    return <div class="flex flex-col items-center min-h-[342px] w-full flex-1 rounded-lg bg-white p-[24px]">
        <div class="flex min-h-[60px] w-full rounded items-center justify-start pl-[15px] bg-blue-dark" >
            <p class="text-white">Review and Finalise Services</p>
        </div>
        {obj.map((e, index) =>
            <div key={e} class={`flex min-h-[50px] w-full flex-grow rounded flex-row items-center justify-start pl-[15px] ${index % 2 !== 0 ? "bg-[#F5F5F5]" : ''}`}>
                <>
                    <p class="min-w-[300px]" style={{fontWeight:"bold"}}>{e}</p>
                    <AntSwitch  checked={!!switchStates[index]} // Convert undefined to false
                    onChange={() => handleToggle(index)} />
                    {
                    switchStates[index] && index == 0 && (
                        <>
                        <div style={{ marginLeft: "20px" }}>
                            {/* Container for all rows */}
                            <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
                            {/* First row */}
                            <div style={{ display: "flex", flexDirection: "row", gap: "20px" }}>
                                {/* Maximum IDV */}
                                <div style={{ flexShrink: 0 }}>
                                <p style={{ fontWeight: "700" }}>Maximum IDV</p>
                                <p style={{ fontWeight: "700",textShadow: "1px 1px 0 #000"  }}>$ 12000</p>
                                </div>

                                {/* Increase Insured Value */}
                                <div style={{ flexGrow: 1 }}>
                                <p style={{ fontWeight: "700" }}>Increase Insured Value*</p>
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <select
                                    value={currency}
                                    onChange={(e) => setCurrency(e.target.value)}
                                    style={{
                                        backgroundColor: "#f0f0f0",
                                        border: "1px solid #ccc",
                                        borderRadius: "4px",
                                        padding: "8px 12px",
                                        cursor: "pointer",
                                        color: "#0000FF",
                                    }}
                                    >
                                    <option value="USD">$ USD</option>
                                    <option value="INR">₹ INR</option>
                                    </select>
                                    <input
                                    type="text"
                                    value={amount}
                                    style={{
                                        width: "100px",
                                        flexGrow: 1, // Makes the input take up remaining space
                                        padding: "8px",
                                        border: "1px solid #ccc",
                                        borderRadius: "4px",
                                        marginRight: "8px", // Optional, to add space between input and any following element
                                    }}
                                    placeholder="Enter value"
                                    onChange={(event) => {
                                        const value = event.target.value;
                                        if (value.length <= 15) {
                                        setAmount(value);
                                        }
                                    }}
                                    />
                                </div>
                                </div>
                                <div style={{ flexShrink: 0 }}>
                                <p style={{ fontWeight: "700" }}>Commodity Type*</p>
                                <select 
                                onChange={(event) => {
                                    enquiryStore.current.commodityType = event.target.value;
                                    enquiryStore.current.insurance_Amount = amount;
                                    setError(false);
                                }}
                                style={{ borderColor: error ? "red" : "initial", width: "200px",
                                        flexGrow: 1, // Makes the input take up remaining space
                                        padding: "8px",
                                        width: "150px",
                                        border: "1px solid #ccc",
                                        borderRadius: "4px",
                                        marginRight: "8px", // Optional, to add space between input and any following element
                                 }}
                                >
                                {commodityList.map((e) => (
                                    <option key={e} value={e}>
                                    {e}
                                    </option>
                                ))}
                                </select>
                                </div>
                                {/* Additional Premium Applicable */}
                                <div style={{ flexShrink: 0,textAlign:"center" }}>
                                <p style={{ fontWeight: "700" }}>
                                    Additional Premium Applicable
                                </p>
                                <p
                                    style={{
                                    display: "flex",
                                    alignItems: "center",
                                    color: "#0000FF",
                                    justifyContent: "center"

                                    }}
                                >
                                    <span style={{ marginRight: "4px" }}>₹</span>{" "}
                                    {/* INR symbol */}
                                    {premium > 500 ? (premium - 500).toFixed(2) : 500}
                                </p>
                                </div>
                               
                            </div>
                           
                            </div>
                        </div>
                        </>
                    )
                }
                </>
            </div>
        )}
    </div>

}

export function LabeledAttributesWithValue({ title, value, color }) {
    return <div className={`min-h-[60px] w-[210px] flex flex-col ${color === 'Yes' ? 'bg-[#F5F5F5]' : ''} py-[6px] px-[18px] justify-evenly`}>
        <p>{title}</p>
        <p className="text-blue-dark font-bold">{value}</p>
    </div>
}

function DestinationWidget({destination,isLast}){
    const height = isLast ? "20px" : "40px"
    const alignItems = isLast ? "start" : "center"
    const flexDirection = isLast ? "col" : "row"
    return <div className={`flex flex-row h-[${height}] relative`}>
         <div className="absolute top-0">
         <VerticalDashedLine height={height} dashArray="3,3" stroke="#555555" />
         </div>
        <div className={`absolute flex flex-row items-center top-[10px] h-[19px]`}>
          <HorizontalDashedLine width="10px"  stroke="#555555" dashArray="3,3" />
          <img src={destinationIcon} />
          <p className="text-black font-bold pl-2">{destination}</p>
        </div>
    </div>
}

export function PricingDetailsPage() {
    useSnapshot(breadCrumbStore);
    //  useSnapshot(truckLoader)
    const { selectedSchedule, subscript, graphNodes } = breadCrumbStore.state
    const [open, setOpen] = useState(false);
    const [checked, setChecked] = useState(false)
    const [openTerms, setOpenTerms] = useState(false);
    const [disabled, setDisabled] = useState(false)
    const [openContract, setOpenContract] = useState(false);
    const [disableBookNow, setDisableBookNow] = useState(false);
    const enquiry = useSnapshot(enquiryStore);
    console.log(enquiry, selectedSchedule, "[][][][]");
    const contractNumber = useRef()
    let mode = enquiry.current.shipmentType.toLowerCase();
    let calculatedCargo;
    useEffect(() => {
        if (disabled || disableBookNow) {
            truckLoader.open = true
        } else {
            truckLoader.open = false
        }
    }, [disabled, disableBookNow])
    if (mode === "air") {
        calculatedCargo = Utils.AirCargoCalculation(enquiry.current.cargoDetails);
    } else {
        calculatedCargo = Utils.CalculateCargo(enquiry.current.cargoDetails);
    }
    const vehicles = Utils.VehicleAggregation(enquiry.current.vehicles);
    const calculatedContainers = CalculateContainer(enquiry.current.containerDetails);
    const requiredDetailsForTheGivenBookingScope = Utils.getRequiredDetailsForGivenShipmentScope();
    const shipmentScope = {
        "Port-to-Port": 'P2P',
        "Port-to-Door": 'P2D',
        "Door-to-Port": 'D2P',
        "Door-to-Door": 'D2D'
    }
    const formattedDate = (etdpol) => {
        const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
        const date = new Date(etdpol);
        const day = String(date.getDate()).padStart(2, '0');
        const month = date.getMonth();
        return `${day} ${months[month]}`;
    }
    let shipmentLogo = selectedSchedule?.dummyContract?.scac ? schLogoMap[selectedSchedule?.dummyContract?.scac] : null;
    console.log(selectedSchedule.dummyContract.schedules[subscript], subscript, "pppppp");
    let transitStop = () => {
        let stop = 0
        const schedules = selectedSchedule.dummyContract.schedules[subscript];
        if (schedules.ts1code && schedules.ts1code !== '') stop = 1
        if (schedules.ts2code && schedules.ts2code !== '') stop = 2
        if (schedules.ts3code && schedules.ts3code !== '') stop = 3
        if (schedules.ts4code && schedules.ts4code !== '') stop = 4
        if (schedules.ts5code && schedules.ts5code !== '') stop = 5
        return stop;

    }
console.log(" the multidest is ",enquiry.current.multidest)
    return <div className="flex flex-col min-w-[875px] flex-1 rounded-lg bg-white" >
        <div className="min-h-[114px] min-w-[874px] flex flex-col gap-[5px]">
            <div className='h-[60px] min-w-[875px] rounded-t-lg bg-blue-dark flex' >
                <div className="flex h-full w-full flex-row items-center justify-start pl-[15px]">
                    <p className="text-white">Review Details</p>
                </div>
            </div>
            <div className="px-[28px] py-[15px] gap-[18px] flex flex-col">
                <p>{enquiry.current.shipmentType.toLowerCase().includes('air') ? 'Air' : 'Ocean'} - {enquiry.current.shipmentType}, {shipmentScope[enquiry.current.shipmentScope]}</p>
                { enquiry.current.multidest?.length>1 ? 
                 <div className="flex flex-col">
                  <div className="flex flex-row items-center gap-[9px]" >
                     <div className="flex items-center justify-center bg-yellow h-[18px] w-[18px] rounded-[42.75px]">
                        <div className="h-[9px] w-[9px] rounded-[42.75px] bg-[white]"></div>
                    </div>  
                    <img src={ship} />
                    <p className="text-black font-bold font-barlow">{enquiry.current.enqRoute.origin}</p>
                    </div>  
                  <div className="flex flex-col ml-[9px]">
                    <VerticalDashedLine  height="15px" dashArray="3,3" stroke="#555555" />
                     {enquiry.current.multidest.map(({destination},i)=><DestinationWidget destination={destination} isLast={i===enquiry.current.multidest.length-1}/>)}
                  </div>
                  </div>
                : <div className="flex flex-row gap-[5px]"> <p class="text-black font-bold font-barlow">{enquiry.current.enqRoute.origin}</p> <img src={routeIcon} /><p class="text-black font-bold">{enquiry.current.enqRoute.destination}</p></div>}
                {selectedSchedule.dummyContract.schedules.length > 0 ? (
                    <p>
                        {transitStop() === 0 ? (
                            <>
                                {formattedDate(selectedSchedule.dummyContract.schedules[subscript].etapod)}
                                , {selectedSchedule.dummyContract.schedules[subscript].transitTime} days
                            </>
                        ) : (
                            <>
                                {formattedDate(selectedSchedule.dummyContract.schedules[subscript].etapod)},
                                {transitStop()} Stops,
                                {selectedSchedule.dummyContract.schedules[subscript].transitTime} days
                            </>
                        )}
                    </p>
                ) : null}
            </div>

        </div>
        <div className="w-full flex flex-col">
        <div className="bg-[#D4D4D4] h-[1px] w-full"></div> 
        <div className="min-h-[129px] min-w-[875px]  flex flex-row justify-between items-center ">
           
            <div className="h-[109px]">
               <Transit days={subscript===-1 ? 0 : selectedSchedule?.minTransitTime} src={shipmentLogo} />
            </div>
            <div className="min-h-[66px] min-w-[763px] px-[10px] flex flex-grow">
                <ScheduleGraph2 graphNodes={graphNodes} />
            </div>
        </div>
        <div className="bg-[#D4D4D4] h-[1px] w-full"></div>
        </div>
        <div className="min-h-[150px] min-w-[874px] flex flex-col pl-[15px]">
            <p className="flex justify-start font-bold pt-[16px]">Handling Details</p>
            <div className="flex flex-row pt-[5px] flex-wrap">
                <LabeledAttributesWithValue title={'Hazardous?'} value={enquiry.current.hazardous} color={'Yes'} />
                {enquiry.current.hazardous === 'Yes' ?
                    <>
                        <LabeledAttributesWithValue title={'Class?'} value={enquiry.current.quoteClass} color={'No'} />
                        <LabeledAttributesWithValue title={'Packing Group'} value={enquiry.current.packingGroup} color={'Yes'} />
                        <LabeledAttributesWithValue title={'UN Number'} value={enquiry.current.unnumber} color={'No'} />
                    </> : <></>}

                {/* <div className={`min-h-[60px] w-[210px] flex flex-col py-[6px] px-[18px] justify-evenly ${selectedSchedule.dummyContract.hazardous==='Yes'?"bg-[#F5F5F5]":''}`}> */}
                <LabeledAttributesWithValue title={'Non-Stackable?'} value={enquiry.current.nonStackable} color={'No'} />
                <LabeledAttributesWithValue title={'Temperature Controlled'} value={enquiry.current.temperatureControlled} color={'Yes'} />
                {
                    enquiry.current.temperatureControlled === 'Yes' && enquiry.current.shipmentType.toLowerCase().includes('air')  ? <>
                        <LabeledAttributesWithValue title={'Maximum Temperature'} value={enquiry.current.maxTemperature} color={'No'} />
                        <LabeledAttributesWithValue title={'Minimum Temperature'} value={enquiry.current.minTemperature} color={'No'} />
                    </> : <></>
                }
                {
                    enquiry.current.temperatureControlled === 'Yes' && enquiry.current.shipmentType.toLowerCase().includes('fcl')  ? <>
                        <LabeledAttributesWithValue title={'Temperature'} value={enquiry.current.temperature} color={'No'} />
                        <LabeledAttributesWithValue title={'Cargo Value'} value={enquiry.current.cargoValue} color={'No'} />
                    </> : <></>
                }

            </div>
            {/* Cargo Details */}
            {selectedSchedule?.dummyContract?.cargoList.length > 0 && <p className="flex justify-start font-bold pt-[16px] font-bold">Cargo Details</p>}
            {selectedSchedule?.dummyContract?.cargoList && selectedSchedule?.dummyContract?.cargoList?.map((e, index) =>
                <div key={index} className="flex flex-row justify-start py-[10px] flex-wrap">
                    <>
                        <LabeledAttributesWithValue title={'Type'} value={e.packageType} color={'Yes'} />
                        <LabeledAttributesWithValue title={'Total Quantity'} value={e.numPackages} color={'No'} />
                        {enquiry.current.shipmentType.toLowerCase().includes('lcl')?
                                <LabeledAttributesWithValue title={'Total Weight'} value={e.totalWeight} color={'Yes'} />
                                :<LabeledAttributesWithValue title={'Weight Per Piece'} value={e.wtPerPackage} color={'Yes'} />
                        }
                        {
                            enquiry.current.shipmentType.toLowerCase().includes('lcl')?
                            <LabeledAttributesWithValue title={'Total Volume'} value={e.totalVolume?.toFixed(3)} color={'No'} />
                            :<></>
                        }
                        {e.length !== 0 && e.width !== 0 && e.height !== 0 ?
                            <LabeledAttributesWithValue title={'Dimensions'} value={e.length + 'L X' + e.width + 'W X' + e.height + 'H'} color={'No'} />
                            : <></>}
                    </>
                </div>
            )}
            {/* Container Details */}
            {selectedSchedule?.dummyContract?.containerList.length > 0 && <p className="flex justify-start font-bold pt-[16px] font-bold">Container Details</p>}
            {selectedSchedule?.dummyContract?.containerList && selectedSchedule?.dummyContract?.containerList?.map((e, index) =>
                <div key={index} className="flex flex-row justify-start py-[10px]">
                    <>
                        <LabeledAttributesWithValue title={'Container Type'} value={e.containerType} color={'Yes'} />
                        <LabeledAttributesWithValue title={'Number of Containers'} value={e.numContainers} color={'No'} />
                        <LabeledAttributesWithValue title={'Weight (Per Container)'} value={e.wtPerContainer + e.unit} color={'Yes'} />
                    </>
                </div>
            )}
        </div>
    </div>
}

export function AccountsDetailsPage() {
    const { selectedSchedule, subscript, graphNodes } = breadCrumbStore.state
    
    return <div class="flex flex-col min-w-[400px] max-w-[400px] rounded-lg bg-white">
        <div class="flex min-h-[60px] w-full rounded-t-lg flex-row items-center justify-between pl-[15px] pr-[15px] bg-blue-dark" >
            <p class="text-white">Your Account Total</p>
            {/* <p class="text-white">Price in INR</p> */}
        </div>
        <div className="flex flex-col w-full py-1">
          { enquiryStore.current.shipmentType.indexOf("LCL")>=0 && <LCLBookingPricingDetail contract={selectedSchedule.dummyContract} isAccordionView={true}/>}
         { enquiryStore.current.shipmentType.indexOf("Air")>=0 && <AIRBookingPricingDetail contract={selectedSchedule.dummyContract} isAccordionView={true}/>}
         { enquiryStore.current.shipmentType.indexOf("FCL")>=0 && <FCLBookingPricingDetail contract={selectedSchedule.dummyContract} isAccordionView={true}/>}
        </div>
    </div>

}

let enabledCharges = [
    'Origin Customs Clearance',
    'Destination Customs Clearance',
    'Pick-up Charges',
    'Delivery Charges'
];



function isValidBooking(contract){
    let isValid = true;
    if(contract.shipmentScope.includes("Door") && (contract.origin.includes('[IN') || contract.destination.includes('[IN'))){
          contract.chargesList.forEach((charge)=>{
            if((charge.chargeDescription === "Pick-up Charges" && charge.total ===0) || (charge.chargeDescription === "Delivery Charges" && charge.total ===0)){
                isValid = false;
             }
          })
       }
    return isValid
}


export function ReviewDetailsPage() {
    const [checked, setChecked] = useState(false)
    const [openTerms, setOpenTerms] = useState('')

    const brec = bookingsStore.current;
    const [open,setOpen] = useState(false);
    const {graphNodes,subscript} = useSnapshot(breadCrumbStore.state)
    const {selectedSchedule} = breadCrumbStore.state;
    const [disableBookNow,setDisableBookNow] = useState(false);
    const isValidForBooking = isValidBooking(selectedSchedule.dummyContract);
    const [scheduleData,setScheduleData] = useState();


    useEffect(()=>{
                if(disableBookNow){
            truckLoader.open = true
                }else{
            truckLoader.open = false
        }
            },[disableBookNow])

    const handleBookingSubmission = async()=>{
        if (configStore.currentSelectedEntity?.entityId?.length === 0) {
            setEntityCreationPopup(true)
            return
        }
        if(!checked){
            configStore.setModalMessage("Accept terms and conditions to proceed further");
            return;
        }
        console.log("SXa",enquiryStore.current.commodityType)
      
        if(enquiryStore.current.commodityType=="" && enquiryStore.current.marineInsuranceEnabled=="Yes"){
            configStore.setModalMessage("Please select the commodity type.");
            return;
        }
        const rec = {...selectedSchedule.dummyContract};
        rec.entityId = configStore.currentSelectedEntity.entityId;
        selectedSchedule.dummyContract.entityId = rec.entityId;
        setDisableBookNow(true)
        EntityV2Api.viewEntity(rec.entityId, (data) => {
            if (data.status === ENTITY_STATUS.DEACTIVATED) {
                configStore.setModalMessage(`Entity ${data.entityName} is Deactivated , please Activate it for Procced to booking `);
                setDisableBookNow(false)
                return;
            }
            else if (data.entityCountry.toLowerCase() === 'india' && (data.branch[0].gst.length < 1 || data.branch[0].branchAddresses[0].address < 1)) {
                if (loginStore.isClient()) {
                    let dataObject = {
                        cb: data?.customer?.crossBorder?.relationshipManager,
                        dt: data?.customer?.domesticTransport?.relationshipManager
                    }

                    AguserApi.getProfile(dataObject, (data) => {
                        let message = "Please update your KYC before proceeding for the Booking. Please reach out to your Relationship Manager if you face any challenges "

                        if (data?.CB_User || data?.DT_User) {
                            let user = data?.CB_User || data?.DT_User
                            message += `${user?.firstName} on ${user?.phonePrefix} ${user?.phone} or ${user?.email}`
                        } else if (data?.PAT_User) {
                            message += data?.PAT_User?.email
                        }

                        configStore.setModalMessage(message)
                        setDisableBookNow(false)
                        return;
                    })
                } else {
                    configStore.setModalMessage('Please Update at least one GST and address to place the Booking')
                }
            }
            else if (data.entityCountry.toLowerCase() !== 'india' && data.tinNum.length < 1) {
                configStore.setModalMessage('Please Update at least tin Number to place the Booking')
            }
            else {
                rtpStore.routeSubscript = subscript
                bookingsStore.initialize()
                bookingsStore.current.schedule = selectedSchedule
                bookingsStore.current.graphNodes = graphNodes
                let rec1 = { ...rec }
                //rec1.tc.push(...rec.temptc)
                bookingsStore.current.contract = { ...rec }
                //bookingsStore.current.contract.temptc = []
                let filteredCharges = bookingsStore.current.contract.chargesList
                /*if (rec.oih && rtpStore.shipperpda == ' ') {
                    filteredCharges = filteredCharges.filter(e => e.chargeDescription != 'Origin Inland Haulage')
                    bookingsStore.current.contract.quoteValueDeduction = rec.oih
                    bookingsStore.current.contract.oih = 0
                }*/
                if (rec.oih && rtpStore.shipperpda == ' ') {
                    bookingsStore.current.contract.oih = rec.oih
                } else {
                    bookingsStore.current.contract.oih = 0
                }
                bookingsStore.current.contract.chargesList = [...filteredCharges]
                bookingsStore.current.contract.quotationNum = 'SPOT'
                bookingsStore.current.contractNum = 'SPOT'
                bookingsStore.current.contractRefNum = 'SPOT'
                bookingsStore.current.hasToShowDummySchedule = false
                bookingsStore.current.emailid = loginStore.email
                bookingsStore.current.createdFromPricing = true
                console.log('\n\n***TC length=', bookingsStore.current.contract.tc.length, '***\n')
                if (rec.schedules?.length===0) bookingsStore.current.hasToShowDummySchedule = true

                contractsStore.current = rec
                // hideme(true)
                // setptbVisible(false)
                configStore.scheduleOrCarrierform = 'carrierform'
                breadCrumbStore.addCrumb("Additional Details");
            }
            setDisableBookNow(false)
        })
    }

    const [EntityCreationPopup, setEntityCreationPopup] = useState(false)
    return <div className="flex flex-row gap-[10px] w-full overflow-auto">
        <div className="flex flex-col gap-[10px] flex-grow">
            <PricingDetailsPage />
    {<ReviewServicesDetailsPage 
        callback={(data)=>{
        setScheduleData(data)
        }} 
       /> }

        </div>
        <TruckLoader/>
        <div className="flex flex-col ">
            <AccountsDetailsPage />
            <div className="flex flex-row justify-end py-[10px]">
                <Checkbox checked={checked} onChange={(e) => {
                    setChecked(e.target.checked)
                }} sx={{ padding: 0 }} /><span onClick={() => {
                    setOpenTerms(true)
                }} style={{ fontSize: "14px", cursor: "pointer" }}>Accept Terms & Conditions</span>
            </div>
            <div className="flex flex-row justify-end gap-[15px]">
                    <button onClick={()=>{
                    breadCrumbStore.setCurrentCrumb(0);
                }} className="h-[40px] w-[80px] px-[18px] py-[6px] rounded-[52px] text-[14px] text-blue-dark border-[1px] border-blue-dark bg-white">Cancel</button>
                <button disabled={!isValidForBooking} onClick={handleBookingSubmission} className={`h-[40px] w-[161px] px-[18px] py-[6px] rounded-[52px]  text-[14px] text-white ${!isValidForBooking ? "bg-[#d3d3d3] cursor-not-allowed color-[#6c757d]" : "bg-blue-dark"}`}>Proceed to Booking</button>
            </div>
        </div>
            <TermsConditionPopup tc={selectedSchedule.dummyContract.temptc?.length>0 ? selectedSchedule.dummyContract.temptc : selectedSchedule.dummyContract.tc } open={openTerms} setClose={()=>{
       setOpenTerms(false)
    }} />
    { EntityCreationPopup && <EntityCreWarnPopup cancelfn={(i) => setEntityCreationPopup(false)} />}
    </div>
}