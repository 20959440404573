import { useEffect, useState } from "react";
import { SHIPMENT_SCOPE } from "../util/quotationUtil";
import { useForm } from "react-hook-form";
import { Cargo, Container, enquiryStore } from "../enquiry/enquiry-data";
import { useSnapshot } from "valtio";
import { configStore, EntityV2Store, loginStore } from "../state/CommonState";
import Utils from "../util/Utils";
import AgCustlist1 from "../components/AgCustlist1";
import EnquiryConfirmationv3 from "./enquiry-confirmation-v3";
import ActrejectModalV3 from "./enquirysuggestion-v3";
import { ActiveTab } from "../v3-componets/AgActiveTab";
import { Route } from "../v3-componets/AgRoute";
import { Details } from "../v3-componets/AgDetails";

export default function AgEnquiryv3(props) {
    let viewonly = '0'
    useSnapshot(enquiryStore);
    useSnapshot(configStore);
    useSnapshot(loginStore);
    const [selectedType, setType] = useState(enquiryStore.current.shipmentType);
    const [disable,setDisable] = useState(false);
    const cargoForm = useForm({
        defaultValues: {
            mode: "LCL",
            origin: enquiryStore.current.enqRoute.origin,
            orginScope: false,
            destinationScope: false,
            cargoreadinessdate: Utils.yyyymmdd(new Date()),
            scope: SHIPMENT_SCOPE.P2P,
            shipmentType: enquiryStore.current.shipmentType,
            originType: false,
            destinationType: false,
            customerreference : enquiryStore.current.enqRoute.customerReferenceNum,
            hazardous: enquiryStore.current.hazardous === "No" ? false : true,
            quoteClass: enquiryStore.current.quoteClass,
            packingGroup: enquiryStore.current.packingGroup,
            temperature: enquiryStore.current.temperature,
            cargoValue: enquiryStore.current.cargoValue,
            unnumber: enquiryStore.current.unnumber,
            nonStackable:  enquiryStore.current.nonStackable === "No" ? false : true,
            temperatureControlled:  enquiryStore.current.temperatureControlled === "No" ? false : true,
            minTemperature: enquiryStore.current.minTemperature,
            maxTemperature: enquiryStore.current.maxTemperature,
            stuffingType: enquiryStore.current.stuffingType,
            destuffingType: enquiryStore.current.destuffingType,
            status: "ENQUIRY",
            cargoDimensionUnit: "Cms",
            enqRoute: enquiryStore.current.enqRoute,
            vehicledetails : enquiryStore.current.vehicles,
            isFba:false,
            showPalletLabel:false,
            ior:false,
            occ : false,
            dcc:false,
            survey:false,
            storageAtDestination : false,
            days : 0,
            palletizationandlabelling : true,
            showCargoDetailsOutGuage: false,
            pick_up_by_amazon:false,
            multidest:enquiryStore.current.multidest,
            containerDetails:[new Container()],
            fbaalways:"No"
        }
    })
    useEffect(()=>{
        if(enquiryStore.current.shipmentType.length>3 && enquiryStore.current.shipmentType.toLowerCase().indexOf('fcl')!=-1){
            enquiryStore.current.shipmentType = 'FCL'
            setValue("mode",'FCL');
        }
        if(enquiryStore.current.multidest==undefined){
            if(enquiryStore.current.containerDetails==undefined) enquiryStore.current.containerDetails = [new Container()]
            if(enquiryStore.current.cargoDetails==undefined) enquiryStore.current.cargoDetails = [new Cargo()]
            setValue("multidest", [
                {
                    destination:enquiryStore.current.enqRoute.destination,
                    cargoDetails:enquiryStore.current.cargoDetails,
                }
            ]);
            enquiryStore.current.multidest = [
                {
                    destination:enquiryStore.current.enqRoute.destination,
                    cargoDetails:enquiryStore.current.cargoDetails,
                }
            ]
        }
    },[enquiryStore.current])
    useEffect(()=>{
        setValue("mode",selectedType);
    },[selectedType])
    const { control, watch, register, setValue, handleSubmit, reset, clearErrors, formState, trigger, getFieldState } = cargoForm;
    const changeShipmentTypes = (type) =>{
        setType(type)
        enquiryStore.initialize();
        enquiryStore.current.shipmentType = type
        setValue("origin", "");
        setValue("multidest", [
            {
                destination:"",
                cargoDetails:[new Cargo()],
            }
        ]);
        setValue("containerDetails",[new Container()])
        setValue("mode",selectedType);
        clearErrors();
        
    }
    const checkcontainer = (containerDetails)=>{
        let k = {}
        let l = true
        for (let j = 0; j < containerDetails.length; j++) {
            if(containerDetails[j]['containerType']!=undefined && containerDetails[j]['containerType'].length>0){
                let c = containerDetails[j]['containerType']
                if(k[c]==undefined){
                k[c] = Number(containerDetails[j]['wtPerContainer'])
                }
                if(k[c]!=Number(containerDetails[j]['wtPerContainer'])){
                configStore.setModalMessage(
                    `Different Container Weight Not Allowed (${containerDetails[j]['containerType']})`
                )
                l=false
                }
            }
        }
        return l
      }
    const onSubmit = async (data, e) => {
        try{
            if(checkcontainer(data.containerDetails)){
                let cargo = []
                let container = []
                let obj = {}
                let multidest_new = []
                for (let i = 0; i < data.multidest.length; i++) {
                    let z1 = {}
                    z1['destination'] = data.multidest[i]['destination']
                    z1['cargoDetails'] = []
                    if(i==0){
                        enquiryStore.current.enqRoute.destination = data.multidest[i]['destination']
                    }else{
                        let k = i+1
                        enquiryStore.current.enqRoute['destination'+k] = data.multidest[i]['destination']
                    }
                    for (let j = 0; j < data.multidest[i]['cargoDetails'].length; j++) {
                        let k = data.multidest[i]['cargoDetails'][j]
                        k = Utils.setCargo_unit(k,data.multidest[0]['cargoDetails'][0],['weight','chargeableWeight','volume','totalWeight','totalVolume','dimensions'])
                        z1['cargoDetails'].push(k)
                        cargo.push(k)
                    }
                    multidest_new.push(z1)
                }
                for (let j = 0; j < data.containerDetails.length; j++) {
                    let z = data.containerDetails[j]['containerType']
                    if(obj[z]==undefined){
                        obj[z] = {
                            containerType : "",
                            numContainers: 0,
                            unit: "",
                            wtPerContainer: 0
                        }
                    }
                    obj[z]['containerType'] = z
                    obj[z]['unit'] = data.containerDetails[0]['unit']
                    obj[z]['numContainers'] = obj[z]['numContainers']+Number(data.containerDetails[j]['numContainers'])
                    obj[z]['wtPerContainer'] = Number(data.containerDetails[j]['wtPerContainer'])
                }
                let o = Object.keys(obj)
                for (let i = 0; i < o.length; i++) {
                    obj[o[i]]['wtPerContainer'] = obj[o[i]]['wtPerContainer']
                    container.push(obj[o[i]])
                }
                enquiryStore.current.nonStackable = data.nonStackable ? 'Yes' : "No"
                enquiryStore.current.enqRoute.cargoReadinessDate = data.cargoreadinessdate
                enquiryStore.current.enqRoute.origin = data.origin
                enquiryStore.current.shipmentScope = data.scope
                if(data.mode == 'FCL'){
                    enquiryStore.current.shipmentType = `FCL (${data.stuffingType}/${data.destuffingType})`;
                }else{
                    enquiryStore.current.shipmentType = data.mode
                }
                enquiryStore.current.enqRoute.customerReferenceNum = data.customerreference
                enquiryStore.current.hazardous = data.hazardous ? 'Yes' : "No"
                enquiryStore.current.temperatureControlled = data.temperatureControlled ? 'Yes' : "No"
                enquiryStore.current.containerDetails.length = 0
                container.forEach(item => enquiryStore.current.containerDetails.push(item));
                enquiryStore.current.cargoDetails.length = 0
                cargo.forEach(item => enquiryStore.current.cargoDetails.push(item));
                // enquiryStore.current.containerDetails = container;
                // enquiryStore.current.cargoDetails = cargo;
                enquiryStore.current.cargoDimensionUnit = data.cargoDimensionUnit;
                enquiryStore.current.unnumber = data.unnumber;
                enquiryStore.current.packingGroup = data.packingGroup
                enquiryStore.current.quoteClass = data.quoteClass
                enquiryStore.current.stuffingType = data.stuffingType
                enquiryStore.current.destuffingType = data.destuffingType
                enquiryStore.current.minTemperature = data.minTemperature
                enquiryStore.current.maxTemperature = data.maxTemperature
                enquiryStore.current.temperature = data.temperature
                enquiryStore.current.cargoValue = data.cargoValue;
                enquiryStore.current.fba = data.isFba ? "Yes" : "No";
                enquiryStore.current.fbaIorRequired = data.ior ? "Yes" : "No";
                enquiryStore.current.fbaOCC = data.occ ? "Yes" : "No";
                enquiryStore.current.fbaDCC = data.dcc ? "Yes" : "No";
                enquiryStore.current.fbaPAL = data.palletizationandlabelling ? "Yes" : "No";
                enquiryStore.current.fbaSAD = data.storageAtDestination ? "Yes" : "No";
                enquiryStore.current.fbaSurvey = data.survey ? "Yes" : "No";
                enquiryStore.current.pick_up_by_amazon = data.pick_up_by_amazon ? "Yes" : "No";
                enquiryStore.current.fbaDays = data.days!=undefined? data.days: 0 ;
                enquiryStore.current.isfromAgragaEnquiry = 'Yes'
                let rec = enquiryStore.current
                if(rec.entityId != undefined && rec.entityId.length==0){
                    configStore.setModalMessage('Select Customer')
                    return
                }
        let l = Utils.enquiry_validation_check(rec,JSON.parse(JSON.stringify(multidest_new)))
        if(l.length>0){
            configStore.setModalMessage(l[0])
            return
        }
        enquiryStore.current.multidest = JSON.parse(JSON.stringify(multidest_new));
        configStore.quotationModalVisible4 = true
        
    }   
                
        }catch(e){
            console.log("the rror is ",e)
          setDisable(false);
            configStore.setModalMessage("Something went wrong")
        }     
            }


return <>
<EnquiryConfirmationv3 />
<ActrejectModalV3 />
<div className="w-full flex flex-col gap-[8px]">
        <h5 className="title is-4" style={{ color: '#2c358a', fontFamily: 'product_sans_bold' }}>
        Enquiries
        </h5>
        <AgCustlist1
              record={enquiryStore.current}
              name="gst"
              label="Customer *"
              isChecked={false}
              dataList={EntityV2Store.Cuslist}
                validationMsg="- Please select one of customers."
                callback={(entityId) => {
                    enquiryStore.current.entityId = entityId;
                    enquiryStore.current.createdBy = loginStore.email
                }}
                />

        <ActiveTab callback={changeShipmentTypes} viewonly={viewonly=='1'} selectedType={selectedType}/>
       <form noValidate onSubmit={handleSubmit(onSubmit)} style={{width:"100%",display:"flex",flexDirection:"column"}}>
       <div className="flex flex-row gap-[10px]">
          
       <Route form={cargoForm} viewonly={viewonly=='1'}/>
       <Details form={cargoForm} viewonly={viewonly=='1'}/> 
    
       </div>
       <div className="flex flex-row w-full items-center justify-end h-[76px] border-b-solid border-b-2 border-b-[#D4D4D4]">
       <button disabled={disable} type="submit" className="flex items-center justify-center text-white bg-blue-dark rounded-[40px] w-[104px] h-[40px]">Submit</button>
      </div>
       </form>
       </div>
       </>
}