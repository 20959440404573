import { useSnapshot } from "valtio"
import { myactionsstore } from "./store-myactions"
import { loader } from "../util/loader"
import { useEffect, useState } from "react"
import { configStore, loginStore } from "../state/CommonState"
import AgLastUpload, { AgLastUpload1, AgLastUpload2 } from "../components/AgLastUpload"
import Api from "../state/Api"
import ShipmentGraph from "./shipment-graph"
import { DocumentCenter } from "./documentcenter"
import { VisibilityTable } from "./visibilitytable"
import MyactionUtils from "./Myactionutils"
import Utils from "../util/Utils"
import airlineCodes from "../static-data/airlines"
import RoleUtils from "../util/RoleUtils"
import { PERMISSIONS } from "../util/constants"
import { Button, Typography } from "@mui/material"
import { ArrowDropDown, ArrowDropUp } from "@mui/icons-material"
import BranchApi from "../state/branch-api"
import EntityV2Api from "../state/entities-api"

export function VisibilityGraph(props) {
    useSnapshot(myactionsstore)
    useSnapshot(loader)
    const [showDetails, setShowDetails] = useState(false)
    const [showDocuments, setShowDocuments] = useState(false)
    const [airlinelist, setAirlinelist] = useState([])
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [criticalChanges, setCriticalChanges] = useState([])

    // useEffect(()=> {
    //     BranchApi.getAllBranches()
    // })
    let e = props.graphNodes
    console.log("visi",e);
    let e1 = props.dsrRec
    let blLabel = 'BL Number'
    let bl_number = ''
    const twodeffdate = (date1, date2) =>{
        let arr = date2.split('-')
        date2 = `${arr[2]}-${arr[1]}-${arr[0]}`
        const diffTime = Math.abs(new Date(date2) - date1);
        let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))
        if(diffDays){
            if(date1.getTime()>new Date(date2).getTime()){
                return "-"+diffDays
            }
        }
        return diffDays
    }
    if(airlinelist.length==0){
        let a = {}
        for (let i = 0; i < airlineCodes.length; i++) {
            a[airlineCodes[i]['code']] = [airlineCodes[i]['name']]
        }
        setAirlinelist([a])
    }
    const handleConfirmation = () => {
        setShowConfirmation(false);
    };
    const nextdueget = (r)=>{
        let l = ""
        let schedule = r.booking.schedule
        let isDummy = r.booking.hasToShowDummySchedule ? true : false
        let stype = r.shipmentType
        let sc = r.shipmentScope
        let etdPol = ""
        let dataest = {}
        if(stype.toLowerCase().indexOf('air') != -1){
            let ch = 0
            if(r['ata_at_arrival_airport'] && r['ata_at_arrival_airport'].length>0 && ch==0){
                ch = 6
            }
            if (r["ts2_departure"] && r["ts2_departure"].length > 0 && ch==0) {
                ch = 5
            }
            if (r["ts2_arrival"] && r["ts2_arrival"].length > 0 && ch==0) {
                ch = 4
            }
            if (r["ts1_departure"] && r["ts1_departure"].length > 0 && ch==0) {
                ch = 3
            }
            if (r["ts1_arrival"] && r["ts1_arrival"].length > 0 && ch==0) {
                ch = 2
            }   
            if (r["loaded_on_board"] && r["loaded_on_board"].length > 0 && ch==0) {
                ch = 1
            }
            if(ch==6){
                return ""
            }
            if (r["etd_airport_of_departure"] && r["etd_airport_of_departure"].length > 0 && ch<1) {
                return MyactionUtils.getdsrdatediff(r["etd_airport_of_departure"],'etd_airport_of_departure')
            }
            if (r["ts1_arrival_estimate"] && r["ts1_arrival_estimate"].length > 0 && ch<2) {
                return MyactionUtils.getdsrdatediff(r["ts1_arrival_estimate"],'ts1_arrival_estimate')
            }
            if (r["ts1_departure_estimate"] && r["ts1_departure_estimate"].length > 0 && ch<3) {
                return MyactionUtils.getdsrdatediff(r["ts1_departure_estimate"],'ts1_departure_estimate')
            }
            if (r["ts2_arrival_estimate"] && r["ts2_arrival_estimate"].length > 0 && ch<4) {
                return MyactionUtils.getdsrdatediff(r["ts2_arrival_estimate"],'ts2_arrival_estimate')
            }
            if (r["ts2_departure_estimate"] && r["ts2_departure_estimate"].length > 0 && ch<5) {
                return MyactionUtils.getdsrdatediff(r["ts2_departure_estimate"],'ts2_departure_estimate')
            }
            if (r["eta_at_arrival_airport"] && r["eta_at_arrival_airport"].length > 0 && ch<6) {
                return MyactionUtils.getdsrdatediff(r["eta_at_arrival_airport"],'eta_at_arrival_airport')
            }

        }else{
            let ch = 0
            if(stype.toLowerCase().indexOf('fcl') != -1){
                if (r.containerlist != undefined && r.containerlist.length != 0) {
                    let lz = 0
                    for (let i = 0; i < r.containerlist.length; i++) {
                        if (r.containerlist[i]['gatein_fpod'] && r.containerlist[i]['gatein_fpod'].length > 0) {
                            lz = 1
                            i = r.containerlist.length+1
                        }
                    }
                    if(lz==1 && ch==0){
                        ch = 16
                    }
                }
            }else{
                if (r["gatein_fpod"] && r["gatein_fpod"].length > 0) {
                    ch = 16
                }
            }
            if(stype.toLowerCase().indexOf('fcl') != -1){
                if (r.containerlist != undefined && r.containerlist.length != 0) {
                    let lz = 0
                    for (let i = 0; i < r.containerlist.length; i++) {
                        if (r.containerlist[i]['gateout_to_fpod'] && r.containerlist[i]['gateout_to_fpod'].length > 0) {
                            lz = 1
                            i = r.containerlist.length+1
                        }
                    }
                    if(lz==1 && ch==0){
                        ch = 15
                    }
                }
            }else{
                if (r["gateout_to_fpod"] && r["gateout_to_fpod"].length > 0 && ch==0) {
                    ch = 15
                }
            }
            if (r["pod_arrival"] && r["pod_arrival"].length > 0 && ch==0) {
                ch = 14
            }
            if (r["reworking_pod_departure"] && r["reworking_pod_departure"].length > 0 && ch==0) {
                ch = 13
            }
            if (r["reworking_pod_arrival"] && r["reworking_pod_arrival"].length > 0 && ch==0) {
                ch = 12
            }
            if (r["ts4_departure"] && r["ts4_departure"].length > 0 && ch==0) {
                ch = 11
            }
            if (r["ts4_arrival"] && r["ts4_arrival"].length > 0 && ch==0) {
                ch = 10
            }
            if (r["ts3_departure"] && r["ts3_departure"].length > 0 && ch==0) {
                ch = 9
            }
            if (r["ts3_arrival"] && r["ts3_arrival"].length > 0 && ch==0) {
                ch = 8
            }
            if (r["ts2_departure"] && r["ts2_departure"].length > 0 && ch==0) {
                ch = 7
            }
            if (r["ts2_arrival"] && r["ts2_arrival"].length > 0 && ch==0) {
                ch = 6
            }
            if (r["ts1_departure"] && r["ts1_departure"].length > 0 && ch==0) {
                ch = 5
            }
            if (r["ts1_arrival"] && r["ts1_arrival"].length > 0 && ch==0) {
                ch = 4
            }
            if (r["reworking_pol_departure"] && r["reworking_pol_departure"].length > 0 && ch==0) {
                ch = 3
            }
            if (r["reworking_pol_arrival"] && r["reworking_pol_arrival"].length > 0 && ch==0) {
                ch = 2
            }
            if (r["sob_pol"] && r["sob_pol"].length > 0 && ch==0) {
                ch = 1
            }
            if(ch==16){
                return ""
            }
            if (r["etd_at_pol"] && r["etd_at_pol"].length > 0 && ch<1) {
                return MyactionUtils.getdsrdatediff(r["etd_at_pol"],'etd_at_pol')
            }
            if (r["reworking_pol_arrival_estimate"] && r["reworking_pol_arrival_estimate"].length > 0 && ch<2) {
                return MyactionUtils.getdsrdatediff(r["reworking_pol_arrival_estimate"],'reworking_pol_arrival_estimate')
            }
            if (r["reworking_pol_departure_estimate"] && r["reworking_pol_departure_estimate"].length > 0 && ch<3) {
                return MyactionUtils.getdsrdatediff(r["reworking_pol_departure_estimate"],'reworking_pol_departure_estimate')
            }
            if (r["ts1_arrival_estimate"] && r["ts1_arrival_estimate"].length > 0 && ch<4) {
                return MyactionUtils.getdsrdatediff(r["ts1_arrival_estimate"],'ts1_arrival_estimate')
            }
            if (r["ts1_departure_estimate"] && r["ts1_departure_estimate"].length > 0 && ch<5) {
                return MyactionUtils.getdsrdatediff(r["ts1_departure_estimate"],'ts1_departure_estimate')
            }
            if (r["ts2_arrival_estimate"] && r["ts2_arrival_estimate"].length > 0 && ch<6) {
                return MyactionUtils.getdsrdatediff(r["ts2_arrival_estimate"],'ts2_arrival_estimate')
            }
            if (r["ts2_departure_estimate"] && r["ts2_departure_estimate"].length > 0 && ch<7) {
                return MyactionUtils.getdsrdatediff(r["ts2_departure_estimate"],'ts2_departure_estimate')
            }
            if (r["ts3_arrival_estimate"] && r["ts3_arrival_estimate"].length > 0 && ch<8) {
                return MyactionUtils.getdsrdatediff(r["ts3_arrival_estimate"],'ts3_arrival_estimate')
            }
            if (r["ts3_departure_estimate"] && r["ts3_departure_estimate"].length > 0 && ch<9) {
                return MyactionUtils.getdsrdatediff(r["ts3_departure_estimate"],'ts3_departure_estimate')
            }
            if (r["ts4_arrival_estimate"] && r["ts4_arrival_estimate"].length > 0 && ch<10) {
                return MyactionUtils.getdsrdatediff(r["ts4_arrival_estimate"],'ts4_arrival_estimate')
            }
            if (r["ts4_departure_estimate"] && r["ts4_departure_estimate"].length > 0 && ch<11) {
                return MyactionUtils.getdsrdatediff(r["ts4_departure_estimate"],'ts4_departure_estimate')
            }
            if (r["reworking_pod_arrival_estimate"] && r["reworking_pod_arrival_estimate"].length > 0 && ch<12) {
                return MyactionUtils.getdsrdatediff(r["reworking_pod_arrival_estimate"],'reworking_pod_arrival_estimate')
            }
            if (r["reworking_pod_departure_estimate"] && r["reworking_pod_departure_estimate"].length > 0 && ch<13) {
                return MyactionUtils.getdsrdatediff(r["reworking_pod_departure_estimate"],'reworking_pod_departure_estimate')
            }
            if (r["eta_at_pod"] && r["eta_at_pod"].length > 0 && ch<14) {
                return MyactionUtils.getdsrdatediff(r["eta_at_pod"],'eta_at_pod')
            }
            if (r["etd_pod"] && r["etd_pod"].length > 0 && ch<15) {
                return MyactionUtils.getdsrdatediff(r["etd_pod"],'etd_pod')
            }
            if (r["eta_fpod"] && r["eta_fpod"].length > 0 && ch<16) {
                return MyactionUtils.getdsrdatediff(r["eta_fpod"],'eta_fpod')
            }
        }
        // if (schedule) {
        //     if (schedule['ETD (POL)']) etdPol = schedule['ETD (POL)']
        //     else if (schedule['oetd']) etdPol = schedule['oetd']
        //     }
        // if (!isDummy && etdPol.length > 0) {
        //     dataest[etdPol] = 'etd_at_pol'
        //     }
        // if (r["etd_at_pol"] && r["etd_at_pol"].length > 0) {
        //     dataest[r["etd_at_pol"]] = 'etd_at_pol'
        //     }
        // if (schedule) {
        //     if (schedule.ts1eta && schedule.ts1eta.length > 0) {
        //         dataest[schedule.ts1eta] = 'ts1_arrival_estimate'
        //     }
        //     }
        //     if (r["ts1_arrival_estimate"] && r["ts1_arrival_estimate"].length > 0) {
        //     dataest[r["ts1_arrival_estimate"]] = 'ts1_arrival_estimate'
        //     }
        // if (schedule) {
        //     if (schedule.ts1etd && schedule.ts1etd.length > 0) {
        //         dataest[schedule.ts1etd] = 'ts1_departure_estimate'
        //     }
        //     }
        // if (r["ts1_departure_estimate"] && r["ts1_departure_estimate"].length > 0) {
        //     dataest[r["ts1_departure_estimate"]] = 'ts1_departure_estimate'
        //                 }
        // if (schedule) {
        //     if (schedule.ts2eta && schedule.ts2eta.length > 0) {
        //         dataest[schedule.ts2eta] = 'ts2_arrival_estimate'
        //             }
        //             }
        // if (r["ts2_arrival_estimate"] && r["ts2_arrival_estimate"].length > 0) {
        //     dataest[r["ts2_arrival_estimate"]] = 'ts2_arrival_estimate'
        //         }
        // if (schedule) {
        //     if (schedule.ts2etd && schedule.ts2etd.length > 0) {
        //         dataest[schedule.ts2etd] = 'ts2_departure_estimate'
        //         }
        //     }
        // if (r["ts2_departure_estimate"] && r["ts2_departure_estimate"].length > 0) {
        //     dataest[r["ts2_departure_estimate"]] = 'ts2_departure_estimate'
        //     }
        // if (schedule) {
        //     if (schedule.ts3eta && schedule.ts3eta.length > 0) {
        //         dataest[schedule.ts3eta] = 'ts3_arrival_estimate'
        //                 }
        //             }
        // if (r["ts3_arrival_estimate"] && r["ts3_arrival_estimate"].length > 0) {
        //     dataest[r["ts3_arrival_estimate"]] = 'ts3_arrival_estimate'
        //             }
        // if (schedule) {
        //     if (schedule.ts3etd && schedule.ts3etd.length > 0) {
        //         dataest[schedule.ts3etd] = 'ts3_departure_estimate'
        //         }
        //         }
        // if (r["ts3_departure_estimate"] && r["ts3_departure_estimate"].length > 0) {
        //     dataest[r["ts3_departure_estimate"]] = 'ts3_departure_estimate'
        //     }
        // if (schedule) {
        //     if (schedule.ts4eta && schedule.ts4eta.length > 0) {
        //         dataest[schedule.ts4eta] = 'ts4_arrival_estimate'
        //     }
        //     }
        // if (r["ts4_arrival_estimate"] && r["ts4_arrival_estimate"].length > 0) {
        //     dataest[r["ts4_arrival_estimate"]] = 'ts4_arrival_estimate'
        //     }
        // if (schedule) {
        //     if (schedule.ts4etd && schedule.ts4etd.length > 0) {
        //         dataest[schedule.ts4etd] = 'ts4_departure_estimate'
        //     }
        //     }
        //     if (r["ts4_departure_estimate"] && r["ts4_departure_estimate"].length > 0) {
        //     dataest[r["ts4_departure_estimate"]] = 'ts4_departure_estimate'
        //     }
        
        // if (r["etd_airport_of_departure"] && r["etd_airport_of_departure"].length > 0) {
        //     dataest[r["etd_airport_of_departure"]] = 'etd_airport_of_departure'
        //     }
        // if (r["reworking_pod_arrival_estimate"] && r["reworking_pod_arrival_estimate"].length > 0) {
        //     dataest[r["reworking_pod_arrival_estimate"]] = 'reworking_pod_arrival_estimate'
        //     }
        // if (r["reworking_pod_departure_estimate"] && r["reworking_pod_departure_estimate"].length > 0) {
        //     dataest[r["reworking_pod_departure_estimate"]] = 'reworking_pod_departure_estimate'
        //     }
        // if (r["reworking_pol_departure_estimate"] && r["reworking_pol_departure_estimate"].length > 0) {
        //     dataest[r["reworking_pol_departure_estimate"]] = 'reworking_pol_departure_estimate'
        //     }
        // if (r["reworking_pol_arrival_estimate"] && r["reworking_pol_arrival_estimate"].length > 0) {
        //     dataest[r["reworking_pol_arrival_estimate"]] = 'reworking_pol_arrival_estimate'
        //     }
        // if (r["eta_at_arrival_airport"] && r["eta_at_arrival_airport"].length > 0) {
        //     dataest[r["eta_at_arrival_airport"]] = 'eta_at_arrival_airport'
        //     }
        // if (r["eta_fpod"] && r["eta_fpod"].length > 0) {
        //     dataest[r["eta_fpod"]] = 'eta_fpod'
        //     }
        // if (r["etd_pod"] && r["etd_pod"].length > 0) {
        //     dataest[r["etd_pod"]] = 'etd_pod'
        //     }
        
        // if (r.containerlist != undefined && r.containerlist.length != 0) {
        //     for (let i = 0; i < r.containerlist.length; i++) {
        //         if (r.containerlist[i]['estimated_delivery_date_to_consignee'] && r.containerlist[i]['estimated_delivery_date_to_consignee'].length > 0) {
        //             dataest[r.containerlist[i]['estimated_delivery_date_to_consignee']] = 'estimated_delivery_date_to_consignee'
        //     }
        //     }
        //     }
        
        // if (r.vdes != undefined && r.vdes.length != 0) {
        //     for (let i = 0; i < r.vdes.length; i++) {
        //         if (r.vdes[i]['estimated_delivery_date_to_consignee'] && r.vdes[i]['estimated_delivery_date_to_consignee'].length > 0) {
        //             dataest[r.vdes[i]['estimated_delivery_date_to_consignee']] = 'estimated_delivery_date_to_consignee'
        //     }
        //     }
        //     }

        // let dl = Object.keys(dataest)
        // console.log(dataest)
        // if(dl.length>0){
        //     let d = MyactionUtils.getmindate(dl)
        //     console.log(d,dataest[d])
        //     return MyactionUtils.getdsrdatediff(d,dataest[d])
        // }else{
        //         return ""
        //     }
        // console.log("++++++++++++++++++++++++++++++++",dl)
       
        return ""
    }
    if (e1.mbl_number) bl_number = e1.mbl_number
    if (e1.hbl_number) bl_number = e1.hbl_number
    if (e1.shipmentType === 'Air') blLabel = 'AWB Number'
    if (e1.mawb_number) bl_number = e1.mawb_number
    if (e1.hawb_number) bl_number = e1.hawb_number
    const refreshproject = (data)=>{
        setCriticalChanges(data)
        if(myactionsstore.recheck==0){
            myactionsstore.recheck = 1
        }else{
            myactionsstore.recheck = 0
        }
        loader.hide()
        if (data.length > 0){
            setShowConfirmation(true)
        }
    }

    const onConfirm=(key)=>{
        if (key === "save"){
         Api.getproject44data( {id: e1.project44_1d,key:key, changes: criticalChanges},refreshproject)
        }
         setShowConfirmation(false)
    }
    const onAcceptance = (action, index) => {
        const updatedChanges = [...criticalChanges];
        updatedChanges[index].acceptance = action;
    
        setCriticalChanges(updatedChanges);
    };

    const ConfirmationPopup = () => {
        return (
            <div className="modal is-active" style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
                <div className="modal-background"></div>
                <div className="modal-content">
                    <div className="box" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <h1 className="has-text-weight-bold">Confirm critical changes</h1>
                        <table className="table is-bordered is-striped is-narrow is-hoverable is-fullwidth">
                <thead>
                    <tr>
                        <th >
                            <h3 className="is-size-5 has-text-centered">Milestone</h3>
                        </th>
                        <th >
                            <h3 className="is-size-5 has-text-centered">Original Value</h3>
                        </th>
                        <th >
                            <h3 className="is-size-5 has-text-centered">New Value</h3>
                        </th>
                        <th >
                            <h3 className="is-size-5 has-text-centered"></h3>
                        </th>
                    </tr>
                </thead>
                <tbody>
                {
    criticalChanges?.map((e, i) => {
        return (
            <tr key={e.key}>
                <td className="has-text-centered">{e.label}</td>
                <td className="has-text-centered">{e.originalVal}</td>
                <td className="has-text-centered">{e.newVal}</td>

                <td className="has-text-centered">
                    {e.acceptance === '' && (
                        <button 
                            className="button is-warning" 
                            onClick={() => onAcceptance("acceptDate", i)}>
                            Accept without port change
                        </button>
                    )}
                    {e.acceptance === 'acceptDate' && (
                        <span>Only date is accepted</span>
                    )}
                </td>

                <td className="has-text-centered">
                    {e.acceptance === '' && (
                        <button 
                            className="button is-success" 
                            onClick={() => onAcceptance("acceptAll", i)}>
                            Accept
                        </button>
                    )}
                    {e.acceptance === 'acceptAll' && (
                        <span>Accepted</span>
                    )}
                </td>
            </tr>
        );
    })
}

                </tbody>
            </table>
                        <div className="buttons" style={{ display: 'flex', justifyContent: 'space-between', width: '100%', marginTop: '20px' }}>
                            <button className="button is-danger" onClick={() => onConfirm("decline")}>Cancel</button>
                            <button className="button is-link" onClick={() => onConfirm("save")}>Save</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    };


    return <div className="box is-fullwidth" style={ loginStore.isClient() ? { border : "none" } : { border: '1px solid lightgrey' }}>
        <div className="columns" style={{ fontSize: '0.8rem', color: '#000055' }}>
            {
                (!loginStore.isClient()) ? <>
                    <div className="column is-4" style={{ textAlign: 'left' }}>
                        <div>Agraga Booking Ref # : <strong>{e1['agraga_booking_#']}</strong></div>
                        {/* {e1?.icegateReqData && <><div>Shipping Bill Date : <strong>{e1?.icegateReqData?.date}</strong></div>
                        <div>Shipping Bill Number : <strong>{e1?.icegateReqData?.no}</strong></div>
                        <div>Shipping Bill Status : <strong>{e1?.icegateReqData?.mismatch?"Pending":"Verified"}</strong></div></>} */}
                        {
                            (e1.booking.bookingDate) ?
                                <div>Booking Date : <strong>{Utils.ddmmyyyystring(e1['booking']['bookingDate'])}</strong></div>
                                : <></>

                        }
                        {
                            (e1.booking.contract.customerReferenceNum) ?
                                <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }} title={e1.booking.contract.customerReferenceNum}>Customer Ref # : <strong>{e1.booking.contract.customerReferenceNum}</strong></div>
                                : <></>
                        }
                        {
                            (e1.booking.shipper) ?
                                <div>Shipper : <strong>{e1['booking']['shipper']}</strong></div>
                                : <></>

                        }
                        {
                            (e1.Branches?.branchId) ?
                                <div>Branch : <strong>{e1.Branches?.state?.split("/")[0] + ' -' + (e1.Branches.gst.length > 0 ? e1.Branches.gst : e1?.Entities?.tinNum)}</strong></div>
                                : <></>

                        }
                        {
                            (e1.booking.consignee) ?
                                <div>Consignee : <strong>{e1['booking']['consignee']}</strong></div>
                                : <></>

                        }

                    </div>

                    <div className="column is-4" style={{ textAlign: 'left' }}>
                        {(e1.status_closed == 'Y' || e1.status_closed == 'Yes' || e1.booking.status == 'ARCHIVED') ? <>
                        {
                            (e1['tsUpdatedOn'] && e1['tsUpdatedBy']) ? <AgLastUpload data={{ updatedOn: e1['tsUpdatedOn'], updatedBy: e1['tsUpdatedBy'] }} /> : <></>
                        }
                        </> : <>
                        {
                            (e1['tsUpdatedOn'] && e1['tsUpdatedBy']) ? <AgLastUpload1 data={{ updatedOn: e1['tsUpdatedOn'], updatedBy: e1['tsUpdatedBy'] }} nextdue={nextdueget(e1)} /> : <>
                            {
                                <AgLastUpload2 nextdue={nextdueget(e1)} />
                            }
                            </>
                        }
                        </>}
                        {
                        (e1.shipmentType).includes("FCL") && e1.containerlist && e1.containerlist.length > 0 && e1.booking.containershow ? <>
                        <div className="select" style={{position:"relative",left:"7em",top:"1rem",}}>
                        <select style={{width:"10em",fontWeight:"bold"}} value={e1.booking.containershow} onChange={(e)=>{
                            for (let i = 0; i < myactionsstore.list.length; i++) {
                                if(e1._id==myactionsstore.list[i]['_id']){
                                    myactionsstore.list[i]['booking']['containershow'] = e.target.value
                                    i = myactionsstore.list.length+1
                                }
                            }
                        }}>
                            <option value="-1">All Container</option>
                            {
                                e1.containerlist.map((e,i)=>{
                                    return <option value={i}>{i+1}. {e.container_types}{(e.container_number && e.container_number.length)?"-"+e.container_number:""}</option>
                                })
                            }
                        </select>
                        </div>
                        </> : ""
            }
                        
                    </div>
                    
                    <div className="column is-4" style={{ textAlign: 'right' }}>
                        <div>Shipment Type : <strong>{e1['shipmentType']}</strong></div>
                        {
                            (bl_number) ?
                                <div>{blLabel} : {bl_number}</div> : <></>
                        }
                        <div>Status : <strong>
                            {(e1.status_closed == 'Y' || e1.status_closed == 'Yes' || e1.booking.status == 'ARCHIVED') ? <>Complete</> : <>In-transit</>}
                        </strong></div>
                        {
                            (e1['shipmentType'].toLowerCase().indexOf('fcl')!=-1)?
                            <div>Carrier : <strong>
                            {e1.booking.contract.carrier}
                        </strong></div>:""
                        }
                        {
                            (e1['shipmentType'].toLowerCase().indexOf('lcl')!=-1 && e1.carrier && e1.carrier.length>0)?
                            <div>Carrier : <strong>
                            {e1.carrier}
                        </strong></div>:""
                        }
                        {
                            (e1['shipmentType'].toLowerCase().indexOf('air')!=-1 && e1.booking.contract.airline && e1.booking.contract.airline.length>0 && airlinelist.length>0)?
                            <div>Airline : <strong>
                            {airlinelist[0][e1.booking.contract.airline]}
                        </strong></div>:""
                        }
                        
                            
                            <div>
                                {
                                    (!props.hideedit && RoleUtils.isUserAuthorized(PERMISSIONS.EDIT.EDIT_VISIBILITY))?
                                    <button className="button is-info is-inverted is-large" style={{top:"0.7em"}}  onClick={()=>{
                                       
                                        EntityV2Api.checkMyactionEnt(e1.booking.entityId , e1.booking.branchId , (data)=> {
                                            if(data.status === 200) {
                                                if(myactionsstore.error.length>0){
                                            myactionsstore.error = []
                                        }
                                        configStore.modalvisibledata = e1
                                        configStore.quotationModalVisible = true
                                                }
                                           
                                        })
                                                                        
                                    }}><span className="icon"><i className="fas fa-pen-to-square fas fa-lg "></i></span></button>:""
                                }
                            
                            {(e1.project44_1d)?<button className="button is-info is-inverted is-large" style={{top:"0.7em"}}  onClick={async ()=>{
                                loader.show()
                                const refreshObj = {id: e1.project44_1d, changes: criticalChanges, key: "updateCheck"}
                                await Api.getproject44data(refreshObj,refreshproject)
                            }}><span className="icon"><i className="fas fa-refresh fas fa-lg "></i></span></button>:""}
                           {(e1.intermo)?<button className="button is-info is-inverted is-large" style={{top:"0.7em"}}  onClick={async ()=>{
                                // loader.show()
                                await Api.getintermodata(e1.mawb_number,refreshproject)
                            }}><span className="icon"><i className="fas fa-refresh fas fa-lg "></i></span></button>:""}
                            </div>
                        
                    </div></> : <>
                    <div className="column is-4" style={{ textAlign: 'left' }}>
                        <div>Agraga Booking Ref # : <strong>{e1['agraga_booking_#']}</strong></div>
                        {
                            (e1.booking.contract.customerReferenceNum) ?
                                <div>Customer Ref # : <strong>{e1.booking.contract.customerReferenceNum}</strong></div>
                                : <></>
                        }
                        {
                            (e1.branch_name) ?
                                <div>Branch Name: <strong>{e1.branch_name}</strong></div>
                                : <></>
                        }
                    </div>
                    <div className="column is-4" style={{ textAlign: 'center' }}>

                        {
                        (e1.shipmentType).includes("FCL") && e1.containerlist && e1.containerlist.length > 0 && e1.booking.containershow ? <>
                        <div className="select" style={{position:"relative",right:"1em",top:"1rem"}}>
                        <select style={{width:"10em"}} value={e1.booking.containershow} onChange={(e)=>{
                            for (let i = 0; i < myactionsstore.list.length; i++) {
                                if(e1._id==myactionsstore.list[i]['_id']){
                                    myactionsstore.list[i]['booking']['containershow'] = e.target.value
                                    i = myactionsstore.list.length+1
                                }
                            }
                        }}>
                            <option value="-1">All Container</option>
                            {
                                e1.containerlist.map((e,i)=>{
                                    return <option value={i}>{i+1}. {e.container_types}{(e.container_number && e.container_number.length)?"-"+e.container_number:""}</option>
                                })
                            }
                        </select>
                        </div>
                        </> : ""
            }
                                    </div>

                    <div className="column is-4" style={{ textAlign: 'right' }}>
                        <div>Shipment Type : <strong>{e1['shipmentType']}</strong></div>
                        {
                            (bl_number) ?
                                <div>{blLabel} : {bl_number}</div> : <></>
                        }
                        <div>Status : <strong>
                            {(e1.status_closed == 'Y' || e1.status_closed == 'Yes' || e1.booking.status == 'ARCHIVED') ? <>Complete</> : <>In-transit</>}
                        </strong></div>
                        {
                            (e1['shipmentType'].toLowerCase().indexOf('fcl')!=-1)?
                            <div>Carrier : <strong>
                            {e1.booking.contract.carrier}
                        </strong></div>:""
                        }
                        {
                            (e1['shipmentType'].toLowerCase().indexOf('lcl')!=-1 && e1.carrier && e1.carrier.length>0)?
                            <div>Carrier : <strong>
                            {e1.carrier}
                        </strong></div>:""
                        }
                        {
                            (e1['shipmentType'].toLowerCase().indexOf('air')!=-1 && e1.booking.contract.airline && e1.booking.contract.airline.length>0 && airlinelist.length>0)?
                            <div>Airline : <strong>
                            {airlinelist[0][e1.booking.contract.airline]}
                        </strong></div>:""
                        }
                    </div>
                </>

            }

        </div>
        <div className="columns">
            <div className="column is-12" >
                <ShipmentGraph graphNodes={e} />
            </div>
        </div>
        <div className="columns" style={{ fontSize: '0.8rem' }}>
            <div className="column is-6 py-2">
                {
                    (showDetails) ?
                    loginStore.isClient() ? 
                    <Button size='small'  onClick={() => {
                        setShowDetails(false)
                    }} variant='contained' endIcon={ <ArrowDropUp/>} fullWidth sx={{display:"flex",textTransform:"capitalize",alignItems:"center",backgroundColor:"#ffdc7d",color:"black","&:hover":{
                        backgroundColor:"#ffdc7d"
                    }}} >  Hide Details</Button>
                    :
                        <button className='button is-warning is-small is-fullwidth'
                            onClick={() => setShowDetails(false)}>
                            Hide Details
                            <span className="icon" style={{ marginLeft: '5px' }}>
                                <i className="fas fa-sort-up"></i>
                            </span>
                        </button> : loginStore.isClient() ?  <Button size="small"  onClick={() => {
                         setShowDetails(true)
                         setShowDocuments(false)
                    }} variant='contained' endIcon={ <ArrowDropDown />} fullWidth sx={{textTransform:"capitalize",alignItems:"center"}} >  Show Details</Button> :
                        <button className='button is-info is-small is-fullwidth'
                            onClick={() => {
                                setShowDetails(true)
                                setShowDocuments(false)
                            }}>
                            Show Details
                            <span className="icon" style={{ marginLeft: '5px' }}>
                                <i className="fas fa-sort-down"></i>
                            </span>
                        </button>
                }
            </div>
            {<div className="column is-6 py-2">
                {
                    (showDocuments) ?
                    loginStore.isClient() ? 
                    <Button size='small'  onClick={() => {
                        setShowDocuments(false)
                    }} variant='contained' endIcon={ <ArrowDropUp/>} fullWidth sx={{display:"flex",textTransform:"capitalize",alignItems:"center",backgroundColor:"#ffdc7d",color:"black","&:hover":{
                        backgroundColor:"#ffdc7d"
                    }}} >  Hide Document Center</Button>
                    :
                    
                        <button className='button is-warning is-small is-fullwidth'
                            onClick={() => setShowDocuments(false)}>
                            Hide Document Center
                            <span className="icon" style={{ marginLeft: '5px' }}>
                                <i className="fas fa-sort-up"></i>
                            </span>
                        </button> :
                        loginStore.isClient() ?  <Button size="small"  onClick={() => {
                            setShowDocuments(true)
                            setShowDetails(false)
                       }} variant='contained' endIcon={ <ArrowDropDown />} fullWidth sx={{textTransform:"capitalize",alignItems:"center"}} >  Document Center</Button> :
                        <button className='button is-info is-small is-fullwidth'
                            onClick={() => {
                                setShowDocuments(true)
                                setShowDetails(false)
                            }}>
                            Document Center
                            <span className="icon" style={{ marginLeft: '5px' }}>
                                <i className="fas fa-sort-down"></i>
                            </span>
                        </button>
                }
            </div>}
        </div>
        {
            (showDetails) ?
                <div className="columns" style={{
                    fontSize: '0.8rem'
                }}>
                    <div className="column is-12">
                        <VisibilityTable dsrRec={props.dsrRec} /></div>
                </div> : <></>
        }
        {
            (showDocuments) ? 
                <div className="columns" style={{
                    fontSize: '0.8rem'
                }}>
                    <div className="column is-12" >
                       <DocumentCenter dsrRec={props.dsrRec} /></div>
                </div> : <></>
        }
        {showConfirmation && (
                <ConfirmationPopup
                    onConfirm={handleConfirmation}
                />
            )}
    </div>
}