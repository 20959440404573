import { Box, IconButton, Typography } from "@mui/material";
import closecontained from "../assets/images/closecontained.svg";
import Utils from "../util/Utils";
import { useState } from "react";
import { DocumentViewer } from "../pages/DocumentViewer";

export function UploadFileComponent({ onRemove, documentTitle, bookingId,view,isDeletable=true }) {
    const [selectedDocument, setSelectedDocument] = useState()
    const [open, setOpen] = useState(false)
    let server = process.env.REACT_APP_FILE_SERVER
    const documentViewrHandler = (documentTitle, documentUrl) => {
        setSelectedDocument({ documentTitle, documentUrl })
        setOpen(true);
    }
    const fileName = Utils.getFileExtension(documentTitle);
    const fileNameWithoutExtension = Utils.getFileNameWithoutExtension(documentTitle);
    const fileViewProperties = Utils.getPropertiesForFileExtension(fileName);
    return<> <Box sx={{ display: "flex", flexDirection: "row", width: "200px", backgroundColor: "white", height: "40px", borderRadius: "6px", alignItems: "center", border: "1px solid #D4D4D4", gap: 0.5, paddingX: "6px", justifyContent: "space-between" }}>
        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", borderRadius: "6px", height: "30px", minWidth: "30px", backgroundColor: fileViewProperties?.backgroundColor }}><img src={fileViewProperties?.icon} /></Box>
        <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "start",flex:1 }}>
            <Typography sx={{ fontSize: "12px", fontFamily: "Figtree", marginRight: "auto", color: "#555555", overflow: "hidden", textOverflow: "ellipsis", cursor: 'pointer',width:"100px" }} onClick={() => documentViewrHandler(documentTitle, `${server}/api/v1/filestore/getfile/${bookingId}/${documentTitle}`)}>{fileNameWithoutExtension}</Typography>
            <Typography sx={{ fontSize: "12px", fontFamily: "Figtree",color: "#555555" }}>{"." + fileName}</Typography>
        </Box>
    { isDeletable && <IconButton sx={{padding:0}} onClick={()=>{
        if(onRemove){
                onRemove();
            }
        }}><img src={closecontained} /></IconButton>}
        
    </Box>
    {view?( open && <DocumentViewer isExcel={selectedDocument?.documentUrl?.includes("xlsx") || selectedDocument?.documentUrl?.includes("xls")} open={open} documentTitle={selectedDocument.documentTitle} documentUrl={selectedDocument.documentUrl} onClose={() => {
        setOpen(false);
        selectedDocument.current = null;
    }} download={false} />):<></>}
    </>
}