import React,{useState,useEffect, useRef} from "react";
import {useTable,usePagination,useSortBy} from "react-table"
import { QueryClient,QueryClientProvider } from "react-query";


import { QuotationColumn } from "../components/ag-table/columns";

import ReactTablePagination from "../components/ag-table/ReactTablePagination";
import TableFilter from "../components/ag-table/TableFilter";


import {reducer,PAGE_CHANGED,PAGE_SIZE_CHANGED,PAGE_SORT_CHANGED,PAGE_FILTER_CHANGED,TOTAL_COUNT_CHANGED,PAYLOAD_CHANGED} from "../components/ag-table/ag-reducer"
import ApiAgTable from "../components/ag-table/api_ag-table";
import { bookingsStore, AgmodalStore, configStore,contractsStore,entityStore,loginStore, modalStore,quoteStore,customerStore,quotatationImportStore, initiaizeCommonState, setCommonState } from "../state/CommonState";
import { useSnapshot } from "valtio";
import { enquiryStore } from "../enquiry/enquiry-data";
import { AgWarnPopup } from "../components/AgCustomComponents";
import AgQuotationImportForm from "../components/ag-import";
import Api from "../state/Api";
import EntityApi from "../state/entity-api";
import BookingsApi from "../state/bookings-api";
import { taskStore } from "../mytasks/task-store";
import Taskapi, { Task } from "../mytasks/tasks-api";
import { getPayload } from "../mytasks/tasks-static-data";

import { Buffer } from 'buffer';
import Utils from "../util/Utils";
import RoleUtils from "../util/RoleUtils";
import TableHead from "../util/dataTable";
import { ENTITY_STATUS, PERMISSIONS } from "../util/constants";
import EntityV2Api from "../state/entities-api";
import { AgLoader } from "../components/ag-loader/ag-loader";

const queryClient = new QueryClient()

const initialState = {
    queryPageIndex: 0,
    queryPageSize: 20,
    totalCount: 0,
    queryPageFilter: "",
    queryPageSortBy: [],
    queryPayload: {}
};

const DataTable = (props) => {
    useSnapshot(quoteStore)
    useSnapshot(customerStore)
    useSnapshot(quotatationImportStore)
    useSnapshot(AgmodalStore)
    useSnapshot(configStore)
    useSnapshot(modalStore)
    const [lid,setLid] = useState('')
    const [qin,SetQin] = useState('')


    const filterStatus = {
        'DRAFT':'DRAFT',
        'FINAL': 'FINAL',
        'ACCEPTED': 'ACCEPTED',
        'DECLINED': 'DECLINED',
        'WON': 'WON',
        'LOST': 'LOST',
        'INVALID': 'INVALID',
        'DRAFT(Enquiry)':'DRAFT(Enquiry)'
    }

    const [selectedFilter, SetSelectedFilter] = useState(`ALL`);
    let [refresh, setRefresh] = useState(false)

    const handleRefresh = () => {
        setRefresh(!refresh)
    };
    const myRole = loginStore.userRec.aguserRec.role
    // const canEdit = (['CUSTOMER SERVICE', 'SALES', 'AGRAGA ADMIN', 'PRODUCTS'].indexOf(myRole) >= 0)
    const canEdit = RoleUtils.isUserAuthorized(PERMISSIONS.OLD.NEW_QUOTATIONS)
    const canEqEdit = RoleUtils.isUserAuthorized(PERMISSIONS.OLD.NEW_ENQUIRY_QUOTATIONS)
    const [agWarnPopupParams, setAgWarnPopupParams] = useState({
        callback: null,
        warnMsg: ''
    });
    const HandleLost = async (id) =>{
        await BookingsApi.getForContract(id+'C', async (data)=>{
            modalStore.lostShowReason = ''
            modalStore.lostShowBooks = data
            setLid(id)
            configStore.quotationModalVisible = true
        })
        
    } 
    const HandleInvalid = async (id) =>{
        await BookingsApi.getForContract(id+'C', async (data)=>{
            modalStore.invalidShowReason = ''
            modalStore.invalidShowBooks = data
            SetQin(id)
            configStore.quotationModalVisible1 = true
        })
    }  


    let column = {}

    if(!loginStore.isClient()){
        column = QuotationColumn
    }else{
        column = {}
    }

    const [keyword, setKeyword] = useState('');
    const [loading, setloading] = useState(true);
    const [data1, setData1] = useState({ results: [] })
    const onClickFilterCallback = (filter) => {
        setKeyword(filter)
    }
    let columns = column
    const [{ queryPageIndex, queryPageSize, totalCount, queryPageFilter, queryPageSortBy, queryPayload }, dispatch] =
    React.useReducer(reducer, initialState);
    
    // console.log("DATA#####4",valueStore.status,valueStore.page,valueStore.limit,valueStore.keyword)
        
        // if(valueStore.status !== initialState.queryPayload.status && queryPayload.status != '' ){
            

    // useEffect(()=>{
    //     console.log("hhhh",queryPayload,"hhhh",queryPageIndex,"hhhh",queryPageSize,"hhhh",queryPageFilter,"hhhh",queryPageSortBy);
    //         ApiAgTable.quotationData(queryPageIndex, queryPageSize, queryPageFilter, queryPageSortBy, queryPayload).then(res=>{
    //             res.clone().json().then((res) => {
    //                 if (JSON.stringify(data1.results) != JSON.stringify(res.results)) {
    //                    if(JSON.stringify(data1)!=JSON.stringify(res)){
    //                     setData1(res)
    //                     setloading(false)
    //                    }
    //                 }
    //             }, err => {
    //                 console.log(err)
    //             })
    //         })

           
    // },[queryPayload,queryPageIndex,queryPageSize,queryPageFilter,queryPageSortBy])
     
    const [hasMounted, setHasMounted] = useState(false);

    useEffect(() => {
        if (hasMounted && AgmodalStore.apiCall) {
            console.log("hhhh", queryPayload, "hhhh", queryPageIndex, "hhhh", queryPageSize, "hhhh", queryPageFilter, "hhhh", queryPageSortBy);
            ApiAgTable.quotationData(queryPageIndex, queryPageSize, queryPageFilter, queryPageSortBy, queryPayload).then(res => {
                res.clone().json().then((res) => {
                    if (JSON.stringify(data1.results) !== JSON.stringify(res.results)) {
                        if (JSON.stringify(data1) !== JSON.stringify(res) && res.results && res.results.length > 0) {
                            setData1(res);
                            setloading(false);
                        }else{
                            setData1({ count: 0, results: [] });
                        }
                    }
                }, err => {
                    console.log(err);
                });
            });
        } else {
            setHasMounted(true);
        }
    }, [queryPayload, queryPageIndex, queryPageSize, queryPageFilter, queryPageSortBy, hasMounted, AgmodalStore.apiCall]);
    


    const totalPageCount = Math.ceil(totalCount / queryPageSize)

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        page,
        pageCount,
        pageOptions,
        gotoPage,
        previousPage,
        canPreviousPage,
        nextPage,
        canNextPage,
        setPageSize,
        state: { pageIndex, pageSize, sortBy }
    } = useTable({
        columns,
        data: data1?.results,
        initialState: {
            pageIndex: queryPageIndex,
            pageSize: queryPageSize,
            sortBy: queryPageSortBy,
        },
        manualPagination: true,
        pageCount: data1 ? totalPageCount : null,
        autoResetSortBy: false,
        autoResetExpanded: false,
        autoResetPage: false
    },
        useSortBy,
        usePagination,
    );


    const manualPageSize = []

    useEffect(() => {
        dispatch({ type: PAGE_CHANGED, payload: pageIndex });
    }, [pageIndex,gotoPage]);

    useEffect(() => {
        dispatch({ type: PAGE_SIZE_CHANGED, payload: pageSize });
        gotoPage(0);
    }, [pageSize, gotoPage]);

    useEffect(() => {
        dispatch({ type: PAGE_SORT_CHANGED, payload: sortBy });
        gotoPage(0);
    }, [sortBy, gotoPage]);

    useEffect(() => {
    const timer = setTimeout(() => {
    const updatedKeyword = keyword.replace(/\s/g, '');
    if ((keyword.length == 0 && updatedKeyword.length == 0) || updatedKeyword.length >= 3) {
        dispatch({ type: PAGE_FILTER_CHANGED, payload: keyword });
        gotoPage(0);
    }
    }, 300);
    return () => clearTimeout(timer);
    }, [keyword, gotoPage]);


    React.useEffect(() => {
        if (data1?.count) {
            dispatch({
                type: TOTAL_COUNT_CHANGED,
                payload: data1.count,
            });
        }
    }, [data1?.count]);

    useEffect(() => {

        dispatch({ type: PAYLOAD_CHANGED, payload: { status:selectedFilter } });
        gotoPage(0);
    }, [modalStore, configStore, selectedFilter, refresh]);

    useEffect(()=>{
        if(!configStore.quotationModalVisible){
            dispatch({ type: PAYLOAD_CHANGED, payload: { status:selectedFilter } });
        }
    },[configStore.quotationModalVisible])

        return  (
    <>
    {
            (configStore.quotationModalVisible)?<ActrejectModal4 id={lid}/>:<></>
        }
        {
            (configStore.quotationModalVisible1) ? <ActrejectModal5 id={qin} data={handleRefresh} /> : <></>
        }
        {
            (modalStore.lostShowModal) ? <AcceptLostPopup id={lid} /> : <></>
        }
        {
            (modalStore.invalidShowModal) ? <AcceptInvalidPopup id={qin} /> : <></>
        }


        <AgWarnPopup callback={agWarnPopupParams.callback} data={handleRefresh}>
                <p className="is-size-7">{agWarnPopupParams.warnMsg}</p>
            </AgWarnPopup>
            <h1 className="title">Quotations</h1>

        <nav className="level">
        <div className="level-left">
            <div className="level-item">
                        <p className="subtitle is-5">
                            {data1?.count } Quotes
                        </p>
                    </div>

        {
                        (canEdit) ?
                            <p className="level-item">
                                <a className="button is-link"
                                    onClick={() => {
                                        initiaizeCommonState()
                                        configStore.disableShipmentType = ''
                                        AgmodalStore.modalPage = "Quotev3";
                                        AgmodalStore.apiCall = false
                                        AgmodalStore.pageVisible = true
                                        console.log('New clicked')
                                    }}>New</a>
                            </p> : <></>
                    }
                    <div class="level-item">
                    <TableFilter onClickFilterCallback={onClickFilterCallback} defaultKeyword={keyword} deBouncingFlag={true} />
                    </div>
            </div>
            <div class="level-item level-left ml-6">
                         <div class="field">
                             <p class="control has-icons-left has-icons-right">
                                 <span class="select">
                                     <select name="status" id="status" 
                                     value={selectedFilter} onChange={(e) => SetSelectedFilter(e.target.value)}
                                     >
                                         <option value="ALL">ALL</option>
                                         {
                                             Object.entries(filterStatus).map(([key, value]) => (
                                                 <option value={key}>{value}</option>
                                             ))
                                         }
                                     </select>
                                     <span class="icon">
                                         <i class="fa-solid fa-filter"></i>
                                     </span>
                                 </span>
                             </p>
                         </div>
                         
                     </div>
                     {/* {
                    <div className="level-left" style={{position:"relative",right:"10rem"}}>
                        <div className="level-item">
                            <p className="control">
                                <button className="button is-danger is-small is-rounded" onClick={() => quotatationImportStore.isFormVisible = !quotatationImportStore.isFormVisible}>
                                    {!quotatationImportStore.isFormVisible ? 'Import' : 'Cancel'}
                                </button>
                            </p>
                        </div>
                    </div>
                } */}
                     {
                    (!loginStore.isClient()) ?
                        <div className="level-right">
                            <div className="level-item">
                                <p className="control">
                                    <a className="button is-danger is-small is-rounded"
                                        href={process.env.REACT_APP_API_SERVER + '/api/v1/quotes/generate/report'}>
                                        Download</a>
                                </p>
                            </div>
                        </div> : <></>
                }
            </nav>
            {
                quotatationImportStore.isFormVisible && <AgQuotationImportForm></AgQuotationImportForm>
            } 
           
            <div className="box">
                <div className="table-container" style={{ overflow: 'initial' }}>
                    <table className="table is-fullwidth is-small ">
                        <TableHead headerGroups={headerGroups} sort={true}/>
                        <tbody className="contractlist" style={{ fontSize: '0.9rem',position:"relative" }} {...getTableBodyProps()}>
                                                {!loading ?page.map((row, i) => {
                                                        prepareRow(row);
                                                    return (
                                                        <tr 
                                                        
                                                        {...row.getRowProps()} 
                                                        // onClick={() => {
                                                        //     if (!loginStore.isClient()) {
                                                        //         window.open(`${window.location.href}quotation/view/${row.original.Quotes._id}`, '_blank').focus();
                                                        //         return;
                                                        //     }
                                                        // }}
                                                        >
                                                            {
                                                                row.cells.map(cell => {
                                                                    {
                                                                        let e = cell['row']['original']
                                                                        if(e.Quotes.status !== 'DRAFT(Enquiry)' && !canEqEdit) {
                                                                        switch (cell['column']['Header']) {
                                                                            case "Date":
                                                                                return <td>{Utils.ddmmyyyystring(e.Quotes.mailedOn)}</td>
                                                                            case "Destination":
                                                                                return <td>{e.Quotes?.destination2!=undefined?"Multiple":e.Quotes?.destination}</td>
                                                                            case "Quotation Ref. #":
                                                                                return <td
                                                                                // onClick={() => {
                                                                                // 	Api.getQuote(e._id);
                                                                                // 	configStore.quoteCurrentView =
                                                                                // 		'view';
                                                                                // }}
                                                                                ><a  onClick={() => {
                                                                                        AgmodalStore.pageVisible = true;
                                                                                        AgmodalStore.modalPage = "QuoteViewMainv3";
                                                                                    	Api.getQuote(e._id);
                                                                                    }}>{e.Quotes._id}</a>
                                                                                    {/* {
                                                                                        (e.Quotes.status == 'DRAFT(Enquiry)') ? 
                                                                                        <a>{e.Quotes._id}</a>
                                                                                        :
                                                                                        <a
                                                                                        href={`${window.location.href}quotation/view/${e.Quotes._id}`} target="_blank" onClick={(event) => {
                                                                                            event.stopPropagation();
                                                                                        }}
                                                                                    >{e.Quotes._id}</a>
                                                                                    } */}
                                                                                </td>
                                                                            case "Status":
                                                                                return <td>
                                                                                {
                                                                                    (e.Quotes.status === 'DECLINED') ?
                                                                                        // <>{e.status}</> :
                                                                                        <>
                                                                                            <div
                                                                                                class='dropdown post-options is-right is-hoverable '
                                                                                                onClick={null}
                                                                                            >
                                                                                                <div class='dropdown-trigger'>
                                                                                                    {e.Quotes.status}
                                                                                                </div>
                                                                                                <div
                                                                                                    class='dropdown-menu'
                                                                                                    id='dropdown-menu-post'
                                                                                                    role='menu'
                                                                                                >
                                                                                                    <div class='dropdown-content'>
                                                                                                        <a class='dropdown-item'>
                                                                                                            {e.Quotes.decline_reason}
                                                                                                        </a>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </> : <>
                                                                                        {
                                                                                            (e.Quotes.status === 'LOST')?<div
                                                                                            class='dropdown post-options is-right is-hoverable '
                                                                                            onClick={null}
                                                                                        >
                                                                                            <div class='dropdown-trigger'>
                                                                                                {e.Quotes.status}
                                                                                            </div>
                                                                                            <div
                                                                                                class='dropdown-menu'
                                                                                                id='dropdown-menu-post'
                                                                                                role='menu'
                                                                                            >
                                                                                                <div class='dropdown-content'>
                                                                                                    <a class='dropdown-item'>
                                                                                                        {e.Quotes.LostReason}
                                                                                                    </a>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>:<>
                                                                                            {
                                                                                                (e.Quotes.status === 'INVALID') ? 
                                                                                                <div
                                                                                                class='dropdown post-options is-right is-hoverable '
                                                                                                onClick={null}
                                                                                            >
                                                                                                <div class='dropdown-trigger'>
                                                                                                    {e.Quotes.status}
                                                                                                </div>
                                                                                                <div
                                                                                                    class='dropdown-menu'
                                                                                                    id='dropdown-menu-post'
                                                                                                    role='menu'
                                                                                                >
                                                                                                    <div class='dropdown-content'>
                                                                                                        <a class='dropdown-item'>
                                                                                                            {e.Quotes.InvalidReason}
                                                                                                        </a>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div> : <>{e.Quotes.status}</>
                                                                                            }
                                                                                        </>
                                                                                        }
                                                                                        </>
                                                                                }</td>
                                                                            case "Actions": 
                                                                                return <td>
                                                                                <div
                                                                                    class='dropdown post-options is-right is-hoverable '
                                                                                    onClick={null}
                                                                                >
                                                                                    <div class='dropdown-trigger'>
                                                                                        <button
                                                                                            class='button'
                                                                                            aria-haspopup='true'
                                                                                            aria-controls='dropdown-menu-post'
                                                                                        >
                                                                                            <span class='icon is-small'>
                                                                                                <i
                                                                                                    class='fas fa-ellipsis-h'
                                                                                                    aria-hidden='true'
                                                                                                ></i>
                                                                                            </span>
                                                                                        </button>
                                                                                    </div>
                                                                                    <div
                                                                                        class='dropdown-menu'
                                                                                        id='dropdown-menu-post'
                                                                                        role='menu'
                                                                                    >
                                                                                        <div class='dropdown-content'>
                                                                                            {(canEdit && e.Quotes.status === 'DRAFT') && (
                                                                                                <a
                                                                                                    class='dropdown-item'
                                                                                                    onClick={() => {
                                                                                                        EntityV2Api.viewEntityAndQuote(e.Entities.entityId , e._id, (data)=> {
                                                                                                        if(data.Entites.status === ENTITY_STATUS.DEACTIVATED){
                                                                                                            configStore.setModalMessage(`Entity ${data.Entites.entityName} is Deactivated , please Activate it for Editing the quotation`)  
                                                                                                        }
                                                                                                        else {
                                                                                                            setCommonState(data.Quotes)
                                                                                                            configStore.disableShipmentType = 'EDIT';
                                                                                                            AgmodalStore.modalPage = "Quotev3";
                                                                                                            AgmodalStore.pageVisible = true;
                                                                                                        }
                                                                                                    } )
                                                                                                       
                                                                                                       
                                                                                                    }}
                                                                                                >
                                                                                                    Edit
                                                                                                </a>
                                                                                            )}
                                    
                                                                                            {
                                                                                                (e.Quotes.status != 'WON' && e.Quotes.status != 'LOST' && e.Quotes.status != 'INVALID' && e.Quotes.status != 'DECLINED' && e.Quotes.status != 'DRAFT' && e.Quotes.status != 'ACCEPTED' && e.Quotes.status != 'DELETED' )?<>
                                                                                                <a class='dropdown-item' onClick={async ()=> {
                                                                                                    e.Quotes.status = "WON"
                                                                                                    // e.quoteData.outCome = "WON"
                                                                                                    
                                                                                                    await Api.setWonquote(e.Quotes._id)
                                                                                                    modalStore.quotationaction = "Won"
                                                                                                    }}>
                                                                                                Won
                                                                                                </a>
                                                                                                </>:<></>
                                                                                                }
                                                                                                {
                                                                                                    // (e.Quotes.status != 'WON' && e.Quotes.status != 'LOST' && e.Quotes.status != 'INVALID' && e.Quotes.status != 'DECLINED' && e.Quotes.status != 'DRAFT' )?<>
                                                                                                   (e.Quotes.status == 'ACCEPTED' || e.Quotes.status == 'FINAL') ? <>
                                                                                                    <a class='dropdown-item' onClick={
                                                                                                    async ()=> {
                                                                                                        //modalStore.lostShowBooks = []
                                                                                                       await  HandleLost(e.Quotes._id)
                                                                                                       modalStore.quotationaction = "Lost"
                                                                                                    }
                                                                                                    }>
                                                                                                    Lost
                                                                                                </a>
                                                                                                </> : <></>
                                                                                                }
                                                                                                {
                                                                                                   (e.Quotes.status != 'WON' && e.Quotes.status != 'LOST' && e.Quotes.status != 'INVALID' && e.Quotes.status != 'DECLINED' && e.Quotes.status != 'DELETED' && e.Quotes.status != 'FINAL'    )?<>
                                                                                                   <a class='dropdown-item' onClick={async ()=>{
                                                                                                    await HandleInvalid(e.Quotes._id)
                                                                                                    modalStore.quotationaction = "Invalid"
                                                                                                    }}>
                                                                                                    Invalid
                                                                                                </a>
                                                                                                   </>:<></> 
                                                                                                }
                                                                                                
                                                                                                {/* <a class='dropdown-item' onClick={async ()=>{
                                                                                                    await HandleInvalid(e.Quotes._id)
                                                                                                    modalStore.quotationaction = "Invalid"
                                                                                                    }}>
                                                                                                    Invalid
                                                                                                </a> */}
                                                                                              
                                                                                       



                                                                                            {(e.Quotes.status != 'DECLINED' || e.Quotes.status == 'DELETED' && canEdit)&&<a
                                                                                                class='dropdown-item'
                                                                                                onClick={() => {
                                                                                                    Api.cloneQuote(
                                                                                                        e.Quotes._id
                                                                                                    );
                                                                                                    configStore.disableShipmentType = 'clone'
                                                                                                    AgmodalStore.modalPage = "Quotev3";
                                                                                                    AgmodalStore.pageVisible = true;
                                                                                                }}
                                                                                            >
                                                                                                Clone
                                                                                            </a>}
                                                                                            {e.Quotes.status == 'DECLINED' &&<a
                                                                                                class='dropdown-item'
                                                                                                onClick={async () => {
                                                                                                    await Api.quoteregenerate(e.Quotes._id)
                                                                                                }}
                                                                                            >
                                                                                                Re-Generate
                                                                                            </a>}
                                                                                            {canEdit && 
                                                                                            // e.Quotes.status === 'DRAFT' &&
                                                                                            e.Quotes.status == 'DRAFT' &&
                                                                                            e.Quotes.status !== 'DECLINED' &&
                                                                                            e.Quotes.status !== 'LOST' &&
                                                                                             <a
                                                                                                class='dropdown-item'
                                                                                                onClick={() => {
                                                                                                    setAgWarnPopupParams({
                                                                                                        callback: () => {
                                                                                                            Api.deleteQuote(
                                                                                                                e.Quotes._id
                                                                                                            );
                                                                                                        },
                                                                                                        warnMsg: `Do you really want to delete the Quotation ${e._id}?`
                                                                                                    })
                                                                                                    configStore.hasToShowAgWarn = true;
                                                                                                }}
                                                                                            >
                                                                                                Delete
                                                                                            </a>}
                                                                                            {e.Quotes.status !== 'DECLINED' && e.Quotes.status !== 'LOST' && e.Quotes.status !== 'DRAFT' && e.Quotes.status !== 'INVALID'  && e.Quotes.status != 'DELETED' &&
                                                                                                <a
                                                                                                class='dropdown-item'
                                                                                                onClick={async () => {
                                                                                                    if(e.Entities._id == 'TBA'){
                                                                                                        let res = await Api.getQuotationDocumentByIdv3(e.Quotes._id)
                                                                                                        const buffer = Buffer.from(res);
                                                                                                        const blob = new Blob([buffer]);
                                                                                                        const fileURL = window.URL.createObjectURL(blob);
                                                                                                        console.log(fileURL)
                                                                                                        let alink = document.createElement('a');
                                                                                                        alink.href = fileURL;
                                                                                                        alink.download = `${e._id}.pdf`;
                                                                                                        alink.click();
                                                                                                    }else{
                                                                                                        let res = await Api.getQuotationDocumentByIdv2(e.Quotes._id)
                                                                                                        const buffer = Buffer.from(res);
                                                                                                        const blob = new Blob([buffer]);
                                                                                                        const fileURL = window.URL.createObjectURL(blob);
                                                                                                        console.log(fileURL)
                                                                                                        let alink = document.createElement('a');
                                                                                                        alink.href = fileURL;
                                                                                                        alink.download = `${e._id}.pdf`;
                                                                                                        alink.click();
                                                                                                    }
                                                                                                }}
                                                                                            >
                                                                                                Download PDF
                                                                                            </a>}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </td>
                                                                            default:
                                                                                return <td {...cell.getCellProps()}><span>{cell.render('Cell')}</span></td>
                                                                        }
                                                                    }if((e.Quotes.status == 'DRAFT(Enquiry)' && canEqEdit  )
                                                                    ||(e.Quotes.status == 'FINAL') ||
                                                                     (e.Quotes.status == 'ACCEPTED') || 
                                                                     (e.Quotes.status == 'DRAFT') ||
                                                                      (e.Quotes.status == 'DECLINED')
                                                                       || (e.Quotes.status == 'WON')
                                                                       || (e.Quotes.status == 'LOST')
                                                                       || (e.Quotes.status == 'INVALID') || RoleUtils.isUserAuthorized(PERMISSIONS.EDIT.ENQUIRYTOQUOTATION)){
                                                                        switch (cell['column']['Header']){
                                                                            case "Date":
                                                                                return <td>{Utils.ddmmyyyystring(e.Quotes.mailedOn)}</td>
                                                                            case "Quotation Ref. #":
                                                                                return <td
                                                                                    // onClick={() => {
                                                                                    // 	Api.getQuote(e._id);
                                                                                    // 	configStore.quoteCurrentView =
                                                                                    // 		'view';
                                                                                    // }}
                                                                                >   
                                                                                {
                                                                                        (e.Quotes.status == 'DRAFT(Enquiry)') ? 
                                                                                        <>{e.Quotes._id}</>
                                                                                        :
                                                                                        <a
                                                                                        href={`${window.location.href}quotation/view/${e.Quotes._id}`} target="_blank" onClick={(event) => {
                                                                                            event.stopPropagation();
                                                                                        }}
                                                                                    >{e.Quotes._id}</a>
                                                                                    }
                                                                                </td>
                                                                            case "Status":
                                                                                    return <td>
                                                                                    {
                                                                                        (e.Quotes.status === 'DECLINED') ?
                                                                                            // <>{e.status}</> :
                                                                                            <>
                                                                                                <div
                                                                                                    class='dropdown post-options is-right is-hoverable '
                                                                                                    onClick={null}
                                                                                                >
                                                                                                    <div class='dropdown-trigger'>
                                                                                                        {e.Quotes.status}
                                                                                                    </div>
                                                                                                    <div
                                                                                                        class='dropdown-menu'
                                                                                                        id='dropdown-menu-post'
                                                                                                        role='menu'
                                                                                                    >
                                                                                                        <div class='dropdown-content'>
                                                                                                            <a class='dropdown-item'>
                                                                                                                {e.Quotes.decline_reason}
                                                                                                            </a>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </> : <>
                                                                                            {
                                                                                                (e.Quotes.status === 'LOST')?<div
                                                                                                class='dropdown post-options is-right is-hoverable '
                                                                                                onClick={null}
                                                                                            >
                                                                                                <div class='dropdown-trigger'>
                                                                                                    {e.Quotes.status}
                                                                                                </div>
                                                                                                <div
                                                                                                    class='dropdown-menu'
                                                                                                    id='dropdown-menu-post'
                                                                                                    role='menu'
                                                                                                >
                                                                                                    <div class='dropdown-content'>
                                                                                                        <a class='dropdown-item'>
                                                                                                            {e.Quotes.LostReason}
                                                                                                        </a>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>:<>
                                                                                                {
                                                                                                    (e.Quotes.status === 'INVALID') ? 
                                                                                                    <div
                                                                                                    class='dropdown post-options is-right is-hoverable '
                                                                                                    onClick={null}
                                                                                                >
                                                                                                    <div class='dropdown-trigger'>
                                                                                                        {e.Quotes.status}
                                                                                                    </div>
                                                                                                    <div
                                                                                                        class='dropdown-menu'
                                                                                                        id='dropdown-menu-post'
                                                                                                        role='menu'
                                                                                                    >
                                                                                                        <div class='dropdown-content'>
                                                                                                            <a class='dropdown-item'>
                                                                                                                {e.Quotes.InvalidReason}
                                                                                                            </a>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div> : 
                                                                                            
                                                                                                      <>
                                                                                                    {e.Quotes.status}
                                                                                                    </>
                                                                                                
                                                                                               
                                                                                                }
                                                                                            </>
                                                                                            
                                                                                            }
                                                                                            </>
                                                                                    }</td>
                                                                            case "Actions": 
                                                                                    return <td>
                                                                                    <div
                                                                                        class='dropdown post-options is-right is-hoverable '
                                                                                        onClick={null}
                                                                                    >
                                                                                        <div class='dropdown-trigger'>
                                                                                            <button
                                                                                                class='button'
                                                                                                aria-haspopup='true'
                                                                                                aria-controls='dropdown-menu-post'
                                                                                            >
                                                                                                <span class='icon is-small'>
                                                                                                    <i
                                                                                                        class='fas fa-ellipsis-h'
                                                                                                        aria-hidden='true'
                                                                                                    ></i>
                                                                                                </span>
                                                                                            </button>
                                                                                        </div>
                                                                                        <div
                                                                                            class='dropdown-menu'
                                                                                            id='dropdown-menu-post'
                                                                                            role='menu'
                                                                                        >
                                                                                            <div class='dropdown-content'>
                                                                                                {(canEdit && e.Quotes.status === 'DRAFT' ) && (
                                                                                                    <a
                                                                                                        class='dropdown-item'
                                                                                                        onClick={() => {
                                                                                                            EntityV2Api.viewEntityAndQuote(e.Entities.entityId , e._id, (data)=> {
                                                                                                                setCommonState(data.Quotes)
                                                                                                                configStore.disableShipmentType = 'EDIT';
                                                                                                                AgmodalStore.modalPage = "Quotev3";
                                                                                                                AgmodalStore.pageVisible = true;
                                                                                                            })
                                                                                                        }}
                                                                                                    >
                                                                                                        Edit
                                                                                                    </a>
                                                                                                )}
                                                                                                {(canEdit && e.Quotes.status === 'DRAFT(Enquiry)') && (
                                                                                                    <a
                                                                                                        class='dropdown-item'
                                                                                                        onClick={() => {
                                                                                                            EntityV2Api.viewEntityAndQuote(e.Entities.entityId , e._id, (data)=> {
                                                                                                            setCommonState(data.Quotes)
                                                                                                            configStore.disableShipmentType = 'EDIT';
                                                                                                            AgmodalStore.modalPage = "Quotev3";
                                                                                                            AgmodalStore.pageVisible = true;
                                                                                                            AgmodalStore.apiCall = false;
                                                                                                            data.Quotes.status = 'DRAFT'
                                                                                                            })
                                                                                                        }}
                                                                                                    >
                                                                                                        Edit
                                                                                                    </a>
                                                                                                )}
                                        
                                                                                                {/* {
                                                                                                    (e.Quotes.status != 'WON' && e.Quotes.status != 'LOST' && e.Quotes.status != 'INVALID' && e.Quotes.status != 'DECLINED')?<>
                                                                                                    <a class='dropdown-item' onClick={async ()=> {
                                                                                                        e.Quotes.status = "WON"
                                                                                                        // e.quoteData.outCome = "WON"
                                                                                                        
                                                                                                        await Api.setWonquote(e.Quotes._id)
                                                                                                        modalStore.quotationaction = "Won"
                                                                                                        }}>
                                                                                                    Won
                                                                                                    </a>
                                                                                                    <a class='dropdown-item' onClick={
                                                                                                        async ()=> {
                                                                                                            //modalStore.lostShowBooks = []
                                                                                                           await  HandleLost(e.Quotes._id)
                                                                                                           modalStore.quotationaction = "Lost"
                                                                                                        }
                                                                                                        }>
                                                                                                        Lost
                                                                                                    </a>
                                                                                                    <a class='dropdown-item' onClick={async ()=>{
                                                                                                        await HandleInvalid(e.Quotes._id)
                                                                                                        modalStore.quotationaction = "Invalid"
                                                                                                        }}>
                                                                                                        Invalid
                                                                                                    </a>
                                                                                                    </>:<></>
                                                                                                } */}
    
    
    
                                                                                                {/* {(e.Quotes.status != 'DECLINED' && canEdit)&&<a
                                                                                                    class='dropdown-item'
                                                                                                    onClick={() => {
                                                                                                        Api.cloneQuote(
                                                                                                            e.Quotes._id
                                                                                                        );
                                                                                                        configStore.disableShipmentType = 'clone'
                                                                                                        configStore.quoteCurrentView =
                                                                                                            'edit';
                                                                                                    }}
                                                                                                >
                                                                                                    Clone
                                                                                                </a>} */}
                                                                                                {/* {e.Quotes.status == 'DECLINED' &&<a
                                                                                                    class='dropdown-item'
                                                                                                    onClick={async () => {
                                                                                                        await Api.quoteregenerate(e.Quotes._id)
                                                                                                    }}
                                                                                                >
                                                                                                    Re-Generate
                                                                                                </a>}
                                                                                                {canEdit && e.Quotes.status === 'DRAFT' && <a
                                                                                                    class='dropdown-item'
                                                                                                    onClick={() => {
                                                                                                        setAgWarnPopupParams({
                                                                                                            callback: () => {
                                                                                                                Api.deleteQuote(
                                                                                                                    e.Quotes._id
                                                                                                                );
                                                                                                            },
                                                                                                            warnMsg: `Do you really want to delete the Quotation ${e._id}?`
                                                                                                        })
                                                                                                        configStore.hasToShowAgWarn = true;
                                                                                                    }}
                                                                                                >
                                                                                                    Delete
                                                                                                </a>} */}
                                                                                                {<a
                                                                                                    class='dropdown-item'
                                                                                                    onClick={async () => {
                                                                                                        if(e.Entities._id == 'GSTTBA000000000'){
                                                                                                            let res = await Api.getQuotationDocumentByIdv3(e.Quotes._id)
                                                                                                            const buffer = Buffer.from(res);
                                                                                                            const blob = new Blob([buffer]);
                                                                                                            const fileURL = window.URL.createObjectURL(blob);
                                                                                                            console.log(fileURL)
                                                                                                            let alink = document.createElement('a');
                                                                                                            alink.href = fileURL;
                                                                                                            alink.download = `${e._id}.pdf`;
                                                                                                            alink.click();
                                                                                                        }else{
                                                                                                            let res = await Api.getQuotationDocumentByIdv2(e.Quotes._id)
                                                                                                            const buffer = Buffer.from(res);
                                                                                                            const blob = new Blob([buffer]);
                                                                                                            const fileURL = window.URL.createObjectURL(blob);
                                                                                                            console.log(fileURL)
                                                                                                            let alink = document.createElement('a');
                                                                                                            alink.href = fileURL;
                                                                                                            alink.download = `${e._id}.pdf`;
                                                                                                            alink.click();
                                                                                                        }
                                                                                                       
                                                                                                    }}
                                                                                                >
                                                                                                    Download PDF
                                                                                                </a>}
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </td>
                                                                            default:
                                                                                return <td {...cell.getCellProps()}><span>{cell.render('Cell')}</span></td>
                                                                        }
                                                                    }
                                                                    }
                                                                    
                                                                })
                                                            }
                                                        </tr>
                                                    )
                                                }):<>{AgmodalStore.modalPage!="QuoteViewv3" && <AgLoader className={"loaderbodyforQuote"}/>}</>}
                                            </tbody>
                    </table>
                    <ReactTablePagination
                        page={page}
                        gotoPage={gotoPage}
                        previousPage={previousPage}
                        nextPage={nextPage}
                        canPreviousPage={canPreviousPage}
                        canNextPage={canNextPage}
                        pageOptions={pageOptions}
                        pageSize={pageSize}
                        pageIndex={pageIndex}
                        pageCount={pageCount}
                        setPageSize={setPageSize}
                        manualPageSize={manualPageSize}
                        dataLength={totalCount}
                        rows={rows}
                    />
                </div>
            </div>
    </>
)

}


function ActrejectModal4(props) {
    useSnapshot(quoteStore)
    useSnapshot(modalStore)
    useSnapshot(configStore)
    let {id} = props
    const [reason, setReason] = useState("");
    let modalClass = 'modal'
    
    if (configStore.quotationModalVisible) modalClass += ' is-active'
    const submit = async () => {
        if(modalStore.lostShowBooks.length>0){
            modalStore.lostShowReason = reason
            configStore.quotationModalVisible = false
            modalStore.lostShowModal = true
        }else{
            let newRec = quoteStore.listData.quotesList
            newRec = newRec.filter(e => {
                if(e._id == id){  
                    e.status = "LOST"
                    e.LostReason = reason
                    return e
                }
                else{
                    return e
                }
            })
            await Api.setLostQuote(id, {reason: reason})
            quoteStore.listData.quotesList = newRec
            configStore.quotationModalVisible = false
        }
    }
    console.log(modalStore.lostShowBooks)
    return (
        <div className={modalClass} onClick={() => { configStore.quotationModalVisible = false }}>
            <div className="modal-background" ></div>
            <div className="modal-card" onClick={(e) => { e.stopPropagation() }} style={{ borderRadius: '10px' }} >
                <section className="modal-card-body" >

                <h2 style={{ color: "red" }}>REJECT REASON</h2>
                                            <br></br>
                                            <textarea class="textarea is-info" placeholder="Your Reasons..." rows={5} onChange={(e) => setReason(e.target.value)}></textarea>
                                            <br></br>
                                            <button class="button is-danger" style={{ float: "right" }}
                                            disabled = {reason.length==0}
                                                onClick={submit}
                                            >Submit</button>
                                            <button class="button is-warning" onClick={() => configStore.quotationModalVisible = false} >cancel</button>
                </section>
            </div>
        </div>
    )
}
 function ActrejectModal5(props) {
    useSnapshot(quoteStore)
    useSnapshot(modalStore)
    useSnapshot(configStore)
    let {id} = props
    const [invalidReason, setInvalidReason] = useState("");
    let modalClass = 'modal'
    
    if (configStore.quotationModalVisible1) modalClass += ' is-active'
    const submit = async () => {

        if(modalStore.invalidShowBooks.length>0){
            modalStore.invalidShowReason = invalidReason
            configStore.quotationModalVisible1 = false
            modalStore.invalidShowModal = true
        }else{
            let newRec = quoteStore.listData.quotesList
            newRec = newRec.filter(e => {
                if(e._id == id){  
                    e.status = "INVALID"
                    e.InvalidReason = invalidReason
                    return e
                }
                else{
                    return e
                }
            })
            console.log(newRec)
            await Api.setInvalidQuote(id, {reason: invalidReason})
            quoteStore.listData.quotesList = newRec
            configStore.quotationModalVisible1 = false
            props.data()
        } 
    }
    return (
        <div className={modalClass} onClick={() => { configStore.quotationModalVisible1 = false }}>
            <div className="modal-background" ></div>
            <div className="modal-card" onClick={(e) => { e.stopPropagation() }} style={{ borderRadius: '10px' }} >
                <section className="modal-card-body" >

                <h2 style={{ color: "red" }}>INVALID REASON</h2>
                                            <br></br>
                                            <textarea class="textarea is-info" placeholder="Your Reasons..." rows={5} onChange={(e) => setInvalidReason(e.target.value)}></textarea>
                                            <br></br>
                                            <button class="button is-danger" style={{ float: "right" }}
                                            disabled = {invalidReason.length==0}
                                                onClick={submit}
                                            >Submit</button>
                                            <button class="button is-warning" onClick={() => configStore.quotationModalVisible1 = false} >cancel</button>
                </section>
            </div>
        </div>
    )
}
function AcceptLostPopup(props) {
    useSnapshot(quoteStore)
    useSnapshot(modalStore)
    useSnapshot(configStore)
    let {id} = props
    let modalClass = 'modal'
    let arr = modalStore.lostShowBooks
    if (modalStore.lostShowModal) modalClass += ' is-active'
    console.log(arr)
    const submit = async () => {
        let newRec = quoteStore.listData.quotesList
        newRec = newRec.filter(e => {
            if(e._id == id){  
                e.status = "LOST"
                e.LostReason = modalStore.lostShowReason
                return e
            }
            else{
                return e
            }
        })
        for (let i = 0; i < arr.length; i++) {
            let d=  arr[i]
            if(d['status']!='Cancellation Requested' && d['status']!='CANCELLED'){
                BookingsApi.cancelBookingfromqoute(d['_id'], 'Quote Lost');
                taskStore.current = new Task('')
                taskStore.current.initialize(d, entityStore.entityRec)
                taskStore.current.actionName = "Request for Booking Cancellation"
                Object.assign(taskStore.current, getPayload("Request for Booking Cancellation"))
                taskStore.current.bookingNum = d['_id']
                taskStore.current.entityId=d['entityId']
                taskStore.current.branchId=d['branchId']
                taskStore.current.reason = 'Quote Lost'
                taskStore.current.stage = 1
                taskStore.current.pendingWith = 'AGRAGA'
                await Taskapi.saveTask(taskStore.current,d["_id"])
            }
        }
        Api.setLostQuote(id, {reason: modalStore.lostShowReason})
        quoteStore.listData.quotesList = newRec
        modalStore.lostShowModal = false
    }
 
    return (

        <div className={modalClass} onClick={() => { modalStore.lostShowModal = false }}>
            <div className="modal-background" ></div>
            <div className="modal-card" onClick={(e) => { e.stopPropagation() }} style={{ borderRadius: '10px' }} >
                <section className="modal-card-body" >

                <h2 style={{ color: "red" }}>There are already bookings placed for the respective quotation.</h2>
                <h2 style={{ color: "red" }}>Please "Confirm" if you want to cancel?</h2>
                                            <br></br>
                                            {
                                                (arr.length > 0) ? <>{
                                                    arr.map((e)=> <>
                                                    <ul>
                                                        <li  onClick={() => {
                                                            window.open(`${window.location.href}bookingsv2/view/${e._id}`, '_blank').focus();
                                                            return;
                                                        }}>Booking ref # <a>{e._id}</a></li>
                                                    </ul>
                                                    </>)
                                                }</> : <></>
                                            }
                                            <br></br>
                                            <button class="button is-danger" style={{ float: "right" }}
                                            // disabled = {reason.length==0}
                                                onClick={submit}
                                            >Confirm</button>
                                            <button class="button is-warning" onClick={() => modalStore.lostShowModal = false} >cancel</button>
                </section>
            </div>
        </div>
    )
}
function AcceptInvalidPopup(props) {
    useSnapshot(quoteStore)
    useSnapshot(modalStore)
    useSnapshot(configStore)
    let {id} = props
    let modalClass = 'modal'
    let arr = modalStore.invalidShowBooks
    if (modalStore.invalidShowModal) modalClass += ' is-active'
    console.log(arr)
    const submit = async () => {
        let newRec = quoteStore.listData.quotesList
        newRec = newRec.filter(e => {
            if(e._id == id){  
                e.status = "INVALID"
                e.InvalidReason = modalStore.invalidShowReason
                return e
            }
            else{
                return e
            }
        })
        for (let i = 0; i < arr.length; i++) {
            let d=  arr[i]
            if(d['status']!='Cancellation Requested' && d['status']!='CANCELLED'){
                BookingsApi.cancelBookingfromqoute(d['_id'], 'Quote Invalid');
                taskStore.current = new Task('')
                taskStore.current.initialize(d, entityStore.entityRec)
                taskStore.current.actionName = "Request for Booking Cancellation"
                Object.assign(taskStore.current, getPayload("Request for Booking Cancellation"))
                taskStore.current.bookingNum = d['_id']
                taskStore.current.entityId=d['entityId']
                taskStore.current.branchId=d['branchId']
                taskStore.current.reason = 'Quote Invalid'
                taskStore.current.stage = 1
                taskStore.current.pendingWith = 'AGRAGA'
                await Taskapi.saveTask(taskStore.current,d["_id"])
            }
        }
        await Api.setInvalidQuote(id, {reason: modalStore.invalidShowReason})
        quoteStore.listData.quotesList = newRec
        modalStore.invalidShowModal = false
    }
 
    return (

        <div className={modalClass} onClick={() => { modalStore.invalidShowModal = false }}>
            <div className="modal-background" ></div>
            <div className="modal-card" onClick={(e) => { e.stopPropagation() }} style={{ borderRadius: '10px' }} >
                <section className="modal-card-body" >

                <h2 style={{ color: "red" }}>There are already bookings placed for the respective quotation.</h2>
                <h2 style={{ color: "red" }}>Please "Confirm" if you want to cancel?</h2>
                                            <br>
                                            </br>
                                            {
                                                (arr.length > 0) ? <>{
                                                    arr.map((e)=> <>
                                                    <ul>
                                                        <li  onClick={() => {
                                                            window.open(`${window.location.href}bookingsv2/view/${e._id}`, '_blank').focus();
                                                            return;
                                                        }}>Booking ref # <a>{e._id}</a></li>
                                                    </ul>
                                                    </>)
                                                }</> : <></>
                                            }
                                            <br></br>
                                            <button class="button is-danger" style={{ float: "right" }}
                                            // disabled = {reason.length==0}
                                                onClick={submit}
                                            >Confirm</button>
                                            <button class="button is-warning" onClick={() => modalStore.invalidShowModal = false} >cancel</button>
                </section>
            </div>
        </div>
    )
}


const QuoteListv3 = (props) => {
    return (
        <QueryClientProvider client={queryClient}>
            <DataTable />
        </QueryClientProvider>
    )
}

export default QuoteListv3;