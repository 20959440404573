import { useSnapshot } from "valtio"
import {
    chargesStore1, contractsStore, loginStore
} from '../state/CommonState'
import Utils from "../util/Utils"
import { configStore } from "../state/CommonState"
import { VehicleTypes } from "../enquiry/enquiry-data"

export function TransportTable1(props) {
    useSnapshot(chargesStore1)
    useSnapshot(loginStore)
    let vehicleRates = props.data
    let vtypes = new VehicleTypes()
    let fields = vtypes.fields
    let vehicles = Object.keys(vehicleRates)

    return (
        <div className="p-4" style={{ width: '300px' }}>
            {
                vehicles.map(e => {
                    if (vehicleRates[e].cost && vehicleRates[e].numVehicles)
                        return (
                            <div className="columns">
                                <div className="column is-6" style={{ padding: '0px' }}>{fields[e]} ({vehicleRates[e].numVehicles})</div>
                                {
                                    (loginStore.isClient()) ? <></> :
                                        <div className="column is-3" style={{ padding: '0px' }}>{vehicleRates[e].vendorId}</div>
                                }
                                <div className="column is-3" style={{ padding: '0px' }}>{vehicleRates[e].cost}</div>
                            </div>
                        )
                    return <></>
                })
            }
        </div>
    )
}
export function TransportTableRow(props) {
    return (
        <div className="columns">
            <div className="column is-5" style={{ padding: '0px', textAlign: 'right' }}>{props.label}</div>
            <div className="column is-1"></div>
            <div className="column is-6" style={{ padding: '0px', textAlign: 'left' }}>{props.textval}</div>
        </div>
    )
}
export default function LCLChargeClientViewv3(props) {
    let { chargesName } = props
    useSnapshot(loginStore)
    useSnapshot(contractsStore)
    if (!chargesName) chargesName = 'chargesList'
    let rec = contractsStore.current
    if (props.dummyContract) {
        rec = props.dummyContract
    }
    const Tn = ({i, name }) => {
        const e = props.data[i]
        if (name === 'total') {
            return (
                <span style={{ textAlign: 'right' }}>{Utils.N(e[name], e.currency)}</span>
            )
        } else {
            //console.log(name, e.currency, e[name])
            let num = e[name]
            num = isNaN(num) ? 0.0 : Number(num)
            return num != 0 ? <span>{Utils.N(num, e.currency)}</span> : <></>
        }
    }
    const displayRec = (e) => {
        const perCBM = parseFloat(e.perCBM)
        const perTon = parseFloat(e.perTon)
        const perShipment = parseFloat(e.perShipment)
        const total = parseFloat(e.total)
        console.log(perCBM, perTon, perShipment, total)
        if (perCBM != 0 || perTon != 0 || perShipment != 0 || total != 0) return true
        return false
    }
    let colspan = 8
    if (loginStore.isClient()) colspan = 3
    if (!props.data) {
        let listo = []
        let listc = []
        let lists = []
        for (let i = 0; i < rec[chargesName].length; i++) {
            let e = rec[chargesName][i]
            e = { ...e }
            if (e.onaccount && e.onaccount.startsWith('Cons')) listc.push(e)
            else if (e.onaccount && e.onaccount.startsWith('Ship')) lists.push(e)
            else listo.push(e)
        }
        let size = 12
        if(listc.length && lists.length){
            size = 4
        }else if(listc.length){
            size = 6
        }else if(lists.length){
            size = 6
        }
        if(listc.length && !lists.length && !listo.length){
            size = 12
        }
        if(!listc.length && lists.length && !listo.length){
            size = 12
        }
        if(!listc.length && !lists.length && listo.length){
            size = 12
        }
        return <>
        <div class="columns">
            {
                (listo.length)?<div class={"column is-"+size}><LCLChargeClientViewv3 data={listo} 
                chargeType='On Your Account' chargesName = 'chargesList'  dummyContract={props.dummyContract}/></div>:""
            }
            {
                (listc.length)?<div class={"column is-"+size}><LCLChargeClientViewv3 data={listc} 
                chargeType='On Consignee Account' chargesName="consigneechargesList" dummyContract={props.dummyContract}/></div>:""
            }
            {
                (lists.length)?<div class={"column is-"+size}><LCLChargeClientViewv3 data={lists} chargeType='On Shipper Account' chargesName="shipperchargesList"
                dummyContract={props.dummyContract}/></div>:""
            }
            </div>
        </>
    }
    let grandTotal = 0.0
    let volumeDiscount = 0.0
    props.data.map(e => {
        let erate = configStore.getExchangeRate(e.currency)
        if (!erate || erate === 0) erate = 1.0
        if (e.chargeDescription == 'Volume Discount') {
            volumeDiscount = (e.total * erate)
            if (volumeDiscount > 0) volumeDiscount *= -1
        } else {
            grandTotal += parseFloat(e.total) * erate
        }
        console.log('E.TOTAL***=', e.total, grandTotal, e.currency, erate)
    })
    let counter = 1

    return (
        <>
            <div className="table-container">
                <table className="table is-bordered is-striped is-narrow is-hoverable ">
                    <thead>
                        <tr>
                            <td colspan={colspan + 4}>
                                <div style={{
                                    width: '100%',
                                    textAlign: 'center',
                                    backgroundColor: '#ffeee0',
                                    fontSize: '1.1rem',
                                    fontWeight: 'bold'
                                }}>{props.chargeType}</div>
                            </td>
                        </tr>
                        <tr>
                            <th></th>
                            <th></th>
                            <th></th>
                            {
                                (!loginStore.isClient()) ?
                                    <>
                                        <th colSpan='4' className="has-text-centered">Per Unit</th>
                                        <th></th>
                                    </>
                                    : <></>
                            }
                            <th></th>
                        </tr>
                        <tr>
                            <th>#</th>
                            <th style={{ textAlign: 'center' }}>Charge Description</th>
                            <th>Currency</th>
                            {
                                (!loginStore.isClient()) ?
                                    <>
                                        <th style={{ textAlign: 'center' }}>Per CBM</th>
                                        <th style={{ textAlign: 'center' }}>Per Ton</th>
                                        <th style={{ textAlign: 'center' }}>Minimum</th>
                                        <th style={{ textAlign: 'center' }}>Maximum</th>
                                        <th style={{ textAlign: 'center' }}>Per Shipment</th>
                                    </>
                                    : <></>
                            }
                            <th style={{ textAlign: 'right' }}>Total</th>
                        </tr>
                    </thead>
                    <tbody>
                    {
                            props.data
                                .filter(e => e.chargeDescription != 'Volume Discount')
                                .map((e, i) => {
                                    {
                                        return (displayRec(e)) ?
                                        
                                            <tr key={e.id}>
                                                <td >{counter++}</td>
                                                <td>{
                                            (e.transportRec) ?
                                                <div className="transporttooltip">{e.chargeDescription}
                                                    <span className="transporttooltiptext"><TransportTable1 data={e.transportRec} /> </span>
                                                </div>
                                                :
                                                e.chargeDescription
                                        }</td>
                                                <td>{e.currency}</td>
                                                {
                                            (!loginStore.isClient()) ?
                                                <>
                                                    <td style={{ textAlign: 'center' }}><Tn i={i} name='perCBM' /></td>
                                                    <td style={{ textAlign: 'enter' }}><Tn i={i} name='perTon' /></td>
                                                    <td style={{ textAlign: 'center' }}><Tn i={i} name='minimum' /></td>
                                                    <td style={{ textAlign: 'center' }}><Tn i={i} name='maximum' /></td>
                                                    <td style={{ textAlign: 'center' }}><Tn i={i} name='perShipment' /></td>
                                                </>
                                                : <></>
                                        }
                                        <td style={{ textAlign: 'right' }}><Tn i={i} name='total' /></td>
                                            </tr> : <></>
                                    }
                                }
                                )
                        }
                        <tr>
                            {
                                (!loginStore.isClient()) ?
                                    <>
                                        <td colSpan={8}></td>
                                    </>
                                    : <td colSpan={3}></td>
                            }
                            <td style={{ textAlign: 'right' }}><strong>{Utils.N(grandTotal)}</strong></td>
                        </tr>
                        {
                            volumeDiscount != 0 && <>
                                <tr>
                                    <td colSpan={colspan} style={{ textAlign: 'right' }}>Volume Discount :</td>
                                    <td style={{ textAlign: 'right' }}><strong>{Utils.N(volumeDiscount).replace("-","")}</strong></td>
                                </tr>
                                {
                                   ((grandTotal + volumeDiscount)>0)?<tr>
                                   <td colSpan={colspan} style={{ textAlign: 'right' }}>TOTAL :</td>
                                   <td style={{ textAlign: 'right' }}><strong>{Utils.N(grandTotal + volumeDiscount)}</strong></td>
                               </tr>:""
                                }
                                
                            </>
                        }
                        <tr>
                            <td colSpan={9} style={{ textAlign: 'right' }}>Taxes & Duties additional, as applicable</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </>
    )
}
