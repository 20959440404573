import { Tooltip } from "@mui/material";

export function MultiDestToolTip({multidest=[]}){
    return  <Tooltip componentsProps={{
        tooltip: {
          sx: {
            fontSize:"16px",
            bgcolor: "white",
            color: "black",
            boxShadow:"4px 4px 20px 0px #00000033"
          }
        }
      }} title={ <div>{multidest.map((dest,i)=><p >Destination {i+1} : {dest.destination}</p>)}</div>}>
                <p className="font-bold text-black text-sm underline-offset-1">{multidest?.length} Destinations</p>
                </Tooltip>
}