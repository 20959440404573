//"tripId": "64db707a779ba021c9fde877"
import { useSnapshot } from "valtio";
import { DtStore } from "./DtStore";
import {CargoDetails} from '../state/Types.js'
import Dtinput from "./cps/Dtinputs";
import Dtselect from "./cps/Dtselect";
import { useEffect, useRef, useState } from "react";
import {
  AgBtnmongofilenew,
  Agmongofile,
  Agmongofilenew,
} from "../components/AgFile";
import { AgmodalStore, breadCrumbStore, configStore, loginStore } from "../state/CommonState";
import "./dt.css";
import DtApi from "../state/dt-api";
import { loader } from "../util/loader";
import { useGlobalState } from "../GlobalState";
import Utils from "../util/Utils";
import { DtupdateDriverNum } from "./DtupdateDriverNum.js";
import Dtnewinput from "./cps/Dtnewinput";
import { DtMap } from "./DtMap";
import Dttrackingtimedate from "./cps/Dttrackingtimedate";
import Dtsearchselect from "./cps/Dtsearchselect";
import { DtInitiateAdvancemodal } from "./DtInitiateAdvancemodal";
import { DtInitiateBalancePaymentmodal } from "./DtInitiateBalancePaymentmodal";
import { DtStopTracking } from "./DtStopTracking";
import AguserApi from "../state/AguserApi";
import Select from "react-select";
import Dt30minselect from "./cps/Dt30minselect";
import RoleUtils from "../util/RoleUtils";
import { PERMISSIONS } from "../util/constants";
import Dtcargodetails from "./cps/Dtcargodetails.js"
import BranchApi from "../state/branch-api.js";

let server = process.env.REACT_APP_FILE_SERVER;
export default function DtBooking() {
  useSnapshot(DtStore);
  useSnapshot(configStore);
  useSnapshot(loginStore);
  useSnapshot(loader);
  useEffect(()=>{
    return ()=>{
     configStore.dtCurrentView = "list";
    }
 },[])
  const { changeCurrentMenu } = useGlobalState();
  let client = loginStore.isClient();
  let dtStore = DtStore.DTBooking;
  let dtview = DtStore.bview;
  [
    "drivername",
    "drivernumber",
    "driverstatus",
    "tripId",
    "publiclink",
    "tripstatus",
    "vendor_assigned",
    "vendor_agreed_cost",
    "additional_cost_reason",
    "vendor_additional_cost",
    "vender_invoice",
    "relationship_manager",
    "customer_success",
    "operations_representative",
    "total_cost",
    "invoice",
    "pickup2appointments",
  ].map((e) => {
    if (dtStore[e]==undefined) {
      if (e != "pickup2appointments") {
        dtStore[e] = "";
      } else {
        dtStore[e] = [{ contactperson: "", contactnumber: "", email: "" }];
      }
    }
  });
  if (!dtStore["trackinglist"]) {
    let kz = {};
    const dn = new Date();
    const h = dn.getHours();
    const m = dn.getMinutes();
    kz["bookingrecieved"] = {};
    kz["bookingrecieved"]["date"] = dtStore["bookingdate"];
    kz["bookingrecieved"]["time"] = `${h}:${m}`;
    kz["vehicleassigned"] = { date: "", time: "" };
    kz["driverassigned"] = { date: "", time: "" };
    kz["driverconsent"] = { date: "", time: "" };
    kz["dispatchedtopickuppoint"] = { date: "", time: "" };
    kz["arrivedtopickuppoint"] = { date: "", time: "" };
    for (let lz = 0; lz < dtStore.details[0].delivery.length; lz++) {
      kz["dispatchedtoD/L" + (lz + 1)] = { date: "", time: "" };
      kz["arrivedtoD/L" + (lz + 1)] = { date: "", time: "" };
    }
    dtStore["trackinglist"] = [kz];
  }
  if(!dtStore["trackinglist"][0]["dispatchedtopickuppoint2"]){
    dtStore["trackinglist"][0]["dispatchedtopickuppoint2"] = { date: "", time: "" }
    dtStore["trackinglist"][0]["arrivedtopickuppoint2"] = { date: "", time: "" };
  }
  if (dtStore.details[0].delivery[0]["ewaybillno"] == undefined) {
    for (let lz = 0; lz < dtStore.details[0].delivery.length; lz++) {
      dtStore.details[0].delivery[lz]["ewaybillno"] = "";
      dtStore.details[0].delivery[lz]["ewaybilldate"] = "";
      dtStore.details[0].delivery[lz]["lorryrecieptNo"] = "";
    }
  }
  if (dtStore.details[0].delivery[0]["lorryrecieptNo"] == undefined) {
    for (let lz = 0; lz < dtStore.details[0].delivery.length; lz++) {
      dtStore.details[0].delivery[lz]["lorryrecieptNo"] = "";
    }
  }
  if (dtStore.details[0].delivery[0]["pod_date"] == undefined) {
    for (let lz = 0; lz < dtStore.details[0].delivery.length; lz++) {
      dtStore.details[0].delivery[lz]["pod_date"] = "";
    }
  }
  if (dtStore && dtStore["details"][0]["pickup_2"] == undefined) {
    for (let i = 0; i < dtStore["details"].length; i++) {
      if (dtStore["details"][i]["pickup_2"] == undefined) {
        dtStore["details"][i]["pickup_2"] = "";
      }
    }
  }
    if(dtStore && dtStore['reportingfrom']?.trim().length==5){
    if(dtStore['reportingfrom'].indexOf(':00') != -1 || dtStore['reportingfrom'].indexOf(':30') != -1){
      dtStore['reportingfrom'] = Utils.formattimetocovertampm(dtStore['reportingfrom'].trim())
    }else{
      dtStore['reportingfrom'] = "08:00 AM"
    }
  }

    if(dtStore && dtStore['reportingto']?.trim().length==5){
    if(dtStore['reportingto'].indexOf(':00') != -1 || dtStore['reportingto'].indexOf(':30') != -1){
      dtStore['reportingto'] = Utils.formattimetocovertampm(dtStore['reportingto'].trim())
    }else{
      dtStore['reportingto'] = "07:00 PM"
    }
  }

  if(dtStore && dtStore['cargo_details'] === undefined){
    dtStore.cargo_details = [new CargoDetails()]
  }

  if(dtStore && dtStore['cargoDimensionUnit'] === undefined){
    dtStore.cargoDimensionUnit = 'Cms'
  }
  if(dtStore && dtStore['nonStackable'] === undefined){
    dtStore.nonStackable = 'No'
  }
  if(dtStore && dtStore['hazardous'] === undefined){
    dtStore.hazardous = 'No'
  }
  if(dtStore && dtStore['quoteClass'] === undefined){
    dtStore.quoteClass = ''
  }
  if(dtStore && dtStore['packingGroup'] === undefined){
    dtStore.packingGroup = ''
  }
  if(dtStore && dtStore['unnumber'] === undefined){
    dtStore.unnumber = ''
  }

  // if (!dtStore.drivername) {
  //   dtStore.drivername = "";
  // }
  // if (!dtStore.drivernumber) {
  //   dtStore.drivernumber = "";
  // }
  // if (!dtStore.driverstatus) {
  //   dtStore.driverstatus = "";
  // }
  // if (!dtStore.tripId) {
  //   dtStore.tripId = "";
  // }
  // if (!dtStore.publiclink) {
  //   dtStore.publiclink = "";
  // }
  // if (!dtStore.tripstatus) {
  //   dtStore.tripstatus = "";
  // }
  const [vehiclelist, setVehiclelist] = useState({});
  const [vehiclelist1, setVehiclelist1] = useState({});

  const [totalcharge, setTotalcharge] = useState("");
  const [chargedetails, setChargedetails] = useState({});
  const [weighttypes, setWeighttypes] = useState({ tons: "tons", kgs: "kgs" });
  const [showweighttypes, setShowweighttypes] = useState(false);
  const [currentview, setCurrentview] = useState("booking");
  const [isCargoModalVisible, setCargoModalVisible] = useState(false);
  const [statuslist, setStatuslist] = useState({
    "Yet to Commence": "Yet to Commence",
    "Report at Pickup": "Report at Pickup",
    Dispatched: "Dispatched",
    "Arrived at Delivery": "Arrived at Delivery",
    Complete: "Complete",
  });
  const [curlist, setCurlist] = useState({});
  const [cursor, setCursor] = useState(0);
  const [showtc, setShowTC] = useState(false);
  const [showvd, setShowVd] = useState(false);
  const [error, setErroe] = useState([]);
  const ref = useRef(null);
  const [enable1, setEnable1] = useState(true);
  const [enable2, setEnable2] = useState(true);
  const [enable3, setEnable3] = useState(false);
  const [enable4, setEnable4] = useState(false);
  const [checkenable, setCheakenable] = useState(false);
  const [defaultOptions, setDefaultOptions] = useState([]);
  const [branches, setBranches] = useState([])
  let defaultValue = null;

  useEffect(()=>{
    return ()=>{
      if(DtStore.isViewParticular){
        breadCrumbStore.setCurrentCrumb(0);
        DtStore.isViewParticular = false;
        configStore.dtCurrentView = "list";
      }
    }
  },[])


  if (dtStore.dtoperation != undefined) {
    if (
      ["Report at Pickup", "Yet to Commence", "Commenced"].indexOf(
        dtStore.status
      ) != -1
    ) {
      defaultValue = { value: dtStore.dtoperation, label: dtStore.dtoperation };
    }
  } else {
    dtStore.dtoperation = "";
  }
  useEffect(() => {
    const input = ref.current;
    if (input) {
      input.setSelectionRange(cursor, cursor);
    }
  }, [ref, cursor]);
  const handleChange = (e) => {
    setCursor(e.target.selectionStart);
    dtStore.specialinstruction = e.target.value;
  };
  let quoteTC = DtStore.DTBooking.tc;
  let customTC = DtStore.DTBooking.ctc.split(/\r?\n/);
  quoteTC.map((e) => customTC.push(e));
  quoteTC = customTC;
  console.log(">>>", quoteTC);

  const getactions = (id) => {
    setCheakenable(true);
    const callback = (data) => {
      let a = false;
      let b = false;
      for (let i = 0; i < data.length; i++) {
        const d = data[i];
        if (data[i]["_id"]["actionName"] == "Document Verification" || (data[i]["_id"]["actionName"] == "Reject - Document Verification" && 
        (dtStore['initiateAdvance']!=undefined && dtStore['initiateAdvance']=="done"))) {
          a = true;
        }
        if (data[i]["_id"]["actionName"] == "Final Document Verification" || (data[i]["_id"]["actionName"] == "Reject - Final Document Verification" && 
        (dtStore['initiateBalancePayment']!=undefined && dtStore['initiateBalancePayment']=="done"))) {
          b = true;
        }
      }
      setEnable1(a);
      setEnable2(b);
    };
    DtApi.getactions(id, callback);
  };
  const actioncheck = (booking) => {
    let a = true;
    let b = true;
    let d = booking;
    let delivery = booking.details[0].delivery;
    if (
      !d.invoice ||
      !d.invoice.length ||
      !d.vender_invoice ||
      !d.vender_invoice.length
    )
      b = false;
    for (let i = 0; i < delivery.length; i++) {
      let d = delivery[i];
      if (
        !d.ewaybill ||
        !d.ewaybill.length ||
        !d.lorryreceipt ||
        !d.lorryreceipt.length
      )
        a = false;
      if (!d.proofofdelivery || !d.proofofdelivery.length) b = false;
    }
    if (a != enable3) {
      setEnable3(a);
    }
    if (b != enable4) {
      setEnable4(b);
    }
  };

  const checkfield = () => {
    console.log(dtStore,'dddt');
    actioncheck(dtStore);
    let e = [];
    let t = 0.0;
    let k = {};
    let ton = 0;
    if (
      dtStore.drivernumber.trim().length != 0 &&
      dtStore.drivernumber.trim().length != 10
    ) {
      e.push("drivernumber");
    }
    if (dtStore.vehicle_no.trim().length != 0) {
      setstatustime("vehicleassigned");
      if (dtStore.vendor_assigned.length == 0) {
        e.push("vendor_assigned");
      }
      if (dtStore.vendor_agreed_cost.length == 0) {
        e.push("vendor_agreed_cost");
      }
    }
    if (dtStore.drivername.trim().length != 0) {
      setstatustime("driverassigned");
      if (dtStore.vendor_assigned.length == 0) {
        e.push("vendor_assigned");
      }
      if (dtStore.vendor_agreed_cost.length == 0) {
        e.push("vendor_agreed_cost");
      }
    }
    if (dtStore.invoice.length != 0) {
      if (dtStore.total_cost.length == 0) {
        e.push("total_cost");
      }
    }
    if (
      dtStore.drivernumber.trim().length == 10 &&
      dtStore.driverstatus == "ALLOWED"
    ) {
      setstatustime("driverconsent");
    }
    if (!dtStore.pickupdate || dtStore.pickupdate.length == 0) {
      e.push("pickupdate");
    }
    if (!dtStore?.branchId || dtStore?.branchId?.length == 0) {
      e.push("branchId");
    }
    if (!dtStore.reportingfrom || dtStore.reportingfrom.length == 0) {
      e.push("reportingfrom");
    }
    if ( !dtStore.reportingto || dtStore.reportingto.length == 0) {
      e.push("reportingto");
    }
    if (dtStore.pricing_type == "vehicle") {
      let t = dtStore.details[0].vehicle.map((v) => {
        if (v.vehicle_type == dtStore.vehicle) {
          return v.vehicle_amount;
        }
      });
      if (dtStore.bookingamount != undefined || dtStore.bookingamount != t) {
        dtStore.bookingamount = t;
      }
    }
    if (dtStore.pricing_type != "vehicle") {
      for (let i = 0; i < dtStore.details[0].vehicle.length; i++) {
        if (dtStore.details[0].vehicle[i].vehicle_type == dtStore.vehicle) {
          k = dtStore.details[0].vehicle[i];
        }
      }
      t = k["minimum"];
      if (dtStore.totel_weight.length > 0) {
        ton = Number(k["minimum"]) / Number(k["amt_per_ton"]);
        if (dtStore.weight_type == "tons") {
          let l = Number(dtStore.totel_weight);
          if (l > ton) {
            t = (l * Number(k["amt_per_ton"])).toFixed(2);
          }
        }
        if (dtStore.weight_type == "kgs") {
          let l = Number(dtStore.totel_weight) / 1000;
          if (l > ton) {
            t = (l * Number(k["amt_per_ton"])).toFixed(2);
          }
        }
      } else {
        e.push("totel_weight");
      }
      if (t != totalcharge) {
        dtStore.bookingamount = t;
        setTotalcharge(t);
      }
      if (JSON.stringify(k) != JSON.stringify(chargedetails)) {
        setChargedetails(k);
      }
    }
    for (let i = 0; i < dtStore.pickupappointments.length; i++) {
      let l = dtStore.pickupappointments[i];
      if (l.contactperson.length == 0) {
        e.push("contactperson" + i);
      }
      if (l.contactnumber.length == 0 || l.contactnumber.length != 10) {
        e.push("contactnumber" + i);
      }
    }
    if (
      dtStore.details[0]["pickup_2"] != undefined &&
      dtStore.details[0]["pickup_2"].trim().length > 0
    ) {
      for (let i = 0; i < dtStore.pickup2appointments.length; i++) {
        let l = dtStore.pickup2appointments[i];
        if (l.contactperson.length === 0) {
          e.push("p2contactperson" + i);
        }
        if (l.contactnumber.length === 0 || l.contactnumber.length !== 10) {
          e.push("p2contactnumber" + i);
        }
      }
    }
    for (let i = 0; i < dtStore.details[0].delivery.length; i++) {
      if (dtStore.details[0]["delivery"][i]["ewaybill"].length > 0) {
        if (dtStore.vendor_assigned.length === 0) {
          e.push("vendor_assigned");
        }
        if (dtStore.vendor_agreed_cost.length === 0) {
          e.push("vendor_agreed_cost");
        }
        if (dtStore.details[0]["delivery"][i]["cargo_Value"].length === 0) {
          e.push("cargo_Value" + i);
        }
        if (dtStore.details[0]["delivery"][i]["ewaybillno"].length === 0) {
          e.push("ewaybillno" + i);
        }
        if (dtStore.details[0]["delivery"][i]["ewaybilldate"].length === 0) {
          e.push("ewaybilldate" + i);
        }
      }
      if (dtStore.details[0]["delivery"][i]["lorryreceipt"].length > 0) {
        if (dtStore.details[0]["delivery"][i]["lorryrecieptNo"].length === 0) {
          e.push("lorryrecieptNo" + i);
        }
      }
      if (dtStore.details[0]["delivery"][i]["proofofdelivery"].length > 0) {
        if (dtStore.details[0]["delivery"][i]["pod_date"].length === 0) {
          e.push("pod_date" + i);
        }
      }
      if (
        dtStore.vender_invoice.length > 0 &&
        dtStore.vendor_agreed_cost.length === 0
      ) {
        e.push("vendor_agreed_cost");
      }
      for (
        let j = 0;
        j < dtStore.details[0].delivery[i].delivery_contact.length;
        j++
      ) {
        let l = dtStore.details[0].delivery[i].delivery_contact[j];
        if (l.contactperson.length === 0) {
          e.push(`contactperson${i}${j}`);
        }
        if (l.contactnumber.length === 0 || l.contactnumber.length !== 10) {
          e.push(`contactnumber${i}${j}`);
        }
      }
    }
    setErroe(e);
    return e;
  };

  const checkvalid = (l) => {
    if (DtStore.valid) {
      if (error.indexOf(l) === -1) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  };
  const goList = (data) => {
    console.log(data);
    loader.hide();
    AgmodalStore.pageVisible = false
    AgmodalStore.apiCall = false
    DtStore.btabview = "Shipments In-Progress";
    changeCurrentMenu("Domestic Transport Booking");
    configStore.dtCurrentView = "list";
  };
  // console.log(">>>>>",dtStore.erp,dtStore._id)
  const save = async () => {
    if (dtStore.bookingversion && dtStore.bookingversion == "v2") {
      checkstatus();
    }
    DtStore.valid = true;
    let er = checkfield();
    if (er.length === 0) {
      loader.show();
      if(!dtStore.branchId){
        dtStore.branchId = branches[0].branchId 
       }
      let d = dtStore;
      if (dtview !== "create") {
        if (
          dtStore.erp &&
          dtStore.erp.trim().length > 0 &&
          dtStore.vehicle_no &&
          dtStore.vehicle_no.trim().length > 0
        ) {
          let callback = async (data) => {
            if (data.data1 !== "ALLOWED") {
              configStore.setModalMessage(data.data1);
              loader.hide();
            } else {
              let callback1 = (data) => {
                if (data.data !== "ALLOWED") {
                  configStore.setModalMessage(data.data);
                  loader.hide();
                } else {
                  DtApi.postEditDtBooking(d, goList);
                }
              };
              await DtApi.getcheakvehicle_no(
                dtStore.vehicle_no.trim(),
                dtStore._id,
                callback1
              );
            }
          };
          await DtApi.getcheakErp_no(dtStore.erp.trim(), dtStore._id, callback);
        } else {
          if (dtStore.erp && dtStore.erp.trim().length > 0) {
            let callback = (data) => {
              if (data.data1 !== "ALLOWED") {
                configStore.setModalMessage(data.data1);
                loader.hide();
              } else {
                DtApi.postEditDtBooking(d, goList);
              }
            };
            await DtApi.getcheakErp_no(
              dtStore.erp.trim(),
              dtStore._id,
              callback
            );
          } else {
            if (dtStore.vehicle_no && dtStore.vehicle_no.trim().length > 0) {
              let callback = (data) => {
                if (data.data !== "ALLOWED") {
                  configStore.setModalMessage(data.data);
                  loader.hide();
                } else {
                  DtApi.postEditDtBooking(d, goList);
                }
              };
              await DtApi.getcheakvehicle_no(
                dtStore.vehicle_no.trim(),
                dtStore._id,
                callback
              );
            } else {
              DtApi.postEditDtBooking(d, goList);
            }
          }
        }
      } else {
        DtApi.postCreateDtBooking(d, goList);
      }
    } else {
      console.log(er);
      if (
        er.indexOf("vendor_assigned") !== -1 ||
        er.indexOf("vendor_agreed_cost") !== -1
      ) {
        configStore.setModalMessage(
          "Please assign Vendor and Cost Agreed, in Vendor Details"
        );
      } else {
        configStore.setModalMessage("Please check the fields");
      }
    }
  };

  const setvlist = () => {
    let l = {};
    for (let i = 0; i < dtStore.details[0].vehicle.length; i++) {
      l[dtStore.details[0].vehicle[i].vehicle_type] =
        dtStore.details[0].vehicle[i].vehicle_type;
    }
    setVehiclelist(l);
  };
  const setvlist1 = (data) => {
    console.log("arr", data);
    let d1 = {};
    if (data["Vehicle Masters"]) {
      let l = data["Vehicle Masters"];
      for (let i = 0; i < l.length; i++) {
        d1[l[i]["Vehicle type"]] = l[i];
      }
      console.log(d1);
      console.log(d1[dtStore.vehicle]["Cargo Weight Capacity (Kgs)"]);
      setVehiclelist1(d1);
    }
  };
  const checkstatus = () => {
    if (dtStore.bookingversion && dtStore.bookingversion === "v2") {
      let key = "arrivedtoD/L" + dtStore.details[0].delivery.length;
      if (dtStore["trackinglist"][0][key]["date"].length > 0) {
        let a = true;
        for (let i = 0; i < dtStore.details[0].delivery.length; i++) {
          if (dtStore.details[0].delivery[i]["proofofdelivery"].length === 0) {
            a = false;
          }
        }
        if (
          a &&
          dtStore.invoice &&
          dtStore.invoice.length > 0 &&
          dtStore.total_cost &&
          dtStore.total_cost.length > 0 &&
          dtStore.vender_invoice &&
          dtStore.vender_invoice.length > 0
        ) {
          dtStore.status = "Complete";
        } else {
          dtStore.status = "In-Transit";
        }
      } else {
        if (dtStore["trackinglist"][0]["dispatchedtoD/L1"]["date"].length > 0) {
          dtStore.status = "In-Transit";
        } else {
          if (
            dtStore["trackinglist"][0]["arrivedtopickuppoint"]["date"].length >
            0
          ) {
            dtStore.status = "Report at Pickup";
          } else {
            if (
              dtStore["trackinglist"][0]["dispatchedtopickuppoint"]["date"]
                .length > 0
            ) {
              dtStore.status = "Commenced";
            } else {
              dtStore.status = "Yet to Commence";
            }
          }
        }
      }
    } else {
      let f1 = 0;
      let f2 = 0;
      let f3 = 0;
      for (let i = 0; i < dtStore.details[0].delivery.length; i++) {
        // eslint-disable-next-line default-case
        switch (dtStore.details[0].delivery[i].status) {
          case "Complete":
            f1 = f1 + 1;
            break;
          case "Arrived at Delivery":
            f2 = 1;
            break;
          case "Dispatched":
            f2 = 1;
            break;
          case "Report at Pickup":
            f3 = 1;
            break;
        }
      }
      if (f1 === dtStore.details[0].delivery.length) {
        dtStore.status = "Complete";
      } else if (f2 === 1) {
        dtStore.status = "In-Transit";
      } else if (f3 === 1) {
        dtStore.status = "Report at Pickup";
      }
    }
  };
  const checkdrivernumstatus = async () => {
    if (dtStore.drivernumber.trim().length == 10) {
      let callback = (data) => {
        dtStore.driverstatus = data.data;
        checkfield();
      };
      await DtApi.getdrivernumstatus(
        dtStore.drivernumber.trim(),
        dtStore._id,
        callback
      );
    } else {
      configStore.setModalMessage("Please Check deriver Number");
    }
  };

  const updatenumber = async () => {
    DtStore.updatenumer = {
      number: "",
      message: "",
    };
    DtStore.modalVisible1 = true;
  };

  const getdatetext = (key) => {
    return Utils.formatdatetimetocovert(
      dtStore["trackinglist"][0][key]["date"],
      dtStore["trackinglist"][0][key]["time"]
    );
  };

  const gethour = (key, key2) => {
    if (dtStore["trackinglist"][0][key]["date"].length > 0) {
      let l = "";
      if (dtStore["trackinglist"][0][key2]["date"].length > 0) {
        l =
          dtStore["trackinglist"][0][key2]["date"] +
          ";" +
          dtStore["trackinglist"][0][key2]["time"];
      }
      let l2 =
        dtStore["trackinglist"][0][key]["date"] +
        ";" +
        dtStore["trackinglist"][0][key]["time"];
      return Utils.diff_hours(l, l2);
    } else {
      return "";
    }
  };

  const setstatustime = (key) => {
    if (
      dtStore["trackinglist"][0][key]["date"].length == 0 &&
      dtStore["trackinglist"][0]["dispatchedtopickuppoint"]["date"].length == 0
    ) {
      const dn = new Date();
      const h = dn.getHours();
      const m = dn.getMinutes();
      dtStore["trackinglist"][0][key]["date"] = Utils.yyyymmdd(dn);
      dtStore["trackinglist"][0][key]["time"] = `${h}:${m}`;
    }
  };

  const getcar = (key1, key2) => {
    let l = dtStore["trackinglist"][0][key2];
    let l2 = dtStore["trackinglist"][0][key1];
    if ((!l || l["date"].length == 0) && l2["date"].length != 0) {
      return (
        <img
          src="./assets/Domestiv Transport (1).png"
          class="dispatch-truck"
          style={{ height: "10px" }}
          alt=""
        />
      );
    } else {
      return "";
    }
  };
  const setuserLists = (users) => {
    let lz = [];
    let agusers = users.filter((e) => {
      if (e.email?.endsWith("agraga.com") && e.status == "ACTIVE") {
        let l = e.firstName;
        if (e.lastName && e.lastName.length > 0) {
          l = l + " " + e.lastName + ` (${e.email})`;
        }
        lz.push({ value: l, label: l });
      }
      return e;
    });
    lz.sort((a, b) => a.value?.localeCompare(b.value));
    console.log(lz);
    setDefaultOptions(lz);
  };

  useEffect(() => {
    BranchApi.getAllBranches(dtStore.entityId,setBranches)
    AguserApi.getList(setuserLists);
    if (Object.keys(vehiclelist1).length == 0) {
      DtApi.getVechicleList(setvlist1);
    }
    if (dtview == "create") {
      setvlist();
    }
    checkfield();
    if (Object.keys(curlist).length == 0) {
      let l = {};
      let k = configStore.currencyList;
      for (let i = 0; i < k.length; i++) {
        l[k[i]["currency"]] = k[i]["currency"];
      }
      setCurlist(l);
    }
  }, []);
  useEffect(() => {
    if (!checkenable) {
      if (dtStore._id && dtStore._id.length > 0) {
        getactions(dtStore._id);
      }
    }
  }, [dtStore]);

  return (
    <>
      <DtupdateDriverNum />
      <DtMap />
      <DtInitiateAdvancemodal />
      <DtInitiateBalancePaymentmodal />
      <DtStopTracking />
      <div
        class="main-content"
        onClick={() => {
          if (showweighttypes) {
            setShowweighttypes(false);
          }
        }}
      >
        {dtStore ? (
          <>
           { !DtStore.isViewParticular && <div class="header-wrapper">
              <div class="left-header">
                <div
                  class="back-div"
                  onClick={() => {
                    configStore.dtCurrentView = "list";
                  }}
                >
                  <img src="./assets/Back.png" alt="Back" class="back" />
                </div>
                <div class="page-heading">
                  <span class="heading margin-none">Domestic Transport</span>
                  <span class="slash margin-none">/</span>
                  {dtview == "create" ? (
                    <span class="heading margin-none">Create Bookings</span>
                  ) : (
                    <>
                      <span class="heading margin-none">Bookings</span>
                      <span class="slash margin-none">/</span>
                      <span class="heading margin-none">
                        Id - {dtStore["_id"]}
                      </span>
                    </>
                  )}
                </div>
              </div>
            </div>}
            {dtStore.status == "CANCELLED" ? (
              <>
                <div class="notification is-danger">
                  <div class="columns">
                    <div class="column">
                      <strong>Cancelled Reason : </strong>
                      {dtStore.reason}
                    </div>
                  </div>
                  <div class="columns">
                    <div class="column">
                      <strong>Cancelled By : </strong>
                      {dtStore.tsCalcelledBy}
                    </div>
                    <div class="column">
                      <strong>Cancelled On : </strong>
                      {Utils.formatToDateTime(Number(dtStore.tsCalcelledOn))}
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <></>
            )}

            {dtStore.bookingversion &&
            dtStore.bookingversion == "v2" &&
            dtview != "create" ? (
              <div class="header-wrapper-2">
                <div class="left-header-2">
                  <span class="status-label">Status:</span>
                  <div class="status-value">
                    <span class="status-text">{dtStore["status"]}</span>
                  </div>
                </div>
                <div>
                  { currentview === 'booking' ? <button
                    style={{
                      background: "#2c358a",
                      color: "#fff",
                      fontSize: "15px",
                      fontWeight: 500,
                    }}
                    class="button js-modal-trigger"
                    data-target="modal-js-example"
                    onClick={() => {
                      setCargoModalVisible(
                        isCargoModalVisible === false ? true : false
                      );
                    }}
                  >
                    Cargo Details
                  </button> : <></>}
                </div>
                <div class="right-header-2">
                  <div
                    class={
                      currentview != "booking"
                        ? "booking-tab active-tab"
                        : "tracking-tab"
                    }
                    onClick={() => {
                      setCurrentview("tracking");
                    }}
                  >
                    <img
                      src="./assets/Tracking (1).png"
                      alt="Tracking"
                      class={
                        currentview != "booking"
                          ? "switch-icon d-none"
                          : "switch-icon d-block"
                      }
                    />
                    <img
                      src="./assets/Tracking (2).png"
                      alt="Tracking"
                      class={
                        currentview != "booking"
                          ? "switch-icon d-block"
                          : "switch-icon d-none"
                      }
                    />
                    <span
                      class={
                        currentview != "booking"
                          ? "switch-text switch-text-active"
                          : "switch-text"
                      }
                    >
                      Tracking
                    </span>
                  </div>
                  <div
                    class={
                      currentview == "booking"
                        ? "booking-tab active-tab"
                        : "tracking-tab"
                    }
                    onClick={() => {
                      setCurrentview("booking");
                    }}
                  >
                    <img
                      src="./assets/Booking (1).png"
                      alt="Tracking"
                      class={
                        currentview == "booking"
                          ? "switch-icon d-none"
                          : "switch-icon d-block"
                      }
                    />
                    <img
                      src="./assets/Booking (2).png"
                      alt="Tracking"
                      class={
                        currentview == "booking"
                          ? "switch-icon d-block"
                          : "switch-icon d-none"
                      }
                    />
                    <span
                      class={
                        currentview == "booking"
                          ? "switch-text switch-text-active"
                          : "switch-text"
                      }
                    >
                      Booking
                    </span>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
            {isCargoModalVisible ? (
              <Dtcargodetails modalClose={(val) => setCargoModalVisible(val)} />
            ) : (
              <></>
            )}
            {currentview == "booking" ? (
              <>
                {dtview == "create" ? (
                  <>
                    <section class="section section-padding">
                      <div class="blue-heading">Basic Details</div>
                      <div class="fields-wrapper">
                        <div class="field">
                          <label for="" class="input-label">
                            Customer Reference Number
                          </label>
                          <Dtnewinput
                            record={dtStore}
                            name="customer_ref_no"
                            classnames="input-field margin-none margin-bottom-2"
                          />
                        </div>
                        <div class="field">
                        <label for="" class="input-label">
                          Customer Branch
                        </label>
                        <div class="select-wrapper">
                          <select
                          // style={branches.length == 1 ? { pointerEvents: 'none', opacity: 0.6,fontSize: 'small' }:{fontSize: 'small'}}
                          style={
                            branches.length === 1
                              ? { pointerEvents: 'none', opacity: 0.6, fontSize: 'small' }
                              : checkvalid("branchId")
                              ? { fontSize: 'small' }
                              :   {border: '1px solid red'}
                          }
                          class="input-field  margin-none select-padding"
                            onChange={(e) => {
                              console.log('FROM INSIDE AgSelect=', e.target.value)
                              dtStore["branchId"] = e.target.value;
                              checkfield();
                            }}
                            value={dtStore["branchId"]}
                          >
                            {branches.length > 1 && <option value="">Select Customer Branch</option>}
                            {branches?.map(value => {
                                if(branches.length === 1){ 
                                dtStore.branchId = value.branchId
                                }
                              return (<option value={value.branchId}>{value.state.split("/")[0]} - {value.gst}</option>)
                            })}
                          </select>
                          <div class="chevron-div p-absolute">
                            <img
                              src="./assets/Chevron.png"
                              alt="Chevron"
                              class="chevron "
                            />
                          </div>
                        </div>
                        
                      </div>
                
                      <div class="field visibility-hidden">
                        <div class="filler"></div>
                      </div>

                      <div class="field visibility-hidden">
                        <div class="filler"></div>
                      </div>
                        </div>
                        <div class="fields-wrapper" style={{marginTop:'15px'}}>
                        <div class="field">
                          <label for="" class="input-label">
                            Contract Id
                          </label>
                          <input
                            type="text"
                            class="input-disabled background-none margin-none"
                            value={
                              dtStore["details"][0]["contract_id"].split("-")[0]
                            }
                            disabled
                          />
                        </div>
                        
                        <div class="field">
                          <label for="" class="input-label ">
                            Delivery Appointment
                          </label>
                          <input
                            type="text"
                            class="input-disabled background-none margin-none"
                            value={dtStore.details[0].delivery_ap}
                            disabled
                          />
                        </div>

                        <div class="field">
                          <label for="" class="input-label ">
                            Commodity Type
                          </label>
                          <input
                            type="text"
                            class="input-disabled background-none margin-none"
                            title={dtStore.commodity_type}
                            value={dtStore.commodity_type}
                            disabled
                          />
                        </div>
                      </div>
                    </section>
                  </>
                ) : (
                  <>
                    {!client ? (
                      <section class="section section-padding">
                        <div class="blue-heading">Basic Details</div>
                        <div class="fields-wrapper">
                          <div class="field">
                            <label for="" class="input-label">
                              Customer Reference Number
                            </label>
                            <Dtnewinput
                              record={dtStore}
                              disabled={
                                !RoleUtils.isUserAuthorized(
                                  PERMISSIONS.EDIT.CUSTOMER_REF_NUMBER
                                )
                              }
                              name="customer_ref_no"
                              label="Enter Customer Reference"
                              placeholder="Enter Customer Ref. No."
                              classnames="input-field margin-none margin-bottom-2"
                            />
                          </div>

                          <div class="field">
                            <label for="" class="input-label">
                              ERP Number
                            </label>
                            <Dtnewinput
                              record={dtStore}
                              name="erp"
                              label="Enter ERP Number"
                              disabled={
                                !RoleUtils.isUserAuthorized(
                                  PERMISSIONS.EDIT.ERP_NUMBER
                                )
                              }
                              classnames="input-field margin-none"
                            />
                          </div>

                          <div class="field">
                            <label for="" class="input-label">
                              Assign DT Operations
                            </label>
                            {[
                              "Report at Pickup",
                              "Yet to Commence",
                              "Commenced",
                            ].indexOf(dtStore.status) == -1 ? (
                              <input
                                type="text"
                                title={dtStore.dtoperation}
                                class="input-disabled background-none"
                                value={dtStore.dtoperation}
                                disabled
                              />
                            ) : (
                              <Select
                                className="basic-single"
                                classNamePrefix="select"
                                defaultValue={defaultValue}
                                isClearable={true}
                                isSearchable={true}
                                options={defaultOptions}
                                onChange={(val) => {
                                  if (val && val.value) {
                                    dtStore.dtoperation = val.value;
                                  } else {
                                    dtStore.dtoperation = "";
                                  }
                                }}
                              />
                            )}
                          </div>

                          <div class="field">
                            <label for="" class="input-label">
                              Contract Id
                            </label>
                            <input
                              type="text"
                              class="input-disabled margin-none background-none"
                              value={
                                dtStore["details"][0]["contract_id"].split(
                                  "-"
                                )[0]
                              }
                              disabled
                            />
                          </div>
                        </div>

                        <div class="fields-wrapper">
                          <div class="field">
                            <label for="" class="input-label">
                              Booking Date
                            </label>
                            <input
                              type="text"
                              class="input-disabled margin-none background-none"
                              value={dtStore.bookingdate}
                              disabled
                            />
                          </div>

                          <div class="field">
                            <label for="" class="input-label ">
                              Delivery Appointment
                            </label>
                            <input
                              type="text"
                              class="input-disabled margin-none background-none"
                              value={dtStore.details[0].delivery_ap}
                              disabled
                            />
                          </div>

                          <div class="field">
                            <label for="" class="input-label ">
                              Commodity Type
                            </label>
                            <input
                              type="text"
                              class="input-disabled margin-none background-none"
                              title={dtStore.commodity_type}
                              value={dtStore.commodity_type}
                              disabled
                            />
                          </div>

                          <div class="field visibility-hidden">
                            <div class="filler"></div>
                          </div>
                        </div>
                      </section>
                    ) : (
                      <section class="section section-padding">
                        <div class="blue-heading">Basic Details</div>
                        <div class="fields-wrapper">
                          <div class="field">
                            <label for="" class="input-label">
                              Customer Reference Number
                            </label>
                            <Dtnewinput
                              record={dtStore}
                              name="customer_ref_no"
                              label="Enter Contact Person"
                              classnames="input-field margin-none margin-bottom-2"
                            />
                          </div>

                          <div class="field">
                            <label for="" class="input-label">
                              Assign DT Operations
                            </label>
                            <input
                              type="text"
                              title={dtStore.dtoperation}
                              class="input-disabled background-none"
                              value={dtStore.dtoperation}
                              disabled
                            />
                          </div>

                          <div class="field">
                            <label for="" class="input-label">
                              Contract Id
                            </label>
                            <input
                              type="text"
                              class="input-disabled background-none margin-none"
                              value={
                                dtStore["details"][0]["contract_id"].split(
                                  "-"
                                )[0]
                              }
                              disabled
                            />
                          </div>
                          <div class="field">
                            <label for="" class="input-label">
                              Booking Date
                            </label>
                            <input
                              type="text"
                              class="input-disabled background-none margin-none"
                              value={dtStore.bookingdate}
                              disabled
                            />
                          </div>
                        </div>

                        <div class="fields-wrapper">
                          <div class="field">
                            <label for="" class="input-label">
                              Delivery Appointment
                            </label>
                            <input
                              type="text"
                              class="input-disabled background-none margin-none"
                              value={dtStore.details[0].delivery_ap}
                              disabled
                            />
                          </div>

                          <div class="field">
                            <label for="" class="input-label ">
                              Commodity Type
                            </label>
                            <input
                              type="text"
                              class="input-disabled background-none margin-none"
                              title={dtStore.commodity_type}
                              value={dtStore.commodity_type}
                              disabled
                            />
                          </div>

                          <div class="field visibility-hidden">
                            <div class="filler"></div>
                          </div>
                          <div class="field visibility-hidden">
                            <div class="filler"></div>
                          </div>
                        </div>
                      </section>
                    )}
                  </>
                )}

                {/* <section class="section section-padding">
            <div class="blue-heading">Address Details</div>
            <div class="address-list">
              <div class="add-block block-1">
                <label for="" class="add-heading">
                  Pickup Address
                </label>
                <div class="add-div">
                  <span class="address">{dtStore.details[0]["pickup"]}</span>
                </div>
              </div>

              {dtStore.details[0]["delivery"].map((v, i) => {
                return (
                  <>
                    <div class="thread-div">
                      <span class="thread"></span>
                    </div>

                    <div class="add-block block-1">
                      {dtStore.details[0]["delivery"].length > 1 ? (
                        <label for="" class="add-heading">
                          Delivery Address-{i + 1}
                        </label>
                      ) : (
                        <label for="" class="add-heading">
                          Delivery Address
                        </label>
                      )}

                      <div class="add-div">
                        <span class="address">{v.address}</span>
                      </div>
                    </div>
                  </>
                );
              })}
            </div>
          </section> */}

                {dtview == "edit" && !client ? (
                  <>
                    <section class="section section-padding">
                      <div class="blue-heading">Vendor Details</div>
                      <div
                        style={
                          !RoleUtils.isUserAuthorized(
                            PERMISSIONS.EDIT.VENDOR_DETAILS
                          )
                            ? { pointerEvents: "none", opacity: 0.6 }
                            : {}
                        }
                      >
                        <div class="fields-wrapper">
                          <div class="field">
                            <label for="" class="input-label">
                              Vendor Assigned
                            </label>
                            {enable1 ? (
                              <input
                                type="text"
                                class="input-field margin-none"
                                value={dtStore.vendor_assigned}
                                disabled
                              />
                            ) : (
                              <>
                                {!client ? (
                                  <Dtsearchselect
                                    type="text"
                                    record={dtStore}
                                    name="vendor_assigned"
                                    classnames="input-field margin-none"
                                    isValid={checkvalid("vendor_assigned")}
                                    callback={checkfield}
                                    keyname={"vendor"}
                                  />
                                ) : (
                                  // <Dtnewinput
                                  //   type="text"
                                  //   record={dtStore}
                                  //   name="vendor_assigned"
                                  //   classnames="input-field margin-none"
                                  //   isValid={checkvalid('vendor_assigned')}
                                  //   callback={checkfield}
                                  // />
                                  <input
                                    type="text"
                                    class="input-field margin-none"
                                    value={dtStore.vendor_assigned}
                                    disabled
                                  />
                                )}
                              </>
                            )}
                          </div>

                          <div class="field">
                            <label for="" class="input-label">
                              Vendor Agreed Cost
                            </label>
                            <div class="input-d-wrapper">
                              <div class="input-dropdown">
                                INR
                                <img
                                  src="./assets/chevron-white.png"
                                  alt="chevron"
                                  class="chevron-3"
                                />
                                <div class="currency-div d-none">
                                  <ul>
                                    <li>INR</li>
                                    <li>USD</li>
                                    <li>DNR</li>
                                    <li>EUR</li>
                                    <li>DIR</li>
                                    <li>QSD</li>
                                  </ul>
                                </div>
                              </div>
                              {enable1 ? (
                                <input
                                  type="text"
                                  class="input-field margin-none input-padding-right"
                                  value={dtStore.vendor_agreed_cost}
                                  disabled
                                />
                              ) : (
                                <>
                                  {!client ? (
                                    <Dtnewinput
                                      type="number"
                                      record={dtStore}
                                      name="vendor_agreed_cost"
                                      classnames="input-field margin-none input-padding-right"
                                      isValid={checkvalid("vendor_agreed_cost")}
                                      callback={checkfield}
                                    />
                                  ) : (
                                    <input
                                      type="text"
                                      class="input-field margin-none input-padding-right"
                                      value={dtStore.vendor_agreed_cost}
                                      disabled
                                    />
                                  )}
                                </>
                              )}
                            </div>
                          </div>

                          <div class="field">
                            <label for="" class="input-label">
                              Additional Cost Reason
                            </label>
                            {enable2 ? (
                              <input
                                type="text"
                                class="input-field margin-none"
                                value={dtStore.additional_cost_reason}
                                disabled
                              />
                            ) : (
                              <>
                                {!client ? (
                                  <Dtnewinput
                                    type="text"
                                    record={dtStore}
                                    name="additional_cost_reason"
                                    classnames="input-field margin-none"
                                  />
                                ) : (
                                  <input
                                    type="text"
                                    class="input-field margin-none"
                                    value={dtStore.additional_cost_reason}
                                    disabled
                                  />
                                )}
                              </>
                            )}
                          </div>

                          <div class="field">
                            <label for="" class="input-label">
                              Vendor Additional Cost
                            </label>
                            <div class="input-d-wrapper">
                              <div class="input-dropdown">
                                INR
                                <img
                                  src="./assets/chevron-white.png"
                                  alt="chevron"
                                  class="chevron-3"
                                />
                                <div class="currency-div d-none">
                                  <ul>
                                    <li>INR</li>
                                    <li>USD</li>
                                    <li>DNR</li>
                                    <li>EUR</li>
                                    <li>DIR</li>
                                    <li>QSD</li>
                                  </ul>
                                </div>
                              </div>
                              {enable2 ? (
                                <input
                                  type="text"
                                  class="input-field margin-none input-padding-right"
                                  value={dtStore.vendor_additional_cost}
                                  disabled
                                />
                              ) : (
                                <>
                                  {!client ? (
                                    <Dtnewinput
                                      type="number"
                                      record={dtStore}
                                      name="vendor_additional_cost"
                                      classnames="input-field margin-none input-padding-right"
                                    />
                                  ) : (
                                    <input
                                      type="text"
                                      class="input-field margin-none input-padding-right"
                                      value={dtStore.vendor_additional_cost}
                                      disabled
                                    />
                                  )}
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                        {/* <div class="field visibility-hidden">
                    <div class="filler"></div>
                  </div> */}
                      </div>
                      <br />
                      <div class="fields-wrapper">
                        <div class="field">
                          <label for="" class="input-label">
                            Vendor Invoice
                          </label>
                          <div class="wrapper">
                            {enable2 ? (
                              <>
                                {dtStore.vender_invoice.length == 0 &&
                                RoleUtils.isUserAuthorized(
                                  PERMISSIONS.EDIT.VENDOR_DETAILS
                                ) ? (
                                  <div
                                    class="file-placeholder font-15 width-200 height-45 "
                                    style={
                                      !RoleUtils.isUserAuthorized(
                                        PERMISSIONS.EDIT.VENDOR_DETAILS
                                      )
                                        ? {
                                            pointerEvents: "none",
                                            opacity: 0.6,
                                          }
                                        : {}
                                    }
                                  >
                                    No Document Uploaded
                                  </div>
                                ) : (
                                  <div class="file-div">
                                    <span>
                                      <img
                                        src="./assets/file (1).png"
                                        alt="File"
                                        class="file-img"
                                      />

                                      <a
                                        target="_blank"
                                        href={
                                          server +
                                          "/api/v1/filestore/getfile/" +
                                          dtStore.details[0].contract_id +
                                          "/" +
                                          dtStore.vender_invoice
                                        }
                                      >
                                        {dtStore.vender_invoice}
                                      </a>
                                    </span>
                                  </div>
                                )}
                              </>
                            ) : (
                              <>
                                {dtStore.vender_invoice.length == 0 ? (
                                  <div
                                    class="file-input-div"
                                    id="vin"
                                    style={
                                      !RoleUtils.isUserAuthorized(
                                        PERMISSIONS.EDIT.VENDOR_DETAILS
                                      )
                                        ? {
                                            pointerEvents: "none",
                                            opacity: 0.6,
                                          }
                                        : {}
                                    }
                                  >
                                    <AgBtnmongofilenew
                                      disabled={false}
                                      parent={"vin"}
                                      label="vender_invoice"
                                      filekey={dtStore.details[0].contract_id}
                                      callback={(data, id) => {
                                        console.log(id);
                                        dtStore.vender_invoice =
                                          data.key.filename;
                                        checkfield();
                                      }}
                                    />
                                  </div>
                                ) : (
                                  <div class="file-div">
                                    <span>
                                      <img
                                        src="./assets/file (1).png"
                                        alt="File"
                                        class="file-img"
                                      />

                                      <a
                                        target="_blank"
                                        href={
                                          server +
                                          "/api/v1/filestore/getfile/" +
                                          dtStore.details[0].contract_id +
                                          "/" +
                                          dtStore.vender_invoice
                                        }
                                      >
                                        {dtStore.vender_invoice}
                                      </a>
                                    </span>
                                    {!client &&
                                    RoleUtils.isUserAuthorized(
                                      PERMISSIONS.EDIT.VENDOR_DETAILS
                                    ) ? (
                                      <span
                                        class="cancel-div"
                                        onClick={() => {
                                          dtStore.vender_invoice = "";
                                          checkfield();
                                        }}
                                      >
                                        {" "}
                                        <img
                                          src="./assets/Cancel.png"
                                          class="cancel-button"
                                          alt="Cancel"
                                        />
                                      </span>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                )}
                              </>
                            )}
                          </div>
                        </div>

                        <div class="field visibility-hidden">
                          <div class="filler"></div>
                        </div>

                        <div class="field visibility-hidden">
                          <div class="filler"></div>
                        </div>
                        <div class="field visibility-hidden">
                          <div class="filler"></div>
                        </div>
                      </div>
                    </section>

                    {/* <section class="section section-padding">
                <div class="blue-heading">Agraga Contact Details</div>
                <div class="fields-wrapper">
                  <div class="field">
                    <label for="" class="input-label">
                      Relationship Manager
                    </label>
                    <div class="input-c-wrapper">
                      <div class="country-code">+91</div>
                      {!client ? (
                        <Dtnewinput
                          type="number"
                          onKeyDown={(e) =>
                            ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
                          }
                          onInput={(e) => {
                            e.target.value = e.target.value.replace(/\D/g, '').slice(0, 10);
                          }}
  
                          record={dtStore}
                          name="relationship_manager"
                          classnames="input-field margin-none input-padding-left"
                        />
                      ) : (
                        <input
                          type="text"
                          class="input-field margin-none input-padding-left"
                          value={dtStore.relationship_manager}
                          disabled
                        />
                      )}
                    </div>
                  </div>

                  <div class="field">
                    <label for="" class="input-label">
                      Customer Success
                    </label>
                    <div class="input-c-wrapper">
                      <div class={(dtview=="create" || (dtview=="edit" && !client ))?"country-code":"country-code remove-country-code"}>+91</div>
                      {!client ? (
                        <Dtnewinput
                          type="number"
                          onKeyDown={(e) =>
                            ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
                          }
                          
                          onInput={(e) => {
                            e.target.value = e.target.value.replace(/\D/g, '').slice(0, 10);
                          }}
                          record={dtStore}
                          name="customer_success"
                          classnames="input-field margin-none input-padding-left"
                        />
                      ) : (
                        <input
                          type="text"
                          class="input-field margin-none input-padding-left"
                          value={dtStore.customer_success}
                          disabled
                        />
                      )}
                    </div>
                  </div>

                  <div class="field">
                    <label for="" class="input-label">
                      Operations Representative
                    </label>
                    <div class="input-c-wrapper">
                      <div class={(dtview=="create" || (dtview=="edit" && !client ))?"country-code":"country-code remove-country-code"}>+91</div>
                      {!client ? (
                        <Dtnewinput
                          type="number"
                          record={dtStore}
                          onKeyDown={(e) =>
                            ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
                          }
                          onInput={(e) => {
                            e.target.value = e.target.value.replace(/\D/g, '').slice(0, 10);
                          }}
                          name="operations_representative"
                          classnames="input-field margin-none input-padding-left"
                        />
                      ) : (
                        <input
                          type="text"
                          class="input-field margin-none input-padding-left"
                          value={dtStore.operations_representative}
                          disabled
                        />
                      )}
                    </div>
                  </div>

                  <div class="field visibility-hidden">
                    <div class="filler"></div>
                  </div>
                </div>
              </section> */}
                  </>
                ) : (
                  ""
                )}

                {dtview == "edit" ? (
                  <section
                    class="section section-padding"
                    style={
                      !RoleUtils.isUserAuthorized(
                        PERMISSIONS.EDIT.DRIVER_DETAILS
                      )
                        ? { pointerEvents: "none", opacity: 0.6 }
                        : {}
                    }
                  >
                    <div class="blue-heading">Driver Details</div>
                    <div class="fields-wrapper">
                      <div class="field">
                        <label for="" class="input-label">
                          Driver Name
                        </label>
                        {client ? (
                          <input
                            type="text"
                            class="input-disabled background-none"
                            value={
                              dtStore.drivername.length == undefined ||
                              dtStore.drivername.length == 0
                                ? "No Driver Name"
                                : dtStore.drivername
                            }
                            // value={dtStore.drivername}
                            disabled
                          />
                        ) : (
                          <>
                            {dtStore.stoptracking == undefined ? (
                              <Dtsearchselect
                                callback={checkfield}
                                record={dtStore}
                                name="drivername"
                                classnames="input-field margin-none"
                                keyname={"driver"}
                              />
                            ) : (
                              <input
                                type="text"
                                class="input-disabled background-none"
                                value={
                                  dtStore.drivername.length == undefined ||
                                  dtStore.drivername.length == 0
                                    ? "No Driver Name"
                                    : dtStore.drivername
                                }
                                // value={dtStore.drivername}
                                disabled
                              />
                            )}
                          </>

                          // <Dtnewinput
                          // callback={checkfield}
                          //   record={dtStore}
                          //   name="drivername"
                          //   classnames="input-field margin-none"
                          // />
                        )}
                      </div>

                      <div class="field">
                        <label for="" class="input-label">
                          Driver Contact Number
                        </label>
                        <div class="input-c-wrapper">
                          <div
                            class={
                              dtview == "create" ||
                              (dtview == "edit" && !client)
                                ? "country-code"
                                : "country-code remove-country-code"
                            }
                          >
                            +91
                          </div>

                          {client ? (
                            <input
                              type="text"
                              // style={{outline:"none"}}
                              class={
                                dtStore.drivernumber.length == undefined ||
                                dtStore.drivernumber.length == 0
                                  ? "remove-country-code input-field-margin-none input-padding-left "
                                  : "input-field margin-none input-padding-left remove-country-code"
                              }
                              value={
                                dtStore.drivernumber.length == undefined ||
                                dtStore.drivernumber.length == 0
                                  ? "No Phone Number"
                                  : dtStore.drivernumber
                              }
                              disabled
                            />
                          ) : dtStore.driverstatus == "ALLOWED" &&
                            dtStore.tripId.trim().length != 0 ? (
                            <input
                              type="text"
                              class="input-disabled"
                              style={{ margin: "0 45px" }}
                              value={
                                dtStore.drivernumber.length == undefined ||
                                dtStore.drivernumber.length == 0
                                  ? "No Phone Number"
                                  : dtStore.drivernumber
                              }
                              disabled
                            />
                          ) : (
                            <>
                              {dtStore.stoptracking == undefined ? (
                                <Dtnewinput
                                  type="number"
                                  callback={checkfield}
                                  record={dtStore}
                                  name="drivernumber"
                                  onKeyDown={(e) =>
                                    ["e", "E", "+", "-"].includes(e.key) &&
                                    e.preventDefault()
                                  }
                                  onInput={(e) => {
                                    e.target.value = e.target.value
                                      .replace(/\D/g, "")
                                      .slice(0, 10);
                                  }}
                                  classnames="input-field margin-none input-padding-left"
                                  isValid={checkvalid("drivernumber")}
                                />
                              ) : (
                                <input
                                  type="text"
                                  class="input-disabled"
                                  style={{ margin: "0 45px" }}
                                  value={
                                    dtStore.drivernumber.length == undefined ||
                                    dtStore.drivernumber.length == 0
                                      ? "No Phone Number"
                                      : dtStore.drivernumber
                                  }
                                  disabled
                                />
                              )}
                            </>
                          )}
                        </div>
                      </div>

                      {client || dtStore.tripstatus == "complete" ? (
                        <>
                          <div class="field visibility-hidden">
                            <div class="filler"></div>
                          </div>

                          <div class="field visibility-hidden">
                            <div class="filler"></div>
                          </div>
                        </>
                      ) : (
                        <>
                          <div class="field justify-content-end">
                            {dtStore.driverstatus == "ALLOWED" &&
                            dtStore.tripId.trim().length != 0 ? (
                              <button
                                className="add-contact"
                                onClick={updatenumber}
                              >
                                Update Number
                              </button>
                            ) : (
                              <button
                                className="add-contact"
                                onClick={checkdrivernumstatus}
                              >
                                Check Status
                              </button>
                            )}
                          </div>

                          <div class="field">
                            {dtStore.driverstatus.length == 0 ? (
                              <div class="filler"></div>
                            ) : (
                              <>
                                <label for="" class="input-label">
                                  Number Status
                                </label>
                                <input
                                  type="text"
                                  title={dtStore.driverstatus}
                                  class="input-disabled"
                                  value={dtStore.driverstatus}
                                  disabled
                                />
                              </>
                            )}
                          </div>
                        </>
                      )}
                    </div>
                  </section>
                ) : (
                  ""
                )}
   

                <section class="section section-padding" style={!RoleUtils.isUserAuthorized(PERMISSIONS.EDIT.VEHICLE_DETAILS) ? { pointerEvents: 'none', opacity: 0.6 } : {}}>
                  <div class="blue-heading">Vehicle Details</div>
                  <div class="fields-wrapper">
                    {dtview != "create" ? (
                      <div class="field">
                        <label for="" class="input-label">
                          Vehicle Registration Number
                        </label>
                        {client ? (
                          <input
                            type="text"
                            class="input-disabled font-18 background-none margin-bottom-2"
                            value={
                              dtStore.vehicle_no.length == undefined ||
                              dtStore.vehicle_no.length == 0
                                ? "Not Yet Updated"
                                : dtStore.vehicle_no
                            }
                            disabled
                          />
                        ) : (
                          <>
                            {dtStore.stoptracking == undefined ? (
                              <Dtsearchselect
                                checkfield={checkfield}
                                record={dtStore}
                                name="vehicle_no"
                                classnames="input-field margin-none margin-bottom-2"
                                keyname={"vechicles"}
                              />
                            ) : (
                              <input
                                type="text"
                                class="input-disabled font-18 background-none margin-bottom-2"
                                value={
                                  dtStore.vehicle_no.length == undefined ||
                                  dtStore.vehicle_no.length == 0
                                    ? "Not Yet Updated"
                                    : dtStore.vehicle_no
                                }
                                disabled
                              />
                            )}
                          </>
                        )}
                      </div>
                    ) : (
                      ""
                    )}
                    {/* <div class="vehicle-dropdown-div d-none">
              <div class="vehicle-list">
                <ul>
                  <li>TN05AD1429</li>
                  <li>TN05AD1429</li>
                  <li>TN05AD1429</li>
                  <li>TN05AD1429</li>
                  <li>TN05AD1429</li>
                  <li>TN05AD1429</li>
                  <li>TN05AD1429</li>
                </ul>
              </div>
              <button class="add-vehicle-button">
                <img src="./assets/add-vehicle.png" alt="add-vehicle" class="add-vehicle" />
                <span class="add-vehicle-text">Add Vehicle</span>
                <img src="./assets/Link-arrow (1).png" alt="Link" title="Add Vehicle" class="link-icon" />
              </button>
            </div> */}

                    {dtview == "create" ? (
                      <div class="field">
                        <label for="" class="input-label">
                          Vehicle Type
                        </label>
                        <div class="select-wrapper">
                          <select
                            class="input-field  margin-none select-padding"
                            onChange={(e) => {
                              //console.log('FROM INSIDE AgSelect=', record, e.target.value)
                              dtStore["vehicle"] = e.target.value;
                              checkfield();
                            }}
                            value={dtStore["vehicle"]}
                          >
                            {Object.entries(vehiclelist).map(([key, value]) => (
                              <option value={value}>{key}</option>
                            ))}
                          </select>
                          <div class="chevron-div p-absolute">
                            <img
                              src="./assets/Chevron.png"
                              alt="Chevron"
                              class="chevron "
                            />
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div class="field">
                        <label for="" class="input-label">
                          Vehicle Type
                        </label>
                        <input
                          type="text"
                          class="input-disabled font-18 background-none"
                          value={dtStore.vehicle}
                          disabled
                        />
                      </div>
                    )}

                    <div class="field">
                      <label for="" class="input-label back">
                        Dimensions
                      </label>
                      {Object.keys(vehiclelist1).length != 0 ? (
                        <input
                          type="text"
                          class="input-disabled background-none font-18"
                          value={
                            vehiclelist1[dtStore.vehicle]["Length"] +
                            "L x " +
                            vehiclelist1[dtStore.vehicle]["Width"] +
                            "H x " +
                            vehiclelist1[dtStore.vehicle]["Height"] +
                            "W"
                          }
                          disabled
                        />
                      ) : (
                        ""
                      )}
                    </div>

                    <div class="field">
                      <label for="" class="input-label">
                        Carrying Capacity
                      </label>
                      {Object.keys(vehiclelist1).length != 0 ? (
                        <input
                          type="text"
                          class="input-disabled background-none font-18"
                          value={
                            vehiclelist1[dtStore.vehicle][
                              "Cargo Weight Capacity (Kgs)"
                            ] + " Kgs"
                          }
                          disabled
                        />
                      ) : (
                        ""
                      )}
                    </div>

                    {dtview == "create" && dtStore.pricing_type == "vehicle" ? (
                      <div class="field">
                        <label for="" class="input-label">
                          Rate of Vehicle
                        </label>
                        <pre class="highlighted-text background-none margin-none">
                          ₹ {dtStore.bookingamount}
                          {""}
                          <span> ( Taxes as applicable )</span>
                        </pre>
                      </div>
                    ) : (
                      <>
                        {dtview != "edit" ? (
                          <div class="field visibility-hidden 12">
                            <div class="filler"></div>
                          </div>
                        ) : (
                          ""
                        )}
                      </>
                    )}
                  </div>
                  {dtview != "create" && dtStore.pricing_type == "vehicle" ? (
                    <div class="fields-wrapper">
                      <div class="field">
                        <label for="" class="input-label">
                          Rate of Vehicle
                        </label>
                        <pre
                          class="highlighted-text width-270 background-none margin-none"
                          // style={{ fontSize: "14px" }}
                        >
                          ₹ {dtStore.bookingamount}{" "}
                          <span> ( Taxes as applicable )</span>
                        </pre>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </section>
                {dtStore.pricing_type != "vehicle" ? (
                  <section class="section section-padding">
                    <div class="blue-heading">Cargo Details</div>
                    <div class="fields-wrapper">
                      <div class="field">
                        <label for="" class="input-label">
                          Total Weight
                        </label>
                        <div class="input-d-wrapper ">
                          <div
                            class="input-dropdown"
                            onClick={() => {
                              if (showweighttypes) {
                                setShowweighttypes(false);
                              } else {
                                setShowweighttypes(true);
                              }
                            }}
                          >
                            {dtStore.weight_type}
                            {(dtview != "create" && !client) ||
                            dtview == "create" ? (
                              <img
                                src="./assets/chevron-white.png"
                                alt="chevron"
                                class="chevron-3"
                              />
                            ) : (
                              ""
                            )}

                            <div
                              className={
                                showweighttypes
                                  ? "weight-div"
                                  : "weight-div d-none"
                              }
                            >
                              <ul>
                                <li
                                  onClick={() => {
                                    dtStore.weight_type = "kgs";
                                    checkfield();
                                  }}
                                >
                                  kgs
                                </li>
                                <li
                                  onClick={() => {
                                    dtStore.weight_type = "tons";
                                    checkfield();
                                  }}
                                >
                                  tons
                                </li>
                              </ul>
                            </div>
                          </div>
                          {dtview == "create" ? (
                            <Dtnewinput
                              callback={checkfield}
                              type="number"
                              isValid={checkvalid("totel_weight")}
                              record={dtStore}
                              name="totel_weight"
                              classnames="input-field pointer margin-none"
                            />
                          ) : (
                            <input
                              type="text"
                              class="input-field pointer margin-none"
                              value={dtStore.totel_weight}
                              disabled
                            />
                          )}
                        </div>
                      </div>

                      <div class="field">
                        <label for="" class="input-label">
                          Charges Per Ton
                        </label>
                        <pre class="input-disabled font-18 background-none margin-none">
                          ₹ {chargedetails["amt_per_ton"]}
                        </pre>
                      </div>

                      <div class="field">
                        <label for="" class="input-label">
                          Minimum Applicable
                        </label>
                        <pre class="input-disabled font-18 background-none margin-none">
                          ₹ {chargedetails["minimum"]}
                        </pre>
                      </div>

                      <div class="field width-270px">
                        <label for="" class="input-label">
                          Total Charges{" "}
                          <img
                            src="./assets/info.png"
                            alt="Info"
                            class="info"
                            title="Total Charges = Maximum between (Minimum applicable) and (charges per ton/kg * Total Weight)"
                          />
                        </label>
                        <pre class="highlighted-text background-none margin-none font-18px width-270">
                          ₹ {dtStore.bookingamount}{" "}
                          <span> ( Taxes as applicable )</span>
                        </pre>
                      </div>
                    </div>
                  </section>
                ) : (
                  ""
                )}

                {dtview == "edit" &&
                (!client || (client && dtStore.invoice.length > 0)) ? (
                  <section class="section section-padding">
                    <div class="blue-heading">
                      Agraga Freight Invoice Details
                    </div>
                    <div class="fields-wrapper">
                    <div class="field">
                          <label for="" class="input-label">
                            Customer Branch
                          </label>
                            <input
                              type="text"
                              class="input-disabled-wrap font-10 background-none"
                              value={branches
                                .filter(branchData => branchData._id === dtStore.branchId)
                                .map(filteredBranch => `${filteredBranch.state.split("/")[0]} - ${filteredBranch.gst}`)}
                              disabled
                            />
                        </div>

                      <div class="field" style={!RoleUtils.isUserAuthorized(PERMISSIONS.EDIT.AGRAGA_FREIGHT_INVOICE_DETAILS) ? { pointerEvents: 'none', opacity: 0.6 } : {}}>
                        <label for="" class="input-label">
                          Invoice Amount
                        </label>
                        <div class="input-d-wrapper">
                          <div class="input-dropdown">
                            INR
                            <img
                              src="./assets/chevron-white.png"
                              alt="chevron"
                              class="chevron-3"
                            />
                            <div class="currency-div d-none">
                              <ul>
                                <li>INR</li>
                                <li>USD</li>
                                <li>DNR</li>
                                <li>EUR</li>
                                <li>DIR</li>
                                <li>QSD</li>
                              </ul>
                            </div>
                          </div>
                          {enable2 ? (
                            <input
                              type="text"
                              class="input-field margin-none input-padding-right"
                              value={dtStore.total_cost}
                              disabled
                            />
                          ) : (
                            <>
                              {!client ? (
                                <Dtnewinput
                                  type="number"
                                  record={dtStore}
                                  name="total_cost"
                                  classnames="input-field margin-none input-padding-right"
                                  isValid={checkvalid("total_cost")}
                                  callback={checkfield}
                                />
                              ) : (
                                <input
                                  type="text"
                                  class="input-field margin-none input-padding-right"
                                  value={dtStore.total_cost}
                                  disabled
                                />
                              )}
                            </>
                          )}
                        </div>
                      </div>

                      <div class="field">
                        <label for="" class="input-label">
                          Invoice
                        </label>
                        <div class="wrapper">
                          {enable2 ? (
                            <>
                              {dtStore.invoice.length == 0 &&
                              RoleUtils.isUserAuthorized(
                                PERMISSIONS.EDIT.AGRAGA_FREIGHT_INVOICE_DETAILS
                              ) ? (
                                <div
                                  class="file-placeholder font-15 width-200 height-45 "
                                  style={
                                    !RoleUtils.isUserAuthorized(
                                      PERMISSIONS.EDIT
                                        .AGRAGA_FREIGHT_INVOICE_DETAILS
                                    )
                                      ? { pointerEvents: "none", opacity: 0.6 }
                                      : {}
                                  }
                                >
                                  No Document Uploaded
                                </div>
                              ) : (
                                <div class="file-div">
                                  <span>
                                    <img
                                      src="./assets/file (1).png"
                                      alt="File"
                                      class="file-img"
                                    />

                                    <a
                                      target="_blank"
                                      href={
                                        server +
                                        "/api/v1/filestore/getfile/" +
                                        dtStore.details[0].contract_id +
                                        "/" +
                                        dtStore.invoice
                                      }
                                    >
                                      {dtStore.invoice}
                                    </a>
                                  </span>
                                </div>
                              )}
                            </>
                          ) : (
                            <>
                              {dtStore.invoice.length == 0 ? (
                                <div
                                  class="file-input-div"
                                  id="cin"
                                  style={
                                    !RoleUtils.isUserAuthorized(
                                      PERMISSIONS.EDIT
                                        .AGRAGA_FREIGHT_INVOICE_DETAILS
                                    )
                                      ? { pointerEvents: "none", opacity: 0.6 }
                                      : {}
                                  }
                                >
                                  <AgBtnmongofilenew
                                    disabled={false}
                                    parent={"cin"}
                                    label="vender_invoice"
                                    filekey={dtStore.details[0].contract_id}
                                    callback={(data, id) => {
                                      console.log(id);
                                      dtStore.invoice = data.key.filename;
                                      checkfield();
                                    }}
                                  />
                                </div>
                              ) : (
                                <div class="file-div">
                                  <span>
                                    <img
                                      src="./assets/file (1).png"
                                      alt="File"
                                      class="file-img"
                                    />

                                    <a
                                      target="_blank"
                                      href={
                                        server +
                                        "/api/v1/filestore/getfile/" +
                                        dtStore.details[0].contract_id +
                                        "/" +
                                        dtStore.invoice
                                      }
                                    >
                                      {dtStore.invoice}
                                    </a>
                                  </span>
                                  {!client &&
                                  RoleUtils.isUserAuthorized(
                                    PERMISSIONS.EDIT
                                      .AGRAGA_FREIGHT_INVOICE_DETAILS
                                  ) ? (
                                    <span
                                      class="cancel-div"
                                      onClick={() => {
                                        dtStore.invoice = "";
                                        checkfield();
                                      }}
                                    >
                                      {" "}
                                      <img
                                        src="./assets/Cancel.png"
                                        class="cancel-button"
                                        alt="Cancel"
                                      />
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              )}
                            </>
                          )}
                        </div>
                      </div>
                                    
                      <div class="field visibility-hidden">
                        <div class="filler"></div>
                      </div>
                    {dtview != "edit" &&
                      (<div class="field visibility-hidden">
                        <div class="filler"></div>
                      </div>)}
                    </div>
                  </section>
                ) : (
                  ""
                )}

                <section class="section section-padding">
                  <div class="blue-heading">Pick-up Appointment Details</div>

                  <div class="fields-wrapper">
                    <div class="field">
                      <label for="" class="input-label">
                        Pick-up Date
                      </label>
                      {dtview == "create" ? (
                        <Dtnewinput
                          type="date"
                          record={dtStore}
                          name="pickupdate"
                          classnames="input-field pointer background-none margin-none width-170"
                          isValid={checkvalid("pickupdate")}
                          callback={checkfield}
                        />
                      ) : (
                        <input
                          type="text"
                          class="input-disabled background-none margin-none"
                          value={dtStore.pickupdate}
                          disabled
                        />
                      )}
                    </div>

                    <div class="field">
                      <label for="" class="input-label">
                        Reporting Time From
                      </label>
                      {dtview == "create" ? (
                        <Dt30minselect
                          record={dtStore}
                          name="reportingfrom"
                          // onfocus="this.showPicker()"
                          classnames="input-field background-none pointer margin-none width-170"
                          isValid={checkvalid("reportingfrom")}
                          callback={checkfield}
                        />
                      ) : (
                        <input
                          type="text"
                          class="input-disabled background-none margin-none"
                          value={dtStore.reportingfrom}
                          disabled
                        />
                      )}
                    </div>

                    <div class="field">
                      <label for="" class="input-label">
                        Reporting Time To
                      </label>
                      {dtview == "create" ? (
                        <Dt30minselect
                          record={dtStore}
                          name="reportingto"
                          classnames="input-field pointer background-none margin-none width-170"
                          isValid={checkvalid("reportingto")}
                          callback={checkfield}
                        />
                      ) : (
                        <input
                          type="text"
                          class="input-disabled background-none margin-none"
                          value={dtStore.reportingto}
                          disabled
                        />
                      )}
                    </div>

                    <div class="field visibility-hidden">
                      <div class="filler"></div>
                    </div>
                  </div>
                </section>

                {(dtview == "edit" && !client) ||
                (dtview == "edit" &&
                  client &&
                  dtStore.vendor_assigned.length > 0 &&
                  dtStore.vendor_agreed_cost.length > 0) ? (
                  <>
                    {dtStore.bookingversion &&
                    dtStore.bookingversion == "v2" ? (
                      <section class="section section-padding">
                        <div class="blue-heading">Destination Details</div>

                        <div class="address-data-div">
                          {dtStore.details[0].delivery.map((e, i) => {
                            return (
                              <div class="address-field-row margin-bottom-3">
                                <div
                                  class="add-block block-1 m-0"
                                  style={
                                    !RoleUtils.isUserAuthorized(
                                      PERMISSIONS.EDIT.DESTINATION_DETAILS
                                    ) && !client
                                      ? { pointerEvents: "none", opacity: 0.6 }
                                      : {}
                                  }
                                >
                                  {dtStore.details[0].delivery.length > 1 ? (
                                    <label for="" class="add-heading">
                                      Delivery Address {i + 1}
                                    </label>
                                  ) : (
                                    <label for="" class="add-heading">
                                      Delivery Address
                                    </label>
                                  )}

                                  <div class="add-div">
                                    <span class="address">{e.address}</span>
                                  </div>
                                </div>

                                <div
                                  class="field"
                                  style={
                                    !RoleUtils.isUserAuthorized(
                                      PERMISSIONS.EDIT.DESTINATION_DETAILS
                                    ) && !client
                                      ? { pointerEvents: "none", opacity: 0.6 }
                                      : {}
                                  }
                                >
                                  <label
                                    for=""
                                    class="input-label width-200 margin-bottom-2"
                                  >
                                    Cargo Value
                                  </label>
                                  <div class="input-d-wrapper">
                                    <div class="input-dropdown height-45">
                                      INR
                                      <img
                                        src="./assets/chevron-white.png"
                                        alt="chevron"
                                        class="chevron-3"
                                      />
                                      <div class="currency-div d-none">
                                        <ul>
                                          <li>INR</li>
                                          <li>USD</li>
                                          <li>DNR</li>
                                          <li>EUR</li>
                                          <li>DIR</li>
                                          <li>QSD</li>
                                        </ul>
                                      </div>
                                    </div>
                                    {enable1 ? (
                                      <input
                                        type="text"
                                        class="input-field margin-none input-padding-right"
                                        value={e.cargo_Value}
                                        disabled
                                      />
                                    ) : (
                                      <Dtnewinput
                                        type="number"
                                        record={e}
                                        name="cargo_Value"
                                        classnames="input-field width-200 height-45 margin-none input-padding-right"
                                        isValid={checkvalid("cargo_Value" + i)}
                                        callback={checkfield}
                                      />
                                    )}
                                  </div>
                                </div>

                                <div class="field">
                                  <label
                                    for=""
                                    class="input-label width-220 margin-bottom-2"
                                    // style={{fontSize:"15px"}}
                                  >
                                    E-Way Bill / Commercial Invoice
                                  </label>
                                  <div class="wrapper  width-200 height-45">
                                    {enable1 ? (
                                      <>
                                        {e.ewaybill.length == 0 &&
                                        RoleUtils.isUserAuthorized(
                                          PERMISSIONS.EDIT.DESTINATION_DETAILS
                                        ) &&
                                        !client ? (
                                          <div class="file-placeholder font-15 width-200 height-45 ">
                                            No Document Uploaded
                                          </div>
                                        ) : (
                                          <div class=" width-200 d-flex height-45 ">
                                            <span class="d-flex aligncenter">
                                              <img
                                                src="./assets/file (1).png"
                                                alt="File"
                                                class="file-img"
                                              />{" "}
                                              <a
                                                target="_blank"
                                                href={
                                                  server +
                                                  "/api/v1/filestore/getfile/" +
                                                  dtStore.details[0]
                                                    .contract_id +
                                                  "/" +
                                                  e.ewaybill
                                                }
                                                className="wrap-ellipsis"
                                              >
                                                E-Way Bill / Commercial Invoice
                                                - {i + 1}
                                              </a>
                                            </span>
                                          </div>
                                        )}
                                      </>
                                    ) : (
                                      <>
                                        {e.ewaybill.length == 0 ? (
                                          <div
                                            class="file-input-div width-200  "
                                            id={"DTEB___" + i}
                                            style={
                                              !RoleUtils.isUserAuthorized(
                                                PERMISSIONS.EDIT
                                                  .DESTINATION_DETAILS
                                              ) && !client
                                                ? {
                                                    pointerEvents: "none",
                                                    opacity: 0.6,
                                                  }
                                                : {}
                                            }
                                          >
                                            <Agmongofilenew
                                              disabled={
                                                e.approved === "Approve"
                                                  ? true
                                                  : false
                                              }
                                              parent={"DTEB___" + i}
                                              label={"ewaybill" + i}
                                              filekey={
                                                dtStore.details[0].contract_id
                                              }
                                              callback={(data, id) => {
                                                console.log(id);
                                                e.ewaybill = data.key.filename;
                                                e["ewaybillCreatedBy"] =
                                                  loginStore.email;
                                                checkfield();
                                              }}
                                            />
                                          </div>
                                        ) : (
                                          <div class=" width-200 d-flex height-45 ">
                                            <span class="d-flex aligncenter">
                                              <img
                                                src="./assets/file (1).png"
                                                alt="File"
                                                class="file-img"
                                              />{" "}
                                              <a
                                                target="_blank"
                                                href={
                                                  server +
                                                  "/api/v1/filestore/getfile/" +
                                                  dtStore.details[0]
                                                    .contract_id +
                                                  "/" +
                                                  e.ewaybill
                                                }
                                                className="wrap-ellipsis"
                                              >
                                                E-Way Bill / Commercial Invoice
                                                - {i + 1}
                                              </a>
                                            </span>
                                            {RoleUtils.isUserAuthorized(
                                              PERMISSIONS.EDIT
                                                .DESTINATION_DETAILS || client
                                            ) ? (
                                              <span
                                                class="cancel-div height-45"
                                                onClick={() => {
                                                  e.ewaybill = "";
                                                  checkfield();
                                                }}
                                              >
                                                {" "}
                                                <img
                                                  src="./assets/Cancel.png"
                                                  class="cancel-button"
                                                  alt="Cancel"
                                                />
                                              </span>
                                            ) : (
                                              ""
                                            )}
                                          </div>
                                        )}
                                      </>
                                    )}
                                  </div>
                                  {e.ewaybill.length > 0 ? (
                                    <div
                                      style={
                                        !RoleUtils.isUserAuthorized(
                                          PERMISSIONS.EDIT.DESTINATION_DETAILS
                                        ) && !client
                                          ? {
                                              pointerEvents: "none",
                                              opacity: 0.6,
                                            }
                                          : {}
                                      }
                                    >
                                      <label for="" class="input-label">
                                        E-Way Bill No
                                      </label>
                                      <Dtnewinput
                                        record={e}
                                        name="ewaybillno"
                                        classnames="input-field margin-none margin-bottom-2 width-200"
                                        isValid={checkvalid("ewaybillno" + i)}
                                        callback={checkfield}
                                      />
                                      <label for="" class="input-label">
                                        Valid Upto
                                      </label>
                                      <Dtnewinput
                                        record={e}
                                        type="date"
                                        name="ewaybilldate"
                                        mindate={dtStore.bookingdate}
                                        classnames="input-field margin-none margin-bottom-2 width-200"
                                        isValid={checkvalid("ewaybilldate" + i)}
                                        callback={checkfield}
                                      />
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </div>

                                <div class="field">
                                  <label
                                    for=""
                                    class="input-label width-200 margin-bottom-2"
                                  >
                                    Lorry Reciept
                                  </label>
                                  <div class="wrapper width-200 height-45">
                                    {enable1 ? (
                                      <>
                                        {e.lorryreceipt.length == 0 &&
                                        RoleUtils.isUserAuthorized(
                                          PERMISSIONS.EDIT.DESTINATION_DETAILS
                                        ) &&
                                        !client ? (
                                          <div class="file-placeholder font-15 width-200 height-45 ">
                                            No Document Uploaded
                                          </div>
                                        ) : (
                                          <div class=" width-200 height-45 ">
                                            <span>
                                              <img
                                                src="./assets/file (1).png"
                                                alt="File"
                                                class="file-img"
                                              />{" "}
                                              <a
                                                target="_blank"
                                                href={
                                                  server +
                                                  "/api/v1/filestore/getfile/" +
                                                  dtStore.details[0]
                                                    .contract_id +
                                                  "/" +
                                                  e.lorryreceipt
                                                }
                                              >
                                                Lorry Receipt - {i + 1}
                                              </a>
                                            </span>
                                          </div>
                                        )}
                                      </>
                                    ) : (
                                      <>
                                        {e.lorryreceipt.length == 0 ? (
                                          <div
                                            style={
                                              !RoleUtils.isUserAuthorized(
                                                PERMISSIONS.EDIT
                                                  .DESTINATION_DETAILS
                                              ) && !client
                                                ? {
                                                    pointerEvents: "none",
                                                    opacity: 0.6,
                                                  }
                                                : {}
                                            }
                                          >
                                            {!client ? (
                                              <div
                                                class="file-input-div width-200 "
                                                id={"DTLB___" + i}
                                              >
                                                <Agmongofilenew
                                                  disabled={
                                                    e.approved === "Approve"
                                                      ? true
                                                      : false
                                                  }
                                                  parent={"DTLB___" + i}
                                                  label={"lorryreceipt" + i}
                                                  filekey={
                                                    dtStore.details[0]
                                                      .contract_id
                                                  }
                                                  callback={(data, id) => {
                                                    console.log(id);
                                                    e.lorryreceipt =
                                                      data.key.filename;
                                                    checkfield();
                                                  }}
                                                />
                                              </div>
                                            ) : (
                                              <div class="file-placeholder font-15 width-200 height-45 ">
                                                No Document Uploaded
                                              </div>
                                            )}
                                          </div>
                                        ) : (
                                          <div class=" width-200 d-flex height-45 ">
                                            <span class="d-flex aligncenter">
                                              <img
                                                src="./assets/file (1).png"
                                                alt="File"
                                                class="file-img"
                                              />{" "}
                                              <a
                                                target="_blank"
                                                href={
                                                  server +
                                                  "/api/v1/filestore/getfile/" +
                                                  dtStore.details[0]
                                                    .contract_id +
                                                  "/" +
                                                  e.lorryreceipt
                                                }
                                                className="wrap-ellipsis"
                                              >
                                                Lorry Receipt - {i + 1}
                                              </a>
                                            </span>
                                            {RoleUtils.isUserAuthorized(
                                              PERMISSIONS.EDIT
                                                .DESTINATION_DETAILS
                                            ) && !client ? (
                                              <span
                                                class="cancel-div height-45"
                                                onClick={() => {
                                                  e.lorryreceipt = "";
                                                  checkfield();
                                                }}
                                              >
                                                {" "}
                                                <img
                                                  src="./assets/Cancel.png"
                                                  class="cancel-button"
                                                  alt="Cancel"
                                                />
                                              </span>
                                            ) : (
                                              ""
                                            )}
                                          </div>
                                        )}
                                      </>
                                    )}
                                  </div>
                                  {e.lorryreceipt.length > 0 ? (
                                    <div
                                      style={
                                        !RoleUtils.isUserAuthorized(
                                          PERMISSIONS.EDIT.DESTINATION_DETAILS
                                        ) && !client
                                          ? {
                                              pointerEvents: "none",
                                              opacity: 0.6,
                                            }
                                          : {}
                                      }
                                    >
                                      <label for="" class="input-label">
                                        Lorry Reciept No
                                      </label>
                                      {!client ? (
                                        <Dtnewinput
                                          record={e}
                                          name="lorryrecieptNo"
                                          mindate={dtStore.bookingdate}
                                          classnames="input-field margin-none margin-bottom-2 width-200"
                                          isValid={checkvalid(
                                            "lorryrecieptNo" + i
                                          )}
                                          callback={checkfield}
                                        />
                                      ) : (
                                        <input
                                          type="text"
                                          class="input-field margin-none input-padding-right"
                                          value={e.lorryrecieptNo}
                                          disabled
                                        />
                                      )}
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </div>

                                <div class="field">
                                  <label
                                    for=""
                                    class="input-label width-200 margin-bottom-2"
                                  >
                                    Proof Of Delivery
                                  </label>
                                  <div class="wrapper width-200 height-45">
                                    {enable2 ? (
                                      <>
                                        {e.proofofdelivery.length == 0 &&
                                        RoleUtils.isUserAuthorized(
                                          PERMISSIONS.EDIT.DESTINATION_DETAILS
                                        ) &&
                                        !client ? (
                                          <div
                                            class="file-placeholder font-15 width-200 height-45 "
                                            style={
                                              !RoleUtils.isUserAuthorized(
                                                PERMISSIONS.EDIT
                                                  .DESTINATION_DETAILS
                                              )
                                                ? {
                                                    pointerEvents: "none",
                                                    opacity: 0.6,
                                                  }
                                                : {}
                                            }
                                          >
                                            No Document Uploaded
                                          </div>
                                        ) : (
                                          <div class=" width-200 height-45 ">
                                            <span>
                                              <img
                                                src="./assets/file (1).png"
                                                alt="File"
                                                class="file-img"
                                              />{" "}
                                              <a
                                                target="_blank"
                                                href={
                                                  server +
                                                  "/api/v1/filestore/getfile/" +
                                                  dtStore.details[0]
                                                    .contract_id +
                                                  "/" +
                                                  e.proofofdelivery
                                                }
                                              >
                                                Proof of Delivery - {i + 1}
                                              </a>
                                            </span>
                                          </div>
                                        )}
                                      </>
                                    ) : (
                                      <>
                                        {e.proofofdelivery.length == 0 ? (
                                          <>
                                            {!client ? (
                                              <div
                                                class="file-input-div width-200 "
                                                id={"DTPD___" + i}
                                                style={
                                                  !RoleUtils.isUserAuthorized(
                                                    PERMISSIONS.EDIT
                                                      .DESTINATION_DETAILS
                                                  )
                                                    ? {
                                                        pointerEvents: "none",
                                                        opacity: 0.6,
                                                      }
                                                    : {}
                                                }
                                              >
                                                <Agmongofilenew
                                                  disabled={
                                                    e.approved === "Approve"
                                                      ? true
                                                      : false
                                                  }
                                                  parent={"DTPD___" + i}
                                                  label={"proofofdelivery" + i}
                                                  filekey={
                                                    dtStore.details[0]
                                                      .contract_id
                                                  }
                                                  callback={(data, id) => {
                                                    console.log(id);
                                                    e.proofofdelivery =
                                                      data.key.filename;
                                                    checkfield();
                                                  }}
                                                />
                                              </div>
                                            ) : (
                                              <div
                                                class="file-placeholder font-15 width-200 height-45"
                                                style={
                                                  !RoleUtils.isUserAuthorized(
                                                    PERMISSIONS.EDIT
                                                      .DESTINATION_DETAILS
                                                  )
                                                    ? {
                                                        pointerEvents: "none",
                                                        opacity: 0.6,
                                                      }
                                                    : {}
                                                }
                                              >
                                                No Document Uploaded
                                              </div>
                                            )}
                                          </>
                                        ) : (
                                          <div class=" width-200 d-flex height-45 ">
                                            <span class="d-flex aligncenter">
                                              <img
                                                src="./assets/file (1).png"
                                                alt="File"
                                                class="file-img"
                                              />{" "}
                                              <a
                                                target="_blank"
                                                href={
                                                  server +
                                                  "/api/v1/filestore/getfile/" +
                                                  dtStore.details[0]
                                                    .contract_id +
                                                  "/" +
                                                  e.proofofdelivery
                                                }
                                                className="wrap-ellipsis"
                                              >
                                                Proof of Delivery - {i + 1}
                                              </a>
                                            </span>
                                            {!client &&
                                            RoleUtils.isUserAuthorized(
                                              PERMISSIONS.EDIT
                                                .DESTINATION_DETAILS
                                            ) ? (
                                              <span
                                                class="cancel-div height-45"
                                                onClick={() => {
                                                  e.proofofdelivery = "";
                                                  checkfield();
                                                }}
                                              >
                                                {" "}
                                                <img
                                                  src="./assets/Cancel.png"
                                                  class="cancel-button"
                                                  alt="Cancel"
                                                />
                                              </span>
                                            ) : (
                                              ""
                                            )}
                                          </div>
                                        )}
                                      </>
                                    )}
                                  </div>
                                  {e.proofofdelivery.length > 0 ? (
                                    <div
                                      style={
                                        !RoleUtils.isUserAuthorized(
                                          PERMISSIONS.EDIT.DESTINATION_DETAILS
                                        ) && !client
                                          ? {
                                              pointerEvents: "none",
                                              opacity: 0.6,
                                            }
                                          : {}
                                      }
                                    >
                                      <label for="" class="input-label">
                                        POD Dated?
                                      </label>
                                      {!client ? (
                                        <Dtnewinput
                                          record={e}
                                          type="date"
                                          name="pod_date"
                                          mindate={dtStore.bookingdate}
                                          classnames="input-field margin-none margin-bottom-2 width-200"
                                          isValid={checkvalid("pod_date" + i)}
                                          callback={checkfield}
                                        />
                                      ) : (
                                        <input
                                          type="date"
                                          class="input-field margin-none input-padding-right"
                                          value={e.pod_date}
                                          disabled
                                        />
                                      )}
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </section>
                    ) : (
                      <div class="columns m-1 is-vcentered is-multiline">
                        <div class="column is-12">
                          <table className="table is-bordered">
                            <thead>
                              <th>Destination</th>
                              <th>E-Way Bill / Commercial Invoice</th>
                              <th>Currency Type</th>
                              <th>Cargo Value</th>
                              <th>Status</th>
                              <th>Lorry Receipt</th>
                              <th>Proof of Delivery</th>
                            </thead>
                            <tbody>
                              {dtStore.details[0].delivery.map((e, i) => {
                                return (
                                  <tr>
                                    <td>{e.address}</td>
                                    <td id={"DTEB___" + i}>
                                      {e.ewaybill.length > 0 ? (
                                        <>
                                          <a
                                            target="_blank"
                                            href={
                                              server +
                                              "/api/v1/filestore/getfile/" +
                                              dtStore.details[0].contract_id +
                                              "/" +
                                              e.ewaybill
                                            }
                                          >
                                            E-Way Bill / Commercial Invoice -{" "}
                                            {i + 1}
                                          </a>{" "}
                                          <button
                                            className="button is-danger is-inverted  mt-0 pt-0"
                                            onClick={() => {
                                              e.ewaybill = "";
                                              checkfield();
                                            }}
                                          >
                                            <span className="icon  mt-0 pt-0">
                                              <i className="fas fa-trash"></i>
                                            </span>
                                          </button>
                                        </>
                                      ) : (
                                        <Agmongofile
                                          disabled={
                                            e.approved === "Approve"
                                              ? true
                                              : false
                                          }
                                          parent={"DTEB___" + i}
                                          label={"ewaybill" + i}
                                          filekey={
                                            dtStore.details[0].contract_id
                                          }
                                          callback={(data, id) => {
                                            console.log(id);
                                            e.ewaybill = data.key.filename;
                                            checkfield();
                                          }}
                                        />
                                      )}
                                    </td>
                                    <td>
                                      <Dtselect
                                        record={e}
                                        name="currency"
                                        dataList={curlist}
                                      />
                                    </td>
                                    <td>
                                      <Dtinput
                                        type="number"
                                        record={e}
                                        name="cargo_Value"
                                      />
                                    </td>
                                    <td>
                                      {!client ? (
                                        <Dtselect
                                          callback={checkstatus}
                                          record={e}
                                          name="status"
                                          dataList={statuslist}
                                        />
                                      ) : (
                                        e["status"]
                                      )}
                                    </td>
                                    <td id={"DTLB___" + i}>
                                      {e.lorryreceipt.length > 0 ? (
                                        <>
                                          <a
                                            target="_blank"
                                            href={
                                              server +
                                              "/api/v1/filestore/getfile/" +
                                              dtStore.details[0].contract_id +
                                              "/" +
                                              e.lorryreceipt
                                            }
                                          >
                                            Lorry Receipt - {i + 1}
                                          </a>{" "}
                                          {!client ? (
                                            <button
                                              className="button is-danger is-inverted  mt-0 pt-0"
                                              onClick={() => {
                                                e.lorryreceipt = "";
                                                checkfield();
                                              }}
                                            >
                                              <span className="icon  mt-0 pt-0">
                                                <i className="fas fa-trash"></i>
                                              </span>
                                            </button>
                                          ) : (
                                            ""
                                          )}
                                        </>
                                      ) : (
                                        <>
                                          {!client ? (
                                            <Agmongofile
                                              disabled={
                                                e.approved === "Approve"
                                                  ? true
                                                  : false
                                              }
                                              parent={"DTLB___" + i}
                                              label={"lorryreceipt" + i}
                                              filekey={
                                                dtStore.details[0].contract_id
                                              }
                                              callback={(data, id) => {
                                                console.log(id);
                                                e.lorryreceipt =
                                                  data.key.filename;
                                                checkfield();
                                              }}
                                            />
                                          ) : (
                                            ""
                                          )}
                                        </>
                                      )}
                                    </td>
                                    <td id={"DTPD___" + i}>
                                      {e.proofofdelivery.length > 0 ? (
                                        <>
                                          <a
                                            target="_blank"
                                            href={
                                              server +
                                              "/api/v1/filestore/getfile/" +
                                              dtStore.details[0].contract_id +
                                              "/" +
                                              e.proofofdelivery
                                            }
                                          >
                                            Proof of Delivery - {i + 1}
                                          </a>{" "}
                                          {!client ? (
                                            <button
                                              className="button is-danger is-inverted  mt-0 pt-0"
                                              onClick={() => {
                                                e.proofofdelivery = "";
                                                checkfield();
                                              }}
                                            >
                                              <span className="icon  mt-0 pt-0">
                                                <i className="fas fa-trash"></i>
                                              </span>
                                            </button>
                                          ) : (
                                            ""
                                          )}
                                        </>
                                      ) : (
                                        <>
                                          {!client ? (
                                            <Agmongofile
                                              disabled={
                                                e.approved === "Approve"
                                                  ? true
                                                  : false
                                              }
                                              parent={"DTPD___" + i}
                                              label={"proofofdelivery" + i}
                                              filekey={
                                                dtStore.details[0].contract_id
                                              }
                                              callback={(data, id) => {
                                                console.log(id);
                                                e.proofofdelivery =
                                                  data.key.filename;
                                                checkfield();
                                              }}
                                            />
                                          ) : (
                                            ""
                                          )}
                                        </>
                                      )}
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    )}
                  </>
                ) : (
                  ""
                )}

                <section class="section section-padding">
                  <div class="blue-heading">
                    Pick-up Location Contact Details
                  </div>

                  <div class="address-data-div width-100">
                    <div class="address-field-row ">
                      <div class="add-block block-1">
                        <label for="" class="add-heading">
                          {dtStore.details[0]["pickup_2"] != undefined &&
                          dtStore.details[0]["pickup_2"].trim().length > 0
                            ? "Pick-up Address 1"
                            : "Pick-up Address"}
                        </label>
                        <div class="add-div">
                          <span class="address">
                            {dtStore.details[0]["pickup"]}
                          </span>
                        </div>
                      </div>

                      <div class="contact-outer-wrapper">
                        {dtStore.pickupappointments.map((e, i) => {
                          return (
                            <div class="contact-field-wrapper">
                              <div class="field">
                                <label for="" class="input-label">
                                  <img
                                    src="./assets/user.png"
                                    class="contact-images"
                                  />
                                  Contact Name
                                </label>
                                {dtview == "create" ? (
                                  <Dtnewinput
                                    type="text"
                                    callback={checkfield}
                                    record={e}
                                    name="contactperson"
                                    label="Enter Contact Person"
                                    isValid={checkvalid("contactperson" + i)}
                                    classnames="input-field background-white height-45 background-none margin-none"
                                  />
                                ) : (
                                  <input
                                    type="text"
                                    class="input-disabled height-45 background-none margin-none padding-left-30"
                                    value={e.contactperson}
                                    disabled
                                  />
                                )}
                              </div>

                              <div class="field">
                                <label for="" class="input-label">
                                  <img
                                    src="./assets/email.png"
                                    class="contact-images"
                                  />
                                  Contact Email
                                </label>
                                {dtview == "create" ? (
                                  <>
                                    <Dtnewinput
                                      record={e}
                                      type="email"
                                      name="email"
                                      label="Enter Contact Email"
                                      classnames="input-field background-white height-45 background-none margin-none"
                                    />
                                    {dtStore.pickupappointments.length ==
                                    i + 1 ? (
                                      <button
                                        class="add-contact"
                                        onClick={() => {
                                          let l = [];
                                          for (
                                            let j = 0;
                                            j <
                                            dtStore.pickupappointments.length;
                                            j++
                                          ) {
                                            l.push(
                                              dtStore.pickupappointments[j]
                                            );
                                            if (i == j) {
                                              l.push({
                                                contactperson: "",
                                                contactnumber: "",
                                                email: "",
                                                pickupdate: "",
                                                reportingfrom: "",
                                                reportingto: "",
                                              });
                                            }
                                          }
                                          console.log(l);
                                          dtStore.pickupappointments = l;
                                        }}
                                      >
                                        <img
                                          src="./assets/contact-person.png"
                                          alt="Add-Contact-Person"
                                          class="add-contact-img"
                                        />{" "}
                                        Add Contact Person
                                      </button>
                                    ) : (
                                      ""
                                    )}
                                  </>
                                ) : (
                                  <input
                                    type="text"
                                    class="input-disabled height-45 background-none margin-none padding-left-30"
                                    value={e.email}
                                    disabled
                                  />
                                )}
                              </div>

                              <div class="field">
                                <label for="" class="input-label">
                                  <img
                                    src="./assets/phone-call.png"
                                    class="contact-images"
                                  />
                                  Contact Number
                                </label>
                                <div class="input-c-wrapper">
                                  <div
                                    class={
                                      dtview == "create"
                                        ? "country-code"
                                        : "country-code remove-country-code"
                                    }
                                  >
                                    +91
                                  </div>
                                  {dtview == "create" ? (
                                    <Dtnewinput
                                      callback={checkfield}
                                      record={e}
                                      onKeyDown={(e) =>
                                        ["e", "E", "+", "-"].includes(e.key) &&
                                        e.preventDefault()
                                      }
                                      onInput={(e) => {
                                        e.target.value = e.target.value
                                          .replace(/\D/g, "")
                                          .slice(0, 10);
                                      }}
                                      type="number"
                                      name="contactnumber"
                                      label="Enter Contact Number"
                                      isValid={checkvalid("contactnumber" + i)}
                                      classnames="input-field input-padding-left background-white height-45 background-none margin-none padding-left-60"
                                    />
                                  ) : (
                                    <input
                                      type="text"
                                      class="input-disabled height-45 background-none margin-none  padding-left-60"
                                      value={e.contactnumber}
                                      disabled
                                    />
                                  )}
                                </div>
                              </div>

                              {dtview == "create" &&
                              dtStore.pickupappointments.length > 1 ? (
                                <div
                                  class="delete-div"
                                  onClick={() => {
                                    let l = [];
                                    for (
                                      let j = 0;
                                      j < dtStore.pickupappointments.length;
                                      j++
                                    ) {
                                      if (i != j) {
                                        l.push(dtStore.pickupappointments[j]);
                                      }
                                    }
                                    dtStore.pickupappointments = l;
                                  }}
                                >
                                  <img
                                    src="./assets/Delete.png"
                                    alt="Delete-Contact"
                                    title="Delete Contact"
                                    class="delete-contact"
                                  />
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </section>
                {dtStore.details[0]["pickup_2"] != undefined &&
                dtStore.details[0]["pickup_2"].trim().length > 0 ? (
                  <section class="section section-padding">
                    <div class="address-data-div width-100">
                      <div class="address-field-row ">
                        <div class="add-block block-1">
                          <label for="" class="add-heading">
                            Pick-up Address 2
                          </label>
                          <div class="add-div">
                            <span class="address">
                              {dtStore.details[0]["pickup_2"]}
                            </span>
                          </div>
                        </div>

                        <div class="contact-outer-wrapper">
                          {dtStore.pickup2appointments.map((e, i) => {
                            return (
                              <div class="contact-field-wrapper">
                                <div class="field">
                                  <label for="" class="input-label">
                                    <img
                                      src="./assets/user.png"
                                      class="contact-images"
                                    />
                                    Contact Name
                                  </label>
                                  {dtview == "create" ? (
                                    <Dtnewinput
                                      type="text"
                                      callback={checkfield}
                                      record={e}
                                      name="contactperson"
                                      label="Enter Contact Person"
                                      isValid={checkvalid(
                                        "p2contactperson" + i
                                      )}
                                      classnames="input-field background-white height-45 background-none margin-none"
                                    />
                                  ) : (
                                    <input
                                      type="text"
                                      class="input-disabled height-45 background-none margin-none padding-left-30"
                                      value={e.contactperson}
                                      disabled
                                    />
                                  )}
                                </div>

                                <div class="field">
                                  <label for="" class="input-label">
                                    <img
                                      src="./assets/email.png"
                                      class="contact-images"
                                    />
                                    Contact Email
                                  </label>
                                  {dtview == "create" ? (
                                    <>
                                      <Dtnewinput
                                        record={e}
                                        type="email"
                                        name="email"
                                        label="Enter Contact Email"
                                        classnames="input-field background-white height-45 background-none margin-none"
                                      />
                                      {dtStore.pickup2appointments.length ==
                                      i + 1 ? (
                                        <button
                                          class="add-contact"
                                          onClick={() => {
                                            let l = [];
                                            for (
                                              let j = 0;
                                              j <
                                              dtStore.pickup2appointments
                                                .length;
                                              j++
                                            ) {
                                              l.push(
                                                dtStore.pickup2appointments[j]
                                              );
                                              if (i == j) {
                                                l.push({
                                                  contactperson: "",
                                                  contactnumber: "",
                                                  email: "",
                                                  pickupdate: "",
                                                  reportingfrom: "",
                                                  reportingto: "",
                                                });
                                              }
                                            }
                                            console.log(l);
                                            dtStore.pickup2appointments = l;
                                          }}
                                        >
                                          <img
                                            src="./assets/contact-person.png"
                                            alt="Add-Contact-Person"
                                            class="add-contact-img"
                                          />{" "}
                                          Add Contact Person
                                        </button>
                                      ) : (
                                        ""
                                      )}
                                    </>
                                  ) : (
                                    <input
                                      type="text"
                                      class="input-disabled height-45 background-none margin-none padding-left-30"
                                      value={e.email}
                                      disabled
                                    />
                                  )}
                                </div>

                                <div class="field">
                                  <label for="" class="input-label">
                                    <img
                                      src="./assets/phone-call.png"
                                      class="contact-images"
                                    />
                                    Contact Number
                                  </label>
                                  <div class="input-c-wrapper">
                                    <div
                                      class={
                                        dtview == "create"
                                          ? "country-code"
                                          : "country-code remove-country-code"
                                      }
                                    >
                                      +91
                                    </div>
                                    {dtview == "create" ? (
                                      <Dtnewinput
                                        callback={checkfield}
                                        record={e}
                                        onKeyDown={(e) =>
                                          ["e", "E", "+", "-"].includes(
                                            e.key
                                          ) && e.preventDefault()
                                        }
                                        onInput={(e) => {
                                          e.target.value = e.target.value
                                            .replace(/\D/g, "")
                                            .slice(0, 10);
                                        }}
                                        type="number"
                                        name="contactnumber"
                                        label="Enter Contact Number"
                                        isValid={checkvalid(
                                          "p2contactnumber" + i
                                        )}
                                        classnames="input-field input-padding-left background-white height-45 background-none margin-none padding-left-60"
                                      />
                                    ) : (
                                      <input
                                        type="text"
                                        class="input-disabled height-45 background-none margin-none  padding-left-60"
                                        value={e.contactnumber}
                                        disabled
                                      />
                                    )}
                                  </div>
                                </div>

                                {dtview == "create" &&
                                dtStore.pickup2appointments.length > 1 ? (
                                  <div
                                    class="delete-div"
                                    onClick={() => {
                                      let l = [];
                                      for (
                                        let j = 0;
                                        j < dtStore.pickup2appointments.length;
                                        j++
                                      ) {
                                        if (i != j) {
                                          l.push(
                                            dtStore.pickup2appointments[j]
                                          );
                                        }
                                      }
                                      dtStore.pickup2appointments = l;
                                    }}
                                  >
                                    <img
                                      src="./assets/Delete.png"
                                      alt="Delete-Contact"
                                      title="Delete Contact"
                                      class="delete-contact"
                                    />
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </section>
                ) : (
                  ""
                )}
                {dtStore.details[0].delivery.map((v, i) => {
                  return (
                    <>
                      <section class="section section-padding">
                        {i == 0 ? (
                          <div class="blue-heading">
                            Delivery Location Contact Details
                          </div>
                        ) : (
                          ""
                        )}

                        <div class="address-data-div width-100">
                          <div class="address-field-row ">
                            <div class="add-block block-1">
                              {dtStore.details[0].delivery.length > 1 ? (
                                <label for="" class="add-heading">
                                  Delivery Address {i + 1}
                                </label>
                              ) : (
                                <label for="" class="add-heading">
                                  Delivery Address
                                </label>
                              )}

                              <div class="add-div">
                                <span class="address">{v.address}</span>
                              </div>
                            </div>

                            <div class="contact-outer-wrapper">
                              {v.delivery_contact.map((e, j) => {
                                return (
                                  <>
                                    <div class="contact-field-wrapper">
                                      <div class="field">
                                        <label for="" class="input-label">
                                          <img
                                            src="./assets/user.png"
                                            class="contact-images"
                                          />
                                          Contact Name
                                        </label>
                                        {dtview == "create" || !client ? (
                                          <Dtnewinput
                                            type="text"
                                            callback={checkfield}
                                            record={e}
                                            name="contactperson"
                                            label="Enter Contact Name"
                                            isValid={checkvalid(
                                              "contactperson" + `${i}${j}`
                                            )}
                                            classnames="input-field background-white height-45 background-none margin-none"
                                          />
                                        ) : (
                                          <input
                                            type="text"
                                            class="input-disabled height-45 background-none margin-none padding-left-30 "
                                            value={e.contactperson}
                                            disabled
                                          />
                                        )}
                                      </div>

                                      <div class="field">
                                        <label for="" class="input-label">
                                          <img
                                            src="./assets/email.png"
                                            class="contact-images"
                                          />
                                          Contact Email
                                        </label>
                                        {dtview == "create" || !client ? (
                                          <>
                                            <Dtnewinput
                                              record={e}
                                              type="email"
                                              name="email"
                                              label="Enter Contact Email"
                                              classnames="input-field background-white height-45 background-none margin-none"
                                            />
                                          </>
                                        ) : (
                                          <input
                                            type="text"
                                            class="input-disabled height-45 background-none margin-none padding-left-30"
                                            value={e.email}
                                            disabled
                                          />
                                        )}
                                      </div>

                                      <div class="field">
                                        <label for="" class="input-label">
                                          <img
                                            src="./assets/phone-call.png"
                                            class="contact-images"
                                          />
                                          Contact Number
                                        </label>
                                        <div class="input-c-wrapper">
                                          <div
                                            class={
                                              dtview == "create" ||
                                              (dtview == "edit" && !client)
                                                ? "country-code"
                                                : "country-code remove-country-code"
                                            }
                                          >
                                            +91
                                          </div>
                                          {dtview == "create" || !client ? (
                                            <Dtnewinput
                                              type="number"
                                              onKeyDown={(e) =>
                                                ["e", "E", "+", "-"].includes(
                                                  e.key
                                                ) && e.preventDefault()
                                              }
                                              onInput={(e) => {
                                                e.target.value = e.target.value
                                                  .replace(/\D/g, "")
                                                  .slice(0, 10);
                                              }}
                                              callback={checkfield}
                                              record={e}
                                              name="contactnumber"
                                              label="Enter Contact Number"
                                              isValid={checkvalid(
                                                "contactnumber" + `${i}${j}`
                                              )}
                                              classnames="input-field input-padding-left background-white height-45 background-none margin-none padding-left-60"
                                            />
                                          ) : (
                                            <input
                                              type="text"
                                              class="input-disabled height-45 background-none margin-none padding-left-60"
                                              value={e.contactnumber}
                                              disabled
                                            />
                                          )}
                                        </div>
                                      </div>
                                      {v.delivery_contact.length > 1 &&
                                      (dtview == "create" || !client) ? (
                                        <div
                                          class="delete-div"
                                          onClick={() => {
                                            let l = [];
                                            for (
                                              let k = 0;
                                              k < v.delivery_contact.length;
                                              k++
                                            ) {
                                              if (j != k) {
                                                l.push(v.delivery_contact[k]);
                                              }
                                            }
                                            v.delivery_contact = l;
                                          }}
                                        >
                                          <img
                                            src="./assets/Delete.png"
                                            alt="Delete-Contact"
                                            title="Delete Contact"
                                            class="delete-contact"
                                          />
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                    {v.delivery_contact.length == j + 1 &&
                                    (dtview == "create" || !client) ? (
                                      <div class="contact-field-wrapper">
                                        <div class="field visibility-hidden">
                                          <div class="filler-2"></div>
                                        </div>

                                        <div class="field">
                                          <button
                                            class="add-contact margin-none"
                                            onClick={() => {
                                              let l = [];
                                              for (
                                                let k = 0;
                                                k < v.delivery_contact.length;
                                                k++
                                              ) {
                                                l.push(v.delivery_contact[k]);
                                                if (j == k) {
                                                  l.push({
                                                    contactperson: "",
                                                    contactnumber: "",
                                                    email: "",
                                                  });
                                                }
                                              }
                                              v.delivery_contact = l;
                                            }}
                                          >
                                            <img
                                              src="./assets/contact-person.png"
                                              alt="Add-Contact-Person"
                                              class="add-contact-img"
                                            />{" "}
                                            Add Contact Person
                                          </button>
                                        </div>

                                        <div class="field visibility-hidden">
                                          <div class="filler-2"></div>
                                        </div>
                                        {v.delivery_contact.length == 1 ? (
                                          <div class="delete-div margin-none visibility-hidden d-none">
                                            <img
                                              src="./assets/Delete.png"
                                              alt="Delete-Contact"
                                              title="Delete Contact"
                                              class="delete-contact"
                                            />
                                          </div>
                                        ) : (
                                          <div class="delete-div visibility-hidden">
                                            <img
                                              src="./assets/Delete.png"
                                              alt="Delete-Contact"
                                              title="Delete Contact"
                                              class="delete-contact"
                                            />
                                          </div>
                                        )}
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                  </>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      </section>
                    </>
                  );
                })}
                <section class="section section-padding">
                  <div class="footer-div-wrapper">
                    <div class="right-wrapper" style={{ width: "100%" }}>
                      <label for="" class="blue-heading">
                        Special Instructions
                      </label>
                      <div class="textarea-section">
                        <textarea
                          placeholder="Add Special Instructions..."
                          class="text-area"
                          ref={ref}
                          value={dtStore.specialinstruction}
                          onChange={handleChange}
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </section>
                <section class="section section-padding">
                  <div
                    class="standard-terms-div"
                    onClick={() => {
                      if (showtc) {
                        setShowTC(false);
                      } else {
                        setShowTC(true);
                      }
                    }}
                  >
                    <span class="st-inner-text">
                      Standard Terms & Conditions
                      <img
                        src="./assets/Chevron.png"
                        alt="chevron"
                        class="chevron-4"
                      />
                    </span>
                  </div>
                  {showtc ? (
                    <div className="column is-full">
                      <ol type="1" style={{ paddingLeft: "20px" }}>
                        <ol>
                          {quoteTC
                            .filter((e) => e.length > 0)
                            .map((e, i) => (
                              <li>{e}</li>
                            ))}
                        </ol>
                      </ol>
                    </div>
                  ) : (
                    ""
                  )}
                </section>
              </>
            ) : (
              <>
                {dtStore.publiclink && dtStore.publiclink.length > 0 ? (
                  <section class="section section-padding">
                    <div class="blue-heading">Tracking Details</div>

                    <div class="field-wrapper tracking-heading">
                      <div class="field">
                        <label for="" class="time-input-label">
                          Track Vehicle
                        </label>
                        <div class="tracking-div">
                          <span
                            class="tracking-link"
                            onClick={() => {
                              DtStore.MapUrl = dtStore.publiclink;
                              DtStore.modalVisible2 = true;
                            }}
                          >
                            <a>Live Location</a>
                          </span>
                          <a>
                            <img
                              src="./assets/Track.png"
                              class="track-img"
                              alt="Track-img"
                            />
                          </a>
                        </div>
                      </div>
                      {/* <div class="field">
      <label for="" class="time-input-label">
        Live Tracking Alerts
      </label>
      <div class="tracking-div">
      <span class="tracking-link live-status">
   
        Vehicle is stationary since 25rd Nov, 2023 | 09:00 
     
    </span>
    <a href="">
      <img src="./assets/nearby.png" class="track-img"  alt="Track-img" />
    </a>
  </div>
          </div> */}
                    </div>
                  </section>
                ) : (
                  ""
                )}

                {client ? (
                  <>
                    <section class="section section-padding">
                      <div class="blue-heading">Booking Status</div>

                      <div
                        class="fields-wrapper new-thread"
                        style={{ height: "fit-content" }}
                      >
                        <div class="field">
                          <label for="" class="time-input-label">
                            Booking Recieved
                          </label>
                          <a href="#" class="time-details">
                            <div class="time-entry-div no-underline align-center margin-none">
                              <span class="date-text">
                                {getdatetext("bookingrecieved")}
                              </span>{" "}
                            </div>
                          </a>
                        </div>

                        <div class="thread-div">
                          <div class="time-spent-div">
                            {" "}
                            {getcar("bookingrecieved", "vehicleassigned")}
                          </div>
                          <span class="thread"></span>
                        </div>

                        <div class="field">
                          <label for="" class="time-input-label">
                            Vehicle Assigned
                          </label>
                          <a href="#" class="time-details">
                            <div class="time-entry-div no-underline align-center margin-none">
                              <span class="date-text">
                                {getdatetext("vehicleassigned")}
                              </span>{" "}
                            </div>
                          </a>
                        </div>

                        <div class="thread-div">
                          <div class="time-spent-div">
                            {" "}
                            {getcar("vehicleassigned", "driverassigned")}
                          </div>
                          <span class="thread"></span>
                        </div>

                        <div class="field">
                          <label for="" class="time-input-label">
                            Driver Assigned
                          </label>
                          <a href="#" class="time-details">
                            <div class="time-entry-div no-underline align-center margin-none">
                              <span class="date-text">
                                {getdatetext("driverassigned")}
                              </span>{" "}
                            </div>
                          </a>
                        </div>
                      </div>
                    </section>

                    {dtStore["details"][0]["pickup_2"] != undefined &&
                    dtStore["details"][0]["pickup_2"].trim().length > 0 ? (

                    <section class="section section-padding">
                      <div class="blue-heading">Vehicle Status</div>

                      <div
                        class="fields-wrapper margin-bottom-4 new-thread"
                        style={
                          RoleUtils.isUserAuthorized(
                            PERMISSIONS.EDIT.VEHICLE_STATUS
                          )
                            ? { height: "fit-content",justifyContent: "normal", }
                            : { pointerEvents: "none", opacity: 0.6,justifyContent: "normal", }
                        }
                      >
                        <div class="field">
                          <label for="" class="time-input-label">
                            Dispatched to Pickup Point 1
                          </label>
                          <a href="#" class="time-details">
                            <div class="time-entry-div no-underline align-center margin-none">
                              <span class="date-text">
                                {getdatetext("dispatchedtopickuppoint")}
                              </span>{" "}
                            </div>
                          </a>
                        </div>
                        <div class="thread-div">
                          <div class="time-spent-div">
                            {" "}
                            {getcar(
                              "dispatchedtopickuppoint",
                              "arrivedtopickuppoint"
                            )}
                          </div>
                          <span class="thread"></span>
                        </div>

                        <div class="field">
                          <label for="" class="time-input-label">
                            Arrived to Pickup Point 1
                          </label>
                          <a href="#" class="time-details">
                            <div class="time-entry-div no-underline align-center margin-none">
                              <span class="date-text">
                                {getdatetext("arrivedtopickuppoint")}
                              </span>{" "}
                            </div>
                          </a>
                        </div>
                        <div class="field">
                          <label for="" class="time-input-label">
                            Dispatched to Pickup Point 2
                          </label>
                          <a href="#" class="time-details">
                            <div class="time-entry-div no-underline align-center margin-none">
                              <span class="date-text">
                                {getdatetext("dispatchedtopickuppoint2")}
                              </span>{" "}
                            </div>
                          </a>
                        </div>
                        <div class="thread-div">
                          <div class="time-spent-div">
                            {" "}
                            {getcar(
                              "dispatchedtopickuppoint2",
                              "arrivedtopickuppoint2"
                            )}
                          </div>
                          <span class="thread"></span>
                        </div>

                        <div class="field">
                          <label for="" class="time-input-label">
                            Arrived to Pickup Point 2
                          </label>
                          <a href="#" class="time-details">
                            <div class="time-entry-div no-underline align-center margin-none">
                              <span class="date-text">
                                {getdatetext("arrivedtopickuppoint2")}
                              </span>{" "}
                            </div>
                          </a>
                        </div>

                        {dtStore.details[0].delivery.map((v, i) => {
                          if (i == 0) {
                            return (
                              <>
                                <div class="thread-div">
                                  <div class="time-spent-div">
                                    {" "}
                                    {getcar(
                                      "arrivedtopickuppoint2",
                                      "dispatchedtoD/L1"
                                    )}
                                  </div>
                                  <span class="thread"></span>
                                </div>

                                <div class="field">
                                  <label for="" class="time-input-label">
                                    Dispatched to D/L 1
                                  </label>
                                  <a href="#" class="time-details">
                                    <div class="time-entry-div no-underline align-center margin-none">
                                      <span class="date-text">
                                        {getdatetext("dispatchedtoD/L1")}
                                      </span>{" "}
                                    </div>
                                  </a>
                                </div>

                                <div class="thread-div">
                                  <div class="time-spent-div">
                                    {" "}
                                    {getcar(
                                      "dispatchedtoD/L1",
                                      "arrivedtoD/L1"
                                    )}
                                  </div>
                                  <span class="thread"></span>
                                </div>

                                <div class="field">
                                  <label for="" class="time-input-label">
                                    Arrived to D/L 1
                                  </label>
                                  <a href="#" class="time-details">
                                    <div class="time-entry-div no-underline align-center margin-none">
                                      <span class="date-text">
                                        {getdatetext("arrivedtoD/L1")}
                                      </span>{" "}
                                    </div>
                                  </a>
                                </div>
                              </>
                            );
                          } else {
                            return (
                              <>
                                <div class="thread-div">
                                  <div class="time-spent-div">
                                    {" "}
                                    {getcar(
                                      "arrivedtoD/L" + i,
                                      "dispatchedtoD/L" + (i + 1)
                                    )}
                                  </div>
                                  <span class="thread"></span>
                                </div>

                                <div class="field">
                                  <label for="" class="time-input-label">
                                    Dispatched to D/L {i + 1}
                                  </label>
                                  <a href="#" class="time-details">
                                    <div class="time-entry-div no-underline align-center margin-none">
                                      <span class="date-text">
                                        {getdatetext(
                                          "dispatchedtoD/L" + (i + 1)
                                        )}
                                      </span>{" "}
                                    </div>
                                  </a>
                                </div>

                                <div class="thread-div">
                                  <div class="time-spent-div">
                                    {getcar(
                                      "dispatchedtoD/L" + (i + 1),
                                      "arrivedtoD/L" + (i + 1)
                                    )}
                                  </div>
                                  <span class="thread"></span>
                                </div>

                                <div class="field">
                                  <label for="" class="time-input-label">
                                    Arrived to D/L {i + 1}
                                  </label>
                                  <div class="time-entry-div no-underline align-center margin-none">
                                    <span class="date-text">
                                      {getdatetext("arrivedtoD/L" + (i + 1))}
                                    </span>{" "}
                                  </div>
                                </div>
                              </>
                            );
                          }
                        })}
                      </div>
                    </section>):(<section class="section section-padding">
                      <div class="blue-heading">Vehicle Status</div>

                      <div
                        class="fields-wrapper margin-bottom-4 new-thread"
                        style={
                          RoleUtils.isUserAuthorized(
                            PERMISSIONS.EDIT.VEHICLE_STATUS
                          )
                            ? { height: "fit-content",justifyContent: "normal", }
                            : { pointerEvents: "none", opacity: 0.6,justifyContent: "normal", }
                        }
                      >
                        <div class="field">
                          <label for="" class="time-input-label">
                            Dispatched to Pickup Point
                          </label>
                          <a href="#" class="time-details">
                            <div class="time-entry-div no-underline align-center margin-none">
                              <span class="date-text">
                                {getdatetext("dispatchedtopickuppoint")}
                              </span>{" "}
                            </div>
                          </a>
                        </div>
                        <div class="thread-div">
                          <div class="time-spent-div">
                            {" "}
                            {getcar(
                              "dispatchedtopickuppoint",
                              "arrivedtopickuppoint"
                            )}
                          </div>
                          <span class="thread"></span>
                        </div>

                        <div class="field">
                          <label for="" class="time-input-label">
                            Arrived to Pickup Point
                          </label>
                          <a href="#" class="time-details">
                            <div class="time-entry-div no-underline align-center margin-none">
                              <span class="date-text">
                                {getdatetext("arrivedtopickuppoint")}
                              </span>{" "}
                            </div>
                          </a>
                        </div>

                        {dtStore.details[0].delivery.map((v, i) => {
                          if (i == 0) {
                            return (
                              <>
                                <div class="thread-div">
                                  <div class="time-spent-div">
                                    {" "}
                                    {getcar(
                                      "arrivedtopickuppoint",
                                      "dispatchedtoD/L1"
                                    )}
                                  </div>
                                  <span class="thread"></span>
                                </div>

                                <div class="field">
                                  <label for="" class="time-input-label">
                                    Dispatched to D/L 1
                                  </label>
                                  <a href="#" class="time-details">
                                    <div class="time-entry-div no-underline align-center margin-none">
                                      <span class="date-text">
                                        {getdatetext("dispatchedtoD/L1")}
                                      </span>{" "}
                                    </div>
                                  </a>
                                </div>

                                <div class="thread-div">
                                  <div class="time-spent-div">
                                    {" "}
                                    {getcar(
                                      "dispatchedtoD/L1",
                                      "arrivedtoD/L1"
                                    )}
                                  </div>
                                  <span class="thread"></span>
                                </div>

                                <div class="field">
                                  <label for="" class="time-input-label">
                                    Arrived to D/L 1
                                  </label>
                                  <a href="#" class="time-details">
                                    <div class="time-entry-div no-underline align-center margin-none">
                                      <span class="date-text">
                                        {getdatetext("arrivedtoD/L1")}
                                      </span>{" "}
                                    </div>
                                  </a>
                                </div>
                              </>
                            );
                          } else {
                            return (
                              <>
                                <div class="thread-div">
                                  <div class="time-spent-div">
                                    {" "}
                                    {getcar(
                                      "arrivedtoD/L" + i,
                                      "dispatchedtoD/L" + (i + 1)
                                    )}
                                  </div>
                                  <span class="thread"></span>
                                </div>

                                <div class="field">
                                  <label for="" class="time-input-label">
                                    Dispatched to D/L {i + 1}
                                  </label>
                                  <a href="#" class="time-details">
                                    <div class="time-entry-div no-underline align-center margin-none">
                                      <span class="date-text">
                                        {getdatetext(
                                          "dispatchedtoD/L" + (i + 1)
                                        )}
                                      </span>{" "}
                                    </div>
                                  </a>
                                </div>

                                <div class="thread-div">
                                  <div class="time-spent-div">
                                    {getcar(
                                      "dispatchedtoD/L" + (i + 1),
                                      "arrivedtoD/L" + (i + 1)
                                    )}
                                  </div>
                                  <span class="thread"></span>
                                </div>

                                <div class="field">
                                  <label for="" class="time-input-label">
                                    Arrived to D/L {i + 1}
                                  </label>
                                  <div class="time-entry-div no-underline align-center margin-none">
                                    <span class="date-text">
                                      {getdatetext("arrivedtoD/L" + (i + 1))}
                                    </span>{" "}
                                  </div>
                                </div>
                              </>
                            );
                          }
                        })}
                      </div>
                    </section>)}
                  </>
                ) : (
                  <>
                    <section class="section section-padding">
                      <div class="blue-heading">Booking Status</div>

                      <div
                        class="fields-wrapper new-thread"
                        style={{
                          pointerEvents: "none",
                          opacity: 0.6,
                          height: "fit-content",
                        }}
                      >
                        <div class="field">
                          <label for="" class="time-input-label">
                            Booking Recieved
                          </label>
                          <a href="#" class="time-details">
                            <div class="time-entry-div no-underline align-center margin-none">
                              <span class="date-text">
                                {getdatetext("bookingrecieved")}
                              </span>{" "}
                            </div>
                          </a>
                        </div>

                        <div class="thread-div">
                          <div class="time-spent-div">
                            {gethour("bookingrecieved", "vehicleassigned")}{" "}
                            {getcar("bookingrecieved", "vehicleassigned")}
                          </div>
                          <span class="thread"></span>
                        </div>

                        <div class="field">
                          <label for="" class="time-input-label">
                            Vehicle Assigned
                          </label>
                          <Dttrackingtimedate
                            callback={() => {
                              checkstatus();
                            }}
                            namekey="vehicleassigned"
                            prevkey="bookingrecieved"
                            nextkey="driverassigned"
                          />
                        </div>

                        <div class="thread-div">
                          <div class="time-spent-div">
                            {gethour("vehicleassigned", "driverassigned")}{" "}
                            {getcar("vehicleassigned", "driverassigned")}
                          </div>
                          <span class="thread"></span>
                        </div>

                        <div class="field">
                          <label for="" class="time-input-label">
                            Driver Assigned
                          </label>
                          <Dttrackingtimedate
                            callback={() => {
                              checkstatus();
                            }}
                            namekey="driverassigned"
                            prevkey="vehicleassigned"
                            nextkey="driverconsent"
                          />
                        </div>
                        <div class="thread-div ">
                          <div class="time-spent-div">
                            {gethour("driverassigned", "driverconsent")}{" "}
                            {getcar("driverassigned", "driverconsent")}
                          </div>
                          <span class="thread"></span>
                        </div>

                        <div class="field">
                          <label for="" class="time-input-label">
                            Driver Consent
                          </label>
                          <Dttrackingtimedate
                            callback={() => {
                              checkstatus();
                            }}
                            namekey="driverconsent"
                            prevkey="driverassigned"
                            nextkey="dispatchedtopickuppoint"
                          />
                        </div>
                      </div>
                    </section>
                    {dtStore["details"][0]["pickup_2"] != undefined &&
                    dtStore["details"][0]["pickup_2"].trim().length > 0 ? (
                      <section class="section section-padding">
                        <div class="blue-heading">Vehicle Status</div>

                        <div
                          class="fields-wrapper margin-bottom-4 new-thread"
                          style={
                            RoleUtils.isUserAuthorized(
                              PERMISSIONS.EDIT.VEHICLE_STATUS
                            )
                              ? {
                                  height: "fit-content",
                                  justifyContent: "normal",
                                }
                              : {
                                  pointerEvents: "none",
                                  opacity: 0.6,
                                  height: "fit-content",
                                  justifyContent: "normal",
                                }
                          }
                        >
                          <div class="field">
                            <label for="" class="time-input-label">
                              Dispatched to Pickup Point 1
                            </label>
                            <Dttrackingtimedate
                              callback={() => {
                                checkstatus();
                              }}
                              namekey="dispatchedtopickuppoint"
                              prevkey="driverconsent"
                              nextkey="arrivedtopickuppoint"
                            />
                          </div>

                          <div class="thread-div">
                            <div class="time-spent-div">
                              {gethour(
                                "dispatchedtopickuppoint",
                                "arrivedtopickuppoint"
                              )}{" "}
                              {getcar(
                                "dispatchedtopickuppoint",
                                "arrivedtopickuppoint"
                              )}
                            </div>
                            <span class="thread"></span>
                          </div>

                          <div class="field">
                            <label for="" class="time-input-label">
                              Arrived to Pickup Point 1
                            </label>
                            <Dttrackingtimedate
                              callback={() => {
                                checkstatus();
                              }}
                              namekey="arrivedtopickuppoint"
                              prevkey="dispatchedtopickuppoint"
                              nextkey="dispatchedtoD/L1"
                            />
                          </div>
                          <div class="field">
                            <label for="" class="time-input-label">
                              Dispatched to Pickup Point 2
                            </label>
                            <Dttrackingtimedate
                              callback={() => {
                                checkstatus();
                              }}
                              namekey="dispatchedtopickuppoint2"
                              prevkey="arrivedtopickuppoint"
                              nextkey="arrivedtopickuppoint2"
                            />
                          </div>

                          <div class="thread-div">
                            <div class="time-spent-div">
                              {gethour(
                                "dispatchedtopickuppoint2",
                                "arrivedtopickuppoint2"
                              )}{" "}
                              {getcar(
                                "dispatchedtopickuppoint2",
                                "arrivedtopickuppoint2"
                              )}
                            </div>
                            <span class="thread"></span>
                          </div>

                          <div class="field">
                            <label for="" class="time-input-label">
                              Arrived to Pickup Point 2
                            </label>
                            <Dttrackingtimedate
                              callback={() => {
                                checkstatus();
                              }}
                              namekey="arrivedtopickuppoint2"
                              prevkey="dispatchedtopickuppoint2"
                              nextkey="dispatchedtoD/L1"
                            />
                          </div>

                          {dtStore.details[0].delivery.map((v, i) => {
                            console.log(i);
                            if (
                              i == 0 &&
                              i == dtStore.details[0].delivery.length - 1
                            ) {
                              return (
                                <>
                                  <div class="thread-div">
                                    <div class="time-spent-div">
                                      {gethour(
                                        "arrivedtopickuppoint2",
                                        "dispatchedtoD/L1"
                                      )}{" "}
                                      {getcar(
                                        "arrivedtopickuppoint2",
                                        "dispatchedtoD/L1"
                                      )}
                                    </div>
                                    <span class="thread"></span>
                                  </div>

                                  <div class="field">
                                    <label for="" class="time-input-label">
                                      Dispatched to D/L 1
                                    </label>
                                    <Dttrackingtimedate
                                      callback={() => {
                                        checkstatus();
                                      }}
                                      namekey="dispatchedtoD/L1"
                                      prevkey="arrivedtopickuppoint2"
                                      nextkey="arrivedtoD/L1"
                                    />
                                  </div>

                                  <div class="thread-div">
                                    <div class="time-spent-div">
                                      {gethour(
                                        "dispatchedtoD/L1",
                                        "arrivedtoD/L1"
                                      )}{" "}
                                      {getcar(
                                        "dispatchedtoD/L1",
                                        "arrivedtoD/L1"
                                      )}
                                    </div>
                                    <span class="thread"></span>
                                  </div>

                                  <div class="field">
                                    <label for="" class="time-input-label">
                                      Arrived to D/L 1
                                    </label>
                                    <Dttrackingtimedate
                                      callback={() => {
                                        checkstatus();
                                      }}
                                      namekey="arrivedtoD/L1"
                                      prevkey="dispatchedtoD/L1"
                                      nextkey={""}
                                    />
                                  </div>
                                </>
                              );
                            } else {
                              if (i == dtStore.details[0].delivery.length - 1) {
                                return (
                                  <>
                                    <div class="thread-div">
                                      <div class="time-spent-div">
                                        {gethour(
                                          "arrivedtoD/L" + i,
                                          "dispatchedtoD/L" + (i + 1)
                                        )}{" "}
                                        {getcar(
                                          "arrivedtoD/L" + i,
                                          "dispatchedtoD/L" + (i + 1)
                                        )}
                                      </div>
                                      <span class="thread"></span>
                                    </div>

                                    <div class="field">
                                      <label for="" class="time-input-label">
                                        Dispatched to D/L {i + 1}
                                      </label>
                                      <Dttrackingtimedate
                                        callback={() => {
                                          checkstatus();
                                        }}
                                        namekey={"dispatchedtoD/L" + (i + 1)}
                                        prevkey={"arrivedtoD/L" + i}
                                        nextkey={"arrivedtoD/L" + (i + 1)}
                                      />
                                    </div>

                                    <div class="thread-div">
                                      <div class="time-spent-div">
                                        {gethour(
                                          "dispatchedtoD/L" + (i + 1),
                                          "arrivedtoD/L" + (i + 1)
                                        )}{" "}
                                        {getcar(
                                          "dispatchedtoD/L" + (i + 1),
                                          "arrivedtoD/L" + (i + 1)
                                        )}
                                      </div>
                                      <span class="thread"></span>
                                    </div>

                                    <div class="field">
                                      <label for="" class="time-input-label">
                                        Arrived to D/L {i + 1}
                                      </label>
                                      <Dttrackingtimedate
                                        callback={() => {
                                          checkstatus();
                                        }}
                                        namekey={"arrivedtoD/L" + (i + 1)}
                                        prevkey={"dispatchedtoD/L" + (i + 1)}
                                        nextkey={""}
                                      />
                                    </div>
                                  </>
                                );
                              } else {
                                if (i == 0) {
                                  return (
                                    <>
                                      <div class="thread-div">
                                        <div class="time-spent-div">
                                          {gethour(
                                            "arrivedtopickuppoint2",
                                            "dispatchedtoD/L1"
                                          )}{" "}
                                          {getcar(
                                            "arrivedtopickuppoint2",
                                            "dispatchedtoD/L1"
                                          )}
                                        </div>
                                        <span class="thread"></span>
                                      </div>

                                      <div class="field">
                                        <label for="" class="time-input-label">
                                          Dispatched to D/L 1
                                        </label>
                                        <Dttrackingtimedate
                                          callback={() => {
                                            checkstatus();
                                          }}
                                          namekey="dispatchedtoD/L1"
                                          prevkey="arrivedtopickuppoint2"
                                          nextkey="arrivedtoD/L1"
                                        />
                                      </div>

                                      <div class="thread-div">
                                        <div class="time-spent-div">
                                          {gethour(
                                            "dispatchedtoD/L1",
                                            "arrivedtoD/L1"
                                          )}{" "}
                                          {getcar(
                                            "dispatchedtoD/L1",
                                            "arrivedtoD/L1"
                                          )}
                                        </div>
                                        <span class="thread"></span>
                                      </div>

                                      <div class="field">
                                        <label for="" class="time-input-label">
                                          Arrived to D/L 1
                                        </label>
                                        <Dttrackingtimedate
                                          callback={() => {
                                            checkstatus();
                                          }}
                                          namekey="arrivedtoD/L1"
                                          prevkey="dispatchedtoD/L1"
                                          nextkey={"dispatchedtoD/L2"}
                                        />
                                      </div>
                                    </>
                                  );
                                } else {
                                  return (
                                    <>
                                      <div class="thread-div">
                                        <div class="time-spent-div">
                                          {gethour(
                                            "arrivedtoD/L" + i,
                                            "dispatchedtoD/L" + (i + 1)
                                          )}{" "}
                                          {getcar(
                                            "arrivedtoD/L" + i,
                                            "dispatchedtoD/L" + (i + 1)
                                          )}
                                        </div>
                                        <span class="thread"></span>
                                      </div>

                                      <div class="field">
                                        <label for="" class="time-input-label">
                                          Dispatched to D/L {i + 1}
                                        </label>
                                        <Dttrackingtimedate
                                          callback={() => {
                                            checkstatus();
                                          }}
                                          namekey={"dispatchedtoD/L" + (i + 1)}
                                          prevkey={"arrivedtoD/L" + i}
                                          nextkey={"arrivedtoD/L" + (i + 1)}
                                        />
                                      </div>

                                      <div class="thread-div">
                                        <div class="time-spent-div">
                                          {gethour(
                                            "dispatchedtoD/L" + (i + 1),
                                            "arrivedtoD/L" + (i + 1)
                                          )}{" "}
                                          {getcar(
                                            "dispatchedtoD/L" + (i + 1),
                                            "arrivedtoD/L" + (i + 1)
                                          )}
                                        </div>
                                        <span class="thread"></span>
                                      </div>

                                      <div class="field">
                                        <label for="" class="time-input-label">
                                          Arrived to D/L {i + 1}
                                        </label>
                                        <Dttrackingtimedate
                                          callback={() => {
                                            checkstatus();
                                          }}
                                          namekey={"arrivedtoD/L" + (i + 1)}
                                          prevkey={"dispatchedtoD/L" + (i + 1)}
                                          nextkey={"dispatchedtoD/L" + (i + 2)}
                                        />
                                      </div>
                                    </>
                                  );
                                }
                              }
                            }
                          })}
                        </div>
                      </section>
                    ) : (
                      <section class="section section-padding">
                        <div class="blue-heading">Vehicle Status</div>

                        <div
                          class="fields-wrapper margin-bottom-4 new-thread"
                          style={
                            RoleUtils.isUserAuthorized(
                              PERMISSIONS.EDIT.VEHICLE_STATUS
                            )
                              ? { height: "fit-content" }
                              : {
                                  pointerEvents: "none",
                                  opacity: 0.6,
                                  height: "fit-content",
                                }
                          }
                        >
                          <div class="field">
                            <label for="" class="time-input-label">
                              Dispatched to Pickup Point
                            </label>
                            <Dttrackingtimedate
                              callback={() => {
                                checkstatus();
                              }}
                              namekey="dispatchedtopickuppoint"
                              prevkey="driverconsent"
                              nextkey="arrivedtopickuppoint"
                            />
                          </div>

                          <div class="thread-div">
                            <div class="time-spent-div">
                              {gethour(
                                "dispatchedtopickuppoint",
                                "arrivedtopickuppoint"
                              )}{" "}
                              {getcar(
                                "dispatchedtopickuppoint",
                                "arrivedtopickuppoint"
                              )}
                            </div>
                            <span class="thread"></span>
                          </div>

                          <div class="field">
                            <label for="" class="time-input-label">
                              Arrived to Pickup Point
                            </label>
                            <Dttrackingtimedate
                              callback={() => {
                                checkstatus();
                              }}
                              namekey="arrivedtopickuppoint"
                              prevkey="dispatchedtopickuppoint"
                              nextkey="dispatchedtoD/L1"
                            />
                          </div>

                          {dtStore.details[0].delivery.map((v, i) => {
                            console.log(i);
                            if (
                              i == 0 &&
                              i == dtStore.details[0].delivery.length - 1
                            ) {
                              return (
                                <>
                                  <div class="thread-div">
                                    <div class="time-spent-div">
                                      {gethour(
                                        "arrivedtopickuppoint",
                                        "dispatchedtoD/L1"
                                      )}{" "}
                                      {getcar(
                                        "arrivedtopickuppoint",
                                        "dispatchedtoD/L1"
                                      )}
                                    </div>
                                    <span class="thread"></span>
                                  </div>

                                  <div class="field">
                                    <label for="" class="time-input-label">
                                      Dispatched to D/L 1
                                    </label>
                                    <Dttrackingtimedate
                                      callback={() => {
                                        checkstatus();
                                      }}
                                      namekey="dispatchedtoD/L1"
                                      prevkey="arrivedtopickuppoint"
                                      nextkey="arrivedtoD/L1"
                                    />
                                  </div>

                                  <div class="thread-div">
                                    <div class="time-spent-div">
                                      {gethour(
                                        "dispatchedtoD/L1",
                                        "arrivedtoD/L1"
                                      )}{" "}
                                      {getcar(
                                        "dispatchedtoD/L1",
                                        "arrivedtoD/L1"
                                      )}
                                    </div>
                                    <span class="thread"></span>
                                  </div>

                                  <div class="field">
                                    <label for="" class="time-input-label">
                                      Arrived to D/L 1
                                    </label>
                                    <Dttrackingtimedate
                                      callback={() => {
                                        checkstatus();
                                      }}
                                      namekey="arrivedtoD/L1"
                                      prevkey="dispatchedtoD/L1"
                                      nextkey={""}
                                    />
                                  </div>
                                </>
                              );
                            } else {
                              if (i == dtStore.details[0].delivery.length - 1) {
                                return (
                                  <>
                                    <div class="thread-div">
                                      <div class="time-spent-div">
                                        {gethour(
                                          "arrivedtoD/L" + i,
                                          "dispatchedtoD/L" + (i + 1)
                                        )}{" "}
                                        {getcar(
                                          "arrivedtoD/L" + i,
                                          "dispatchedtoD/L" + (i + 1)
                                        )}
                                      </div>
                                      <span class="thread"></span>
                                    </div>

                                    <div class="field">
                                      <label for="" class="time-input-label">
                                        Dispatched to D/L {i + 1}
                                      </label>
                                      <Dttrackingtimedate
                                        callback={() => {
                                          checkstatus();
                                        }}
                                        namekey={"dispatchedtoD/L" + (i + 1)}
                                        prevkey={"arrivedtoD/L" + i}
                                        nextkey={"arrivedtoD/L" + (i + 1)}
                                      />
                                    </div>

                                    <div class="thread-div">
                                      <div class="time-spent-div">
                                        {gethour(
                                          "dispatchedtoD/L" + (i + 1),
                                          "arrivedtoD/L" + (i + 1)
                                        )}{" "}
                                        {getcar(
                                          "dispatchedtoD/L" + (i + 1),
                                          "arrivedtoD/L" + (i + 1)
                                        )}
                                      </div>
                                      <span class="thread"></span>
                                    </div>

                                    <div class="field">
                                      <label for="" class="time-input-label">
                                        Arrived to D/L {i + 1}
                                      </label>
                                      <Dttrackingtimedate
                                        callback={() => {
                                          checkstatus();
                                        }}
                                        namekey={"arrivedtoD/L" + (i + 1)}
                                        prevkey={"dispatchedtoD/L" + (i + 1)}
                                        nextkey={""}
                                      />
                                    </div>
                                  </>
                                );
                              } else {
                                if (i == 0) {
                                  return (
                                    <>
                                      <div class="thread-div">
                                        <div class="time-spent-div">
                                          {gethour(
                                            "arrivedtopickuppoint",
                                            "dispatchedtoD/L1"
                                          )}{" "}
                                          {getcar(
                                            "arrivedtopickuppoint",
                                            "dispatchedtoD/L1"
                                          )}
                                        </div>
                                        <span class="thread"></span>
                                      </div>

                                      <div class="field">
                                        <label for="" class="time-input-label">
                                          Dispatched to D/L 1
                                        </label>
                                        <Dttrackingtimedate
                                          callback={() => {
                                            checkstatus();
                                          }}
                                          namekey="dispatchedtoD/L1"
                                          prevkey="arrivedtopickuppoint"
                                          nextkey="arrivedtoD/L1"
                                        />
                                      </div>

                                      <div class="thread-div">
                                        <div class="time-spent-div">
                                          {gethour(
                                            "dispatchedtoD/L1",
                                            "arrivedtoD/L1"
                                          )}{" "}
                                          {getcar(
                                            "dispatchedtoD/L1",
                                            "arrivedtoD/L1"
                                          )}
                                        </div>
                                        <span class="thread"></span>
                                      </div>

                                      <div class="field">
                                        <label for="" class="time-input-label">
                                          Arrived to D/L 1
                                        </label>
                                        <Dttrackingtimedate
                                          callback={() => {
                                            checkstatus();
                                          }}
                                          namekey="arrivedtoD/L1"
                                          prevkey="dispatchedtoD/L1"
                                          nextkey={"dispatchedtoD/L2"}
                                        />
                                      </div>
                                    </>
                                  );
                                } else {
                                  return (
                                    <>
                                      <div class="thread-div">
                                        <div class="time-spent-div">
                                          {gethour(
                                            "arrivedtoD/L" + i,
                                            "dispatchedtoD/L" + (i + 1)
                                          )}{" "}
                                          {getcar(
                                            "arrivedtoD/L" + i,
                                            "dispatchedtoD/L" + (i + 1)
                                          )}
                                        </div>
                                        <span class="thread"></span>
                                      </div>

                                      <div class="field">
                                        <label for="" class="time-input-label">
                                          Dispatched to D/L {i + 1}
                                        </label>
                                        <Dttrackingtimedate
                                          callback={() => {
                                            checkstatus();
                                          }}
                                          namekey={"dispatchedtoD/L" + (i + 1)}
                                          prevkey={"arrivedtoD/L" + i}
                                          nextkey={"arrivedtoD/L" + (i + 1)}
                                        />
                                      </div>

                                      <div class="thread-div">
                                        <div class="time-spent-div">
                                          {gethour(
                                            "dispatchedtoD/L" + (i + 1),
                                            "arrivedtoD/L" + (i + 1)
                                          )}{" "}
                                          {getcar(
                                            "dispatchedtoD/L" + (i + 1),
                                            "arrivedtoD/L" + (i + 1)
                                          )}
                                        </div>
                                        <span class="thread"></span>
                                      </div>

                                      <div class="field">
                                        <label for="" class="time-input-label">
                                          Arrived to D/L {i + 1}
                                        </label>
                                        <Dttrackingtimedate
                                          callback={() => {
                                            checkstatus();
                                          }}
                                          namekey={"arrivedtoD/L" + (i + 1)}
                                          prevkey={"dispatchedtoD/L" + (i + 1)}
                                          nextkey={"dispatchedtoD/L" + (i + 2)}
                                        />
                                      </div>
                                    </>
                                  );
                                }
                              }
                            }
                          })}
                        </div>
                      </section>
                    )}
                  </>
                )}

                {/* <section class="section section-padding">
        <div class="blue-heading">Booking Status</div>

        <div class="fields-wrapper new-thread">

          <div class="field">
            <label for="" class="time-input-label">
              Booking Recieved
            </label>
            <a href="#" class="time-details">
              <div class="time-entry-div no-underline align-center margin-none"><span class="date-text">{Utils.formatdatetimetocovert(dtStore["trackinglist"][0]['bookingrecieved']['date'],dtStore["trackinglist"][0]['bookingrecieved']['time'])}</span> </div>
            </a>
            
          </div>



          {
            (dtStore["trackinglist"][0]['vehicleassigned']['date'].length>0 && client)?
            <>
            <div class="thread-div">
            <div class="time-spent-div"></div>
            <span class="thread"></span>
          </div>
          <div class="field">
          <label for="" class="time-input-label">
            Vehicle Assigned
          </label>
          <a  class="time-details">
              <div class="time-entry-div no-underline align-center margin-none"><span class="date-text">{Utils.formatdatetimetocovert(dtStore["trackinglist"][0]['vehicleassigned']['date'],dtStore["trackinglist"][0]['vehicleassigned']['time'])}</span></div>
            </a>
          </div>
            </>:""}

          {
            (dtStore["trackinglist"][0]['vehicleassigned']['date'].length==0 && client)?
            <><div class="thread-div">
            <div class="time-spent-div"><img src="./assets/Domestiv Transport (1).png" class="dispatch-truck" style={{height:"10px"}} alt="" /></div>
            <span class="thread"></span>
          </div>
          <div class="field">
          <label for="" class="time-input-label">
            Vehicle Assigned
          </label>
          </div></>
          :""}

{
  (dtStore["trackinglist"][0]['vehicleassigned']['date'].length>0 && dtStore["trackinglist"][0]['vehicleassigned']['time'].length>0 && !client)?<>
  <div class="thread-div">
            <div class="time-spent-div">24 H </div>
            <span class="thread"></span>
          </div>
  <div class="field">
            <label for="" class="time-input-label">
              Vehicle Assigned
            </label>
            <a class="time-details">
              <div class="time-entry-div no-underline align-center margin-none"><span class="date-text">{Utils.formatdatetimetocovert(dtStore["trackinglist"][0]['vehicleassigned']['date'],dtStore["trackinglist"][0]['vehicleassigned']['time'])}</span>  <img src="./assets/edit.png" alt="Link" class="edit-button"/></div>
            </a></div>
  </>:<>
  <div class="thread-div">
            <div class="time-spent-div">24 H <img src="./assets/Domestiv Transport (1).png" class="dispatch-truck" style={{height:"10px"}} alt="" /></div>
            <span class="thread"></span>
          </div>
  <div class="field">
            <label for="" class="time-input-label">
              Vehicle Assigned
            </label>
            <div class="time-date-div">
              <Dtnewinput
                                        record={dtStore["trackinglist"][0]['vehicleassigned']}
                                        type="date"
                                        name="date"
                                        classnames="date"
                                      />
                                      <Dtnewinput
                                        record={dtStore["trackinglist"][0]['vehicleassigned']}
                                        type="time"
                                        name="time"
                                        classnames="time"
                                      />
          </div></div>
  </>
}







          {
            (dtStore["trackinglist"][0]['vehicleassigned']['date'].length>0 || !client)?
            <>
            <div class="thread-div">
            <div class="time-spent-div"></div>
            <span class="thread"></span>
          </div>

          <div class="field">
            <label for="" class="time-input-label">
              Driver Assigned
            </label>
            <a href="#" class="time-details">
              <div class="time-entry-div no-underline align-center margin-none"><span class="date-text">26th Jan 2023</span><span class="separator">|</span><span class="time-text">09:00</span>  <img src="./assets/edit.png" alt="Link" class="edit-button"/></div>
            </a>
            
          </div>
            </>:""
          }
          
     
          

          {(!client)?
          <>
          <div class="thread-div">
            <div class="time-spent-div"></div>
            <span class="thread"></span>
          </div>
          <div class="field">
            <label for="" class="time-input-label">
              Driver Consent
            </label>
            <a href="#" class="time-details">
              <div class="time-entry-div no-underline align-center margin-none"><span class="date-text">26th Jan 2023</span><span class="separator">|</span><span class="time-text">09:00</span>  <img src="./assets/edit.png" alt="Link" class="edit-button"/></div>
            </a>
            
          </div>
          </>
          :
          <>
          <div class="thread-div visibility-hidden">
            <div class="time-spent-div"></div>
            <span class="thread"></span>
          </div>
          <div class="field">
            <div class="filler-4"></div>
            
          </div>
          </>
          }

          
          
          
        </div>
      </section> */}
              </>
            )}
          </>
        ) : (
          ""
        )}

        {dtStore.status != "CANCELLED" ? (
          <section
            class="section section-padding"
            style={{ padding: "0 10px 0" }}
          >
            <div
              class={
                currentview != "booking"
                  ? "buttons-div padding-right-1"
                  : "buttons-div"
              }
            >
              {dtview == "create" ? (
                <div class="submit-form-button" onClick={save}>
                  <span>
                    <img
                      src="./assets/save-booking-white.png"
                      alt="submit"
                      class="submit-form"
                    />
                    Confirm Booking
                  </span>
                </div>
              ) : (
                <>
                  {(currentview == "booking" && client) || !client ? (
                    <div class="submit-form-button" onClick={save}>
                      <span>
                        <img
                          src="./assets/save-booking-white.png"
                          alt="submit"
                          class="submit-form"
                        />
                        Save Booking
                      </span>
                    </div>
                  ) : (
                    ""
                  )}
                </>
              )}
              {currentview != "booking" &&
              !client &&
              dtStore.stoptracking == undefined &&
              dtStore.vehicle_no.length > 0 &&
              dtStore.vehicle_no.length > 0 &&
              dtStore.drivernumber.length > 0 &&
              dtStore.drivername.length > 0 &&
              RoleUtils.isUserAuthorized(PERMISSIONS.EDIT.STOP_TRACKING) ? (
                <div
                  class="cancel-form-button"
                  onClick={() => {
                    DtStore.stoptrackingmodal = true;
                  }}
                >
                  Stop Tracking
                </div>
              ) : (
                ""
              )}
              {(currentview == "booking" && client) || !client ? (
                <>
                  <div
                    class="cancel-form-button"
                    onClick={() => {
                      AgmodalStore.pageVisible = false;
                    }}
                  >
                    <span>
                      <img
                        src="./assets/close-blue.png"
                        alt="Cancel"
                        class="cancel-form"
                      />
                      Cancel
                    </span>
                  </div>
                  {currentview != "booking" &&
                  !client &&
                  dtStore.stoptracking != undefined ? (
                    <div class="">
                      This Booking Tracking Stopped{" "}
                      {dtStore.tsStoppedOn != undefined ? (
                        <>
                          (
                          {dtStore.tsStoppedOn != undefined
                            ? "StoppedOn:" +
                              Utils.formatToDateTime(
                                Number(dtStore.tsStoppedOn)
                              ) +
                              ","
                            : ""}{" "}
                          {dtStore.tsStoppedBY != undefined
                            ? "StoppedBy:" + dtStore.tsStoppedBY
                            : ""}
                          )
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  ) : (
                    ""
                  )}
                  {console.log(dtStore.initiateAdvance,'ads')}
                  {enable3 &&
                  currentview == "booking" &&
                  !client &&
                  dtStore.initiateAdvance == "No" &&
                  RoleUtils.isUserAuthorized(
                    PERMISSIONS.CREATE.INITIATE_ADVANCE
                  ) ? (
                    <div
                      class="trigger-form-button"
                      onClick={() => {
                        DtStore.initiateAdvancemodal = true;
                      }}
                    >
                      Initiate Advance
                    </div>
                  ) : (
                    ""
                  )}
                  {enable4 &&
                  currentview == "booking" &&
                  !client &&
                  dtStore.initiateBalancePayment == "No" &&
                  (dtStore.initiateAdvance == "done" ||
                    (dtStore.initiateAdvance == undefined && enable1)) &&
                  RoleUtils.isUserAuthorized(
                    PERMISSIONS.CREATE.INITIATE_BALANCE_PAYMENT
                  ) ? (
                    <div
                      class="trigger-form-button"
                      onClick={() => {
                        DtStore.initiateBalancePaymentmodal = true;
                      }}
                    >
                      Initiate Balance Payment
                    </div>
                  ) : (
                    ""
                  )}
                </>
              ) : (
                ""
              )}
            </div>
          </section>
        ) : (
          ""
        )}
      </div>
    </>
  );
}
