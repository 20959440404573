
import { AgMuiSelect2 } from "../mui-components/Controlled";
import { TableHeading } from "./AgTableHeading";
export function HeadingWithSelect({heading,control,name,options,values,onDropdownChange,disabled}){
    return <div className="flex flex-row items-center justify-center gap-2.5 flex-1 ">
  <TableHeading title={heading} />
            <AgMuiSelect2 onChangeHandler={()=>{
                if(onDropdownChange){
                    onDropdownChange();
                }
            }} disabled={disabled} padding={0} control={control} name={name} size="small"  variant="standard" disableUnderline={true} values={values} options={options } fontColor="#555555" iconColor="#555555"  style={{ fontSize: "12px", alignItems:"center", height:"25px",
                // minWidth:"68px",
                fontSize:"13px",
                fontWeight:"bold",
                color: "#555555",
                borderLeft :0,
                padding:0,
                paddingRight: "0px !important",
                backgroundColor:"white", }} />
    </div>
}