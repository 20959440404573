import { useEffect } from "react";
import { useSnapshot } from "valtio";
import { configStore, loginStore } from "../state/CommonState";
import { enquiryStore } from "../enquiry/enquiry-data";
import { rtpStore } from "../pricing/pricing-store";
import HomeMainv3 from "./home-main-v3";


export default function QuickQuotev3(params) {
  useSnapshot(enquiryStore);
  useSnapshot(configStore);
  useSnapshot(loginStore);
  useSnapshot(rtpStore);

  useEffect(() => {
    enquiryStore.initialize();
    enquiryStore.currentCargoTableKey = "" + new Date().getMilliseconds();
    enquiryStore.current.createdBy = loginStore.email;
    // enquiryStore.current.gst = configStore.currentSelectedEntity.gst;
    enquiryStore.current.shipmentType =
      configStore.homescreenCurrentShipmentType;
    configStore.homescreenCurrentView = "new";
    configStore.scheduleOrCarrierform = "schedule";
    if(loginStore.isClient() && configStore.currentSelectedEntity.entityId.length === 0){
      rtpStore.customerTBA = "TBA"
    }
  
    return ()=>{
      enquiryStore.initialize();
    }
  }, []);
  return <HomeMainv3 />;
}