export const Card = (props) => <div style={{
    backgroundColor: "#fff",
    borderRadius: ".25rem",
    boxShadow: "0 .5em 1em -.125em rgba(10, 10, 10, .1), 0 0 0 1px rgba(10, 10, 10, .02)",
    color: "#4a4a4a",
    maxWidth: "100%",
    position: "relative",
    padding:"15px"
}}>
{props.children}
</div>