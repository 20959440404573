const categoryMapping = {
  customer: "customerType",
  vendor: "vendorType",
};

const salesMapping = {
  customer: "relationshipManager",
  vendor: "",
};

const customerServiceMapping = {
  customer: "customerService",
  vendor: "",
};

class EntityUtils {
  static isIndia(entity) {
    return entity?.entityCountry?.toLowerCase().includes("india");
  }

  static isSoleProp(entity) {
    return entity?.vendor?.isSoleProp;
  }

  static isMsme(entity) {
    return entity?.vendor?.isMsme;
  }

  static isCustomer(entity) {
    return entity?.entityType?.toLowerCase().includes("customer");
  }

  static isVendor(entity) {
    return entity?.entityType?.toLowerCase().includes("vendor");
  }

  static isRejected(entity) {
    return entity?.status?.toLowerCase().includes("rejected");
  }

  static isCustomsBroker(entity) {
    return (
      entity?.vendor?.vendorType.toLowerCase() === "customs broker" ||
      entity?.vendor?.isCustomBrokerApplied
    );
  }

  static isMsmeType(entity) {
    return (
      entity?.customer?.customerType.toLowerCase() === "msme");
  }

  static isRoadTransport(entity) {
    return (
      entity?.vendor?.vendorType.toLowerCase() === "road transport" ||
      entity?.vendor?.isRoadTransportApplied
    );
  }

  static isFreightForwarder(entity) {
    return entity?.vendor?.vendorType.toLowerCase() === "freight forwarder";
  }

  static isWarehouse(entity) {
    return entity?.vendor?.vendorType.toLowerCase() === "warehouse operator";
  }
  static isContainerFreightStation(entity) {
    return entity?.vendor?.vendorType.toLowerCase() === "container freight station";
  }
  static isInlandContainerDepot(entity) {
    return entity?.vendor?.vendorType.toLowerCase() === "inland container depot";
  }
  static isSurveyor(entity) {
    return entity?.vendor?.vendorType.toLowerCase() === "surveyor";
  }
  static isLashingandClothing(entity) {
    return entity?.vendor?.vendorType.toLowerCase() === "lashing & choking";
  }
  static isFumigation(entity) {
    return entity?.vendor?.vendorType.toLowerCase() === "fumigation";
  }
  static isPackaging(entity) {
    return entity?.vendor?.vendorType.toLowerCase() === "packaging";
  }
  static isSecondhandContainerVendor(entity) {
    return entity?.vendor?.vendorType.toLowerCase() === "second-hand container vendor";
  }


  static getType(entity) {
    let entityType =
      entity?.customer?.customerType?.length > 0
        ? "customer"
        : entity.vendor?.vendorType?.length > 0
        ? "vendor"
        : null;
    return entityType;
  }

  static getCategory(entity) {
    const entityType = this.getType(entity);
    return entityType ? entity[entityType][categoryMapping[entityType]] : "";
  }

  static getSalesPerson(entity) {
    const entityType = this.getType(entity);
    return entityType ? entity[entityType][salesMapping[entityType]] : "";
  }

  static getCustServicePerson(entity) {
    const entityType = this.getType(entity);
    return entityType
      ? entity[entityType][customerServiceMapping[entityType]]
      : "";
  }

  static extractPanFromGST(gst) {
    if (gst.trim().length !== 15) {
      throw new Error('Invalid GST number length')
    }

    const pan = gst.slice(2, 12)
    return pan
  }
}

export default EntityUtils;
