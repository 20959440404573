import { useState } from "react";
import { Agmongofile } from "../components/AgFile";
import Api from "../state/Api"
let server = process.env.REACT_APP_FILE_SERVER

export default function Taskfiletable(props) {
    const [newLabel, setNewLabel] = useState('')
    const [newButtonEnabled, setNewButtonEnabled] = useState(false)
    
    const [docs, setDocs] = useState(props?.data?.files || [])
    
    // if (!docs) docs = []
    return (
        <div>
            <table className="table is-striped is-fullwidth is-narrow" style={{ fontSize: '0.8rem' }}>
                <thead>
                    <tr>
                        <th style={{ textAlign: 'center' }}>Document</th>
                        <th style={{ textAlign: 'center' }}>Link</th>
                        <th style={{ textAlign: 'center' }}>Upload</th>
                    </tr>
                </thead>
                <tbody>
                    {docs.map((doc, index) => (
                        <tr key={doc.label.replaceAll(" ", "") + "___" + index} style={{ padding: '0.5rem' }}>
                            <td>{doc.label}</td>
                            <td>
                                <a target='_blank' href={server + '/api/v1/filestore/getfile/' + props.data._id + '/' + doc.filename}>
                                    {doc.filename}
                                </a>&nbsp;
                                {(doc.filename) ?
                                    <button class="button is-small is-danger" onClick={() => {
                                        docs.splice(index, 1);
                                        Api.updateDocumentById(props.data._id, docs,'del')
                                    }
                                    }><i class="fa fa-trash" aria-hidden="true"></i></button>
                                    : <></>
                                }

                            </td>
                            <td>
                                <Agmongofile
                                    disabled={doc.approved === 'Approve'}
                                    parent={doc.label.replaceAll(" ", "") + "___" + index}
                                    label={doc.label}
                                    filekey={props.data._id}
                                    callback={(data, id) => {
                                        docs[id].filename = data.key.filename;
                                        Api.updateDocumentById(props.data._id, docs)
                                        // e.filename = data.key.filename
                                        // Taskapi.updateTask(taskStore.current)
                                    }}
                                />
                            </td>
                        </tr>
                    ))}
                    <tr>
                        <td>
                            <input
                                placeholder="Name for new Document"
                                className="input is-small"
                                value={newLabel}
                                onChange={(e) => {
                                    if (e.target.value.length > 0) setNewButtonEnabled(true);
                                    else setNewButtonEnabled(false);
                                    setNewLabel(e.target.value);
                                }}
                            />
                        </td>
                        <td>
                            <button
                                disabled={!newButtonEnabled}
                                className="button is-small is-link"
                                onClick={() => {
                                    setDocs([...docs,{
                                        label: newLabel,
                                        filename: ''
                                    }])
                                    setNewLabel('');
                                    setNewButtonEnabled(false);
                                }}
                            >
                                Add Document Type to Table
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
            <hr />
        </div>
    )
}