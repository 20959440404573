import { useSnapshot } from "valtio"
import { bookingsStore } from "../state/CommonState";
import { useEffect, useState } from "react";
import Api from "../state/Api";
import { taskStore } from "./task-store";

export function KycDocumentsActionTrigger() {
    const [existance, setExistance] = useState([])

    useSnapshot(bookingsStore)
    useEffect(() => {
        Api.checkKycTriggered(bookingsStore.current._id, (response) => {
            setExistance(response)
        });
    }, []);

    const submit = async () => {
        Api.triggerKycRequirement(bookingsStore.current._id, (response) => {
            taskStore.triggerActionVisible = false
            taskStore.myActionVisible = false
        });
    }
    return (
        <div>
            {existance.length < 2 ? (
                <div>
                    <h2 style={{ width: '100%', marginTop: '20px' }}>There are currently no KYC documents required for this booking.</h2>
                </div>
            ) : (
                <div>
                    <table style={{ width: '100%', marginTop: '20px', borderCollapse: 'collapse' }}>
                        <thead>
                            <tr>
                                <th style={{ textAlign: 'left', padding: '10px', borderBottom: '2px solid #ddd' }}>Documents that would be requested for this booking</th>
                            </tr>
                        </thead>
                        <tbody>
                            {existance.map((fileList, index) => (
                                <tr key={index}>
                                    <td style={{ paddingLeft: '20px', borderBottom: '1px solid #ddd' }}>
                                        {fileList.label}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <button className="button islink" style={{ float: "right", marginTop: '20px' }}
                        onClick={submit}
                    >
                        Trigger Action
                    </button>
                </div>
            )}
        </div>
    );

}