import { useSnapshot } from "valtio"
import { useEffect, useState } from "react"
import {
    quoteDataRec, fclChargesStore, containerStore
} from '../state/CommonState'
import Utils from "../util/Utils"
import { configStore } from "../state/CommonState"
import { Card } from "../v3-componets/AgCard"
import { Headspan } from "../v3-componets/AgHeadspan"
import pepiconspopperson from "../assets/images/pepicons-pop_person.svg";
import pepiconspopperson1 from "../assets/images/pepicons-pop_person1.svg";

export default function FCLChargesViewv3(props) {
    const imContainerStore = useSnapshot(containerStore)
    const containerNames = imContainerStore.list.map(e => e.containerType)


    const Tn = ({ i, name , list}) => {
        const e = list[i]
        let disabled = false
        if (e.disabled) {
            for (let i = 0; i < e.disabled.length; i++) {
                if (name.endsWith(e.disabled[i])) {
                    disabled = true
                    break
                }
            }
        }
        if (disabled)
            return (
                <div style={{ color: '#dddddd',  border: 'none'}} >.</div>
            )
        else {
            let num = e[name]
            num = isNaN(num) ? 0.0 : Number(num)
            return num > 0 ? <span>{Utils.N(num, e.currency)}</span> : <></>
        }
    }
    const displayRec = (e) => {
        for (let i = 0; i < containerNames.length; i++) {
            const c = containerNames[i]
            const wmKey = c + 'perWM'
            const shipmentKey = c + 'perShipment'
            const totalKey = c + 'total'
            const perWM = parseFloat(e[wmKey])
            const perShipment = parseFloat(e[shipmentKey])
            const total = parseFloat(e[totalKey])
            console.log(perWM, perShipment, total)
            if (perWM > 0 || perShipment > 0 || total > 0) return true
        }
        return false
    }
    if (!props.data) {
        let listo = []
        let listc = []
        let lists = []
        for (let i = 0; i < fclChargesStore.list.length; i++) {
            let e = fclChargesStore.list[i]
            e = { ...e }
            if (e.onaccount && e.onaccount.startsWith('Cons')) listc.push(e)
            else if (e.onaccount && e.onaccount.startsWith('Ship')) lists.push(e)
            else listo.push(e)
        }
        return <>
        {
            (listo.length>0)?<FCLChargesViewv3 data={listo} chargeType='On Your Account' isMailBody={props.isMailBody}/>:""
        }
        {
            (listc.length>0)?<FCLChargesViewv3 data={listc} chargeType='On Consignee Account' isMailBody={props.isMailBody}/>:""
        }
        {
            (lists.length>0)?<FCLChargesViewv3 data={lists} chargeType='On Shipper Account' isMailBody={props.isMailBody}/>:""
        }
        </>
    }
    let counter = 1

    let grandTotal = {}
    let grantTotaloftotals = 0.0
    containerStore.list.map(c => {
        grandTotal[c.containerType] = 0.0
        console.log(grandTotal)
    })
    props.data.map(e => {
        let erate = configStore.getExchangeRate(e.currency)
        if (!erate || erate === 0) erate = 1.0
        containerStore.list.map(c => {
            let totalField = c.containerType + 'total'
            let value = e[totalField] * erate
            grandTotal[c.containerType] += value
            grantTotaloftotals += value
        })
    })
    let checkValue = false;
    let checkPerTon = false;
    props.data.forEach(element => {
        containerNames.map((container) => {
            if((+(element[container+'perWM'])) !== 0 || (+(element[container+'perShipment'])) !== 0 || (+(element[container+'total'])) !== 0){
                checkValue = true;
            }
            if(element[container+'perTon']!=undefined && (+(element[container+'perTon'])) !== 0 ){
                checkPerTon = true;
            }
        })
    });
    return (
        <>
            {
                checkValue ? 
                <>
                <div style={{marginTop:"10px"}}>
                                <Card>
                                    {!props.isMailBody?<span><img src={props.chargeType=="On Your Account"?pepiconspopperson:pepiconspopperson1}/></span>:<></>}<Headspan color="#00000" fontSize= "14px" fontWeight="900" data={" "+props.chargeType}/>
                                    <div className="table-container">
                                    <table style={{border:"none", width:"100%"}}>
                    <thead>
                    <tr>
                                <th></th>
                                <th></th>
                                <th></th>
                                {containerNames.map(e => ( 
                                    <th style={{border:"solid 0.5px #4BA7DD", backgroundColor:"#4BA7DD", color:"#fff",fontWeight:"900", padding:"10px", textAlign:"center"}} colSpan={checkPerTon?4:3}>{e}</th>
                                ))}
                            </tr>
                            <tr style={{ fontWeight:"900",border:"solid 0.5px #D4D4D4"}}>
                                <th style={{color:"#2C358A", padding:"10px"}}>ID</th>
                                <th style={{color:"#2C358A", padding:"10px"}}>Charge Description</th>
                                <th style={{color:"#2C358A", padding:"10px"}}>Currency</th>
                                {containerNames.map(e => (
                                    <>
                                        <th style={{color:"#2C358A",textAlign: 'center', padding:"10px"}}>Per Container</th>
                                        <th style={{color:"#2C358A",textAlign: 'center', padding:"10px"}}>Per BL</th>
                                        {
                                            (checkPerTon)?<th style={{color:"#2C358A",textAlign: 'center', padding:"10px"}}>Per Ton</th>:""
                                        }
                                        <th style={{color:"#2C358A",textAlign: 'right', padding:"10px"}}>Total</th>
                                    </>
                                ))}
                            </tr>
                    </thead>
                                        <tbody>
                            {
                                props.data.map((e, i) => (
                                    displayRec(e) ?
                                        <tr key={e.id} style={{border:"solid 0.5px #D4D4D4", backgroundColor:(i%2)==1?"#F3F3F3":"none"}}>
                                            <td style={{color:"#555555", padding:"10px"}}>{counter++}</td>
                                            <td style={{color:"#555555", padding:"10px"}}>{e.chargeDescription}</td>
                                            <td style={{color:"#555555", padding:"10px"}}>{e.currency}</td>
                                            {containerNames.map(e1 => (
                                                <>
                                                    <td style={{textAlign: 'center',color:"#555555", padding:"10px"}}><Tn i={i} name={e1 + 'perWM'} list={props.data} /></td>
                                                    <td style={{textAlign: 'center',color:"#555555", padding:"10px"}}><Tn i={i} name={e1 + 'perShipment'} list={props.data} /></td>
                                                    {
                                                        (checkPerTon)?<td style={{textAlign: 'center',color:"#555555", padding:"10px"}}><Tn i={i} name={e1 + 'perTon'} list={props.data} /></td>:""
                                                    }
                                                    <td style={{textAlign: 'right',color:"#555555", padding:"10px"}}>
                                                        {Utils.N(e[e1 + 'total'], e.currency)}
                                                    </td>
                                                </>
                                            ))}
                                        </tr> :
                                        <></>
                                )
                                )
                            }
                            <tr style={{border:"solid 0.5px #D4D4D4"}}>
                                <td colSpan={3}></td>
                                {
                                    containerStore.list.map(c => {
                                        return (
                                            <>
                                                <td></td>
                                                <td></td>
                                                {
                                                    (checkPerTon)?<td></td>:""
                                                }
                                                <td style={{ textAlign: 'right',color:"#555555", padding:"10px", fontWeight:"900"}}>
                                                    
                                                        {Utils.N(grandTotal[c.containerType])}
                                                    
                                                </td>
                                            </>
                                        )
                                    })
                                }
                            </tr>
                            <tr style={{border:"solid 0.5px #D4D4D4"}}>
                                <td colSpan={3 + containerStore.list.length * 4}
                                    style={{ color:"#2C358A", padding:"10px", textAlign: 'right', fontSize: '1.1rem', fontWeight:"900"}}>
                                        {Utils.N(grantTotaloftotals)}
                                </td>
                            </tr>
                            <tr>
                                <td colSpan={3 + containerStore.list.length * 4} style={{ textAlign: 'right' }}>Taxes & Duties additional, as applicable</td>
                            </tr>

                        </tbody>
                                    </table>
                                    </div>
                                </Card>
                                </div>
                </> : <></>
            }
        </>
    )
}
