import { configStore, contractsStore, loginStore } from "../state/CommonState"
import { useSnapshot } from "valtio"
import { useEffect } from "react"
import HomeScreenv3 from "./home-screen-v3"

export default function HomeMainv3() {
    const a = useSnapshot(configStore)
    useSnapshot(contractsStore)
    useEffect(() => {
        //if (!contractsStore.navigateFromCharts) Newapi.getContracts()
    },[configStore.currentSelectedEntity,configStore.currentSelectedBranch])

    useEffect(() => {
        if (configStore.homescreenCurrentShipmentType.startsWith('FCL') &&
        configStore.homescreenCurrentShipmentType != 'FCL') {
            configStore.homescreenCurrentShipmentType = 'FCL'
        }
    }, [])
    return <HomeScreenv3/>
}