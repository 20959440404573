import { Box, Button, Typography } from "@mui/material";
import { PinnedText } from "../../mui-components/PinnedText";
import { ApproveOrRejectFile, FileViewComponent } from "../../mui-components/FileComponent";
import { useSnapshot } from "valtio";
import { taskStore } from "../../mytasks/task-store";
import Taskapi from "../../mytasks/tasks-api";
import { useEffect, useState } from "react";
import { configStore, viewBookingStore } from "../../state/CommonState";
import { validateFileApproval } from "../../mytasks/task-file-approval";
let server = process.env.REACT_APP_FILE_SERVER
export function ShippingBillCheckListApproval(){
    const task = useSnapshot(taskStore).current;
    const stage = task.stage;
    const [taskcopy, settaskcopy] = useState({})

    useEffect(() => {
        let f = async () => {
            let t = await Taskapi.getTaskCopy(taskStore.current)
            settaskcopy(t[0])
        }
        f()
    }, [task])
    
    if (task._id.bookingNum !== taskcopy?._id?.bookingNum || task._id.num !== taskcopy?._id?.num) return <></>

    return <Box sx={{height:"100%",width:"100%",display:"flex",flexDirection:"column",paddingX:"15px",gap:3}}>
    <Box sx={{display:"flex",flexDirection:"column",gap:1}}>
<PinnedText content="Please Review and Approve the attached document"/>

    </Box>
    <Box sx={{display:"flex",flexDirection:"column",gap:2,marginBottom:"auto"}}>
       <Typography sx={{fontFamily:"Figtree",color:"black",fontWeight:"bold",fontSize:"18px"}}>Documents</Typography>
       <>
       {task.files.map((file,i)=><Box sx={{display:"flex",flexDirection:"row",gap:2,alignItems:"center"}}>
           <Typography sx={{fontFamily:"Figtree",color:"black"}}>{file.label}</Typography>
           <span>:</span>
           <ApproveOrRejectFile onApprove={()=>{
               taskStore.current.files[i].approved = "Approve" 
           }}
           onReject={()=>{
            taskStore.current.files[i].approved = "Reject"
           }}
           onRejection={(rejectReason)=>{
            console.log("the reject reason ",rejectReason);
                 taskStore.current.files[i].rejectReason = rejectReason;
           }} fileStatus={taskcopy.files[i].approved} needsApproval={!(taskcopy.files[i].approved && taskcopy.files[i].approved.length>0)} documentTitle={file.filename} documentUrl={server + '/api/v1/filestore/getfile/' + task.bookingNum + '/' + file.filename} />
       </Box>)}
       </>
    </Box>
  <Box sx={{display:"flex",width:"100%",flexDirection:"row",justifyContent:"end"}}>
       <Button onClick={async (e) => {
                                let task = taskStore.current
                                let ret = validateFileApproval(task)
                                if (ret.errors.length > 0) {
                                    configStore.setModalMessage(ret.errors.join(','))
                                    return
                                }
                                if (ret.rejected)
                                    [task.stage, task.pendingWith] = [2, 'AGRAGA']
                                else
                                    [task.stage, task.pendingWith] = [3, 'AGRAGA']
                                taskStore.setCustomerComplete()
                                await Taskapi.updateTask(taskStore.current)
                                taskStore.triggerActionVisible = false
                                taskStore.myActionVisible = false
                                viewBookingStore.accordionToBeOpended = -1;
                            }}  variant="contained" sx={{borderRadius:"5px",height:"40px",width:"150px",fontFamily:"Figtree",textTransform:"capitalize"}}>Submit</Button>
    </Box>
   </Box>
}