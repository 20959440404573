export function ActiveTab(props){
    const {selectedType,viewonly,callback} = props
    const shipmentTypes = ['LCL', 'FCL', 'Air'];
    return <div className="w-full h-[40px] bg-blue-dark rounded-t-md flex flex-row">
        {shipmentTypes.map((type,i)=><div onClick={()=>{
            if(!viewonly){
                callback(type)
            }
        }} className={` h-full text-white font-bold text-[18px] flex items-center justify-center w-[80px] uppercase hover:cursor-pointer ${type==="LCL" && "rounded-tl-md"} ${type===selectedType && "bg-yellow"}`}>{type}</div>)}
    </div>
}