
import { chargesStore1} from '../state/CommonState'
import Utils from "../util/Utils"
import { configStore } from "../state/CommonState"
import { Card } from "../v3-componets/AgCard"
import { Headspan } from "../v3-componets/AgHeadspan"
import pepiconspopperson from "../assets/images/pepicons-pop_person.svg";
import pepiconspopperson1 from "../assets/images/pepicons-pop_person1.svg";
export default function LCLChargesViewv3(props) {
    let isMailBody=props.isMailBody

    const Tn = ({ i, name }) => {
        const e = props.data[i]
        if (e.disabled && e.disabled.indexOf(name) >= 0)
            return (
                <div style={{ color: '#dddddd', border: 'none' }} >.</div>
            )
        else if (name === 'total') {
            return (
                <span style={{ textAlign: 'right' }}>{Utils.N(e[name], e.currency)}</span>
            )
        } else {
            let num = e[name]
            num = isNaN(num) ? 0.0 : Number(num)
            return num != 0 ? <span>{Utils.N(num, e.currency)}</span> : <></>
        }
    }
    const displayRec = (e) => {
        const perCBM = parseFloat(e.perCBM)
        const perTon = parseFloat(e.perTon)
        const perShipment = parseFloat(e.perShipment)
        const total = parseFloat(e.total)
        console.log(perCBM, perTon, perShipment, total)
        if (perCBM != 0 || perTon != 0 || perShipment != 0 || total != 0) return true
        return false
    }
    if (!props.data) {
        if (props.dummyContract) {
            chargesStore1.list = props.dummyContract.chargesList
        }
        let listo = []
        let listc = []
        let lists = []
        for (let i = 0; i < chargesStore1.list.length; i++) {
            let e = chargesStore1.list[i]
            e = { ...e }
            if (e.onaccount && e.onaccount.startsWith('Cons')) listc.push(e)
            else if (e.onaccount && e.onaccount.startsWith('Ship')) lists.push(e)
            else listo.push(e)
        }
        return <>
        {
            (listo.length>0)?<LCLChargesViewv3 data={listo} chargeType='On Your Account'  dummyContract={props.dummyContract} isMailBody={isMailBody}/>:""
        }
        {
            (listc.length>0)?<LCLChargesViewv3 data={listc} 
            chargeType='On Consignee Account' dummyContract={props.dummyContract} isMailBody={isMailBody}/>:""
        }
        {
            (lists.length>0)?<LCLChargesViewv3 data={lists} chargeType='On Shipper Account' 
            dummyContract={props.dummyContract} isMailBody={isMailBody}/>:""
        }
        </>
    }
    let grandTotal = 0.0
    let volumeDiscount = 0.0
    props.data.map(e => {
        let erate = configStore.getExchangeRate(e.currency)
        if (!erate || erate === 0) erate = 1.0
        if (e.chargeDescription == 'Volume Discount') {
            volumeDiscount = (e.total * erate)
            if (volumeDiscount > 0) volumeDiscount *= -1
        } else {
            grandTotal += parseFloat(e.total) * erate
        }
        console.log('E.TOTAL***=', e.total, grandTotal, e.currency, erate)
    })
    let counter = 1

    let checkValue = false;
    props.data.forEach(element => {
        if((+element.perCBM) !== 0 || (+element.perShipment) !== 0 || (+element.perTon) !== 0 || (+element.minimum) !== 0 || (+element.maximum) !== 0 || (+element.total) !== 0){
            checkValue = true;
        }
    });
    return (
        <>
            {checkValue ?
            <>
            <div style={{marginTop:"10px"}}>
                                <Card>
                                    {!props.isMailBody?<span><img src={props.chargeType=="On Your Account"?pepiconspopperson:pepiconspopperson1}/></span>:<></>}<Headspan color="#00000" fontSize= "14px" fontWeight="900" data={" "+props.chargeType}/>
                                    <div className="table-container">
                                    <table style={{border:"none", width:"100%"}}>
                    <thead>
                    <tr>
                        <th></th>
                        <th></th>
                        <th></th>
                        {
                            (!isMailBody) ?
                                <>
                                    <th colSpan='4' style={{border:"solid 0.5px #4BA7DD", backgroundColor:"#4BA7DD", color:"#fff",fontWeight:"900", padding:"10px", textAlign:"center"}} className="has-text-centered">Per Unit</th>
                        <th></th>
                                </>
                                : <></>
                        }
                        <th></th>
                    </tr>
                    <tr style={{ fontWeight:"900",border:"solid 0.5px #D4D4D4"}}>
                        <th style={{color:"#2C358A", padding:"10px"}}>ID</th>
                        <th style={{color:"#2C358A", padding:"10px",width: '20rem' }}>Charge Description</th>
                        <th style={{color:"#2C358A", padding:"10px"}}>Currency</th>
                        {
                            (!isMailBody) ?
                                <>
                                    <th style={{color:"#2C358A", padding:"10px", textAlign: 'center' }}>Per CBM</th>
                                    <th style={{color:"#2C358A", padding:"10px", textAlign: 'center' }}>Per Ton</th>
                                    <th style={{color:"#2C358A", padding:"10px", textAlign: 'center' }}>Minimum</th>
                                    <th style={{color:"#2C358A", padding:"10px", textAlign: 'center' }}>Maximum</th>
                                    <th style={{color:"#2C358A", padding:"10px", textAlign: 'center' }}>Per shipment</th>
                                </>
                                : <></>
                        }
                        
                        <th style={{color:"#2C358A", padding:"10px", textAlign: 'right' }}>Total</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        props.data
                            .filter(e => e.chargeDescription != 'Volume Discount')
                            .map((e, i) => {
                                {
                                    return (displayRec(e)) ?
                                        <tr key={e.id} style={{border:"solid 0.5px #D4D4D4", backgroundColor:(i%2)==1?"#F3F3F3":"none"}}>
                                            <td style={{color:"#555555", padding:"10px"}}>{counter++}</td>
                                            <td style={{color:"#555555", padding:"10px"}}>{e.chargeDescription}</td>
                                            <td style={{color:"#555555", padding:"10px"}}>{e.currency}</td>
                                            {
                            (!isMailBody) ?
                                <>
                                    <td style={{ color:"#555555", padding:"10px",textAlign: 'center' }}><Tn i={i} name='perCBM' /></td>
                                            <td style={{color:"#555555", padding:"10px", textAlign: 'center' }}><Tn i={i} name='perTon' /></td>
                                            <td style={{color:"#555555", padding:"10px", textAlign: 'center' }}><Tn i={i} name='minimum' /></td>
                                            <td style={{color:"#555555", padding:"10px", textAlign: 'center' }}><Tn i={i} name='maximum' /></td>
                                            <td style={{color:"#555555", padding:"10px", textAlign: 'center' }}><Tn i={i} name='perShipment' /></td>
                                </>
                                : <></>
                        }
                                            
                                            <td style={{ color:"#555555", padding:"10px",textAlign: 'right' }}><Tn i={i} name='total' /></td>
                                        </tr> : <></>
                                }
                            }
                            )
                    }
                    <tr style={{border:"solid 0.5px #D4D4D4"}}>
                    {
                            (!isMailBody) ?
                                <>
                                    <td colSpan={8}></td>
                                </>
                                : <td colSpan={3}></td>
                        }
                        
                        <td style={{ color:"#2C358A", padding:"10px", textAlign: 'right', fontSize: '1.1rem', fontWeight:"900"}}>{Utils.N(grandTotal)}</td>
                    </tr>
                    {
                        volumeDiscount != 0 && <>
                        {
                            (!isMailBody)?<tr style={{border:"solid 0.5px #D4D4D4"}}>
                            <td colSpan={8} style={{ textAlign: 'right',color:"#555555", padding:"10px", fontWeight:"900"}}>Volume Discount :</td>
                            <td style={{ color:"#2C358A", padding:"10px", textAlign: 'right', fontSize: '1.1rem', fontWeight:"900"}}>{Utils.N(volumeDiscount)}</td>
                        </tr>:<tr style={{border:"solid 0.5px #D4D4D4"}}>
                                <td colSpan={3} style={{ textAlign: 'right' }}>Volume Discount :</td>
                                <td style={{ color:"#2C358A", padding:"10px", textAlign: 'right', fontSize: '1.1rem', fontWeight:"900"}}>{Utils.N(volumeDiscount).replace("-","")}</td>
                            </tr>
                        }
                        {
                            (!isMailBody)?<tr style={{border:"solid 0.5px #D4D4D4"}}>
                            <td colSpan={8} style={{ textAlign: 'right',color:"#555555", padding:"10px", fontWeight:"900"}}>TOTAL :</td>
                            <td style={{ color:"#2C358A", padding:"10px", textAlign: 'right', fontSize: '1.1rem', fontWeight:"900"}}>{Utils.N(grandTotal + volumeDiscount)}</td>
                        </tr>:<>
                        {((grandTotal + volumeDiscount)>0)?
                        <tr style={{border:"solid 0.5px #D4D4D4"}}>
                        <td colSpan={3} style={{ textAlign: 'right' }}>TOTAL :</td>
                        <td style={{ color:"#2C358A", padding:"10px", textAlign: 'right', fontSize: '1.1rem', fontWeight:"900"}}>{Utils.N(grandTotal + volumeDiscount)}</td>
                    </tr>:""
                        }
                        </>
                        }
                            
                        </>
                    }
                    <tr>
                        <td colSpan={9} style={{ textAlign: 'right' }}>Taxes & Duties additional, as applicable</td>
                    </tr>
                </tbody>
                </table>
                                    </div>
                                </Card>
                                </div></> : <></>
            
    }</>
            
    )
}
