export const ConsolidatorView = (props) => {
    let l = props.rec.consolidatorList;
    let rows = [];
    for (let i = 0; i < l.length; i++) {
        rows.push(
            <tr key={l[i].scacCode} style={{border:"solid 0.5px #D4D4D4", backgroundColor:(i%2)==1?"#F3F3F3":"none"}}>
                <td style={{padding:"5px"}}>
                    <span style={{color:"#555555"}}>
                        {`${l[i].consolidatorName}`}{"\n"}
                    </span>
                    <span style={{color:"#2C358A", fontWeight:"900"}}>
                        {`( ${l[i].scacCode} )`}
                    </span>
                </td>
            </tr>
        );
    }
    return <>{rows}</>;
}