import { configStore } from "../state/CommonState"
import SpotBookingv3 from "./spot.booking-v3";

export default function HomeScreenv3(props) {
    const stype = configStore.homescreenCurrentShipmentType && configStore.homescreenCurrentShipmentType.length>0 ? configStore.homescreenCurrentShipmentType : "LCL"
    console.log(props,"****",stype);
    switch(stype) {
        case 'LCL': return <SpotBookingv3 shipmentType={stype} {...props}/>
        case 'FCL': return <SpotBookingv3 shipmentType={stype} {...props}/>
        case 'Air': return <SpotBookingv3 shipmentType={stype} {...props}/>
    }
}