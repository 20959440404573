import { useSnapshot } from "valtio"
import {
  ApprovalTabStore,
  EntityV2Store,
  configStore,
  creditAppStore,
  creditformStore,
  loginStore,
  routerStore,
} from "../state/CommonState"
import { ApprovalTab, EntityTypeV2 } from "../state/Types"
import EntityApi from "../state/entities-api"
import { useEffect, useRef, useState } from "react"


//import { Col12, Col1,Col2, Col3, Col4, Col5, Col6, Columns } from "../Controls";
import {
  Col12,
  Col1,
  Col2,
  Col3,
  Col4,
  Col8,
  Col7,
  Col5,
  Cols,
  Col6,
  Columns,
  Col10,
  Col11,
} from "../Controls"

import { loader } from "../util/loader"
import RoleUtils from "../util/RoleUtils"
import Approval_Apis from "../state/approval-api"
import MainEntityV2 from './../entity-v2/Entities/main-entity-v2'
import { BsFillEyeFill } from "react-icons/bs"
import EntityUtils from "../util/entityUtils"
import AguserApi from "../state/AguserApi"
import { CiFileOff } from "react-icons/ci";
import { FREGIHT_FORWARD_TYPE, PERMISSIONS, SCOPE_CUSTOMS_BROKER, SCOPE_OF_ACTIVITY, SCOPE_ROAD_TRANSPORT } from "../util/constants"
// import { BsToggleOn,BsToggleOff } from "react-icons/bs";
import off from "../components/off.png";
import on from "../components/on2.png";
import {AgmodalStore} from "../state/CommonState"


export default function EditEntityApproval(props) {
  const Entity = EntityV2Store.EntityRec
  const APP = ApprovalTabStore.ApprovalRec
  console.log(APP);
  const [FormUserStatus, setFormUserStatus] = useState("")
  const [Remarks, setRemarks] = useState("")
  const [errors, setErrors] = useState({})
  const [pendingErrors, setPendingErrors] = useState({})
  const [approveCheckbox, setApproveCheckbox] = useState(false)
  const [approvalStatus, setApprovalStatus] = useState(null)
  const [message, setMessage] = useState("")
  const [downloadFormData, setDownloadFormData] = useState({})
  const [cursor, setCursor] = useState(0)
  const [subbtnstatus, setsubbtnstatus] = useState(false)
  const [RDIR, setRDIR] = useState(false)
  const [REDIRType, setREDIRType] = useState("edit")
  
  const [ROLE, setROLE] = useState("")
  const [Lists, setLists] = useState([])
  const ref = useRef(null)
  const hiddenFileInput1 = useRef()
  let title = ""
  switch (props.mode) {
    case "edit":
      title = `Modify (${Entity?._id}) ${Entity?.status}`
      break
    case "view":
      title = `View (${Entity?._id}) ${Entity?.status}`
      break
    case "new":
      title = "Create Entity"
      break
    default:
      title = "Entity"
      break
  }
  useSnapshot(ApprovalTabStore)
  useSnapshot(AgmodalStore)
  useSnapshot(loader)
  useEffect(() => {
    const input = ref.current
  }, [ref, cursor])
console.log("hhhhhhhhhhhhhhh");
  useEffect(() => {
    const CreditFormStatus =  EntityV2Store.EntityRec
    setROLE(ApprovalTabStore.ApprovalRec.userRole)
    setFormUserStatus(ApprovalTabStore.ApprovalRec.action)
  }, [])
  const handleVerifyRadioChange = (event) => {
    setRemarks("")
    setApprovalStatus(event.target.value)
    setApproveCheckbox(true)
    setMessage("")
  }

  const handleMessageChange = (e) => {
    setRemarks(e.target.value)
  }

  const timelinepushfn = (r) => {
    let timeline = {
      date: Date.now(),
      event: r,
      user: loginStore.email,
      role: RoleUtils.getUserRole()
    }
    Entity.timeline.push(timeline)
  }
  useEffect(() => {
    setRemarks(Entity?.remarks)
    if(!Entity.timeline || Entity.timeline === null){
      Entity.timeline = []
    }
  }, [])

  // const filterUsers = (criteria) => {
  //   const filteredUsers = Lists?.find((d)=>d.email == criteria);
  //   console.log(filteredUsers?.firstName + filteredUsers?.lastName);
  //   return filteredUsers?.firstName + " " + filteredUsers?.lastName;
  // };
  
  const handleSubmit = async (e) => {
    setsubbtnstatus(true)
    console.log(ROLE);
      if (approvalStatus === "approve" ) {
        const chkBox = document.getElementById("approveChkbox")?.checked
        if (chkBox) {
          await timelinepushfn("Verified")
          let dataUpdate = {
            formdata: Entity,
            userTypeObj:{
            role: ROLE,
            status:"approve",
            updatedBy: loginStore.email,
            },
          }
          await Approval_Apis.entUpdateStatus(dataUpdate, setsubbtnstatus,(d)=>{
            setsubbtnstatus(false)
          })
        } else {
          setsubbtnstatus(false)
        }

      } else if (approvalStatus === "reject") {
        if (Remarks.length < 1) {
          setsubbtnstatus(false)
          return configStore.setModalMessage("Please enter remarks , it should contain atleast 3 characters")
        }
        if (Remarks.trim().length < 3) {
          setsubbtnstatus(false)
          return configStore.setModalMessage("Remarks should contain atleast 3 characters")
        }
        await timelinepushfn("Rejected")
        let dataUpdate = {
          formdata: Entity,
          userTypeObj:{
            role: ROLE,
            status:"reject",
            rejectedrole: Entity.role,
            updatedBy: loginStore.email,
          },
          Remarks
        }
        await Approval_Apis.entUpdateStatus(dataUpdate, setsubbtnstatus,(d)=>{
          setsubbtnstatus(false)
        })
      } else{
        setsubbtnstatus(false)
      }
  } 
  const onRedirect =async (event)=>{
    setsubbtnstatus(true)
    await timelinepushfn("Rejection-Acknowledged")
    let dataUpdate = {
      formdata: Entity,
      userTypeObj:{
      role: ROLE,
      status:"approve",
      updatedBy: loginStore.email,
      },
    }

    await Approval_Apis.entUpdateStatus(dataUpdate, setsubbtnstatus,async(d)=>{
    if (d.status === "REJECTED") {
      console.log("dbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbdfdtdt\nggggggggggggggggggggggggggggg");
      await EntityApi.viewEntity(Entity.entityId,async(d)=>{
        if (d.status === "REJECTED") {
          setsubbtnstatus(false)
          setRDIR(true)
        }
      })
     }
   })
  // }
  }
  const redir = async()=>{
    await EntityApi.viewEntity(Entity.entityId,async(d)=>{
      if (d.Status === "REJECTED") {
        setRDIR(true)
      }
    })
    setREDIRType("view")
    setRDIR(true)
  }
  const checkScopeFreitFowrd = (r) => {
    return Entity?.vendor?.scopeOfActivityFreitForward?.includes(r);
  };
  const checkScopeRt = (r) => {
    return Entity.vendor.scopeOfActivityRT.includes(r);
  };
  const checkScopeBs = (r) => {
    return Entity?.scopeOfBusiness?.includes(r);
  };
  const checkScopeCb = (r) => {
    return Entity.vendor.scopeOfActivityCB.includes(r);
  };
  return (
    <>
    
    { RDIR ? <MainEntityV2 fn={RDIR} type={REDIRType}/>: <>
      <div class="main-content" style={{ zoom: "0.9" }}>
        <div className="header-wrapper">
          <div className="left-header">
            <div className="page-heading" >

              <span className="heading">
                <span >{Entity.entityId}</span>/{FormUserStatus}{' '}
              </span>

            </div>
          </div>
        </div>
        <section
          class="section section-padding"
          style={{ paddingTop: "0px" }}
        >
          <Cols>
            <Col7 style={{ zoom: "0.9",height:"100vh",overflowX:"scroll",display:"flex",flexWrap:"wrap"}}>
            <div style={{width:"50%",padding:"0.75rem",margin:"0.5rem 0"}}>
                <label style={{ color: "#000",padding:"0 0.75rem",fontWeight:600 }}>Entity Name</label>
                <Col12 style={{border:"1px solid rgb(180 178 178)",margin:"0 0.75rem",borderRadius:"4px"}}>{Entity.entityName}</Col12>
            </div>
            <div style={{width:"50%",padding:"0.75rem",margin:"0.5rem 0"}}>
                <label style={{ color: "#000",padding:"0 0.75rem",fontWeight:600 }}>Entity Type</label>
                <Col12 style={{border:"1px solid rgb(180 178 178)",margin:"0 0.75rem",borderRadius:"4px"}}>{Entity.entityType}</Col12>
            </div>
            <div style={{width:"50%",padding:"0.75rem",margin:"0.5rem 0"}}>
                <label style={{ color: "#000",padding:"0 0.75rem",fontWeight:600 }}>Entity Category</label>
                {Entity.entityType === "Vendor"?
                <Col12 style={{border:"1px solid rgb(180 178 178)",margin:"0 0.75rem",borderRadius:"4px"}}>{Entity?.vendor?.vendorType}</Col12>:
                <Col12 style={{border:"1px solid rgb(180 178 178)",margin:"0 0.75rem",borderRadius:"4px"}}>{Entity?.customer?.customerType}</Col12>
                }
            </div>
            <div style={{width:"50%",padding:"0.75rem",margin:"0.5rem 0"}}>
                <label style={{ color: "#000",padding:"0 0.75rem",fontWeight:600 }}>Entity Country</label>
                <Col12 style={{border:"1px solid rgb(180 178 178)",margin:"0 0.75rem",borderRadius:"4px"}}>{Entity.entityCountry}</Col12>
            </div>
            {Entity.entityType === "Customer"?<>
            <div style={{width:"50%",padding:"0.75rem",margin:"0.5rem 0"}}>
            <label style={{ color: "#000",padding:"0 0.75rem",fontWeight:600 }}>Scope Of Business</label>
            <Col12 style={{margin:"0 0.75rem",borderRadius:"4px"}}>{Object.values(SCOPE_OF_ACTIVITY).map((r, i) =>{
              return                             <>
              <p className="is-flex is-flex-direction-row">
                <input
                  id="scopeOfActivityFreitForward"
                  type="checkbox"
                  checked={checkScopeBs(r)}
                  disabled></input>
                <span className="is-size-7 ml-2">{r}</span>
              </p>
            </>
            } )}</Col12>
          </div>
            
            </>:null}
            {Entity.entityType === "Vendor"?<>
            <div style={{width:"50%",padding:"0.75rem",margin:"0.5rem 0"}}>
                <label style={{ color: "#000",padding:"0 0.75rem",fontWeight:600 }}>Agraga Branch</label>
                <Col12 style={{border:"1px solid rgb(180 178 178)",margin:"0 0.75rem",borderRadius:"4px"}}>{Entity.agragaBranch ||"NA"}</Col12>
            </div>
            <div style={{width:"50%",padding:"0.75rem",margin:"0.5rem 0"}}>
                <label style={{ color: "#000",padding:"0 0.75rem",fontWeight:600 }}>Relationship Manager Supply</label>
                <Col12 style={{border:"1px solid rgb(180 178 178)",margin:"0 0.75rem",borderRadius:"4px"}}>{Entity.vendor.relationShipManagerSupply || "NA"}</Col12>
            </div>
            </>
            :null}
                {EntityUtils.isVendor(Entity) &&
                  EntityUtils.isIndia(Entity) &&
                  !EntityUtils.isFreightForwarder(Entity) &&
                  (EntityUtils.isRoadTransport(Entity) ||
                    EntityUtils.isCustomsBroker(Entity) ||
                    EntityUtils.isWarehouse(Entity) ||
                    EntityUtils.isContainerFreightStation(Entity) ||
                    EntityUtils.isInlandContainerDepot(Entity) ||
                    EntityUtils.isSurveyor(Entity) ||
                    EntityUtils.isLashingandClothing(Entity) ||
                    EntityUtils.isFumigation(Entity) ||
                    EntityUtils.isPackaging(Entity) ||
                    EntityUtils.isSecondhandContainerVendor(Entity)) ? (
                    <>
            <div style={{width:"50%",padding:"0.75rem",margin:"0.5rem 0"}}>
            <label style={{ color: "#000",padding:"0 0.75rem",fontWeight:600 }}> Is Sole Proprietorship?</label>
            <Col12 style={{margin:"0 0.75rem",borderRadius:"4px"}}>
                                      {Entity?.vendor?.isSoleProp ? (
                                       <img height="30px" width="46px" src={on}></img>
                                      ) : (
                                        <img height="30px" width="46px" src={off}></img>
                                      )}
            </Col12>
          </div>
                    </>):null}
                  {EntityUtils.isVendor(Entity) &&
                  EntityUtils.isIndia(Entity) &&
                  !EntityUtils.isFreightForwarder(Entity) &&
                  (EntityUtils.isRoadTransport(Entity) ||
                    EntityUtils.isCustomsBroker(Entity) ||
                    EntityUtils.isWarehouse(Entity) ||
                    EntityUtils.isContainerFreightStation(Entity) ||
                    EntityUtils.isInlandContainerDepot(Entity) ||
                    EntityUtils.isSurveyor(Entity) ||
                    EntityUtils.isLashingandClothing(Entity) ||
                    EntityUtils.isFumigation(Entity) ||
                    EntityUtils.isPackaging(Entity) ||
                    EntityUtils.isSecondhandContainerVendor(Entity)) ? (
                    <>
             <div style={{width:"50%",padding:"0.75rem",margin:"0.5rem 0"}}>
            <label style={{ color: "#000",padding:"0 0.75rem",fontWeight:600 }}> Is MSME?</label>
            <Col12 style={{margin:"0 0.75rem",borderRadius:"4px"}}>
                                      {Entity?.vendor?.isMsme ? (
                                       <img height="30px" width="46px" src={on}></img>
                                      ) : (
                                        <img height="30px" width="46px" src={off}></img>
                                      )}
            </Col12>
          </div>
                    </>):null}
            <div style={{width:"50%",padding:"0.75rem",margin:"0.5rem 0"}}>
                <label style={{ color: "#000",padding:"0 0.75rem",fontWeight:600 }}>ERP ID</label>
                <Col12 style={{border:"1px solid rgb(180 178 178)",margin:"0 0.75rem",borderRadius:"4px"}}>{Entity.erpnumber || "NA"}</Col12>
            </div>
            {Entity?.vendor?.vendorType ==="Freight Forwarder"?
            <div style={{width:"50%",padding:"0.75rem",margin:"0.5rem 0"}}>
            <label style={{ color: "#000",padding:"0 0.75rem",fontWeight:600 }}> Provides Road Transport Service?</label>
            <Col12 style={{margin:"0 0.75rem",borderRadius:"4px"}}>
                                      {Entity?.vendor?.isRoadTransportApplied ? (
                                       <img height="30px" width="46px" src={on}></img>
                                      ) : (
                                        <img height="30px" width="46px" src={off}></img>
                                      )}
            </Col12>
          </div>:null}
            {Entity?.vendor?.vendorType ==="Freight Forwarder"?<div style={{width:"50%",padding:"0.75rem",margin:"0.5rem 0"}}>
            <label style={{ color: "#000",padding:"0 0.75rem",fontWeight:600 }}>Customs Broking License Holder?</label>
            <Col12 style={{margin:"0 0.75rem",borderRadius:"4px"}}>
                                      {Entity?.vendor?.isCustomBrokerApplied ? (
                                       <img height="30px" width="46px" src={on}></img>
                                      ) : (
                                        <img height="30px" width="46px" src={off}></img>
                                      )}
            </Col12>
          </div>:null}
      {(Entity.entityType === "Vendor" && Entity?.vendor?.vendorType ==="Road Transport") || (Entity?.vendor?.isRoadTransportApplied && Entity?.vendor?.vendorType ==="Freight Forwarder")?
      <>  
          <div style={{width:"50%",padding:"0.75rem",margin:"0.5rem 0"}}>
            <label style={{ color: "#000",padding:"0 0.75rem",fontWeight:600 }}>Operator Type</label>
            <Col12 style={{border:"1px solid rgb(180 178 178)",margin:"0 0.75rem",borderRadius:"4px"}}>{Entity.vendor.operatorType}</Col12>
          </div>
          <div style={{width:"50%",padding:"0.75rem",margin:"0.5rem 0"}}>
            <label style={{ color: "#000",padding:"0 0.75rem",fontWeight:600 }}>Scope of Activity Road Transport?</label>
            {/* <Col12 style={{border:"1px solid rgb(180 178 178)",margin:"0 0.75rem",borderRadius:"4px"}}>{Entity.vendor.scopeOfActivityRT.map((d)=> <span style={{display:"block"}}>{d}</span>)}</Col12> */}
            <Col12 style={{margin:"0 0.75rem",borderRadius:"4px"}}>{Object.values(SCOPE_ROAD_TRANSPORT).map((r, i) =>{
              return                             <>
              <p className="is-flex is-flex-direction-row">
                <input
                  id="scopeOfActivityFreitForward"
                  type="checkbox"
                  checked={checkScopeRt(r)}
                  disabled></input>
                <span className="is-size-7 ml-2">{r}</span>
              </p>
            </>
            } )}</Col12>
          </div>
      </>
      :null
       }
      {Entity.entityType === "Vendor" && Entity?.vendor?.vendorType ==="Freight Forwarder" ?
      <>

          <div style={{width:"50%",padding:"0.75rem",margin:"0.5rem 0"}}>
            <label style={{ color: "#000",padding:"0 0.75rem",fontWeight:600 }}>Scope of Activity Freight Forwarder?</label>
            <Col12 style={{margin:"0 0.75rem",borderRadius:"4px"}}>{Object.values(FREGIHT_FORWARD_TYPE).map((r, i) =>{
              return                             <>
              <p className="is-flex is-flex-direction-row">
                <input
                  id="scopeOfActivityFreitForward"
                  type="checkbox"
                  checked={checkScopeFreitFowrd(r)}
                  disabled></input>
                <span className="is-size-7 ml-2">{r}</span>
              </p>
            </>
            } )}</Col12>
          </div>
      </>
      :null
       }
       
      {(Entity.entityType === "Vendor" && Entity?.vendor?.vendorType ==="Customs Broker")  || (Entity?.vendor.isCustomBrokerApplied && Entity?.vendor?.vendorType ==="Freight Forwarder")?
      <>
          <div style={{width:"50%",padding:"0.75rem",margin:"0.5rem 0"}}>
            <label style={{ color: "#000",padding:"0 0.75rem",fontWeight:600 }}>Scope of Activity Customs Broker?</label>
            {/* <Col12 style={{border:"1px solid rgb(180 178 178)",margin:"0 0.75rem",borderRadius:"4px"}}>{Entity.vendor.scopeOfActivityCB.map((d)=> <span style={{display:"block"}}>{d}</span>)}</Col12> */}
            <Col12 style={{margin:"0 0.75rem",borderRadius:"4px"}}>{Object.values(SCOPE_CUSTOMS_BROKER).map((r, i) =>{
              return                             <>
              <p className="is-flex is-flex-direction-row">
                <input
                  id="scopeOfActivityFreitForward"
                  type="checkbox"
                  checked={checkScopeCb(r)}
                  disabled></input>
                <span className="is-size-7 ml-2">{r}</span>
              </p>
            </>
            } )}</Col12>
          </div>
      </>
      :null
       }
      {/* {Entity.entityType === "Customer" ?
      <>
          <div style={{width:"50%",padding:"0.75rem",margin:"0.5rem 0"}}>
            <label style={{ color: "#000",padding:"0 0.75rem",fontWeight:600 }}>Scope Of Business</label>
            <Col12 style={{border:"1px solid rgb(180 178 178)",margin:"0 0.75rem",borderRadius:"4px"}}>{Entity.scopeOfBusiness.map((d)=> <span style={{display:"block"}}>{d}</span>)}</Col12>
          </div>
      </>
      :null
       } */}
       {Entity.entityType === "Customer"?<>
       {Entity.scopeOfBusiness.includes("Cross Border")?<>
       <div style={{width:"100%",padding:"0.75rem",margin:"0.5rem 0"}}>
      <hr style={{border:"4px solid grey",width:"100%"}}/ >
      <h3 style={{width:"100%",fontSize:"30px"}}>Agraga Point of Contact- Cross-Border</h3>
      </div>
      <div style={{width:"50%",padding:"0.75rem",margin:"0.5rem 0"}}>
            <label style={{ color: "#000",padding:"0 0.75rem",fontWeight:600 }}>Sales Vertical</label>
            <Col12 style={{border:"1px solid rgb(180 178 178)",margin:"0 0.75rem",borderRadius:"4px"}}>{Entity.customer.crossBorder.salesVertical}</Col12>
      </div>
      <div style={{width:"50%",padding:"0.75rem",margin:"0.5rem 0"}}>
            <label style={{ color: "#000",padding:"0 0.75rem",fontWeight:600 }}>Agraga Branch</label>
            <Col12 style={{border:"1px solid rgb(180 178 178)",margin:"0 0.75rem",borderRadius:"4px"}}>{Entity.customer.crossBorder.agragaBranch}</Col12>
      </div>
      <div style={{width:"50%",padding:"0.75rem",margin:"0.5rem 0"}}>
            <label style={{ color: "#000",padding:"0 0.75rem",fontWeight:600 }}>Customer Service</label>
            <Col12 style={{border:"1px solid rgb(180 178 178)",margin:"0 0.75rem",borderRadius:"4px"}}>{Entity.customer.crossBorder.customerService}</Col12>
      </div>
      <div style={{width:"50%",padding:"0.75rem",margin:"0.5rem 0"}}>
            <label style={{ color: "#000",padding:"0 0.75rem",fontWeight:600 }}>Relationship Manager</label>
            <Col12 style={{border:"1px solid rgb(180 178 178)",margin:"0 0.75rem",borderRadius:"4px"}}>{Entity.customer.crossBorder.relationshipManager}</Col12>
      </div>
      </>:null}
      {Entity.scopeOfBusiness.includes("Domestic Transport")?<>
      <div style={{width:"100%",padding:"0.75rem",margin:"0.5rem 0"}}>
      <hr style={{border:"4px solid grey",width:"100%"}}/ >
      <h3 style={{width:"100%",fontSize:"30px"}}>Agraga Point of Contact- Domestic Transport</h3>
      </div>
      <div style={{width:"50%",padding:"0.75rem",margin:"0.5rem 0"}}>
            <label style={{ color: "#000",padding:"0 0.75rem",fontWeight:600 }}>Agraga Branch</label>
            <Col12 style={{border:"1px solid rgb(180 178 178)",margin:"0 0.75rem",borderRadius:"4px"}}>{Entity.customer.domesticTransport.agragaBranch}</Col12>
      </div>
      <div style={{width:"50%",padding:"0.75rem",margin:"0.5rem 0"}}>
            <label style={{ color: "#000",padding:"0 0.75rem",fontWeight:600 }}>Customer Service</label>
            <Col12 style={{border:"1px solid rgb(180 178 178)",margin:"0 0.75rem",borderRadius:"4px"}}>{Entity.customer.domesticTransport.customerService}</Col12>
      </div>
      <div style={{width:"50%",padding:"0.75rem",margin:"0.5rem 0"}}>
            <label style={{ color: "#000",padding:"0 0.75rem",fontWeight:600 }}>Relationship Manager</label>
            <Col12 style={{border:"1px solid rgb(180 178 178)",margin:"0 0.75rem",borderRadius:"4px"}}>{Entity.customer.domesticTransport.relationshipManager}</Col12>
      </div>
      <div style={{width:"50%",padding:"0.75rem",margin:"0.5rem 0"}}>
            <label style={{ color: "#000",padding:"0 0.75rem",fontWeight:600 }}>Branch Operation Manager (Domestic Transport)</label>
            <Col12 style={{border:"1px solid rgb(180 178 178)",margin:"0 0.75rem",borderRadius:"4px"}}>{Entity.customer.domesticTransport.branchOperationManager}</Col12>
      </div>
      <div style={{width:"50%",padding:"0.75rem",margin:"0.5rem 0"}}>
            <label style={{ color: "#000",padding:"0 0.75rem",fontWeight:600 }}>Customer Billing Cycle</label>
            <Col12 style={{border:"1px solid rgb(180 178 178)",margin:"0 0.75rem",borderRadius:"4px"}}>{Entity.customer.domesticTransport.billingCycle || "NA"}</Col12>
      </div>
      <div style={{width:"50%",padding:"0.75rem",margin:"0.5rem 0"}}>
            <label style={{ color: "#000",padding:"0 0.75rem",fontWeight:600 }}>POD Type Of Accepting Invoice</label>
            <Col12 style={{border:"1px solid rgb(180 178 178)",margin:"0 0.75rem",borderRadius:"4px"}}>{Entity.customer.domesticTransport.podInvoiceAcceptance  || "NA"}</Col12>
      </div>
      <div style={{width:"50%",padding:"0.75rem",margin:"0.5rem 0"}}>
      <label style={{ color: "#000",padding:"0 0.75rem",fontWeight:600 }}></label>

                <Col12 style={{margin:"0 0.75rem",display:"flex",gap:"1rem",alignItems:"center"}}>
                <span style={{color: "#000"}}>Invoice Format</span> 
                      {Entity.documents[11]?.pkey.length > 0 ?<a style={{display:"flex"}}
                        href={
                          process.env.REACT_APP_FILE_SERVER +
                          "/api/v1/filestore/getfile/" +
                          Entity.documents[11]?.pkey +
                          "/" +
                          Entity.documents[11]?.fileLink
                        }
                        target="_blank"
                        rel="noreferrer"
                        alt=""
                      >
                        <BsFillEyeFill style={{fontSize:"35px"}} />
                      </a>:<CiFileOff style={{fontSize:"35px"}} />}
                    </Col12>
      </div>
      </>
      :null}
      </>: null}
      <div style={{width:"100%",padding:"0.75rem",margin:"0.5rem 0"}}>
      <hr style={{border:"4px solid grey",width:"100%"}}/ >
      <h3 style={{width:"100%",fontSize:"30px"}}>KYC Details</h3>
      </div>
      {EntityUtils.isIndia(Entity)?
      <div style={{width:"50%",padding:"0.75rem",margin:"0.5rem 0"}}>
      <label style={{ color: "#000",padding:"0 0.75rem",fontWeight:600 }}>PAN Number</label>

                <Col12 style={{border:"1px solid rgb(180 178 178)",margin:"0 0.75rem",borderRadius:"4px"}}>
                    <Cols  >
                    <Col10>
                     {Entity.panNum}
                     </Col10>
                <Col2 style={{margin:"auto",padding:"0"}}>
                      {Entity.documents[1]?.pkey.length > 0 ?<a style={{display:"flex"}}
                        href={
                          process.env.REACT_APP_FILE_SERVER +
                          "/api/v1/filestore/getfile/" +
                          Entity.documents[1]?.pkey +
                          "/" +
                          Entity.documents[1]?.fileLink
                        }
                        target="_blank"
                        rel="noreferrer"
                        alt=""
                      >
                        <BsFillEyeFill style={{fontSize:"35px"}} />
                      </a>:<CiFileOff style={{fontSize:"35px"}} />}
                </Col2>
                    </Cols>
                    </Col12>
       </div>:null}
      {!EntityUtils.isIndia(Entity)?<><div style={{width:"50%",padding:"0.75rem",margin:"0.5rem 0"}}>
      <label style={{ color: "#000",padding:"0 0.75rem",fontWeight:600 }}>TAX Identification Number</label>

                <Col12 style={{border:"1px solid rgb(180 178 178)",margin:"0 0.75rem",borderRadius:"4px"}}>
                    <Cols  >
                    <Col10>
                     {Entity.tinNum}
                     </Col10>
                <Col2 style={{margin:"auto",padding:"0"}}>
                      {Entity.documents[4]?.pkey.length > 0 ?<a style={{display:"flex"}}
                        href={
                          process.env.REACT_APP_FILE_SERVER +
                          "/api/v1/filestore/getfile/" +
                          Entity.documents[4]?.pkey +
                          "/" +
                          Entity.documents[4]?.fileLink
                        }
                        target="_blank"
                        rel="noreferrer"
                        alt=""
                      >
                        <BsFillEyeFill style={{fontSize:"35px"}} />
                      </a>:<CiFileOff style={{fontSize:"35px"}} />}
                </Col2>
                    </Cols>
                    </Col12>
       </div>
       <div style={{width:"50%",padding:"0.75rem",margin:"0.5rem 0"}}>
      <label style={{ color: "#000",padding:"0 0.75rem",fontWeight:600 }}></label>

      <Col12 style={{margin:"0 0.75rem",display:"flex",gap:"1.5rem",alignItems:"center"}}>
                <span style={{color: "#000"}}>Tax Residency Certificate</span>
                      {Entity.documents[5]?.pkey.length > 0 ?<a style={{display:"flex"}}
                        href={
                          process.env.REACT_APP_FILE_SERVER +
                          "/api/v1/filestore/getfile/" +
                          Entity.documents[5]?.pkey +
                          "/" +
                          Entity.documents[5]?.fileLink
                        }
                        target="_blank"
                        rel="noreferrer"
                        alt=""
                      >
                        <BsFillEyeFill style={{fontSize:"35px"}} />
                      </a>:<CiFileOff style={{fontSize:"35px"}} />}
                    </Col12>
      </div>
       </>:null
       }
      {Entity.entityType === "Vendor"?<div style={{width:"50%",padding:"0.75rem",margin:"0.5rem 0"}}>
      <label style={{ color: "#000",padding:"0 0.75rem",fontWeight:600 }}></label>

                <Col12 style={{margin:"0 0.75rem",display:"flex",gap:"1.5rem",alignItems:"center"}}>
                <span style={{color: "#000"}}>Vendor Agreement</span>
                    
                      {Entity.documents[6]?.pkey.length > 0 ?<a style={{display:"flex"}}
                        href={
                          process.env.REACT_APP_FILE_SERVER +
                          "/api/v1/filestore/getfile/" +
                          Entity.documents[6]?.pkey +
                          "/" +
                          Entity.documents[6]?.fileLink
                        }
                        target="_blank"
                        rel="noreferrer"
                        alt=""
                      >
                        <BsFillEyeFill style={{fontSize:"35px"}} />
                      </a>:<CiFileOff style={{fontSize:"35px"}} />}
                    </Col12>
      </div>:null}
      {Entity.entityType === "Customer"&&EntityUtils.isIndia(Entity)?<div style={{width:"50%",padding:"0.75rem",margin:"0.5rem 0"}}>
      <label style={{ color: "#000",padding:"0 0.75rem",fontWeight:600 }}>IEC CODE</label>

                <Col12 style={{border:"1px solid rgb(180 178 178)",margin:"0 0.75rem",borderRadius:"4px"}}>
                    <Cols  >
                    <Col10>
                    {Entity.IECCode || "NA"}
                     </Col10>
                <Col2 style={{margin:"auto",padding:"0"}}>
                      {Entity.documents[0]?.pkey.length > 0 ?<a style={{display:"flex"}}
                        href={
                          process.env.REACT_APP_FILE_SERVER +
                          "/api/v1/filestore/getfile/" +
                          Entity.documents[0]?.pkey +
                          "/" +
                          Entity.documents[0]?.fileLink
                        }
                        target="_blank"
                        rel="noreferrer"
                        alt=""
                      >
                        <BsFillEyeFill style={{fontSize:"35px"}} />
                      </a>:<CiFileOff style={{fontSize:"35px"}} />}
                </Col2>
                    </Cols>
                    </Col12>
      </div>:null}
      {Entity?.vendor?.isSoleProp?<div style={{width:"50%",padding:"0.75rem",margin:"0.5rem 0"}}>
      <label style={{ color: "#000",padding:"0 0.75rem",fontWeight:600 }}>Aadhar Card No</label>

                <Col12 style={{border:"1px solid rgb(180 178 178)",margin:"0 0.75rem",borderRadius:"4px"}}>
                    <Cols  >
                    <Col10>
                    {Entity.aadharNum || "NA"}
                     </Col10>
                <Col2 style={{margin:"auto",padding:"0"}}>
                      {Entity.documents[3]?.pkey.length > 0 ?<a style={{display:"flex"}}
                        href={
                          process.env.REACT_APP_FILE_SERVER +
                          "/api/v1/filestore/getfile/" +
                          Entity.documents[3]?.pkey +
                          "/" +
                          Entity.documents[3]?.fileLink
                        }
                        target="_blank"
                        rel="noreferrer"
                        alt=""
                      >
                        <BsFillEyeFill style={{fontSize:"35px"}} />
                      </a>:<CiFileOff style={{fontSize:"35px"}} />}
                </Col2>
                    </Cols>
                    </Col12>
      </div>:null}
      {Entity?.vendor?.isMsme?<div style={{width:"50%",padding:"0.75rem",margin:"0.5rem 0"}}>
      <label style={{ color: "#000",padding:"0 0.75rem",fontWeight:600 }}></label>

                <Col12 style={{margin:"0 0.75rem",display:"flex",gap:"1rem",alignItems:"center"}}>
                <span style={{color: "#000"}}>Udyam Certificate</span>
                      {Entity.documents[10]?.pkey.length > 0 ?<a style={{display:"flex"}}
                        href={
                          process.env.REACT_APP_FILE_SERVER +
                          "/api/v1/filestore/getfile/" +
                          Entity.documents[10]?.pkey +
                          "/" +
                          Entity.documents[10]?.fileLink
                        }
                        target="_blank"
                        rel="noreferrer"
                        alt=""
                      >
                        <BsFillEyeFill style={{fontSize:"35px"}} />
                      </a>:<CiFileOff style={{fontSize:"35px"}} />}
                    </Col12>
      </div>:null}
      {(EntityUtils.isVendor(Entity) && EntityUtils.isRoadTransport(Entity)) || EntityUtils.isCustomer(Entity)?<div style={{width:"100%",padding:"0.75rem",margin:"0.5rem 0"}}>
      <hr style={{border:"4px solid grey",width:"100%"}}/ >
      <h3 style={{width:"100%",fontSize:"30px"}}>Advance Details</h3>
      </div>:null}
      {EntityUtils.isVendor(Entity) && EntityUtils.isRoadTransport(Entity)?<div style={{width:"50%",padding:"0.75rem",margin:"0.5rem 0"}}>
            <label style={{ color: "#000",padding:"0 0.75rem",fontWeight:600 }}>Advance Payable for Domestic Transport Bookings</label>
            <Col12 style={{border:"1px solid rgb(180 178 178)",margin:"0 0.75rem",borderRadius:"4px"}}>{Entity.advancePayable || "NA"}</Col12>
            {/* <Col12 style={{border:"1px solid rgb(180 178 178)",margin:"0 0.75rem",borderRadius:"4px"}}>{Entity.advanceReceivableForDT|| "NA"}</Col12> */}
      </div>:null}
      {EntityUtils.isCustomer(Entity)?<div style={{width:"50%",padding:"0.75rem",margin:"0.5rem 0"}}>
            <label style={{ color: "#000",padding:"0 0.75rem",fontWeight:600 }}>Advance Receivable for Domestic Transport Bookings</label>
            {/* <Col12 style={{border:"1px solid rgb(180 178 178)",margin:"0 0.75rem",borderRadius:"4px"}}>{Entity.advancePayable || "NA"}</Col12> */}
            <Col12 style={{border:"1px solid rgb(180 178 178)",margin:"0 0.75rem",borderRadius:"4px"}}>{Entity.advanceReceivableForDT|| "NA"}</Col12>
      </div>:null}
      <div style={{width:"100%",padding:"0.75rem",margin:"0.5rem 0"}}>
      <hr style={{border:"4px solid grey",width:"100%"}}/ >
      <h3 style={{width:"100%",fontSize:"30px"}}>Branch Details</h3>
      </div>
      {EntityUtils.isIndia(Entity) && Entity.documents[2].pkey?<div style={{width:"50%",padding:"0.75rem",margin:"0.5rem 0"}}>
      <label style={{ color: "#000",padding:"0 0.75rem",fontWeight:600 }}>GST Number</label>

                <Col12 style={{border:"1px solid rgb(180 178 178)",margin:"0 0.75rem",borderRadius:"4px"}}>
                    <Cols  >
                    <Col10>
                    {Entity.documents[2].pkey || "NA"}
                     </Col10>
                <Col2 style={{margin:"auto",padding:"0"}}>
                      {Entity.documents[2]?.pkey.length > 0 ?<a style={{display:"flex"}}
                        href={
                          process.env.REACT_APP_FILE_SERVER +
                          "/api/v1/filestore/getfile/" +
                          Entity.documents[2]?.pkey +
                          "/" +
                          Entity.documents[2]?.fileLink
                        }
                        target="_blank"
                        rel="noreferrer"
                        alt=""
                      >
                        <BsFillEyeFill style={{fontSize:"35px"}} />
                      </a>:<CiFileOff style={{fontSize:"35px"}} />}
                </Col2>
                    </Cols>
                    </Col12>
      </div>:null}
      {Entity.entityType === "Vendor" && EntityUtils.isIndia(Entity) && Entity.documents[9].pkey?<div style={{width:"50%",padding:"0.75rem",margin:"0.5rem 0"}}>
      <label style={{ color: "#000",padding:"0 0.75rem",fontWeight:600 }}></label>

                <Col12 style={{margin:"0 0.75rem",display:"flex",gap:"1.5rem",alignItems:"center"}}>
                <span style={{color: "#000"}}>GST Declaration</span>
                    
                      {Entity.documents[9]?.pkey.length > 0 ?<a style={{display:"flex"}}
                        href={
                          process.env.REACT_APP_FILE_SERVER +
                          "/api/v1/filestore/getfile/" +
                          Entity.documents[9]?.pkey +
                          "/" +
                          Entity.documents[9]?.fileLink
                        }
                        target="_blank"
                        rel="noreferrer"
                        alt=""
                      >
                        <BsFillEyeFill style={{fontSize:"35px"}} />
                      </a>:<CiFileOff style={{fontSize:"35px"}} />}
                    </Col12>
      </div>:null}
       
      {/* {Entity.entityType === "Vendor" && EntityUtils.isIndia(Entity) && Entity.documents[9].pkey?<div style={{width:"50%",padding:"0.75rem",margin:"0.5rem 0"}}>
      <label style={{ color: "#000",padding:"0 0.75rem",fontWeight:600 }}>GST Declaration</label>

                <Col12 style={{border:"1px solid rgb(180 178 178)",margin:"0 0.75rem",borderRadius:"4px"}}>
                    <Cols  >
                    <Col10>
                    {Entity.documents[9].pkey || "NA"}
                     </Col10>
                <Col2 style={{margin:"auto",padding:"0"}}>
                      {Entity.documents[9]?.pkey.length > 0 ?<a style={{display:"flex"}}
                        href={
                          process.env.REACT_APP_API_SERVER +
                          "/api/v1/filestore/getfile/" +
                          Entity.documents[9]?.pkey +
                          "/" +
                          Entity.documents[9]?.fileLink
                        }
                        target="_blank"
                        rel="noreferrer"
                        alt=""
                      >
                        <BsFillEyeFill style={{fontSize:"35px"}} />
                      </a>:<CiFileOff style={{fontSize:"35px"}} />}
                </Col2>
                    </Cols>
                    </Col12>
      </div>:null} */}
      {!EntityUtils.isIndia(Entity)?<>
      <div style={{width:"50%",padding:"0.75rem",margin:"0.5rem 0"}}>
      <label style={{ color: "#000",padding:"0 0.75rem",fontWeight:600 }}>Unique Identifier Document Reference Number</label>

                <Col12 style={{border:"1px solid rgb(180 178 178)",margin:"0 0.75rem",borderRadius:"4px"}}>
                    <Cols  >
                    <Col10>
                    {Entity?.branch[0]?.uidRefNo || "NA"}
                     </Col10>
                <Col2 style={{margin:"auto",padding:"0"}}>
                      {Entity.documents[7]?.pkey.length > 0 ?<a style={{display:"flex"}}
                        href={
                          process.env.REACT_APP_FILE_SERVER +
                          "/api/v1/filestore/getfile/" +
                          Entity.documents[7]?.pkey +
                          "/" +
                          Entity.documents[7]?.fileLink
                        }
                        target="_blank"
                        rel="noreferrer"
                        alt=""
                      >
                        <BsFillEyeFill style={{fontSize:"35px"}} />
                      </a>:<CiFileOff style={{fontSize:"35px"}} />}
                </Col2>
                    </Cols>
                    </Col12>
      </div>
      <div style={{width:"50%",padding:"0.75rem",margin:"0.5rem 0"}}>
            <label style={{ color: "#000",padding:"0 0.75rem",fontWeight:600 }}>Unique Identifier Document Name</label>
            <Col12 style={{border:"1px solid rgb(180 178 178)",margin:"0 0.75rem",borderRadius:"4px"}}>{Entity?.branch[0]?.uidName}</Col12>
      </div>
      </>:null}
      <div style={{width:"50%",padding:"0.75rem",margin:"0.5rem 0"}}>
            <label style={{ color: "#000",padding:"0 0.75rem",fontWeight:600 }}>Name</label>
            <Col12 style={{border:"1px solid rgb(180 178 178)",margin:"0 0.75rem",borderRadius:"4px"}}>{Entity?.entityName}</Col12>
      </div>
      <div style={{width:"50%",padding:"0.75rem",margin:"0.5rem 0"}}>
            <label style={{ color: "#000",padding:"0 0.75rem",fontWeight:600 }}>Address</label>
            <Col12 style={{border:"1px solid rgb(180 178 178)",margin:"0 0.75rem",borderRadius:"4px"}}>{Entity?.branch[0]?.branchAddresses[0]?.address}</Col12>
      </div>
      <div style={{width:"50%",padding:"0.75rem",margin:"0.5rem 0"}}>
            <label style={{ color: "#000",padding:"0 0.75rem",fontWeight:600 }}>State</label>
            <Col12 style={{border:"1px solid rgb(180 178 178)",margin:"0 0.75rem",borderRadius:"4px"}}>{Entity?.branch[0]?.state?.split("/")[0]}</Col12>
      </div>
      <div style={{width:"50%",padding:"0.75rem",margin:"0.5rem 0"}}>
            <label style={{ color: "#000",padding:"0 0.75rem",fontWeight:600 }}>City</label>
            <Col12 style={{border:"1px solid rgb(180 178 178)",margin:"0 0.75rem",borderRadius:"4px"}}>{Entity?.branch[0]?.branchAddresses[0]?.city}</Col12>
      </div>
      {EntityUtils.isIndia(Entity)?<div style={{width:"50%",padding:"0.75rem",margin:"0.5rem 0"}}>
            <label style={{ color: "#000",padding:"0 0.75rem",fontWeight:600 }}>Pincode</label>
            <Col12 style={{border:"1px solid rgb(180 178 178)",margin:"0 0.75rem",borderRadius:"4px"}}>{Entity?.branch[0]?.branchAddresses[0]?.pincode}</Col12>
      </div>:null}
      {!EntityUtils.isIndia(Entity)?<div style={{width:"50%",padding:"0.75rem",margin:"0.5rem 0"}}>
            <label style={{ color: "#000",padding:"0 0.75rem",fontWeight:600 }}>Zipcode</label>
            <Col12 style={{border:"1px solid rgb(180 178 178)",margin:"0 0.75rem",borderRadius:"4px"}}>{Entity?.branch[0]?.branchAddresses[0]?.zipcode}</Col12>
      </div>:null}
      {Entity.entityType==="Vendor" ?<>
      <div style={{width:"100%",padding:"0.75rem",margin:"0.5rem 0"}}>
      <hr style={{border:"4px solid grey",width:"100%"}}/ >
      <h3 style={{width:"100%",fontSize:"30px"}}>Credit Details</h3>
      </div>
      <div style={{width:"50%",padding:"0.75rem",margin:"0.5rem 0"}}>
            <label style={{ color: "#000",padding:"0 0.75rem",fontWeight:600 }}>Is Credit Available?</label>
            <Col12 style={{margin:"0 0.75rem",borderRadius:"4px"}}>
                                      {Entity?.vendor?.isCreditFacilitated ? (
                                       <img height="30px" width="46px" src={on}></img>
                                      ) : (
                                        <img height="30px" width="46px" src={off}></img>
                                      )}
            </Col12>
      </div>
      {Entity.vendor.isCreditFacilitated?<><div style={{width:"50%",padding:"0.75rem",margin:"0.5rem 0"}}>
            <label style={{ color: "#000",padding:"0 0.75rem",fontWeight:600 }}>Currency</label>
            <Col12 style={{border:"1px solid rgb(180 178 178)",margin:"0 0.75rem",borderRadius:"4px"}}>{Entity?.vendor?.currency || "NA"}</Col12>
      </div>
      <div style={{width:"50%",padding:"0.75rem",margin:"0.5rem 0"}}>
            <label style={{ color: "#000",padding:"0 0.75rem",fontWeight:600 }}>Credit Days</label>
            <Col12 style={{border:"1px solid rgb(180 178 178)",margin:"0 0.75rem",borderRadius:"4px"}}>{Entity?.vendor?.creditDays || "NA"}</Col12>
      </div>
      <div style={{width:"50%",padding:"0.75rem",margin:"0.5rem 0"}}>
            <label style={{ color: "#000",padding:"0 0.75rem",fontWeight:600 }}>Credit Limit</label>
            <Col12 style={{border:"1px solid rgb(180 178 178)",margin:"0 0.75rem",borderRadius:"4px"}}>{Entity?.vendor?.creditLimit|| "NA"}</Col12>
      </div></>:null}
      </>:null}
      {Entity.entityType==="Vendor"?<>
      <div style={{width:"100%",padding:"0.75rem",margin:"0.5rem 0"}}>
      <hr style={{border:"4px solid grey",width:"100%"}}/ >
      <h3 style={{width:"100%",fontSize:"30px"}}>Login Details</h3>
      </div>
      <div style={{width:"50%",padding:"0.75rem",margin:"0.5rem 0"}}>
            <label style={{ color: "#000",padding:"0 0.75rem",fontWeight:600 }}>Website URL</label>
            <Col12 style={{border:"1px solid rgb(180 178 178)",margin:"0 0.75rem",borderRadius:"4px"}}>{Entity?.vendor?.loginUrl || "NA"}</Col12>
      </div>
      <div style={{width:"50%",padding:"0.75rem",margin:"0.5rem 0"}}>
            <label style={{ color: "#000",padding:"0 0.75rem",fontWeight:600 }}>User Id</label>
            <Col12 style={{border:"1px solid rgb(180 178 178)",margin:"0 0.75rem",borderRadius:"4px"}}>{Entity?.vendor?.userId|| "NA"}</Col12>
      </div>
      <div style={{width:"50%",padding:"0.75rem",margin:"0.5rem 0"}}>
            <label style={{ color: "#000",padding:"0 0.75rem",fontWeight:600 }}>Password</label>
            <Col12 style={{border:"1px solid rgb(180 178 178)",margin:"0 0.75rem",borderRadius:"4px"}}>{Entity?.vendor?.password|| "NA"}</Col12>
      </div>
      <div style={{width:"50%",padding:"0.75rem",margin:"0.5rem 0"}}>
            <label style={{ color: "#000",padding:"0 0.75rem",fontWeight:600 }}>Remarks</label>
            <Col12 style={{border:"1px solid rgb(180 178 178)",margin:"0 0.75rem",borderRadius:"4px"}}>{Entity?.vendor?.remarks || "NA"}</Col12>
      </div>
      </>:null}
      {Entity.entityType==="Vendor"&&Entity?.vendor?.vendorType ==="Freight Forwarder"?<>
      <div style={{width:"100%",padding:"0.75rem",margin:"0.5rem 0"}}>
      <hr style={{border:"4px solid grey",width:"100%"}}/ >
      <h3 style={{width:"100%",fontSize:"30px"}}>Handling Charges</h3>
      </div>
      <Col12 style={{display:"flex",gap:".5rem"}}>
      <Col5>Handling Charges Per CBM</Col5>
      <Col2 style={{border:"1px solid rgb(180 178 178)"}}>{Entity?.vendor?.handlingCharges?.perCbm?.currency || "NA" }</Col2>
      <Col3 style={{border:"1px solid rgb(180 178 178)"}}>{Entity?.vendor?.handlingCharges?.perCbm?.value}</Col3>
      </Col12>
      <Col12 style={{display:"flex",gap:".5rem"}}>
      <Col5>Handling Charges Per BL</Col5>
      <Col2 style={{border:"1px solid rgb(180 178 178)"}}>{Entity?.vendor?.handlingCharges?.perBL?.currency || "NA" }</Col2>
      <Col3 style={{border:"1px solid rgb(180 178 178)"}}>{Entity?.vendor?.handlingCharges?.perBL?.value}</Col3>
      </Col12>
      <Col12 style={{display:"flex",gap:".5rem"}}>
      <Col5>Handling Charges Per Container</Col5>
      <Col2 style={{border:"1px solid rgb(180 178 178)"}}>{Entity?.vendor?.handlingCharges?.perContainer?.currency || "NA" }</Col2>
      <Col3 style={{border:"1px solid rgb(180 178 178)"}}>{Entity?.vendor?.handlingCharges?.perContainer?.value}</Col3>
      </Col12>
      <Col12 style={{display:"flex",gap:".5rem"}}>
      <Col5>Handling Charges Per AWB</Col5>
      <Col2 style={{border:"1px solid rgb(180 178 178)"}}>{Entity?.vendor?.handlingCharges?.perAWB?.currency || "NA" }</Col2>
      <Col3 style={{border:"1px solid rgb(180 178 178)"}}>{Entity?.vendor?.handlingCharges?.perAWB?.value}</Col3>
      </Col12>

      
      
      
      
      </>:null}
      {Entity.entityType==="Vendor"?<>
      <div style={{width:"100%",padding:"0.75rem",margin:"0.5rem 0"}}>
      <hr style={{border:"4px solid grey",width:"100%"}}/ >
      <h3 style={{width:"100%",fontSize:"30px"}}>Bank Account Details</h3>
      </div>
      <div style={{width:"50%",padding:"0.75rem",margin:"0.5rem 0"}}>
            <label style={{ color: "#000",padding:"0 0.75rem",fontWeight:600 }}>Bank Name</label>
            <Col12 style={{border:"1px solid rgb(180 178 178)",margin:"0 0.75rem",borderRadius:"4px"}}>{Entity.vendor.bankName}</Col12>
      </div>
      <div style={{width:"50%",padding:"0.75rem",margin:"0.5rem 0"}}>
            <label style={{ color: "#000",padding:"0 0.75rem",fontWeight:600 }}>Account Holder Name</label>
            <Col12 style={{border:"1px solid rgb(180 178 178)",margin:"0 0.75rem",borderRadius:"4px"}}>{Entity.vendor.accountHolderName}</Col12>
      </div>
      <div style={{width:"50%",padding:"0.75rem",margin:"0.5rem 0"}}>
            <label style={{ color: "#000",padding:"0 0.75rem",fontWeight:600 }}>Account Number</label>
            <Col12 style={{border:"1px solid rgb(180 178 178)",margin:"0 0.75rem",borderRadius:"4px"}}>{Entity.vendor.accountNo}</Col12>
      </div>
      {EntityUtils.isIndia(Entity)?<div style={{width:"50%",padding:"0.75rem",margin:"0.5rem 0"}}>
            <label style={{ color: "#000",padding:"0 0.75rem",fontWeight:600 }}>IFSC Code</label>
            <Col12 style={{border:"1px solid rgb(180 178 178)",margin:"0 0.75rem",borderRadius:"4px"}}>{Entity.vendor.IFSCcode}</Col12>
      </div>:null}
      {!EntityUtils.isIndia(Entity)?<div style={{width:"50%",padding:"0.75rem",margin:"0.5rem 0"}}>
            <label style={{ color: "#000",padding:"0 0.75rem",fontWeight:600 }}>Swift Code</label>
            <Col12 style={{border:"1px solid rgb(180 178 178)",margin:"0 0.75rem",borderRadius:"4px"}}>{Entity.vendor.swiftCode}</Col12>
      </div>:null}
      <div style={{width:"50%",padding:"0.75rem",margin:"0.5rem 0"}}>
      <label style={{ color: "#000",padding:"0 0.75rem",fontWeight:600 }}></label>

                <Col12 style={{margin:"0 0.75rem",display:"flex",gap:"1rem",alignItems:"center"}}>
                <span style={{color: "#000"}}>Upload Cheque Leaf/Bank Statement </span>
                      {Entity.documents[8]?.pkey.length > 0 ?<a style={{display:"flex"}}
                        href={
                          process.env.REACT_APP_FILE_SERVER +
                          "/api/v1/filestore/getfile/" +
                          Entity.documents[8]?.pkey +
                          "/" +
                          Entity.documents[8]?.fileLink
                        }
                        target="_blank"
                        rel="noreferrer"
                        alt=""
                      >
                        <BsFillEyeFill style={{fontSize:"35px"}} />
                      </a>:<CiFileOff style={{fontSize:"35px"}} />}
                    </Col12>
      </div>
      </>:null}
      <div style={{width:"100%",padding:"0.75rem",margin:"0.5rem 0"}}>
      <hr style={{border:"4px solid grey",width:"100%"}}/ >
      <h3 style={{width:"100%",fontSize:"30px"}}>Logistic Contact Information</h3>
      </div>

                <div style={{width:"100%",padding:"0.75rem",margin:"0.5rem 0"}}>
                  <table className="table_caf_fin wd_80">
                    <thead>
                      <td>Contact Person</td>
                      <td>Description/Department</td>
                      <td>Contact Number</td>
                      <td>Email</td>
                    </thead>
                    <tbody>


                      {Entity.logisticInformation?.map((r, i) => {
                        return  <tr>
                            <td>
                              <input
                                className={`inp_table_caf input is-small`}
                                value={r.customercontact}
                                ref={ref}
 
                                disabled={true}>

                                </input>
                            </td>
                            <td>
                              <input
                                className={`inp_table_caf input is-small`}
                                value={r.department}
                                ref={ref}
 
                                disabled={true}>

                                </input>
                            </td>
                            <td>
                              <input
                                className={`inp_table_caf input is-small`}
                                value={r.contactnum}
                                ref={ref}
 
                                disabled={true}>

                                </input>
                            </td>
                            <td>
                              <input
                                className={`inp_table_caf input is-small`}
                                value={r.email}
                                ref={ref}
 
                                disabled={true}>

                                </input>
                            </td>
                          </tr>
                        
                      })}
                    </tbody>
                  </table>
                </div>
            </Col7>
            <Col5 style={{position:"relative",height:"95vh"}}>
              <div
                style={{
                  fontWeight: "bold",
                  color: "purple",
                  fontSize: "1.1rem",
                }}
                className=""
              >
                TimeLine
              </div>
              <table
                className="table is-fullwidth is-narrow timeline_table"
                style={{ zoom: "0.8" }}
              >
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Event</th>
                    <th>User</th>
                    <th>Role</th>
                  </tr>
                </thead>
                <tbody>
                  {Entity?.timeline?.map((r, i) => {
                    return <>
                      <tr key={i}>
                        <td>{new Date(Entity.timeline[i].date).toLocaleString()}</td>
                        <td>{Entity.timeline[i].event}</td>
                        <td>{Entity?.timeline[i]?.user}</td>
                        <td>{Entity.timeline[i].role}</td>
                      </tr>
                    </>
                  })}
                </tbody>
              </table>
              <div className="approve-container">
              {(
            <>
              {(ROLE === "FINANCE" || ROLE === "Operations") && (APP.status ==="Pending") && (
                <p className="is-size-5 has-text-weight-bold">
                  Document Verification
                </p>

              )
              }
              {/* {true && (
                <p className="is-size-5 has-text-weight-bold">
                  Finance Approvals for this Credit Facility
                </p>
              )
              } */}
              {((ROLE === "FINANCE" || ROLE === "Operations") && (APP.status ==="Pending")) && (
                <div className="is-flex is-justify-content-space-between">
                  <span className="is-size-6" style={{ marginTop: "1%" }}>
                    <div>
                      <label>
                        <input
                          type="radio"
                          className="is-size-2"
                          name="approvalStatus"
                          value="approve"
                          checked={approvalStatus === "approve"}
                          onChange={handleVerifyRadioChange}
                        />
                        Approve
                      </label>
                      <label style={{ marginLeft: "10px" }}>
                        <input
                          type="radio"
                          className="is-size-2"
                          name="approvalStatus"
                          value="reject"
                          checked={approvalStatus === "reject"}
                          onChange={handleVerifyRadioChange}
                        />
                        Reject
                      </label>
                    </div>
                  </span>
                </div>
              )}

              {approvalStatus === "reject" && (
                <div>
                  <textarea
                    //  id="rejectremarksid"
                    className="px-2"
                    rows="4"
                    cols="50"
                    placeholder="Enter your Remarks"
                    value={Remarks}
                    onChange={handleMessageChange}
                    //onChange={approvebtnnfn}
                    style={{ marginTop: "10px", border: "1px solid #d1d1e0" }}
                  />
                </div>
              )}
              {(approvalStatus === "approve") && (
                <div>
                  Do you want to approve? <span></span>
                  <input
                    type="checkbox"
                    className="is-size-2"
                    name="approvalStatus"
                    // value="approve"
                    id="approveChkbox"
                  // onChange={approvebtnnfn}
                  />
                </div>
              )}
            </>
          )}
            {(ROLE === "FINANCE" || ROLE === "Operations") && (APP.status ==="Pending") &&
            (
              <div>
                <span>
                  <button
                    className="button_cancel btn_height"
                    style={{ marginLeft: "3px", paddingLeft: "10px" }}
                    onClick={() => {
                      EntityV2Store.EntityRec.set(new EntityTypeV2())
                      AgmodalStore.pageVisible = false;
                      routerStore.creditApplicationView = "list"
                    }}
                  >
                    CANCEL
                  </button>
                  <button
                    className="button_submit btn_height"
                    id="approvebtnid"
                    onClick={(e) => handleSubmit(e)}
                    disabled={subbtnstatus}
                  >
                    SUBMIT
                  </button>
                </span>
              </div>
            )
          }
 
              </div>
              { (ROLE === "FINANCE" || ROLE === "Operations"|| ROLE === "SALES") && (ROLE === "SALES" || Entity?.timeline?.find((d)=>d.event === "Rejection-Acknowledged")) && (
              <>
            <div style={{border:"1px solid #f5f5f5"}}>
              <p style={{padding:"1rem",backgroundColor:"#f2f2f2"}}>Rejection Remarks</p>
             <p style={{padding:"1rem"}}>{Entity?.remarks}</p> 
            </div>  
            {(APP.status ==="Pending") && ROLE === "SALES" && <button className="button_submit" disabled={subbtnstatus} style={{padding:".7rem",}} onClick={(e)=>onRedirect(e)}>Acknowlegement</button>}
            </>
          )

          }

            </Col5>
          </Cols>
        </section>




        <hr />
      </div>
      </>
} </>
  )

  function clearErrorMessages(r) {
    pendingErrors[r] = ""
    errors[r] = ""
    setPendingErrors(pendingErrors)
  }

}
