import { configStore, contractsStore } from "../state/CommonState"
import { useSnapshot } from "valtio"
import { useEffect } from "react"
import Newapi from "../state/new-api"
import HomeScreenv3 from "./home-screen-v3"
import { useGlobalState } from "../GlobalState"
import HomeListv3 from "./home-list-v3"

export default function HomeMainv3() {
    const a = useSnapshot(configStore)
    useSnapshot(contractsStore)
    const { currentMenu } = useGlobalState()
    useEffect(() => {
        if (!contractsStore.navigateFromCharts && (["Upload Schedules","Upload Schedulesv2","Upload Schedulesv3","Create Booking","Create Bookingv2","Create Bookingv3"].indexOf(currentMenu)!=-1)) Newapi.getContracts()
    },[configStore.currentSelectedEntity])

    switch(configStore.homescreenCurrentView) {
        case 'list':
            configStore.displaySchedules = false 
            return <HomeListv3/>
        case 'view': 
            //configStore.displaySchedules = true
            return <HomeScreenv3 viewonly='1'/>
        case 'new': return <HomeScreenv3/>
    }
}