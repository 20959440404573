import { Controller } from "react-hook-form";
import { AntSwitch } from "../mui-components/AntSwitch";

export function ControlledDetailSwitch({title,isChecked,formKey,onChange,control,disable=false}){
    return <span style={{ display: "flex", flexDirection: "row", gap: "10px", alignItems: "center" }}>
        <p className="font-sm text-[#555555]">{title}</p>
        <Controller
          name={formKey}
          control={control}
          defaultValue={isChecked}
          render={({ field }) => (
            <AntSwitch
            disabled={disable}
              checked={isChecked}
              onChange={(e) => {
                field.onChange(e.target.checked); // Update the form state
                if (onChange) {
                  onChange(e.target.checked);
                }
              }}
              style={{ scale: "1" }}
            />
          )}
        />
    </span>
}