import { CargoDetails } from "./AgCargoDetails";
import { ContainerDetails } from "./AgContainerDetails";
import { ServiceOptIns } from "./AgServiceOptIns";

export function Details({form,viewonly=false}){
    
    const {getValues,watch} = form;
    const stuffing = watch("stuffingType")
 const deStuffing = watch("destuffingType")
  
 const outGuage = watch("showCargoDetailsOutGuage")
 const mode = watch("mode");
 const Scope = getValues("scope")
 const multidest = watch("multidest")
 const containerDetails = watch("containerDetails")
   
  let showCargoDetail = false;
   
  if(mode.toLowerCase()==="lcl" || mode.toLowerCase()==="air"){
    showCargoDetail = true;
  }else{
      let origin = Scope.split("-")[0];
      let destination = Scope.split("-")[2];
      if((origin?.toLowerCase()==="door" && stuffing.toLowerCase() === "dock" ) || (destination?.toLowerCase()==="door" && deStuffing.toLowerCase() === "dock" )){
          showCargoDetail = true;
      }else{
        showCargoDetail = false
      }
  }
    return <div className="flex flex-col grow w-[876px] gap-2.5">
      <ServiceOptIns form={form} viewonly={viewonly}/>
         { Scope!="" && mode.toLowerCase() === "fcl" && containerDetails!=undefined && typeof(containerDetails)=='object' && containerDetails.length>0 && containerDetails[0]['unit']!=undefined && <ContainerDetails form={form} viewonly={viewonly} />}
         {(multidest!=undefined && multidest.length>0)?<>{ Scope!="" && (showCargoDetail||outGuage)  && multidest[0]['cargoDetails']!=undefined && multidest[0]['cargoDetails'][0]['weight']!=undefined && typeof(multidest[0]['cargoDetails'][0]['weight'])=='object' && multidest[0]['cargoDetails'][0]['weight']['unit']!=undefined && <CargoDetails form={form} viewonly={viewonly}/>}
      </>:""}
    </div>
}