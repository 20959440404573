const ENTITY_STATUS = {
  INCOMPLETE: `INCOMPLETE`,
  PENDING: `PENDING`,
  VERIFIED: `VERIFIED`,
  REJECTED: `REJECTED`,
  DEACTIVATED: `DEACTIVATED`,
};

const FREGIHT_FORWARD_TYPE ={
  LCL:`LCL`,
  FCL:`FCL`,
  AIR:`AIR`
}

const SHIPMENT_TYPES_DSR ={
  LCL:`LCL`,
  FCL_F_F:`FCL (Factory to Factory)`,
  FCL_F_CFS:`FCL (Factory to CFS)`,
  FCL_CFS_F:`FCL (CFS to Factory)`,
  FCL_CFS_CFS:`FCL (CFS to CFS)`,
  AIR:`AIR`
}

const ABNORMAL_COUNTRY_NAMES = [
  {name:"Turkey", possibleCases:["Türkiye"]},
  {name:"Hong Kong", possibleCases:["Hong Kong S.A.R."]},
  {name:"Cote d'Ivoire", possibleCases:["Côte d'Ivoire", "Côte d'Ivoire (Ivory Coast)"]}
]

const STAKEHOLDER_DEFAULT_VALUES = {
  COUNTRY: "india",
  CURRENCY: "INR",
};

const ENTITY_TYPE = {
  CUSTOMER: `Customer`,
  VENDOR: `Vendor`,
};

const CUSTOMER_BILLING_CYCLE = {
  WEEKLY: `Weekly`,
  MONTHLY: `Monthly`,
};
const POD_TYPE_INVOICE = {
  SOFTCOPY: `Soft Copy`,
  HARDCOPY: `Hard Copy`,
};

const CUSTOMER_CATEGORY = {
  MSME: `MSME`,
  ENTERPRISE: `Enterprise`,
};
  // CONTRACT_MANUFACTURER: `Contract Manufacturer`,

const VENDOR_CATEGORY = {
  ROAD_TRANSPORT: "Road Transport",
  CUSTOMS_BROKER: "Customs Broker",
  OCEAN_CARRIER: "Ocean Carrier",
  AIRLINE: "Airline",
  NVOCC: "NVOCC",
  // FREIGHT_FORWARDER: "Freight Forwarder",
  LCL_CONSOLIDATOR: "LCL Consolidator",
  WAREHOUSE_OPERATOR: "Warehouse Operator",
  CONTAINER_FREIGHT_STATION:"Container Freight Station",
  INLAND_CONTAINER_DEPOT:"Inland Container Depot",
  SURVEYOR:"Surveyor",
  LASHING_AND_CHOKING:"Lashing & Choking",
  FUMIGATION:"Fumigation",
  PACKAGING:"Packaging",
  SECONGHAND_CONTAINER_VENDOR:"Second-hand Container Vendor",
};

const VENDOR_CATEGORY_NONIN = {
  ROAD_TRANSPORT: "Road Transport",
  CUSTOMS_BROKER: "Customs Broker",
  OCEAN_CARRIER: "Ocean Carrier",
  AIRLINE: "Airline",
  NVOCC: "NVOCC",
  FREIGHT_FORWARDER: "Freight Forwarder",
  LCL_CONSOLIDATOR: "LCL Consolidator",
  WAREHOUSE_OPERATOR: "Warehouse Operator",
  CONTAINER_FREIGHT_STATION:"Container Freight Station",
  INLAND_CONTAINER_DEPOT:"Inland Container Depot",
  SURVEYOR:"Surveyor",
  LASHING_AND_CHOKING:"Lashing & Choking",
  FUMIGATION:"Fumigation",
  PACKAGEING:"Packaging",
  SECONGHAND_CONTAINER_VENDOR:"Second-hand Container Vendor",

};

const OPERATOR_TYPE = {
  ROAD_TRANSPORT: "Transporter",
  CUSTOMS_BROKER: "Broker",
  OCEAN_CARRIER: "Fleet-Owner",
};

const SCOPE_ROAD_TRANSPORT = {
  LTL: "LTL",
  FTL: "FTL",
  CONTAINER_DRAYAGE: "Container Drayage",
  TRANS_LOADING: "Trans Loading",
};
const SCOPE_OF_ACTIVITY = {
  Cross_Border: "Cross Border",
  Domestic_Transport: "Domestic Transport",
};

const SCOPE_CUSTOMS_BROKER = {
  AIR_FREIGHT_EXPORT: "Air Freight(Export)",
  AIR_FREIGHT_IMPORT: "Air Freight(Import)",
  OCEAN_FREIGHT_EXPORT: "Ocean Freight(Export)",
  OCEAN_FREIGHT_IMPORT: "Ocean Freight(Import)",
};

const BRANCH_STATUS = {
  PENDING: `PENDING`,
  VERIFIED: `VERIFIED`,
  REJECTED: `REJECTED`,
};

const CREDIT_STATUS = {
  NOTAVAILED: `Not Availed`,
  INITIATED: `Initiated`,
  APPPENSALES: `Approval Pending- Sales`,
  APPPENFINANCE: `Approval Pending- Finance`,
  SIGCPYUPLOAD: `Signed Credit Application- Upload Pending`,
  SIGNED_REUPL_PEN: "Signed Credit Application- Re-Upload Pending",
  SIGCPYUPLOADED: `Signed Credit Application Uploaded`,
  REJECTED: `Rejected`,
  VERIFIED: `Verified`
};

const USERS_STATUS = {
  NEW_REG : "NEWREG",
  // ACCEPTED : "ACCEPTED",
  ACTIVE : "ACTIVE",
  INACTIVE : "INACTIVE",
  DEACTIVATE : "DEACTIVATED",

};

const CREDIT_APPLICATION_ACTIONS = {
  APP_PEN_SALES: "Credit Application- Approval- Sales",
  APP_PEN_FINANCE: "Credit Application- Approval- Finance",
  APPROVED_SALES: "Credit Application- Approved- Sales",
  APPROVED_FINANCE: "Credit Application- Approved- Finance",
  UPL_SGN_COPY: "Signed Credit Application Upload",
  SGN_COPY_UPLD: "Signed Credit Application Uploaded",
  UPLSGN_APP_FIN: "Signed credit Application- Approval -Finance",
  SGN_APP_FIN : "Signed Credit Application Approved- Finance ",
  SGN_APP_REJFIN : "Signed Credit Application Rejected- Finance ",
  REUPLD_SGN_APP : "Re-Upload -Signed Credit Application",
  SGN_APP_REUPLD : "Signed Credit Application Re-Uploaded ",
  VERIFIED: "Verified",
  REJ_SALES: "Credit Application- Rejected- Sales",
  REJ_FINANCE: "Credit Application- Rejected- Finance",
  VERIFIED_CREDIT: "Credit Application- Verified",
}

const HTTP_STATUS_CODES = {
  // Success
  OK: 200,
  CREATED: 201,
  NO_CONTENT: 204,

  // Client Errors
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  METHOD_NOT_ALLOWED: 405,
  CONFLICT: 409,
  PAYLOAD_TOO_LARGE: 413,
  UNSUPPORTED_MEDIA_TYPE: 415,
  TOO_MANY_REQUESTS: 429,

  // Server Errors
  INTERNAL_SERVER_ERROR: 500,
  NOT_IMPLEMENTED: 501,
  BAD_GATEWAY: 502,
  SERVICE_UNAVAILABLE: 503,
  GATEWAY_TIMEOUT: 504,
  HTTP_VERSION_NOT_SUPPORTED: 505,
};

const VERTICALS = {
  CROSS_BORDER: `Cross-Border`,
  DOMESTIC_TRANSPORT: `Domestic Transport`,
  CENTRAL: `Central`,
  CB_DT:`CB-DT`
};

const STATUSFIELD = {
  'NEWREG':'NEWREG',
  'ACTIVE': 'ACTIVE',
  'INACTIVE': 'INACTIVE',
  // 'ACCEPTED': 'ACCEPTED'
}

const CUSTOMERROLES = {
  CLIENT_USER: `CLIENT USER`,
  CLIENT_ADMIN: `CLIENT ADMIN`,
  BRANCH_USER:`Branch User`,
  Client_admin:`Client Admin`
};

const DT = {
  ADMIN: `Admin`,
    BRANCH_HEAD:`Branch Head`,
    RELATIONSHIP_MANAGER_DEMAND: `Relationship Manager (Demand)`,
    CUSTOMER_SUCCESS: `Customer Success`,
    DT_OPERATIONS: `DT Operations`,
    RELATIONSHIP_MANAGER_SUPPLY: `Relationship Manager (Supply)`,
    INVOICE_LEAD: `Invoice Lead`,
    RMSUPPLY_OPERATIONSANDINVOICING: `RM (Supply),Operations & Invoicing`,
}

const CB ={
  ADMIN: `Admin`,
  BRANCH_HEAD: `Branch Head`,
  PROCUREMENT: `Procurement`,
  // PROCUREMENT_LCL: `Procurement-LCL`,
  // PROCUREMENT_FCL: `Procurement-FCL`,
  // PROCUREMENT_AIR: `Procurement-Air`,
  RELATIONSHIP_MANAGER: `Relationship Manager`,
  CUSTOMER_SUCCESS: `Customer Success`,
} 

const CB_PROCUREMENT_SUBROLES = {
  LCL_Procurement:`LCL Procurement`,
  FCL_Procurement:`FCL Procurement`,
  AIR_Procurement:`AIR Procurement`,
}

const CENTRAL_OPERATION_SUBROLES ={
  OCEAN_EXPORT_COORDINATION :`Ocean export Coordination`,
  OCEAN_IMPORT_COORDINATION:`Ocean Import Coordination`,
  AIR_EXPORT_COORDINATION:`Air export Coordination`,
  AIR_IMPORT_COORDINTAION:`Air Import Coordination`,
  OCEAN_EXPORT_DOCUMENTATION:`Ocean export Documentation`,
  OCEAN_IMPORT_DOCUMENTATION:`Ocean Import Documentation`,
  AIR_EXPORT_DOCUMENTATION:`Air export Documentation`,
  AIR_IMPORT_DOCUMENTAION:`Air Import Documentation`,
  OCEAN_VISIBILITY:`Ocean Visibility`,
  AIR_VISIBILITY:`Air Visibility`,
  DOMESTIC_TRANSPORT:`Domestic Transport`,
  VENDOR_MANAGEMENT:`Vendor Management`,
  VEHICLE_MANAGEMENT:`Vehicle Management`,
  DRIVER_MANAGEMENT:`Driver Management`
}

const CB_DT = {
  ADMIN: `Admin`,
    BRANCH_HEAD:`Branch Head`,
    RELATIONSHIP_MANAGER: `Relationship Manager`,
    CUSTOMER_SUCCESS: `Customer Success`,
}


const CENTRAL = {
  AGRAGA_ADMIN: `Agraga Admin`,
  TECHANDPRODUCT: `Tech & Product`,
  BPO: `BPO`,
  DATABASE: `Database`,
  HR: `HR`,
  FINANCE: `Finance`,
  CENTRAL_OPERATIONS: `Central Operations`,
  PRODUCT_ADOPTION:`Product Adoption`
}

const SALESVERTICAL = {
  AUTOMOTIVE: `Automotive`,
  PHARMA: `Pharma`,
  RENEWABLE_ENERGY: `Renewable Energy`,
  NEW_AGE_ENTERPRISE: `New Age Enterprise`,
  MARKET_PLACE: `Market Place`,
  MSME: `MSME`,
  ENTERPRISE: `Enterprise`,
  CONTRACT_MANUFACTURER: `Contract Manufacturer`
}

const ROLESFILTER = {
  "Domestic Transport":
    DT,
  "Cross-Border": 
    CB,
  "Central": 
   CENTRAL,
  ALL:
    {...DT, ...CB, ...CENTRAL}
  
};

const ROLES = {
  DT:
    DT,
  CB: 
    CB,
  CENTRAL: 
   CENTRAL,
   CB_DT: 
   CB_DT,
   CB_PROCUREMENT_SUBROLES:CB_PROCUREMENT_SUBROLES,
   CENTRAL_OPERATION_SUBROLES:CENTRAL_OPERATION_SUBROLES
};

const DIVISION = {
  NATIONAL: `National`,
  ZONAL: `Zonal`,
  REGIONAL: `Regional`,
  BRANCH: `Branch`,
};

const PERMISSIONS = {
  OLD: {
    NEW_QUOTATIONS: `edit_quotations`,
    MYACTIONS_TYPE: `myactions_type`,
    MYACTIONS_ROLE: `myactions_role`,
    NEW_ENQUIRY_QUOTATIONS:`agraga_enquiry_quotation`,
  },
  EDIT: {
    CB_QUOTATION_CREATE_DUMMY:`CB_Quotation_Create_Dummy`,
    SAVE_UPLOAD_SCHEDULES_ONLY: `Save_Upload_Schedules_Only`,
    CONVERT_QUOTATIONS_TO_CONTRACTS: `Convert_Quotations_To_Contracts`,
    CB_QUOTATION_AR:`CB_Quotation_Accept_Reject`,
    CUSTOMER_REF_NUMBER: `Customer_Ref_Number`,
    ERP_NUMBER: `ERP_Number`,
    ENTITIES: `entities`,
    DESTINATION_DETAILS: `Destination_Details`,
    VEHICLE_STATUS: `VEHICLE_STATUS`,
    VENDOR_DETAILS: `Vendor_Details`,
    STOP_TRACKING: `Stop_Tracking`,
    REJECTED_VEHICLES:`Rejected_Vehicles`,
    REJECTED_DRIVERS:`Rejected_Drivers`,
    APPROVAL_VEHICLES:`Approval_Vehicles`,
    APPROVAL_DRIVERS:`Approval_Drivers`,
    APPROVAL_VEHICLES_ACKNOWLEDGEMENT:`Approval_Vehicles_Acknowledgement`,
    APPROVAL_DRIVERS_ACKNOWLEDGEMENT:`Approval_Drivers_Acknowledgement`,
    APPROVAL_VENDOR:`Approval_Vendor`,
    APPROVAL_RT_VENDOR:`Approval_RT_Vendor`,
    APPROVAL_CUSTOMER_ENTITY_ACKNOW:`Approval_Customer_Entity_Acknowledgement`,
    APPROVAL_VENDOR_RT_ENTITY_ACKNOW:`Approval_Vendor_RT_Entity_Acknowledgement`,
    APPROVAL_VENDOR_ENTITY_ACKNOW:`Approval_Vendor_Entity_Acknowledgement`,
    APPROVAL_RT_VENDOR_BRANCH:`Approval_RT_Vendor_Breanch`,
    ENTITIESV2_VENDOR_RTREJECTED: `Entities_V2_Ven_RT_Rejected`,
    BOOKING_DETAILS:`Booking_Details`,
    VEHICLE_DETAILS:`Vehicle_Details`,
    DRIVER_DETAILS:`Driver_Details`,
    AGRAGA_FREIGHT_INVOICE_DETAILS:`Agraga_Freight_Invoice_Details`,
    MYACTIONS_BUSINESS: `MyActions_Business`,
    MYACTIONS_BUSINESS_VIEW: `MyActions_Business_View`,
    MYACTIONS_BUSINESS_TAB_VIEW:`MyActions_Business_View`,
    MYACTIONS_FINANCE: `MyActions_Finance`,
    MYACTIONS_OPERATIONS: `MyActions_Operations`,
    MYACTIONS_FINANCE_OPERATIONS_VIEW: `MyActions_Finance_Operations_View`,
    MYACTIONS_FINANCE_BUSINESS_VIEW: `MyActions_Finance_Business_View`,
    MY_AGRAGA_ACTIONS_OPERATIONS: `Agraga_Actions_Operations`,
    MYACTIONS_CUSTOMER_FOLLOW_UP: `Customer_Follow_Up`,
    CREDIT_APPROVALS: `Credit_Approvals`,
    ENQUIRYTOQUOTATION :`Enquiry_To_Quotation`,
    CUSTOMER_USERS:`Customer_Users`,
    REJECTED_ENTITES_V2_CUSTOMER:`Rejected_Entities_V2_CUSTOMERS`,
    NAV_MENU: `NAV_MENU`,
    ENTITIES_KYCVERIFIED:`Entities_KYCVerified`,
    ENTITIES_STATUS:`Entities_STATUS`,
    PENDING_CUSENTITYV2_VR:'Pending_CustomerEntityV2_VerifyReject',
    PENDING_VENENTITYV2_VR:'Pending_VendorEntityV2_VerifyReject',
    PENDING_VENENTITYV2RT_VR:'Pending_VendorEntityV2RoadTransport_VerifyReject',
    VEHICLES_APPROVE_REJECT:`Vehicles_Approve_Reject`,
    DRIVERS_APPROVE_REJECT:`DRIVERS_APPROVE_REJECT`,
    CUSTOMER_ENTITYV2:'Customer_EntityV2',
    VENDOR_RT_ENTITYV2:'Vendor_RT_EntityV2',
    VENDOR_EXCEPTRT_ENTITYV2:'Vendor_ExceptRT_EntityV2',
    UPLOADS_FILE: 'Uploads_File',
    EDIT_VISIBILITY:'Edit_Visibility',
    EDIT_UPLOADS:'Edit_Uploads',
    ENTITIES_VIEW:'Entities_View',
    ENTITIESV2_VIEW:'EntitiesV2_View',
    ENTITIESV2_RT_VIEW:'EntitiesV2_RoadTransport_View',
    ENTITIESV2_VENDOR_VIEW:'EntitiesV2_Vendor_View',
    ENTITIES_VERIFIED:'Entities_Verified',
    APPROVALS_FINANCE:'Approvals_Finance',
    APPROVALS_SALES:'Approvals_Sales',
    APPROVALS_OPERATIONS:'Approvals_Operations',
    AGRAGA_USER:'Agraga_User',
    BOOKING_ACTION:'Booking_Action',
    CB_CUSTOMER_REF_NUMBER:'CB_Customer_Reference_Number',
    CB_ERP_SHIPMENT_NUMBER:'CB_ERP_Shipment_Number',
    PROCUREMENT_ONLY_SUBMIT:`Procurement_only_submit`,
    AGRAGA_ENQUIRY_BUTTON:`Agraga_enquiry_button`,
    Razor_Pay:'Razor_Pay',
    GENERATE_ENTITIES:'Generate_Entities',
    GENERATE_ENTITIES_VENDOR_RT:'Generate_Entities_Vendor_RT',
    GENERATE_DRIVERS:'Generate_Drivers',
    GENERATE_VEHICLES:'Generate_Vehicles',
    GENERATE_USERS:'Generate_Users',
    GENERATE_USERS_AGRAGA_ONLY:'Generate_Users_Agraga_Only',
    GENERATE_DSR_MY_ACTION_REPORT:'Generate_Dsr_My_Action_Report',
    GENERATE_DT_REPORTS:'Generate_Dt_Reports',
    GENERATE_REAL_TIME_PRICING:'Generate_Real_Time_Pricing',
    GENERATE_DSR_NOT_UPDATED:'Generate_Dsr_Not_Updated',
    GENERATE_ENQUIRY:'Generate_Enquiry',
    GENERATE_MY_ACTIONS:'Generate_My_Actions',
    GENERATE_QUOTATION_REPORT:'Generate_Quotation_Report',
    GENERATE_SALES_REPORT:'Generate_Sales_Report',
    DEACTIVATE_CUSTOMER_USER:`Deactivate_Customer_User`,
  },
  CREATE: {
    MANAGE_CREDIT:`MANAGE_CREDIT`,
    SERVICEABILITY:`Serviceability`,
    MANAGE_USERS:`MANAGE_USERS`,
    NEW_BRANCH_CUSTOMER:`New_Branch_Customer`,
    NEW_BRANCH_VENDOR:`New_Branch_Vendor`,
    NEW_BRANCH_VENDOR_RT_ONLY:`New_Branch_Vendor_RT_Only`,
    ALL_ENTITIES: `All_Entities`,
    NEW_VEHICLES:`New_Vehicles`,
    NEW_DRIVERS:`New Drivers`,
    NEW_ENTITYV2:`New_EntityV2`,
    NEW_ENTITYV2_ROADTRANSPORT:`New_EntityV2_RoadTransport`,
    NEW_ENTITYV2_ROADTRANSPORT_ONLY:`New_EntityV2_RoadTransport_Only`,
    NEW_ENTITYV2_REMVENDOR:`New_EntityV2_RemainingVendor`,
    INITIATE_ADVANCE:`Initiate_Advance`,
    INITIATE_BALANCE_PAYMENT:`Initiate_Balance_Payment`,
    NEW_ENTITY:`New_Entity`,
    NEW_CUSTOMER_USER:`New_Customer_User`,
    NEW_ENTITYV2_CUSTOMERS:`New_EntityV2_Customer`,
    NEW_ENTITYV2_CUSTOMERS_CB_DT:`New_ENtityV2_Customer`,
    BOOKING:`Booking`,
    DTBOOKING:`DT Booking`,
    NEW_AGRAGAUSER:`New_AgragaUser`,
    NEW_QUOTATIONS:`New Quotation`,
    NEW_USER: `New_User`,
    NEW_SHIPPER:`New_Shipper`,
    UPDATE_SHIPPER:`Update_Shipper`,
    LOGIN_REPORT: `Login_Report`,
  },
  NAV_MENU: {
    REMAINING_ANALYTICS:`Remaining_Analytics`,
    ALL_ACCESS: `All_Acsess`,
    CROSS_BORDER: `Cross_Border`,
    DOMESTIC_TRANSPORT: `Domestic_Transport`,
    ANALYTICS: `Analytics`,
    ANALYTICS_FCL_PRICING: `Analytics FCL Pricing`,
    APPROVALS: `Approvals`,
    ENQUERIES_RECEIVED: `Enquiries_Received`,
    QUOTATIONS_CB: `Quotations_CB`,
    QUOTATIONS_DT: `Quotations_DT`,
    SPOT_BOOKING_LCL: `Spot_Booking_LCL`,
    UPLOAD_SCHEDULES: `Upload_Schedules`,
    BOOKINGS_CB: `Bookings_CB`,
    BOOKINGS_DT: `Bookings_DT`,
    VISIBILITY: `Visibility`,
    MY_ACTIONS_CB: `My_Actions_CB`,
    MY_ACTIONS_DT: `My_Actions_DT`,
    OPERATIONS_CB: `Opeartions_CB`,
    SALES_CB: `Sales_CB`,
    CONFIG: `Config`,
    UPLOADS: `Uploads`,
    ENTITIES: `Entities`,
    ENTITIES_V2: `Entities_V2`,
    VEHICLES: `Vehicles`,
    DRIVERS: `Drivers`,
    USERS: `Users`,
    CUSTOMERS: `Customers`,
    REPORTS: `Reports`,
    ACCESS_CONTROL: `Access_Control`,
    AUDIT: `Audit`,
    SITE_INFORMATION: `Site-Information`,
    HOME: `Home`,
  }
};

const APP_VERSION = "v1.8"
const DEFAULT_AGRAGA_BRANCH = "Chennai"

export {
  ABNORMAL_COUNTRY_NAMES,
  ENTITY_STATUS,
  ENTITY_TYPE,
  CUSTOMER_CATEGORY,
  VENDOR_CATEGORY,
  VENDOR_CATEGORY_NONIN,
  OPERATOR_TYPE,
  SCOPE_ROAD_TRANSPORT,
  SCOPE_CUSTOMS_BROKER,
  BRANCH_STATUS,
  STAKEHOLDER_DEFAULT_VALUES,
  HTTP_STATUS_CODES,
  FREGIHT_FORWARD_TYPE,
  VERTICALS,
  ROLES,
  DIVISION,
  CUSTOMERROLES,
  PERMISSIONS,
  CREDIT_STATUS,
  CREDIT_APPLICATION_ACTIONS,
  SCOPE_OF_ACTIVITY,
  STATUSFIELD,
  ROLESFILTER,
  USERS_STATUS,
  CUSTOMER_BILLING_CYCLE,
  POD_TYPE_INVOICE, 
  APP_VERSION,
  SALESVERTICAL,
  SHIPMENT_TYPES_DSR,
  DEFAULT_AGRAGA_BRANCH
};
