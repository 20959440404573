import { SelectedRadio, UnSelectedRadio } from "../tailwind-components/CustomRadioButton";
export function LabeledRadioButton({selected=false,label,onClick}){
    return <div onClick={()=>{
        if(onClick){
            onClick();
        }
    }} className="flex flex-row gap-[10px] items-center  hover:cursor-pointer">
         {selected ? <SelectedRadio/> : <UnSelectedRadio/>}
         <p className={`${selected?"text-blue-dark font-bold":"text-grey"} font-roboto`}>{label}</p>
    </div>
}