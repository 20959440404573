import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Box, Button, Dialog, DialogContent, Typography } from "@mui/material";
import { CustomIconButton } from "../mui-components/CustomIconButton";
import downloadIcon from "../assets/images/downloadicon.svg";
import share from "../assets/images/share.svg";
import MonApi from "../state/mongo-api";
import { useEffect } from "react";
import { useState } from "react";
import axios from "axios";
import Utils from "../util/Utils";
import { aguserStore, configStore, viewBookingStore } from "../state/CommonState";
import { DocumentViewer } from "./DocumentViewer";
import { ShareButton } from "../mui-components/ShareButton";
import Api from "../state/Api";
import dummyImage from "../assets/images/approveddocumentsdummy.svg";
import { useMode } from "../context/AuthContext";
import { Util } from "reactstrap";
import { AguserType } from "../state/Types";


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#2B6ED408",
    color: "black",
    fontSize: 14,
    fontWeight: "bold"
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:last-child td, &:last-child th": {
    border: 0,
    borderColor: "#D4D4D4",
  },
}));



function FileShare({documentUrl,documentTitle,type,pkey}){
  return <ShareButton  width="30px" height="30px" title="Share Document" onSubmit={(emails)=>{
  Api.sendEmailAttachment({to:emails,type,fileName:documentTitle,pkey})
  }} />
}



function FileNameComponent({label,documentUrl,documentTitle,onClick,serverUrl}){
  return <Button onClick={()=>{
    if(onClick){
      onClick(documentTitle,documentUrl,serverUrl);
    }
  }} variant="text" sx={{fontFamily: "Figtree", color: "#2C358A", textTransform:"capitalize" }} >{label}</Button>
}

export function Approveddocuments({callback}){
    return viewBookingStore.commonView ? <Dummy callback={callback}/> : <Documents/>
}

function Dummy({callback}){
 
  return <Box sx={{height:"518px",width:"100%",position:"relative"}}>
    <Box sx={{height:"100%",width:"100%",position:"absolute",top:0}}> <img src={dummyImage} /></Box>
    <Box sx={{height:"100%",width:"100%",display:"flex",justifyContent:"center",alignItems:"center",position:"absolute",top:0}}>
     <Box sx={{height:"292px",width:'478px',borderRadius:"16px",display:"flex",flexDirection:"column",justifyContent:"space-evenly",alignItems:"center",backgroundColor:"white"}}>
<Typography sx={{fontFamily:"Figtree",color:"black",fontSize:"25px",fontWeight:"bold"}}>Sign up to view your documents</Typography>
<Button onClick={()=>{
   window.history.pushState('signup', 'signup', '/');
   aguserStore.aguserRec.set(new AguserType())
   callback("register");
}} variant="contained" sx={{textTransform:"capitalize",borderRadius:"60px",fontSize:"18px"}}>Sign up</Button>
        </Box>
        </Box>
  </Box>
}

function Documents() {
  const [fileList, setFileList] = useState([])
  const [open,setOpen]= useState(false);
  const [selectedDocument,setSelectedDocument] = useState()
  // const selectedDocument = React.useRef();

  const documentViewrHandler = (documentTitle,documentUrl,serverUrl)=>{
    // if(documentUrl.includes("xlsx") || documentUrl.includes("xls")){
    //   const link = document.createElement('a'); 
    //   link.href = documentUrl;
    //   link.setAttribute('download', documentTitle);
    //   document.body.appendChild(link);
    //   link.click();
    // }else{
      setSelectedDocument({documentTitle,documentUrl,serverUrl})
      setOpen(true);
    // }
  }
  const booking = viewBookingStore.current
  const downloadPdf = async (fileName, serverUrl) => {
    try {
      const response = await axios(serverUrl, {
        responseType: 'blob'
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a'); // const buffer = await response.arrayBuffer();
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      if(error.message.includes(500)){
        configStore.setModalMessage('File Not Found')
      }else{
        configStore.setModalMessage(error.message)
      }
    }
  };

  useEffect(() => {
    let f = async () => {
      let ret = await MonApi.find('Filestore', { '_id.pkey': booking._id }, { 'content': 0 })
      let actions = await MonApi.find('Myactions', { bookingNum: booking._id })
      let files = []
      for (let i = 0; i < actions.length; i++) {
        let a = actions[i]
        if(a.actionName==='Additional Document Request'){
          // if(a.customerStatus ==='COMPLETED'){
              a.documentRequest.map(e => (e.uploadedDocument.length > 0&&e.status==='Approve') ? files.push(e.uploadedDocument) : 1)
              ret.filter((e) => files.includes(e._id.filename) || e?.label === "template")
          // }
      }
        if (a.actionName.indexOf('Shipping Instructions') === -1) {
          if (a.status === 'COMPLETED') {
            a.files.map(e => (e.filename.length > 0) ? files.push(e.filename) : 1)
            continue
          }
          if (a.customerStatus === 'COMPLETED') {
            a.files.map(e => (e.filename.length > 0 && e.approved === 'Approve') ? files.push(e.filename) : 1)
          }
        } else {
          if (a.status === 'COMPLETED') {
            if (a.files.length > 1 && a.files[1].filename.length > 0) {
              files.push(a.files[1].filename)
            } else {
              a.files.map(e => (e.filename.length > 0) ? files.push(e.filename) : 1)
            }
            continue
          }
          if (a.customerStatus === 'COMPLETED') {
            if (a.files.length > 1 && a.files[1].filename.length > 0) {
              files.push(a.files[1].filename)
            } else {
              a.files.map(e => (e.filename.length > 0) ? files.push(e.filename) : 1)
            }
          }
        }
        
      }
      ret = ret.filter(e => files.indexOf(e._id.filename) >= 0)
      console.log('RESULT OF DOCUMNET CENTER ACTIONS=', ret)
      setFileList(ret)
    }
    f()
  }, [])

  useEffect(()=>{
     console.log("the file list ",fileList);
  },[fileList])

  let server = process.env.REACT_APP_FILE_SERVER

  const DownloadAll = async (serverUrl, list) => {
    try {
      const response = await axios(serverUrl, {
        responseType: 'blob'
      });
      // const blobData = await response.blob();
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'documents.zip');
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      configStore.setModalMessage(error.message)
    }
  };

  return (
    <>
    {((booking?.booking?.commercialInvoice_filename_list?.length === 0
    //  || !Utils.isMyBooking(booking?.booking?.contract?.entityId)
    ) 
    && (booking?.booking?.packingList_filename_list?.length===0) 
    && (booking?.booking?.msds_filename?.length===0) 
    && (booking?.booking?.nondgdecl_filename?.length===0)
    && (booking?.booking?.dgdecl_filename?.length===0)
    && (booking?.booking?.iipcert_filename?.length === 0)
    && (fileList?.length === 0 
      || ( fileList?.length===1 && fileList[0].label?.toLowerCase()==="invoice" && !Utils.isMyBooking(booking?.booking?.contract?.entityId))
    )) ?
  
    <Typography sx={{fontFamily:"Figtree",fontWeight:"bold",textTransform:"capitalize",textAlign:"center",paddingY:"10px"}}>No Documents Found</Typography>
    :
    <Box sx={{ paddingX: "10px",display:"flex",flexDirection:"column",gap:1}}>
    <TableContainer
      sx={{ border: 1, borderColor: "#D4D4D4", width: "100%", borderRadius: "10px"}}
    //   component={Paper}
    >
      <Table sx={{ width: "100%" }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Document Type</StyledTableCell>
            <StyledTableCell align="right">Created Date</StyledTableCell>
            <StyledTableCell align="right">Created By</StyledTableCell>
            <StyledTableCell align="right"></StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>

          {/* commercialInvoice_filename_list */}
          {
          //  Utils.isMyBooking(booking?.booking?.contract?.entityId) && 
           booking?.booking?.commercialInvoice_filename_list?.map((row) => (
            <StyledTableRow key={row.name}>
              <StyledTableCell align="right">
                <FileNameComponent serverUrl={`${server + '/api/v1/filestore/staticfiles/' + row}`} onClick={documentViewrHandler} label="Commercial Invoice" documentTitle={row} documentUrl={`${server}/clientfiles/${row}`} />
                {/* <Typography sx={{ display: "flex", fontFamily: "Figtree", color: "#2C358A" }}><a target="_blank"
                href={server + '/clientfiles/' + row} rel="noreferrer">
                {"Commercial Invoice"}
              </a></Typography> */}
              </StyledTableCell>
              <StyledTableCell align="right"><Typography sx={{ display: "flex", fontFamily: "Figtree" }}>{Utils.formatToDateTime1(booking.booking.bookingDate)}</Typography></StyledTableCell>
              <StyledTableCell align="right"><Typography sx={{ display: "flex", fontFamily: "Figtree" }}>{booking.booking.email || booking.booking.tsUpdatedBy}</Typography></StyledTableCell>
              <StyledTableCell align="right"><Box sx={{ display: "flex", flexDirection: "row", gap: 2, justifyContent: "end" }}>
                <CustomIconButton src={downloadIcon} onClick={async () => {
                  const serverUrl = `${server + '/api/v1/filestore/staticfiles/' + row}`
                  const filename = row
                  downloadPdf(filename, serverUrl)
                }} />
                <FileShare type="ClientFile" documentTitle={row} pkey={booking._id}/>
              </Box></StyledTableCell>
            </StyledTableRow>
          ))}

          {/* packingList_filename_list */}
          {booking?.booking?.packingList_filename_list?.map((row) => (
            <StyledTableRow key={row.name}>
              <StyledTableCell align="right">
              <FileNameComponent serverUrl={`${server + '/api/v1/filestore/staticfiles/' + row}`} onClick={documentViewrHandler} label="Packing List" documentTitle={row} documentUrl={`${server}/clientfiles/${row}`} />
                </StyledTableCell>
              <StyledTableCell align="right"><Typography sx={{ display: "flex", fontFamily: "Figtree" }}>{Utils.formatToDateTime1(booking.booking.bookingDate)}</Typography></StyledTableCell>
              <StyledTableCell align="right"><Typography sx={{ display: "flex", fontFamily: "Figtree" }}>{booking.booking.email || booking.booking.tsUpdatedBy}</Typography></StyledTableCell>
              <StyledTableCell align="right"><Box sx={{ display: "flex", flexDirection: "row", gap: 2, justifyContent: "end" }}>
                <CustomIconButton src={downloadIcon} onClick={() => {
                  const serverUrl = `${server + '/api/v1/filestore/staticfiles/' + row}`
                  const filename = `${row}`
                  downloadPdf(filename, serverUrl)
                }} />
                 <FileShare type="ClientFile" documentTitle={row} pkey={booking._id}/>
              </Box></StyledTableCell>
            </StyledTableRow>
          ))}

          {/* msds_filename */}
          {booking?.booking?.msds_filename ?
            <StyledTableRow >
              <StyledTableCell align="right">
              <FileNameComponent serverUrl={`${server + '/api/v1/filestore/staticfiles/' + booking?.booking?.msds_filename}`} onClick={documentViewrHandler} label="MSDS" documentTitle={booking?.booking?.msds_filename} documentUrl={`${server}/clientfiles/${booking?.booking?.msds_filename}`} />
               
              </StyledTableCell>
              <StyledTableCell align="right"><Typography sx={{ display: "flex", fontFamily: "Figtree" }}>{Utils.formatToDateTime1(booking.booking.bookingDate)}</Typography></StyledTableCell>
              <StyledTableCell align="right"><Typography sx={{ display: "flex", fontFamily: "Figtree" }}>{booking.booking.email || booking.booking.emailid}</Typography></StyledTableCell>
              <StyledTableCell align="right"><Box sx={{ display: "flex", flexDirection: "row", gap: 2, justifyContent: "end" }}>
                <CustomIconButton src={downloadIcon} onClick={() => {
                  const serverUrl = `${server + '/api/v1/filestore/staticfiles/' + booking?.booking?.msds_filename}`
                  const filename = booking?.booking?.msds_filename
                  downloadPdf(filename, serverUrl)
                }} />
                  <FileShare type="ClientFile" documentTitle={booking?.booking?.msds_filename} pkey={booking._id}/>
              </Box></StyledTableCell>
            </StyledTableRow>
            : <></>}

          {/* dsrRec.booking.nondgdecl_filename */}
          {booking?.booking?.nondgdecl_filename ?
            <StyledTableRow >
              <StyledTableCell align="right">
              <FileNameComponent serverUrl={`${server + '/api/v1/filestore/staticfiles/' + booking?.booking?.nondgdecl_filename}`} onClick={documentViewrHandler} label="Non-DG Declaration" documentTitle={booking?.booking?.nondgdecl_filename} documentUrl={`${server}/clientfiles/${booking?.booking?.nondgdecl_filename}`} />
              </StyledTableCell>
              <StyledTableCell align="right"><Typography sx={{ display: "flex", fontFamily: "Figtree" }}>{Utils.formatToDateTime1(booking.booking.bookingDate)}</Typography></StyledTableCell>
              <StyledTableCell align="right"><Typography sx={{ display: "flex", fontFamily: "Figtree" }}>{booking.booking.email || booking.booking.emailid}</Typography></StyledTableCell>
              <StyledTableCell align="right"><Box sx={{ display: "flex", flexDirection: "row", gap: 2, justifyContent: "end" }}>
                <CustomIconButton src={downloadIcon} onClick={() => {
                  // const serverUrl = `${server + '/api/v1/filestore/getfile/' + row._id.pkey + '/' + row._id.filename}`
                  const serverUrl = `${server + '/api/v1/filestore/staticfiles/' + booking?.booking?.nondgdecl_filename}`
                  const filename = booking?.booking?.nondgdecl_filename
                  downloadPdf(filename, serverUrl)
                }} />
                 <FileShare type="ClientFile" documentTitle={booking?.booking?.nondgdecl_filename} pkey={booking._id}/>
              </Box></StyledTableCell>
            </StyledTableRow>
            : <></>}

          {/* dgdecl_filename */}
          {booking?.booking?.dgdecl_filename ?
            <StyledTableRow >
              <StyledTableCell align="right">
              <FileNameComponent serverUrl={`${server + '/api/v1/filestore/staticfiles/' + booking?.booking?.dgdecl_filename}`} onClick={documentViewrHandler} label="DG Declaration" documentTitle={booking?.booking?.dgdecl_filename} documentUrl={`${server}/clientfiles/${booking?.booking?.dgdecl_filename}`} />
              </StyledTableCell>
              <StyledTableCell align="right"><Typography sx={{ display: "flex", fontFamily: "Figtree" }}>{Utils.formatToDateTime1(booking.booking.bookingDate)}</Typography></StyledTableCell>
              <StyledTableCell align="right"><Typography sx={{ display: "flex", fontFamily: "Figtree" }}>{booking.booking.email || booking.booking.emailid}</Typography></StyledTableCell>
              <StyledTableCell align="right"><Box sx={{ display: "flex", flexDirection: "row", gap: 2, justifyContent: "end" }}>
                <CustomIconButton src={downloadIcon} onClick={() => {
                  const serverUrl = `${server + '/api/v1/filestore/staticfiles/' + booking?.booking?.dgdecl_filename}`
                  const filename = booking?.booking?.dgdecl_filename
                  downloadPdf(filename, serverUrl)
                }} />
                 <FileShare type="ClientFile" documentTitle={booking?.booking?.dgdecl_filename} pkey={booking._id}/>
              </Box></StyledTableCell>
            </StyledTableRow>
            : <></>}

          {/* iipcert_filename */}
          {booking?.booking?.iipcert_filename ?
            <StyledTableRow >
              <StyledTableCell align="right">
              <FileNameComponent serverUrl={`${server + '/api/v1/filestore/staticfiles/' + booking?.booking?.iipcert_filename}`} onClick={documentViewrHandler} label="IIP Certificate" documentTitle={booking?.booking?.iipcert_filename} documentUrl={`${server}/clientfiles/${booking?.booking?.iipcert_filename}`} />
               
              </StyledTableCell>
              <StyledTableCell align="right"><Typography sx={{ display: "flex", fontFamily: "Figtree" }}>{Utils.formatToDateTime1(booking.booking.bookingDate)}</Typography></StyledTableCell>
              <StyledTableCell align="right"><Typography sx={{ display: "flex", fontFamily: "Figtree" }}>{booking.booking.email || booking.booking.emailid}</Typography></StyledTableCell>
              <StyledTableCell align="right"><Box sx={{ display: "flex", flexDirection: "row", gap: 2, justifyContent: "end" }}>
                <CustomIconButton src={downloadIcon} onClick={() => {
                  const serverUrl = `${server + '/api/v1/filestore/staticfiles/' + booking?.booking?.iipcert_filename}`
                  const filename = booking?.booking?.iipcert_filename
                  downloadPdf(filename, serverUrl)
                }} />
                <FileShare type="ClientFile" documentTitle={booking?.booking?.iipcert_filename} pkey={booking._id}/>
              </Box></StyledTableCell>
            </StyledTableRow>
            : <></>}

          {/* fileList */}
          {fileList.map((row) => (
            (
              !Utils.isMyBooking(booking?.booking?.contract?.entityId)  
            &&
             row.label?.toLowerCase().includes("invoice")
          ) 
            ? <></> :
            <StyledTableRow key={row.name}>
              <StyledTableCell align="right">
              <FileNameComponent serverUrl={`${server + '/api/v1/filestore/Download/' + row._id.pkey + '/' + row._id.filename}`} onClick={documentViewrHandler} label={row.label} documentTitle={row._id.filename} documentUrl={`${server}/api/v1/filestore/getfile/${row._id.pkey}/${row._id.filename}`} />
                {/* <Typography  sx={{ display: "flex", fontFamily: "Figtree", color: "#2C358A" }}><a target="_blank"
                href={server + '/api/v1/filestore/getfile/' + row._id.pkey + '/' + row._id.filename} rel="noreferrer">
                {row.label}
              </a></Typography> */}
              </StyledTableCell>
              <StyledTableCell align="right"><Typography sx={{ display: "flex", fontFamily: "Figtree" }}>{Utils.formatToDateTime(row.createdOn)}</Typography></StyledTableCell>
              <StyledTableCell align="right"><Typography sx={{ display: "flex", fontFamily: "Figtree" }}>{row.createdBy}</Typography></StyledTableCell>
              <StyledTableCell align="right"><Box sx={{ display: "flex", flexDirection: "row", gap: 2, justifyContent: "end" }}>
                <CustomIconButton src={downloadIcon} onClick={async () => {
                  const serverUrl = `${server + '/api/v1/filestore/Download/' + row._id.pkey + '/' + row._id.filename}`
                  const filename = `${row._id.filename}`
                  await downloadPdf(filename, serverUrl)
                }} />
                <FileShare type="S3" documentTitle={row._id.filename} pkey={booking._id}/>
              </Box></StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
     <Box sx={{ width: "100%", display: "flex", justifyContent: "end" }}>
     <Button variant="contained" startIcon={<img src={downloadIcon} />} sx={{ textTransform: "capitalize", fontFamily: "Figtree" }}
       onClick={async () => {
         const serverUrl = `${process.env.REACT_APP_FILE_SERVER + '/api/v1/filestore/DownloadAll/' + `${viewBookingStore.current._id}`}`
         await DownloadAll(serverUrl, viewBookingStore.list)
       }} >Download All</Button>
   </Box>
  {selectedDocument && <DocumentViewer isExcel={selectedDocument.documentUrl.includes("xlsx") || selectedDocument.documentUrl.includes("xls")} open={open} {...selectedDocument} onClose={()=>{
      setOpen(false);
      selectedDocument.current = null;
    }}/>}
   </Box>
}
</>
  );
}