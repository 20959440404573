import { useEffect, useState } from "react"
import { subscribe, useSnapshot } from "valtio"
import { AgInput, AgUnloc } from "../components/AgComponents"
import { useGlobalState } from "../GlobalState"
import { configStore, contractsStore, bookingsStore, loginStore } from "../state/CommonState"
import { enquiryStore } from "../enquiry/enquiry-data"
import PricingAddress2 from "./pricing.address.v2"
import PricingAddress3 from "./pricing.address.v3"


export default function PricingRoute2(props) {
    useSnapshot(contractsStore)
    useSnapshot(enquiryStore)
    const { currentMenu } = useGlobalState();
    const rec = enquiryStore.current.enqRoute
    useEffect(() => {
        console.log('\n\n\n\n\n==============================')
        console.log('/',enquiryStore.current.enqRoute.origin,'/',enquiryStore.current.enqRoute.destination, '/\n%%%%%%%%%%%%%n\n\n')
    }, [enquiryStore.current.enqRoute.origin, enquiryStore.current.enqRoute.destination])

    if (!contractsStore.current) return <></>
    //console.log('FROM ENQUIRY STORE=', enquiryStore.current.enqRoute)
    let disabled = {}
    if (props.viewonly) disabled = { disabled: 1 }

    let otype = enquiryStore.current.originType
    if (!otype) otype = 'Port'
    let dtype = enquiryStore.current.destinationType
    if (!dtype) dtype = 'Port'

    let pureview = {}
    if (props.pureview) {
        pureview = { disabled: '1' }
        disabled = { disabled: '1' }
    }
    configStore.homescreenCurrentShipmentScope = `${otype}-to-${dtype}`
    return (
        <div style={ loginStore.isClient() ? {backgroundColor:"white" , borderRadius : "10px"}  :{ boxShadow: '5px 5px #dddddd', border: '1px solid lightgrey' }}>
            <header className="card-header">
                <p className="card-header-title">
                    <span className="icon is-small mr-2">
                        <i className="fas fa-road"></i>
                    </span>
                    Route
                </p>
            </header>
            <div className="card-content">
                <div className="content">
                    <div className="columns">
                        <div className="column is-12">
                            <PricingAddress3 {...disabled} label='Origin' record={rec} name='origin' />
                        </div>
                    </div>
                    <div className="columns">
                        <div className="column is-12">
                            <PricingAddress3  {...disabled} label='Destination' record={rec} name='destination' />
                        </div>
                    </div>
                    <div className="columns">
                        <div className="column is-6">
                            <AgInput {...disabled} label='Cargo Readiness Date' record={rec}
                                name='cargoReadinessDate' type='date' />
                        </div>
                        <div className="column is-6">
                            <AgInput label='Cust Ref #' record={rec}
                                name='customerReferenceNum' />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}