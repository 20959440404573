import { useSnapshot } from "valtio"
import { AgmodalStore, aguserStore, configStore, loginStore, routerStore, } from "../state/CommonState"
import { $c, $cg, $cr, AgInput, AgPhone, AgSelect } from '../components/AgComponents'
import AguserApi from "../state/AguserApi"
import { useEffect, useRef, useState } from "react"
import on from "../components/on2.png"
import off from "../components/off.png"
import Api from "../state/Api"
import { Roles, ServerLists } from "../StaticData"
import UserEntity from "./user-entity"
import {
  VERTICALS,
  ROLES,
  DIVISION,
  CUSTOMERROLES,
  PERMISSIONS,
} from "../util/constants"
import { Agyesno } from "../components/Agyesno"
import RoleUtils from "../util/RoleUtils"
import { useNavigate } from 'react-router-dom'

export default function EditAguser(props) {
    let navigate = useNavigate()
    const aguser = aguserStore.aguserRec
    const [checked1, setChecked] = useState(
      aguser.isAgragaUser ? aguser.isAgragaUser : "Yes"
    )

    const [roleChecked, setRoleChecked] = useState({}); 
    const [salesChecked, setSalesChecked] = useState({}); 
    const [showInvite, setShowInvite] = useState(false)
    const [firstNameError, setFirstNameError] = useState("")
    const [emailError, setEmailError] = useState("")
    const [phoneError, setPhoneError] = useState("")
    const [roleError, setRoleError] = useState("")
    const [CountryList, setCountryList] = useState([])
    const [userdata , setUserdata] = useState(JSON.parse(JSON.stringify(aguserStore.aguserRec)))

    let title = 'Create New User'
    if (props.mode !== 'new') {
        title = 'Modify User Details'
        if (!showInvite && aguser.status === 'NEWREG' && aguser.password !== '') setShowInvite(true)
    }
    
    useSnapshot(aguserStore)
    const [roles, setRoles] = useState([])
    const [password, setPassword] = useState(aguserStore.aguserRec.password)
    const [country, setCountry] = useState(aguserStore)
    const [department, setDepartment] = useState('')
    const [cursor, setCursor] = useState(0)
    const [salesVertical, setSalesVertical] = useState([])
    const [childError, setChildError] = useState(false)
    const [loading , setLoading] = useState(false)
    const ref = useRef(null)
    useEffect(() => {
        const input = ref.current
        if (input) {
            input.setSelectionRange(cursor, cursor)
        }
    }, [ref, cursor])
    useEffect(() => {
        console.log('Useeffect alled inside edit aguser')
        console.log('>>>>>>>>>>', JSON.stringify(loginStore))
        const newval = "Yes" 
        setChecked(newval)
        aguser.isAgragaUser = newval
        if (loginStore.userRec.aguserRec.role.includes( 'AGRAGA ADMIN'))
            AguserApi.getRoles(setRoles)
        else 
            setRoles(['','CLIENT USER', 'CLIENT ADMIN'])

        if (
          props.mode !== "new" &&
          aguser.isAgragaUser === "Yes" &&
          aguser.vertical
        ) {
          AguserApi.getManagersForAgusers(aguser.role, setManagersForAgusers)
          AguserApi.getbranchesForTypes(aguser, setbranchesForTypes)
        }

        if(editMode()){
            let initialSalesCheckedState={}
          cbAdminSalesVertical.forEach((data, index) => {
               if(aguser.AdminSalesVertical.includes(data)){
                initialSalesCheckedState[index] = 'Yes';
              }else{
                initialSalesCheckedState[index] = 'No';
              }
          });
          setSalesChecked(initialSalesCheckedState);
        }
      
    }, [])


    const [branchesForTypes, setbranchesForTypes] = useState([])
    const [managersForAgusers, setManagersForAgusers] = useState([])


    const isBranchHeadRole = aguser.role.includes(ROLES.DT.BRANCH_HEAD)
    const isAdminRole = aguser.role.includes(ROLES.DT.ADMIN)
    const rmOnChnage = async (role) => {
      
      const isBranchHead = aguser.role.includes(ROLES.DT.BRANCH_HEAD) || aguser.role.includes(ROLES.DT.RMSUPPLY_OPERATIONSANDINVOICING)
      const isCentralManager = aguser.vertical === VERTICALS.CENTRAL
      const isDTAdmin = aguser.vertical === VERTICALS.DOMESTIC_TRANSPORT &&aguser.role.includes(ROLES.DT.ADMIN)
      const isCBAdmin = aguser.vertical === VERTICALS.CROSS_BORDER && aguser.role.includes(ROLES.CB.ADMIN);

      if (isBranchHead) {
        aguser.division = DIVISION.BRANCH
        await AguserApi.getbranchesForTypes(aguser, setbranchesForTypes)
      }

      if (isCentralManager || isDTAdmin || isCBAdmin) {
        await AguserApi.getManagersForAgusers(role, setManagersForAgusers)
      }
    }

    const targetCountries = ["India", "China", "Hong Kong", "United States"];

    useEffect(() => {
      if (editMode) {
        const contryList =  ServerLists.countryNameList.filter(d => targetCountries.includes(d))
        setCountryList(contryList) 
      }
    }, [])


    useEffect(() => {
      cbAdminSalesVertical.forEach((data, index) => {
        if(props.mode === 'new'){
        if (salesChecked[index] === 'Yes') {
          if (!aguser.AdminSalesVertical.includes(data)) {
            aguser.AdminSalesVertical.push(data);
          }  
        }else if(salesChecked[index] === 'No'){
          aguser.AdminSalesVertical = aguser.AdminSalesVertical.filter(salesVer => salesVer !== data);
        }
      } else if(props.mode === 'edit'){
          if(salesChecked[index] === 'Yes'){
            if (!aguser.AdminSalesVertical.includes(data)) {
              aguser.AdminSalesVertical.push(data);
          }           
          }else if(salesChecked[index] === 'No') {
          aguser.AdminSalesVertical = aguser.AdminSalesVertical.filter(salesVer => salesVer !== data);
          }
        }
      });
    }, [salesChecked]);

    const editMode = ()=>{
      return props.mode === "edit"
    }
   
    function isEmailValid(email) {
      const pattern = /^[\w\.\-+]+@agraga\.com$/i
      return pattern.test(email)
    }

    const divisonOnChnage = () => {
      AguserApi.getbranchesForTypes(aguser, setbranchesForTypes)
    }

    function trimStr(str) {
        if(!str) return str;
  return str.replace(/^\s+/g, '');
    }

    const handleChange = (e, r) => {
        setCursor(e.target.selectionStart)
        const { value } = e.target
        if (r == 'email') {
          aguser.vertical = ""
          aguser.role = []
          aguser.division = ""
          aguser.branch = ""
          aguser.regional = ""
          aguser.zonal = ""
          aguser.national = ""
          aguser.reportingManager = ""
          aguser.country = "India"
          aguser.phonePrefix = "+91"
          aguser.phone=""
          // const newval = "No";
          // setChecked(newval);
          // aguser.isAgragaUser = newval;
        }
        if (r == 'firstName' || r == 'lastName' || r == 'companyName') {
            aguser[r] = trimStr(e.target.value)
        } else {
            aguser[r] = e.target.value.trim()
        }

    // Validate the first name
    if ("firstName" === r) {
      if (
        /^[A-Z0-9a-z\s]+$/.test(value) &&
        value.length >= 1 &&
        value.length <= 25
      ) {
        setFirstNameError("")
      } else {
        setFirstNameError(
          "Please enter a valid first name with 1 to 25 characters (letters and spaces allowed)."
        )
      }
    }
    if ("role" === r) {
      if (value.length <= 0) {
        setRoleError("Please select a role")
      } else {
        setRoleError("")
      }
    }
  }

  const handleValidationComplete = (data) => {
    return data !== null ? setChildError(true) : setChildError(false)
  }

  const handleBlur = (e, r) => {
    const { value } = e.target
    if ("email" === r) {
      aguser.email = e.target.value.replace(/ /g, "").toLowerCase()

      if (/^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(value)) {
        setEmailError("")
      } else {
        setEmailError("Please enter a valid email ID")
      }
    } else if ("phone" === r) {
      aguser.phone = e.target.value.replace(/ /g, "")

      if (aguser.country === "India") {
        if (/^\d{10}$/.test(aguser.phone)) {
       //  if(aguser.country != "China"){
          setPhoneError("")  
        // }
          
        } else {
          // configStore.setModalMessage(`Please enter the valid mobile number`)
          setPhoneError(
            "Please enter a valid 10-digit phone number for India."
          )
        }

        // Validate the first name
        if ('firstName' === r) {
          if (/^[A-Z0-9a-z\s]+$/.test(value) && value.length >= 1 && value.length <= 25) {
            setFirstNameError('')
          } else {
            setFirstNameError('Please enter a valid first name with 1 to 25 characters (letters and spaces allowed).')
          }

        }
        if ('role' === r) {
          if (value.length <= 0) {
            setRoleError('Please select a role')
          } else {
            setRoleError('')
          }
        }

      } else if(aguser.country !== "India" && aguser.isAgragaUser === "Yes") {
        setPhoneError("")
      } else {
        if(aguser.phone === "")
        setPhoneError("Field is mandatory")
      }

    } else {
      if (/^\d{7,15}$/.test(aguser.phone)) {
        // Change '7-15' to '7,15'
        setPhoneError("")
      } else {
        setPhoneError(
          "Please enter a valid phone number between 7 and 15 digits for other countries."
        )
      }
    }
  }



  const handleSubmit1 = (e) => {
    e.preventDefault()

    // If all validations pass, submit the form
    console.log(aguser, "submitting")
    if (aguser.firstName === '' || aguser.email === '' || (aguser.phone === '' && aguser.isAgragaUser === "Yes" && aguser.country === 'India') ||
    (aguser.phone === '' && aguser.isAgragaUser === "No") ) {
      const mandate = 'Field is mandatory'
      if (aguser.firstName === '') {
        setFirstNameError(mandate)
      }
      if (aguser.email === '') {
        setEmailError(mandate)
      }
      if (aguser.phone === '') {
        //
        if(( aguser.country !== 'India') && aguser.isAgragaUser === "Yes"){
          setPhoneError("")
        } 
        if(aguser.country == "India") {
          configStore.setModalMessage(`Please enter the valid mobile number`)
          setPhoneError(mandate)
        }
        //  if (aguser.country == "Hong Kong"){
        //    setPhoneError("")
        //  }
        //  setPhoneError(mandate)
      } else {
        setPhoneError("")
      }
      if (aguser.role === '') {
        setRoleError(mandate)
      }
    } else if ((!aguser.email.includes('@agraga.com') && RoleUtils.isUserAuthorized(PERMISSIONS.CREATE.NEW_CUSTOMER_USER))) {
      if (aguser.email.indexOf('@') < 0) {
        configStore.setModalMessage(`Invalid email ${aguser.email}`)
        return
      }

      if (!aguser.role) {
        configStore.setModalMessage(`Role need to be selected || Check the email address`)
        return
      }
       

      if (aguser.status === 'NEWREG' && aguser.password === '') {
        const chars = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ"
        const passwordLength = 12
        let password = ""
        for (var i = 0;i <= passwordLength;i++) {
          var randomNumber = Math.floor(Math.random() * chars.length)
          password += chars.substring(randomNumber, randomNumber + 1)
        }
        aguser.password = password
        aguser.status = 'NEWREG'
      }
      /*if (aguser.password === password) {*/
      if (props.mode === 'new') AguserApi.createUser((saved) => {
        if (saved) setShowInvite(true)
      })
      else AguserApi.saveUser()
      AgmodalStore.apiCall = true
      routerStore.agusersCurrentView = "list"
    } else if ((aguser.email.includes('@agraga.com') && RoleUtils.isUserAuthorized(PERMISSIONS.CREATE.NEW_AGRAGAUSER))) {
      if (aguser.email.indexOf('@') < 0) {
        configStore.setModalMessage(`Invalid email ${aguser.email}`)
        return
      }
      if (aguser.isAgragaUser === 'Yes' && !aguser.vertical) {
        configStore.setModalMessage(`Vertical need to be selected`)
        return
      }
      if (!aguser.role) {
        configStore.setModalMessage(`Role need to be selected || Check the email address`)
        return
      }
      if (aguser.vertical != 'Central') {
        if (aguser.isAgragaUser === 'Yes' && !aguser.division) {
          configStore.setModalMessage(`Division need to be selected`)
          return
        }
        if (aguser.isAgragaUser === 'Yes' && (!aguser.zonal && !aguser.regional && !aguser.branch && !aguser.national) && aguser.division != 'National') {
          configStore.setModalMessage(`Branch/Zone/Region need to be selected`)
          return
        }
        // if (aguser.isAgragaUser && !aguser.reportingManager) {
        //     configStore.setModalMessage(`Please select the Reporting Manager`)
        //     return
        // }

      }

      if (aguser.status === 'NEWREG' && aguser.password === '') {
        const chars = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ"
        const passwordLength = 12
        let password = ""
        for (var i = 0;i <= passwordLength;i++) {
          var randomNumber = Math.floor(Math.random() * chars.length)
          password += chars.substring(randomNumber, randomNumber + 1)
        }
        aguser.password = password
        aguser.status = 'NEWREG'
      }
      /*if (aguser.password === password) {*/
      if (props.mode === 'new') AguserApi.createUser((saved) => {
        if (saved) setShowInvite(true)
      })
      else AguserApi.saveUser()


    }
    else {
      configStore.setModalMessage(`Create user permission needed`)
      return
    }
    /* } else configStore.setModalMessage('Passwords do not match')*/


  }
  
  const handleSubmit = (e)=>{
    e.preventDefault()
    if(aguser.vertical === 'Cross-Border' && aguser.department !== 'Admin'){
      aguser.AdminSalesVertical = []
     }

     if(aguser.vertical === 'Cross-Border' && aguser.department !== 'Relationship Manager'){
      aguser.RMSalesVertical = []
     }
      // If all validations pass, submit the form
      console.log(aguser, "submitting")
      const mandate = 'Field is mandatory'
      if (aguser.isAgragaUser==="Yes") {
        if (aguser.firstName === '') {
          setFirstNameError(mandate)
         return configStore.setModalMessage(`Please enter a valid First Name`)
        }
         if(aguser.firstName.length > 1){
          if (/^[A-Z0-9a-z\s]+$/.test(aguser.firstName) && aguser.firstName.length >= 1 && aguser.firstName.length <= 25) {
            setFirstNameError('')
          } else {
            setFirstNameError('Please enter a valid first name with 1 to 25 characters (letters and spaces allowed).')
          return  configStore.setModalMessage(`Please enter a valid first name with 1 to 25 characters (letters and spaces allowed).`)
          }
        }
        if (aguser.email === '') {
          setEmailError(mandate)
          return configStore.setModalMessage(`Please enter a valid email ID`)
         
        }
        if (aguser.email.length > 1) {
          aguser.email = aguser.email.trim().replace(/ /g, "").toLowerCase()
    
          if (/^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(aguser.email)) {
            setEmailError("")
          } else {
            setEmailError("Please enter a valid email ID")
            return configStore.setModalMessage(`Please enter a valid email ID`)
          }
          console.log(aguser.email.includes('@agraga.com'),RoleUtils.isUserAuthorized(PERMISSIONS.CREATE.NEW_AGRAGAUSER));
          if ((!aguser.email.includes('@agraga.com') && RoleUtils.isUserAuthorized(PERMISSIONS.CREATE.NEW_AGRAGAUSER))) {
           return configStore.setModalMessage(`Please enter a valid Agraga email ID`)
          }
          if (aguser.email.indexOf('@') < 0) {
            configStore.setModalMessage(`Invalid email ${aguser.email}`)
            return
          }
        }
        if (aguser.phone === '' && aguser.country == "India") {
          setPhoneError(mandate)
          return configStore.setModalMessage(`Please enter the valid mobile number`)
        }
        else {
          if (/^\d{10}$/.test(aguser.phone) && aguser.country == "India") {
            setPhoneError("")      
          }
          else {
            if(( aguser.country !== 'India')){
              setPhoneError("")
            } 
            if(aguser.country == "India") {
              setPhoneError(mandate)
              return configStore.setModalMessage(`Please enter the valid mobile number`)
            }
          }

        }
      


           
           if (!aguser.vertical) {
            configStore.setModalMessage(`Vertical need to be selected`)
            return
          }
        if (aguser?.role?.length === 0) {
          return configStore.setModalMessage(`Please select atleast one role`)
        }

        if(aguser.vertical==='Cross-Border' && aguser.department === 'Relationship Manager' &&aguser.RMSalesVertical?.length===0){
          return configStore.setModalMessage(`Please select atleast one RM Sales Vertical`)
        }

        if(aguser.vertical==='Cross-Border' && aguser.department === 'Admin' && aguser.AdminSalesVertical?.length===0 && aguser.status !== 'INACTIVE'){
          return configStore.setModalMessage(`Please select atleast one Admin Sales Vertical`)
        }
      if (aguser.vertical != 'Central') {
          if (aguser.isAgragaUser === 'Yes' && !aguser.division) {
            configStore.setModalMessage(`Division need to be selected`)
            return
          }
          if (aguser.isAgragaUser === 'Yes' && (!aguser.zonal && !aguser.regional && !aguser.branch && !aguser.national) && aguser.division != 'National') {
            configStore.setModalMessage(`Branch/Zone/Region need to be selected`)
            return
          }
          // if (aguser.isAgragaUser && !aguser.reportingManager) {
          //     configStore.setModalMessage(`Please select the Reporting Manager`)
          //     return
          // }
  
        }
        if (aguser.status === 'NEWREG' && aguser.password === '') {
          const chars = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ"
          const passwordLength = 12
          let password = ""
          for (var i = 0;i <= passwordLength;i++) {
            var randomNumber = Math.floor(Math.random() * chars.length)
            password += chars.substring(randomNumber, randomNumber + 1)
          }
          aguser.password = password
          aguser.status = 'NEWREG'
        }
        if (props.mode === 'new') AguserApi.createUser((saved) => {
          if (saved) setShowInvite(true)
        })
        else AguserApi.saveUser()
      }
      else if(aguser.isAgragaUser==="No"){
        // 
        if (aguser.firstName === '') {
          setFirstNameError(mandate)
         return configStore.setModalMessage(`Please enter a valid First Name`)
        }
         if(aguser.firstName.length > 1){
          if (/^[A-Z0-9a-z\s]+$/.test(aguser.firstName) && aguser.firstName.length >= 1 && aguser.firstName.length <= 25) {
            setFirstNameError('')
          } else {
            setFirstNameError('Please enter a valid first name with 1 to 25 characters (letters and spaces allowed).')
          return  configStore.setModalMessage(`Please enter a valid first name with 1 to 25 characters (letters and spaces allowed).`)
          }
        }
        if (aguser.email === '') {
          setEmailError(mandate)
          return configStore.setModalMessage(`Please enter a valid email ID`)
         
        }
        if (aguser.email.length > 1) {
          aguser.email = aguser.email.replace(/ /g, "").toLowerCase()
    
          if (/^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(aguser.email)) {
            setEmailError("")
          } else {
            setEmailError("Please enter a valid email ID")
            return configStore.setModalMessage(`Please enter a valid email ID`)
          }
          console.log(aguser.email.includes('@agraga.com'),RoleUtils.isUserAuthorized(PERMISSIONS.CREATE.NEW_CUSTOMER_USER));
          if ((aguser.email.includes('@agraga.com') && RoleUtils.isUserAuthorized(PERMISSIONS.CREATE.NEW_CUSTOMER_USER))) {
           return configStore.setModalMessage(`Please enter a valid email ID`)
          }
          if (aguser.email.indexOf('@') < 0) {
            configStore.setModalMessage(`Invalid email ${aguser.email}`)
            return
          }
        }
        if (aguser.phone === '') {
          setPhoneError(mandate)
          return configStore.setModalMessage(`Please enter the valid mobile number`)
        }

      
        if (aguser?.role?.length === 0) {
          return configStore.setModalMessage(`Please enter the valid role`)
        }
        if (aguser.status === 'NEWREG' && aguser.password === '') {
          const chars = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ"
          const passwordLength = 12
          let password = ""
          for (var i = 0;i <= passwordLength;i++) {
            let randomNumber = Math.floor(Math.random() * chars.length)
            password += chars.substring(randomNumber, randomNumber + 1)
          }
          aguser.password = password
          aguser.status = 'NEWREG'
        }
        /*if (aguser.password === password) {*/
        if (props.mode === 'new') AguserApi.createUser((saved) => {
          if (saved) setShowInvite(true)
        })
        else AguserApi.saveUser()
      }
  }

  const randomBytes = (count) => {
    const result = Array(count)
    for (let i = 0;i < count;++i) {
      result[i] = Math.floor(256 * Math.random())
    }
    return result
  }

    const bin2hex = () =>
    {
    let bin = randomBytes(32)
      var i = 0, l = bin.length, chr, hex = ''
    
      for (i; i < l; ++i)
      {
    
        chr = bin[i].toString(16)
    
        hex += chr.length < 2 ? '0' + chr : chr
    
      }
    
      return hex
    
    }
  const getType = () => {
    if (aguser.branch) return aguser.branch
    if (aguser.zonal) return aguser.zonal
    if (aguser.regional) return aguser.regional
  }

  const salesVerticalOptions = [
    'Automotive',
    'Pharma',
    'Renewable Energy',
    'New Age Enterprise',
    'Market Place',
    'MSME',
    'Enterprise',
    'Contract Manufacturer'
  ];

  const cbAdminSalesVertical = ['Automotive', 'Pharma', 'Renewable Energy', 'New Age Enterprise', 'Market Place', 'MSME', 'Enterprise','Contract Manufacturer'];

  const handleClick = () => {
    aguser.vertical = ""
    aguser.role = []
    aguser.division = ""
    aguser.branch = ""
    aguser.regional = ""
    aguser.zonal = ""
    aguser.national = ""
    aguser.reportingManager = ""
    aguser.companyName = ''
    // const newval = checked1 === "Yes" ? "No" : "Yes"
    const newval = checked1 === "Yes"
    setChecked(newval)
    aguser.isAgragaUser = newval
    aguser.email = ""
    aguser.country = "India"
    aguser.phonePrefix = "+91"
    aguser.phone = ""
    const contryList = ServerLists.countryNameList.filter(d => targetCountries.includes(d))
    if (checked1 === "No") {
      setCountryList(contryList)
    }
  }
    var newcountryarr = []

    const [selectedRole, setSelectedRole] = useState('');
    const [selectedOptions, setSelectedOptions] = useState([]);

    const rolesData = [
      { label: 'Admin', role: 'Admin', selection: 'single', options: [] },
      { label: 'Branch Head', role: 'Branch Head', selection: 'single', options: [] },
      {
        label: 'Procurement',
        role: 'Procurement',
        selection: 'multiple',
        options: [
          { label: 'Procurement-LCL', role: 'Procurement-LCL' },
          { label: 'Procurement-FCL', role: 'Procurement-FCL' },
          { label: 'Procurement-Air', role: 'Procurement-Air' },
        ],
      },
    ];
  
    const handleRoleChange = (event) => {
      const value = event.target.value;
      setSelectedRole(value);
      const selectedRoleData = rolesData.find((roleData) => roleData.role === value);
      if (selectedRoleData && selectedRoleData.selection === 'multiple') {
        setSelectedOptions([]);
      }
    };
  
    const handleOptionsChange = (event) => {
      // const options = Array.from(event.target.selectedOptions, (option) => option.value);
      // // setSelectedOptions(options);
      // setSelectedOptions([...selectedOptions, options]);

      const { value } = event.target;
    if (selectedOptions.includes(value)) {
      setSelectedOptions(selectedOptions.filter(option => option !== value));
    } else {
      setSelectedOptions([...selectedOptions, value]);
    }
    };

    
    if (!aguser || !aguser.phonePrefix || aguser.phonePrefix.length <= 0) aguser.phonePrefix = '+91'
    const domain = loginStore.email.split('@')[1]

    const roleOptions = {
      'Procurement':[{ value: ROLES.CB_PROCUREMENT_SUBROLES.LCL_Procurement, label: ROLES.CB_PROCUREMENT_SUBROLES.LCL_Procurement },
                      { value: ROLES.CB_PROCUREMENT_SUBROLES.FCL_Procurement, label: ROLES.CB_PROCUREMENT_SUBROLES.FCL_Procurement},
                      { value: ROLES.CB_PROCUREMENT_SUBROLES.AIR_Procurement, label: ROLES.CB_PROCUREMENT_SUBROLES.AIR_Procurement }],
      'Admin':[{value: ROLES.CB.ADMIN, label: ROLES.CB.ADMIN}],
      'Branch Head':[{value: ROLES.CB.BRANCH_HEAD, label: ROLES.CB.BRANCH_HEAD}],
      'Relationship Manager':[{value: ROLES.CB.RELATIONSHIP_MANAGER, label: ROLES.CB.RELATIONSHIP_MANAGER}],
      'Customer Success':[{value: ROLES.CB.CUSTOMER_SUCCESS, label: ROLES.CB.CUSTOMER_SUCCESS}],
      'Agraga Admin':[{value: ROLES.CENTRAL.AGRAGA_ADMIN, label: ROLES.CENTRAL.AGRAGA_ADMIN}],
      'Tech & Product':[{value: ROLES.CENTRAL.TECHANDPRODUCT, label: ROLES.CENTRAL.TECHANDPRODUCT}],
      'BPO':[{value: ROLES.CENTRAL.BPO, label: ROLES.CENTRAL.BPO}],
      'Database':[{value: ROLES.CENTRAL.DATABASE, label: ROLES.CENTRAL.DATABASE}],
      'HR':[{value: ROLES.CENTRAL.HR, label: ROLES.CENTRAL.HR}],
      'Finance':[{value: ROLES.CENTRAL.FINANCE, label: ROLES.CENTRAL.FINANCE}],
      'Central Operations':[
        {value: ROLES.CENTRAL_OPERATION_SUBROLES.OCEAN_EXPORT_COORDINATION, label: ROLES.CENTRAL_OPERATION_SUBROLES.OCEAN_EXPORT_COORDINATION},
        {value: ROLES.CENTRAL_OPERATION_SUBROLES.OCEAN_IMPORT_COORDINATION, label: ROLES.CENTRAL_OPERATION_SUBROLES.OCEAN_IMPORT_COORDINATION},
        {value: ROLES.CENTRAL_OPERATION_SUBROLES.AIR_EXPORT_COORDINATION, label: ROLES.CENTRAL_OPERATION_SUBROLES.AIR_EXPORT_COORDINATION},
        {value: ROLES.CENTRAL_OPERATION_SUBROLES.AIR_IMPORT_COORDINTAION, label: ROLES.CENTRAL_OPERATION_SUBROLES.AIR_IMPORT_COORDINTAION},
        {value: ROLES.CENTRAL_OPERATION_SUBROLES.OCEAN_EXPORT_DOCUMENTATION, label: ROLES.CENTRAL_OPERATION_SUBROLES.OCEAN_EXPORT_DOCUMENTATION},
        {value: ROLES.CENTRAL_OPERATION_SUBROLES.OCEAN_IMPORT_DOCUMENTATION, label: ROLES.CENTRAL_OPERATION_SUBROLES.OCEAN_IMPORT_DOCUMENTATION},
        {value: ROLES.CENTRAL_OPERATION_SUBROLES.AIR_EXPORT_DOCUMENTATION, label: ROLES.CENTRAL_OPERATION_SUBROLES.AIR_EXPORT_DOCUMENTATION},
        {value: ROLES.CENTRAL_OPERATION_SUBROLES.AIR_IMPORT_DOCUMENTAION, label: ROLES.CENTRAL_OPERATION_SUBROLES.AIR_IMPORT_DOCUMENTAION},
        {value: ROLES.CENTRAL_OPERATION_SUBROLES.OCEAN_VISIBILITY, label: ROLES.CENTRAL_OPERATION_SUBROLES.OCEAN_VISIBILITY},
        {value: ROLES.CENTRAL_OPERATION_SUBROLES.AIR_VISIBILITY, label: ROLES.CENTRAL_OPERATION_SUBROLES.AIR_VISIBILITY},
        {value: ROLES.CENTRAL_OPERATION_SUBROLES.DOMESTIC_TRANSPORT, label: ROLES.CENTRAL_OPERATION_SUBROLES.DOMESTIC_TRANSPORT},
        {value: ROLES.CENTRAL_OPERATION_SUBROLES.VENDOR_MANAGEMENT, label: ROLES.CENTRAL_OPERATION_SUBROLES.VENDOR_MANAGEMENT},
        {value: ROLES.CENTRAL_OPERATION_SUBROLES.VEHICLE_MANAGEMENT, label: ROLES.CENTRAL_OPERATION_SUBROLES.VEHICLE_MANAGEMENT},
        {value: ROLES.CENTRAL_OPERATION_SUBROLES.DRIVER_MANAGEMENT, label: ROLES.CENTRAL_OPERATION_SUBROLES.DRIVER_MANAGEMENT},

      ],

      'Product Adoption':[{value: ROLES.CENTRAL.PRODUCT_ADOPTION, label: ROLES.CENTRAL.PRODUCT_ADOPTION}],
      'Relationship Manager (Demand)':[{value: ROLES.DT.RELATIONSHIP_MANAGER_DEMAND, label: ROLES.DT.RELATIONSHIP_MANAGER_DEMAND}],
      'DT Operations':[{value: ROLES.DT.DT_OPERATIONS, label: ROLES.DT.DT_OPERATIONS}],
      'Relationship Manager (Supply)':[{value: ROLES.DT.RELATIONSHIP_MANAGER_SUPPLY, label: ROLES.DT.RELATIONSHIP_MANAGER_SUPPLY}],
      'Invoice Lead':[{value: ROLES.DT.INVOICE_LEAD, label: ROLES.DT.INVOICE_LEAD}],
      'RM (Supply),Operations & Invoicing':[{value: ROLES.DT.RMSUPPLY_OPERATIONSANDINVOICING, label: ROLES.DT.RMSUPPLY_OPERATIONSANDINVOICING}],
    }


    const handleSalesChange = (event) => {
      aguser.RMSalesVertical = []
      aguser.RMSalesVertical.push(event.target.value)
    };

    function toggleValueInArray(array, value) {
      if (array.includes(value)) {
        return array.filter(item => item !== value);
      } else {
        return [...array, value];
      }
    }


    const handleToggleSwitch = (value,index) => {
      aguser.role = toggleValueInArray(aguser.role,value)
      setRoleChecked(prevState => ({
        ...prevState,
        [index]: prevState[index] === 'Yes' ? 'No' : 'Yes'
      }));
    };

    const handleSalesToggleSwitch = (index) => {   
      setSalesChecked(prevState => ({
        ...prevState,
        [index]: prevState[index] === 'Yes' ? 'No' : 'Yes'
      }));
    };
    
    
    const currentRoleOptions = props.mode === 'edit' ? roleOptions[aguser.department] : roleOptions[department];
 
    return (
        <>
            <h1 className="title">{title}</h1>
            <div className="columns">
                <$cr n='2'>First Name *</$cr>
                <$cg n='3'>
                    <input className="input" style={firstNameError ? { borderColor: "red" } : null} value={aguser.firstName} ref={ref}
                        onChange={(e) => handleChange(e,'firstName')}/>
          <p style={{ color: "red" }}>{firstNameError}</p>
                </$cg>
                <$cr n='2'>Last Name</$cr>
                <$cg n='3'>
                    <input className="input" value={aguser.lastName}
                        onChange={(e) => handleChange(e,'lastName')} />
                </$cg>
            </div>
            <div className="columns">
              <div className="column is-2" style={{ textAlign: "right" }}>
                Is Agraga User?
              </div>
              <$cg n="3">
                <div className="columns" disabled={true} style={props.mode == 'new' ? { margin: "3px" } : { pointerEvents: 'none', opacity: 0.6, margin: "3px" }}>
                  {/* <div onClick={handleClick}> */}
                    {checked1 === "Yes" ? (
                      <img height="36px" width="36px" src={on}></img>
                    ) : (
                      <img height="36px" width="36px" src={off}></img>
                    )}
                  {/* </div> */}
                </div>
              </$cg>

              {aguser.isAgragaUser === "No" && (
                <>
                <$cr n='2'>Company</$cr>
                <$cg n='3'>
                    <input className="input" value={aguser.companyName}
                        onChange={(e) => handleChange(e,'companyName')} />
                </$cg>
                </>
            )}
            </div>
            {/*<div className="columns">
                <$cr n='2'>Password</$cr>
                <$cg n='3'>
                    <input className="input" type='password' value={aguser.password}
                        onChange={(e) => aguser.password = e.target.value} />
                </$cg>
                <$cr n='2'>Re-enter Password</$cr>
                <$cg n='3'>
                    <input className="input" type='password' value={password}
                        onChange={(e) => setPassword(e.target.value)} />
                </$cg>
    </div>*/}
            <div className="columns">
                <$cr n='2'>Email *</$cr>
                <$cg n='3'>
          <input
            className="input"
            value={aguser.email}
            style={emailError ? { borderColor: "red" } : null}
            onChange={(e) => handleChange(e, "email")}
            disabled={props.mode !== "new"}
            onBlur={(e) => handleBlur(e, "email")}
          />
          <p style={{ color: "red" }}>{emailError}</p>
        </$cg>


              <$cr n="2">Country</$cr>
              <$cg n="3">
                    <select className="select is-fullwidth" value={aguser.country}
                        onChange={(e) => {
                          aguser.AdminSalesVertical =[]
                          aguser.RMSalesVertical =[]
                          aguser.department=""
                          setPhoneError("")
                            aguser.phone = ""
                            aguser.country = e.target.value
                            const prefix = ServerLists.countryList.filter(e => e.name === aguser.country).map(e => e.dialCode)
                            aguser.phonePrefix = prefix[0]
                            if(aguser.country !== "India" && aguser.isAgragaUser === "Yes"){
                              aguser.vertical = VERTICALS.CROSS_BORDER
                              aguser.role = [ROLES.CB.ADMIN]
                              aguser.division = DIVISION.NATIONAL
                            } else {
                              aguser.vertical = ""
                              aguser.role = []
                              aguser.division = ""
                            }
                        }} 
                        disabled={editMode() && aguser.isAgragaUser === "No"}
                        >
                          <option disabled selected={aguser.isAgragaUser==="Yes"}>Select Country</option>
                        {aguser.isAgragaUser === "No"?ServerLists.countryNameList.filter((e) => e.trim() !== "").map((e) => (<option value={e} selected={e===aguser.country}>{e}</option>)):
                        CountryList.filter((e) => e.trim() !== "").map((e) => (<option value={e} selected={e===aguser.country}>{e}</option>))}
                    </select>
                </$cg>
            </div>
            <div className="columns">
                <$cr n='2'>Phone *</$cr>
                <$cg n='3'>
          <div style={{ display: "flex", alignItems: "center" }}>
            <span style={{ paddingRight: "3px" }}>
              <strong>{aguser?.phonePrefix}</strong>
            </span>
            <input
              className="input"
              value={
                aguser?.country === "India"
                  ? aguser?.phone?.substring(0, 10)
                  : aguser?.phone?.substring(0, 15)
              }
              style={ phoneError ? { borderColor: "red" } : null}
              type="number"
              onChange={(e) => handleChange(e, "phone")}
              onBlur={(e) => handleBlur(e, "phone")}
              onKeyDown={(e) =>
                ["e", "E", "+", "-"].includes(e.key) &&
                e.preventDefault()
              }
            />
          </div>
          <p style={{ color: "red" }}>{phoneError}</p>
        </$cg>
        {(checked1 === "Yes" && isEmailValid(aguser.email)) && (
          <>
            <$cr n="2">Vertical*</$cr>
            <$cg n="3">
              {aguser.country==="India" && checked1 ==="Yes"?
              <select
                className="select"
                value={aguser.vertical || "default"}
                onChange={(e) => {
                  // aguser.AdminSalesVertical =[]
                  aguser.RMSalesVertical =[]
                  aguser.department=""
                  aguser.role = []
                  aguser.division = ""
                  aguser.reportingManager = ""
                  aguser.branch = ""
                  aguser.regional = ""
                  aguser.zonal = ""
                  aguser.national = ""
                  aguser.vertical = e.target.value
             if(props.mode == 'edit'||(props.mode == 'new' && department)){
                    setRoleChecked({})
                  const roleList= [];
                      // if(checked1 === "No" && aguser.email && !aguser.email.includes('@agraga.com')) {
                      //   aguser.department =   Object.values(CUSTOMERROLES)[0]
                      // }
                      if(aguser.vertical === VERTICALS.CROSS_BORDER) {
                        aguser.department = Object.values(ROLES.CB)[0]
                      }
                      if(aguser.vertical === VERTICALS.CENTRAL) {
                      aguser.department = Object.values(ROLES.CENTRAL)[0]
                      }
                      if(aguser.vertical === VERTICALS.DOMESTIC_TRANSPORT) {
                      aguser.department = Object.values(ROLES.DT)[0]
                      }
                       if(aguser.vertical === VERTICALS.CB_DT) {
                        aguser.department = Object.values(ROLES.CB_DT)[0]
                      }
                      const initialCheckedState = {};
                      roleOptions[aguser.department].forEach((data, index) => {
                        initialCheckedState[index] = 'Yes';
                        roleList.push(data.value)
                      });
                      setRoleChecked(initialCheckedState);
                      setDepartment(aguser.department)
                      aguser.role.push(...roleList);
                    }
                }}
                style={{ width: "160px" }}
              >
                <option value="default" disabled>
                  Select Vertical
                </option>
                {Object.keys(VERTICALS).map((key) => (
                  <option value={VERTICALS[key]}>{VERTICALS[key]}</option>
                ))}
              </select>
              :
                            <select
                            className="select"
                            value={aguser.vertical="Cross-Border"}
                            style={{ width: "160px" }}
                            disabled={true}
                          >
                            {Object.keys(VERTICALS).map((key) => (
                              <option value={VERTICALS[key]==="Cross-Border"}>{VERTICALS[key]}</option>
                            ))}
                          </select>}
                </$cg>
                  </>
                )}
            </div>
         <div className="columns">
                {(aguser.isAgragaUser === "Yes" && isEmailValid(aguser.email) && aguser.vertical !== '') &&
                <>
                  <$cr n='2'>Department *</$cr>
                <$cg n='3'>
      
                  <select
                    className="select"
                    // value={aguser.department ? aguser.department : "default"}
                    value ={aguser.department || (aguser.country !== 'India' ? 'Admin' : 'default')}
                    disabled={(checked1 === "Yes" && isEmailValid(aguser.email) &&aguser.isAgragaUser==="Yes" && aguser.country !=="India" && aguser.role)}
                    onChange={(e) => {
                      // aguser.AdminSalesVertical =[]
                      aguser.RMSalesVertical =[]
                      handleChange(e, "role")
                        if ( aguser.vertical === 'Cross-Border' && e.target.value === ROLES.CB.RELATIONSHIP_MANAGER && salesVerticalOptions.length > 0) {
                        aguser.RMSalesVertical.push(salesVerticalOptions[0]);
                      }
                      const roleList= [];
                      setDepartment(e.target.value)
                      const initialCheckedState = {};
                        roleOptions[e.target.value].forEach((data, index) => {
                          initialCheckedState[index] = 'Yes';
                          roleList.push(data.value)
                        });
                        setRoleChecked(initialCheckedState);                    

                      if (!Array.isArray(aguser.role)) {
                        aguser.role = [];
                      }

                      aguser.role.push(...roleList);
                      aguser.department =  e.target.value;               

                      // Reporting Manager service call
                      rmOnChnage(aguser.role)

                      // Reset other related fields
                      aguser.zonal = ""
                      aguser.branch = ""
                      aguser.regional = ""
                      aguser.division = ""
                      aguser.reportingManager = ""
                    }}
                    style={{ width: "240px" }}
                  >
                    <option value="default" disabled>
                      Select Department
                    </option>
                    {
                      (checked1 === "No" && aguser.email && !aguser.email.includes('@agraga.com')) &&
                      Object.keys(CUSTOMERROLES).map((key) => (
                        <option key={key} value={CUSTOMERROLES[key]}>
                          {CUSTOMERROLES[key]}
                        </option>
                      ))}
                    {aguser.vertical === VERTICALS.CROSS_BORDER &&
                      Object.keys(ROLES.CB).map((key) => (
                        <option key={key} value={ROLES.CB[key]}>
                          {ROLES.CB[key]}
                        </option>
                      ))}
                    {aguser.vertical === VERTICALS.CENTRAL &&
                      Object.keys(ROLES.CENTRAL)?.map((key) => (
                        <option key={key} value={ROLES.CENTRAL[key]}>
                          {ROLES.CENTRAL[key]}
                        </option>
                      ))}
                    {aguser.vertical === VERTICALS.DOMESTIC_TRANSPORT &&
                      Object.keys(ROLES.DT).map((key) => (
                        <option key={key} value={ROLES.DT[key]}>
                          {ROLES.DT[key]}
                        </option>
                      ))}
                         {aguser.vertical === VERTICALS.CB_DT &&
                      Object.keys(ROLES.CB_DT).map((key) => (
                        <option key={key} value={ROLES.CB_DT[key]}>
                          {ROLES.CB_DT[key]}
                        </option>
                      ))}
                      {(aguser?.country!=='India')&& (<option value='Admin'selected>
                        Admin
                      </option>)}
                   </select> 
                  </$cg></>}
                 
                  
                {/* {
                  (aguser.role)
                } */}
                {/* {(aguser.isAgragaUser === "Yes" && isEmailValid(aguser.email)) && aguser.vertical && aguser.role( */}
                      {/* )} */}
              
          

             
                {(aguser.isAgragaUser === "Yes" && isEmailValid(aguser.email)) && aguser.vertical && (aguser.role && aguser.role.length > 0) && (
                  <>
                    <$cr n="2">Division*</$cr>
                    <$cg n="3">
                    {aguser.country==="India" && aguser.isAgragaUser ==="Yes"?
                      <select
                        className="select"
                        value={aguser.division || "default"}
                        disabled={
                          (aguser.role.includes(ROLES.CB.BRANCH_HEAD) && aguser.division === DIVISION.BRANCH) || (aguser.division === DIVISION.NATIONAL && aguser.vertical === VERTICALS.CENTRAL)
                        }
                        onChange={(e) => {
                          aguser.zonal = ""
                          aguser.branch = ""
                          aguser.regional = ""
                          aguser.division = e.target.value
                          aguser.reportingManager = ""

                        const initialSalesCheckedState = {};
                        cbAdminSalesVertical.forEach((data, index) => {
                            initialSalesCheckedState[index] = 'No';
                        });
                        setSalesChecked(initialSalesCheckedState);
                          // if (aguser.division != 'National') {
                          divisonOnChnage()
                          // }
                          // else {
                          //     rmOnChnage()
                          // }
                        }}
                        style={{ width: "160px" }}
                        readOnly={true}
                       >
                        {
                        (aguser.vertical == VERTICALS.CENTRAL &&
                          (aguser.role.some(role => Object.values(ROLES.CENTRAL).includes(role)) || aguser.department == 'Central Operations')
                        ) ? 
                        (
                          <>
                            {(aguser.division = "National")}
                            <option value="default">National</option>
                          </>
                        ) : 
                        
                        
                            
                        (aguser.vertical == VERTICALS.DOMESTIC_TRANSPORT && isBranchHeadRole) ||
                          (aguser.vertical == VERTICALS.CROSS_BORDER && isBranchHeadRole) ||
                          // (aguser.vertical == VERTICALS.CROSS_BORDER && aguser.role == ROLES.CB.PROCUREMENT)||
                          (aguser.vertical == VERTICALS.CB_DT && isBranchHeadRole) ||
                          (aguser.vertical == VERTICALS.DOMESTIC_TRANSPORT &&
                            aguser.role.includes(ROLES.DT.RMSUPPLY_OPERATIONSANDINVOICING)) ? 
                        (
                          <>
                            {(aguser.division = "Branch")}
                            <option value="default">Branch</option>
                          </>
                        ) :
                         (aguser.vertical == VERTICALS.DOMESTIC_TRANSPORT && isAdminRole) ||
                          (aguser.vertical == VERTICALS.CROSS_BORDER && isAdminRole) || 
                            // (aguser.vertical == VERTICALS.CROSS_BORDER && aguser.role == ROLES.CB.PROCUREMENT ) ||
                            // (!aguser.vertical == "Cross-Border" && !aguser.role == "Procurement") ||
                            (aguser.vertical == VERTICALS.CB_DT && isAdminRole) ? 
                            (
                              <>
                                <option value="default" disabled>
                                  Select Division
                                </option>
                                {Object.keys(DIVISION).map((key) => {
                                  if ((aguser.vertical === "Cross-Border" && DIVISION[key] === "Regional")
                                  // ||(aguser.vertical == "Cross-Border" && aguser.role == "Procurement")
                                   // (aguser.vertical === "CB-DT" && DIVISION[key] === "Regional")|| 
                                  || (!isAdminRole && DIVISION[key] === "National") 
                                || DIVISION[key] === 'Branch') {
                                    return null
                                  }
                                  return (
                                    
                                    <option value={DIVISION[key]} key={key}>
                                      
                                      {DIVISION[key]}
                                    </option>
                                  )
                                })}
                              </>
                            ) :
                            (
                              <>
                                <option value="default" disabled>
                                  Select Division
                                </option>
                                {Object.keys(DIVISION).map((key) => {
                                  
                                  if ((aguser.vertical === "Cross-Border" && DIVISION[key] === "Regional") 
                                  || (!(aguser.vertical === "Cross-Border") && DIVISION[key] === "National")
                                ) {
                                    return null
                                  }
                                  return (
                                    
                                    <option value={DIVISION[key]} key={key}>
                                      
                                      {DIVISION[key]}
                                    </option>
                                  )
                                })}
                              </>
                            ) 
                            
                        }
                      </select>
                      :
                      <select
                      className="select"
                      value={aguser.division="National"}
                      disabled={checked1==="Yes"}
                      style={{ width: "160px" }} 
                    > 
  
                          <option value="National">National</option>

                    </select>
                      }
                    </$cg>
                  </>
                )} 
                
              </div>
            <div className="columns">
            {((((department !== '' && props.mode === 'new') || (aguser.department !== '' && editMode())) && isEmailValid(aguser.email) && aguser.vertical != '')  && (aguser?.department !== 'Admin' || aguser.vertical !== 'Cross-Border') &&  (aguser?.department !== 'Relationship Manager' || aguser.vertical !== 'Cross-Border') ) && (
              <>
                <div className="column is-2" style={{ textAlign: "right" }}>
                  Role*
                </div>
                <$cg n="3">
                  <div
                    className="columns"
                    style={{ margin: '3px', display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}
                  >
                    {
                  currentRoleOptions?.map((data, index) => (
                <label key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                  <input
                    type="checkbox"
                    checked={props.mode == 'edit'?aguser.role.includes(data.value):roleChecked[index] === 'Yes'}
                    onChange={() => handleToggleSwitch(data.value,index)}
                    style={{ display: 'none' }}
                    disabled={currentRoleOptions.length === 1}
                  />

                  <img
                      height="36px"
                      width="36px"
                      src={props.mode === 'edit' ? (aguser.role.includes(data.value) ? on : off) : (roleChecked[index] === 'Yes' ? on : off)}
                      alt={props.mode === 'edit' ? (aguser.role.includes(data.value) ? 'On' : 'Off') : (roleChecked[index] === 'Yes' ? 'On' : 'Off')}
                    />
                <span style={{ marginLeft: '8px' }}>{data.label}</span>
                </label>
              ))}
                  </div>
                </$cg>
              </>
            )}


    {(aguser.department === 'Admin' && aguser.vertical === 'Cross-Border' && aguser.country === 'India' &&(aguser.division === 'National' || (aguser.division === 'Zonal' && aguser.zonal != ''))) && (
              <>
                <div className="column is-2" style={{ textAlign: "right" }}>
                  Sales Vertical*
                </div>
                <$cg n="3">
                  <div
                    className="columns"
                    style={{ margin: '3px', display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}
                  >
                    {
                  cbAdminSalesVertical?.map((data, index) => (
                <label key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                  <input
                    type="checkbox"
                    onChange={() => {
                      handleSalesToggleSwitch(index)
                    }
                    }
                    style={{ display: 'none' }}
                  />
                  <img
                      height="36px"
                      width="36px"
                      src={salesChecked[index] === 'Yes' ? on : off}
                      alt={salesChecked[index] === 'Yes' ? 'On' : 'Off'}
                    />
                <span>{data}</span>
                </label>
                
))}
                  </div>
                </$cg>
              </>
            )}
  
  {(aguser.vertical === 'Cross-Border' && aguser.department === ROLES.CB.RELATIONSHIP_MANAGER && aguser.country === 'India' && ((aguser.division === 'Zonal' && aguser.zonal != '') || (aguser.division === 'Branch' && aguser.branch != '')||(aguser.division==='National'))) && (
            <>
              <div className="column is-2" style={{ textAlign: "right" }}>
                Sales Vertical*
              </div>
              <$cg n="3">
                <div>
                  {salesVerticalOptions.map((value, index) => (
                    <label key={index} style={{ display: 'block', marginBottom: '8px' }}>
                      <input type="radio" name="salesVertical" value={value} style={{marginRight:'20px'}} onChange={handleSalesChange} defaultChecked={props.mode === 'new' ? index === 0: (aguser.RMSalesVertical.length > 0)? aguser.RMSalesVertical[0] === value:index === 0}/>
                      {value}
                    </label>
                  ))}
                </div>
              </$cg>
            </>)}


                {(checked1 === "Yes" && isEmailValid(aguser.email)) &&
                  aguser.division &&
                  aguser.division != "National" &&
                  aguser.vertical != "Central" && (aguser.role && aguser.role.length > 0) && (
                    <>
                      {/* {aguser.division=='Zonal' && 
                      (aguser.division='Zone')} */}
                      <$cr n="2">{aguser.division == 'Zonal' ? 'Zone*' : (aguser.division == 'Regional') ? 'Region*' : aguser.division + '*'}</$cr>
                      <$cg n="3">
                        <select
                          className="select"
                          value={getType() || "default"}
                          onChange={(e) => {
                             
                             
                            const initialSalesCheckedState = {};
                            cbAdminSalesVertical.forEach((data, index) => {
                                initialSalesCheckedState[index] = 'No';
                            });
                            setSalesChecked(initialSalesCheckedState);

                            const division = aguser.division.toLowerCase()
                            aguser[division] = e.target.value
                            console.log(e.target.value, division, "rrrr")
                            aguser.reportingManager = ""
                          }}
                          style={{ width: "160px" }}
                        >
                          <option value="default" disabled>
                            Select
                          </option>
                          
                          {
                           branchesForTypes.length > 0 && branchesForTypes[0]?.branch
                            ? branchesForTypes.map((e) => {
                              if (e.hasBranchHead) {
                                return (
                                  <option value={e.branch} disabled>
                                    {e.branch}
                                  </option>
                                )
                              } else {
                                return <option value={e.branch}>{e.branch}</option>
                              }
                              
                            })
                            : 
                            
                            branchesForTypes.length > 0 &&branchesForTypes.map((e) =>
                           
                             (
                              
                              <option value={e}>{e}</option>
                            ))}
                        </select>
                      </$cg>
                    </>
                  )}
                {(checked1 === "Yes" && isEmailValid(aguser.email)) &&
                  aguser.division == "National" && !(aguser.division == 'National' && aguser.vertical == 'Cross-Border') && !(aguser.division == 'National' && aguser.vertical == 'Domestic Transport')&&!(aguser.division == 'National' && aguser.vertical == 'Domestic Transport') && !(aguser.division == 'National' && aguser.vertical == 'CB-DT') && (
                    <>
                      <$cr n="2">Reporting Manager</$cr>
                      <div style={{backgroundColor: 'white', border: '1px solid #efefef',height:'67px'}} className="column is-3 is-small">
                        <select
                          className="select"
                          value={aguser.reportingManager || "default"}
                          onChange={(e) => {
                            aguser.reportingManager = e.target.value
                          }}
                          style={{ width: "180px" }}
                        >
                          <option value="default" disabled>
                            Select Reporting Manager
                          </option>
                          {managersForAgusers.filter((e) => e.status === 'ACTIVE').map((e) => (
                            <option value={e._id}>
                              {e.firstName +
                                " " +
                                e.lastName +
                                "  " +
                                " ( " +
                                e?.email +
                                " ) "}
                            </option>
                          ))}
                        </select>
                      </div>
                    </>
                  )}
              </div>

      <div className="columns">
                      {props.mode !== "new" ? (
                      <>
                        <$cr n="2">Status</$cr>
                        <$cg n="3">
              <select
                className="select"
                value={aguser.status}
                onChange={(e) => (aguser.status = e.target.value)}
              >
              
                {/* <option value="ACCEPTED">ACCEPTED</option> */}
                {(!aguser.inviteSend && aguser.status !== undefined ) ? <>
                  <option value="NEWREG">NEWREG</option>

                </>: <>
              
                {
                  userdata.status === 'NEWREG' && 
                  <option value="NEWREG">NEWREG</option>
                }
                  
                <option value="ACTIVE">ACTIVE</option>
                <option value="INACTIVE">INACTIVE</option>
                </>

                }
             
              </select>
            </$cg>
          </>
        ) : (
          <></>
        )}
      </div>

      {checked1 === "No" && (
        <div className="columns">
          <$cr n="2">Client ID</$cr>
          <$cg n="8">
                        {aguser.client_id ? (aguser.client_id) : (<a className="button is-link"
                        onClick={() => {
                            aguser.client_id = bin2hex()
                        }}>Generate</a>
                    )}
                </$cg>
            </div>
      )}
      {checked1 === "No" && (
            <div className="columns">
                <$cr n='1'></$cr>
                <$c n='9'>
                    <UserEntity onValidationComplete={handleValidationComplete} />
                </$c>
            </div>
      )}
            <div className="columns">
                <$cr n='1'></$cr>
                <$cr n='8' style={{ display: "flex", justifyContent: "space-between" }}>
          <button
            class="button is-danger"
            onClick={() => {
              AgmodalStore.pageVisible = false; 
              AgmodalStore.apiCall = true;
            }}
          >
            Cancel
          </button>
          <button
            className="button is-primary"
            onClick={(e) => {
              handleSubmit(e)
            }}
          >
                        Save
                    </button>
                </$cr>
        {/* <$cr n="2"></$cr> */}
                <$cr n='0'>
                    {
                        (showInvite && aguser.status === 'NEWREG') ?
                            <button className="button is-info" onClick={() => {
                                if(!loading){
                                  setLoading(true)
                                aguser.inviteSend = true
                                let u = loginStore.userRec.aguserRec
                                let from = u.firstName + ' ' + u.lastName + ` <${process.env.REACT_APP_SUPPORTMAIL_ID}>`
                                let to = aguser.email
                                let subject = 'Invitation'
                                let message = `
                        <p>Please click on <<server>> to log-on to the system.</p>
                        <p>Use the following credentials to log on and update your profile</p>
                        <table>
                        <tbody>
                        <tr>
                        <td>User Name</td><td>${aguser.email}</td>
                        </tr>
                        <tr>
                        <td>Password</td><td>${aguser.password}</td>
                        </tr>
                        </tbody>
                        </table>
                        <p></p><p>Team Agraga</p>`

                                Api.sendClientMail(from, to, subject, message)
                                AguserApi.saveUser((data)=> {
                                 if(data){
                                  setLoading(false)
                                 }
                                })
                                }
                            }}> {!aguser.inviteSend ? 'Invite User' : 'Re-Invite User'}</button> :
                            <></>
                    }
                </$cr>
            </div>
        </>
    )
}