import { useSnapshot } from "valtio"
import {
    contractsStore, configStore
} from '../state/CommonState'
import Utils from "../util/Utils"
import { rtpStore } from "../pricing/pricing-store"
import { $c, $cr} from '../components/AgComponents'
import { GenericSwitch } from "../components/ag-switch"

export default function FCLChargeClientViewv3(props) {
    let { chargesName } = props
    if (!chargesName) chargesName = 'chargesList'
    useSnapshot(contractsStore)
    useSnapshot(rtpStore)
    let rec = contractsStore.current
    if (props.dummyContract) {
        rec = props.dummyContract
        //if (!contractsStore.current) contractsStore.current = props.dummyContract
    }
    //return <div>{JSON.stringify(rec)}</div>
    const containerNames = rec.containerList.map(e => e.containerType)
    let heading = 'On Your Account'
    switch (chargesName) {
        case 'shipperchargesList':
            heading = `On Shipper Account`
            break
        case 'consigneechargesList':
            heading = `On Consignee Account`
            break
    }

    const Tn = ({ i, name }) => {
        const e = props.data[i]
        let num = e[name]
        num = isNaN(num) ? 0.0 : Number(num)
        return num > 0 ? <span>{Utils.N(num, e.currency)}</span> : <></>
    }

    const displayRec = (e) => {
        for (let i = 0; i < containerNames.length; i++) {
            const c = containerNames[i]
            const wmKey = c + 'perWM'
            const shipmentKey = c + 'perShipment'
            const totalKey = c + 'total'
            const perWM = parseFloat(e[wmKey])
            const perShipment = parseFloat(e[shipmentKey])
            const total = parseFloat(e[totalKey])
            if (perWM > 0 || perShipment > 0 || total > 0) return true
        }
        return false
    }

    if (!props.data) {
        let listo = []
        let listc = []
        let lists = []
        let listname = 'chargesList'
        if (props.chargesName) listname = props.chargesName
        let charges = rec[listname]
        for (let i = 0; i < charges.length; i++) {
            let e = charges[i]
            e = { ...e }
            if (e.onaccount && e.onaccount.includes('Cons')) listc.push(e)
            else if (e.onaccount && e.onaccount.includes('Ship')) lists.push(e)
            else listo.push(e)
            // if (!props.chargesName) listo.push(e)
            // else if (props.chargesName == 'shipperchargesList') lists.push(e)
            // else listc.push(e)
        }
        let size = 12
        if (listc.length && lists.length) {
            size = 4
        } else if (listc.length) {
            size = 6
        } else if (lists.length) {
            size = 6
        }
        if (listc.length && !lists.length && !listo.length) {
            size = 12
        }
        if (!listc.length && lists.length && !listo.length) {
            size = 12
        }
        if (!listc.length && !lists.length && listo.length) {
            size = 12
        }

        /*return <>
            <p>
                {JSON.stringify(listo)}
            </p>
            <hr/>
            <p>
                {JSON.stringify(listc)}
            </p>
            <hr/>
            <p>
                {JSON.stringify(lists)}
            </p>
        </>*/
        return <>
            <div class="columns">
                {
                    (listo.length) &&
                    <div class={"column is-" + size}>
                        <FCLChargeClientViewv3 dummyContract={props.dummyContract} cwfVisible={props.cwfVisible}
                            data={listo} chargeType='On Your Account' chargesName={chargesName} /></div>
                }
                {
                    (listc.length) ? <div class={"column is-" + size}>
                        <FCLChargeClientViewv3 dummyContract={props.dummyContract} data={listc}
                            chargeType='On Consignee Account' chargesName="consigneechargesList" /></div> : ""
                }
                {
                    (lists.length) ? <div class={"column is-" + size}>
                        <FCLChargeClientViewv3 dummyContract={props.dummyContract} data={lists}
                            chargeType='On Shipper Account' chargesName="shipperchargesList" /></div> : ""
                }
            </div>
        </>
    }

    let shipperpdafilter = (e) => {
        if (rec.oih && rec.oih > 0 && rtpStore.shipperpda == ' ' && e.chargeDescription == 'Origin Inland Haulage') return false
        return true
    }

    let grandTotal = {}
    let grantTotaloftotals = 0.0
    rec.containerList.map(c => {
        grandTotal[c.containerType] = 0.0
    })
    props.data.filter(shipperpdafilter).map(e => {
        let erate = configStore.getExchangeRate(e.currency)
        if (!erate || erate === 0) erate = 1.0
        rec.containerList.map(c => {
            let totalField = c.containerType + 'total'
            let value = e[totalField] * erate
            grandTotal[c.containerType] += value
            grantTotaloftotals += value
        })
    })
    let counter = 1

    let checkPerTon = false;
    props.data.map(e => {
        rec.containerList.map((c) => {
            if (e[c.containerType + 'perTon'] != undefined && (+(e[c.containerType + 'perTon'])) !== 0) {
                checkPerTon = true;
            }
        })
    });

    return (
        <>
            <hr />
            <div className="table-container">
                <table className="table is-bordered is-striped is-narrow is-hoverable ">
                    <thead>
                        <tr>
                            <th colSpan='18'>
                                <h3 className="is-size-5 has-text-centered">{heading}</h3>
                            </th>
                        </tr>
                        <tr>
                            <th></th>
                            <th></th>
                            <th></th>
                            {containerNames.map(e => (
                                <td className="has-text-centered has-text-weight-bold" colSpan={checkPerTon ? 4 : 3}>{e}</td>
                            ))}
                        </tr>
                        <tr>
                            <th>ID</th>
                            <th>Charge Description</th>
                            <th>Currency</th>
                            {containerNames.map(e => (
                                <>
                                    <th style={{ textAlign: 'center' }}>Per Container</th>
                                    <th style={{ textAlign: 'center' }}>Per BL</th>
                                    {
                                        (checkPerTon) ? <th style={{ textAlign: 'center' }}>Per Ton</th> : ""
                                    }
                                    <th style={{ textAlign: 'right' }}>Total</th>
                                </>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {
                            props.data.filter(shipperpdafilter).map((e, i) => (
                                displayRec(e) ?
                                    <tr key={e.id}>
                                        <td >{counter++}</td>
                                        <td>{e.chargeDescription}</td>
                                        <td>{e.currency}</td>
                                        {containerNames.map(e1 => (
                                            <>
                                                <td style={{ textAlign: 'center' }}><Tn i={i} name={e1 + 'perWM'} /></td>
                                                <td style={{ textAlign: 'center' }}><Tn i={i} name={e1 + 'perShipment'} /></td>
                                                {
                                                    (checkPerTon) ? <td style={{ textAlign: 'center' }}><Tn i={i} name={e1 + 'perTon'} /></td> : ""
                                                }
                                                <td style={{ textAlign: 'right' }}>
                                                    {Utils.N(e[e1 + 'total'], e.currency)}
                                                </td>
                                            </>
                                        ))}
                                    </tr> :
                                    <></>
                            )
                            )
                        }
                        <tr>
                            <td colSpan={3}></td>
                            {
                                rec.containerList.map(c => {
                                    return (
                                        <>
                                            <td></td>
                                            <td></td>
                                            {
                                                (checkPerTon) ? <td></td> : ""
                                            }
                                            <td style={{ textAlign: 'right' }}>
                                                <strong>
                                                    {Utils.N(grandTotal[c.containerType])}
                                                </strong>
                                            </td>
                                        </>
                                    )
                                })
                            }
                        </tr>
                        <tr>
                            {
                                (checkPerTon) ? <td></td> : ""
                            }
                            <td colSpan={3 + rec.containerList.length * 3}
                                style={{ textAlign: 'right', fontSize: '1.1rem' }}>
                                <strong>
                                    {Utils.N(grantTotaloftotals)}
                                </strong>
                            </td>
                        </tr>
                        <tr>
                            {
                                (checkPerTon) ? <td></td> : ""
                            }
                            <td colSpan={3 + rec.containerList.length * 3} style={{ textAlign: 'right' }}>Taxes & Duties additional, as applicable</td>
                        </tr>

                    </tbody>
                </table>
                {((rec.oih && heading.includes('Your') && !props.cwfVisible) )?
                    <>
                        <div className="columns">
                            <$cr n='2'></$cr>
                            <$c n='10'>
                                <GenericSwitch store={rtpStore} property='shipperpda' leftValue='Change to Shipper PDA' rightValue=' ' />
                            </$c>
                        </div>
                        <br />
                    </>:""
                }
            </div>
        </>
    )
}
