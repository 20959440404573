
import { useSnapshot } from "valtio"
import { contractsStore } from "../state/CommonState"
import Utils from "../util/Utils"
import LCLScheduleCompv3 from "./LCLScheduleComp-v3"
import FCLScheduleCompv3 from "./FCLScheduleComp-v3"

export default function LCLSchedulesv3(props) {
    useSnapshot(contractsStore)
    const rec = contractsStore.current
    if (!rec || rec === {}) return <></>
    let data = [...props.data]
    data.sort((o1,o2) => {
        if (o1.oetd) {
            return Utils.compareDates(o1.oetd, o2.oetd)
        }
        return Utils.compareDates(o1['ETD (POL)'], o2['ETD (POL)'])
    })
    return (
        <>
            <div style={{ boxShadow: '5px 5px #dddddd', border: '1px solid lightgrey' }}>
                <div className="card-content" style={{ paddingTop: '0px' }}>
                    <div className="content">
                        {
                            data.map(e => {
                                if (rec.shipmentType.startsWith('LCL'))
                                    return <LCLScheduleCompv3 data={e} />
                                else if (rec.shipmentType.startsWith('FCL'))
                                    return <FCLScheduleCompv3 data={e} />
                            })
                        }
                    </div>
                </div>
            </div>
        </>
    )
}