export const ContainerView = (props) => {
    let containerDetails = props.rec;
    console.log("+++++++++++67",containerDetails)
    if(containerDetails!=undefined){
        let rows1 = [];
        let rows = [];
            for (let j = 0; j < containerDetails.length; j++) {
                let e = containerDetails[j]
                rows.push(<tr key={e.containerType+j} style={{border:"solid 0.5px #D4D4D4", backgroundColor:(j%2)==1?"#F3F3F3":"none"}}>
                        <td style={{textAlign: 'center',color:"#555555", padding:"10px"}}>{j + 1}</td>
                        <td style={{textAlign: 'center',color:"#555555", padding:"10px"}}>{e.containerType}</td>
                        <td style={{textAlign: 'center',color:"#555555", padding:"10px"}}>{e.numContainers}</td>
                        <td style={{textAlign: 'center',color:"#555555", padding:"10px"}}>{e.wtPerContainer}</td>
                    </tr>
                )
            }
            rows1.push(<div className="table-container">
                <table style={{border:"none", width:"100%"}}>
                    <thead>
                        <tr style={{ fontWeight:"900",border:"solid 0.5px #D4D4D4"}}>
                            <th style={{color:"#2C358A",textAlign: 'center', padding:"10px"}}>ID</th>
                            <th style={{color:"#2C358A",textAlign: 'center', padding:"10px"}}>Container Type</th>
                            <th style={{color:"#2C358A",textAlign: 'center', padding:"10px"}}># of Containers</th>
                            <th style={{color:"#2C358A",textAlign: 'center', padding:"10px"}} >Cargo Weight per Container (kgs)</th>
                        </tr>
                    </thead>
                    <tbody>
                        {rows}
                    </tbody>
                </table>
            </div>)
    return (
        <>{rows1}</>
    )
    }else{
        return <></>
    } 
}