import { useEffect, useState } from "react";
import destinationIcon from "../assets/images/destinationimg.svg";
import { useFieldArray} from "react-hook-form";
import { Container } from "../enquiry/enquiry-data";
import { containerTypeList, containerTypeListTC } from "../StaticData";
import { Quantity } from "../tailwind-components/Quantity";
import { TextWithSelect } from "../mui-components/TextWithSelect";
import { AgMuiSelect2 } from "../mui-components/Controlled";
import { Add} from "@mui/icons-material";
import deleteicon from "../assets/images/deletei.svg";
import { FlexibleCenteredDiv } from "./AgFlexibleCenteredDiv";
import { TableHeading } from "./AgTableHeading";
import { HeadingWithSelect } from "./AgHeadingWithSelect";
import { useSnapshot } from "valtio";
import { AgmodalStore } from "../state/CommonState";
export function MultiContainerDetails({form,viewonly,ind}){
    const { control, watch, getValues, resetField, setValue } = form;
    const { fields: containerDetails, append: appendContainer, remove: removeContainer } = useFieldArray({
        control: control,
        name: `containerDetails`
    })
    const [dis,setDis] = useState(false)
    useSnapshot(AgmodalStore)
    const [typeList, setTypeList] = useState(containerTypeList)
    let containerDetailsFromForm = watch(`containerDetails`);
    const temperatureControlled = watch("temperatureControlled");
    const stuffingType = watch("stuffingType")
    const showCargoDetailsOutGuage = watch("showCargoDetailsOutGuage")
    const destuffingType = watch("destuffingType");
    const weightPerContainer = getValues("containerDetails.0.unit")
    const containerTypes =["20'OT (Out-gauge)","40'OT (Out-gauge)","20'FR (Out-gauge)","40'FR (Out-gauge)"]
    useEffect(()=>{
        if(viewonly || AgmodalStore.modalPage=='Quotev3'){
            if(!dis){
                setDis(true)
            }
        }else{
            if(dis){
                setDis(false)
            }
        }
    },[AgmodalStore])
    useEffect(()=>{
        let l = false
        for (let i = 0; i < containerDetailsFromForm.length; i++) {
            let k = containerDetailsFromForm[i]
            if(k["numContainers"] == undefined){
                setValue(`containerDetails.${i}.numContainers`,0)
            }
            if(k["wtPerContainer"] == undefined){
                setValue(`containerDetails.${i}.wtPerContainer`,0)
            }
        }
        if(containerDetailsFromForm!=undefined){
            for (let j = 0; j < containerDetailsFromForm.length; j++) {
                if(l==false){
                    l = containerTypes.includes(containerDetailsFromForm[j]['containerType'])
                }
            }
        }
        if(showCargoDetailsOutGuage!=l){
            setValue('showCargoDetailsOutGuage', l)
        }
    },[JSON.stringify(containerDetailsFromForm)])
    const generateId = (id,i)=> {
        if(id==undefined){
            let a = i + Date.now() + '-' + Math.floor(Math.random() * 1000)
            containerDetailsFromForm[i].id = a
            return a
        }else{
            return id
        }
    }
    useEffect(()=>{
        for(let i = 1; i < containerDetailsFromForm.length;i++){
               setValue(`containerDetails.${i}.unit`,weightPerContainer)
        }
    },[weightPerContainer])
    useEffect(() => {
        if (temperatureControlled) {
            setTypeList(containerTypeListTC);
        // } else if (stuffingType.toLowerCase() !== "factory" || destuffingType.toLowerCase() !== "factory") {
        //     setTypeList(containerTypeList.slice(0, 4))
          
        } else {
            setTypeList(containerTypeList)
           
        }

    }, [temperatureControlled, stuffingType, destuffingType]);
    const validateGreaterThanZero = (value) => {
        return parseFloat(value) > 0 || 'Invalid Value';
    };
    return <div className="w-full flex flex-col gap-2 items-end">
    <div className="flex flex-col w-full">
    <div className={`flex flex-row w-full h-[35px] items-center border-[0.5px] border-solid border-[#D4D4D4] border-b-0 px-[12px]`}>
         <FlexibleCenteredDiv>
             <TableHeading title="Container Type *" />   
         </FlexibleCenteredDiv>
         <FlexibleCenteredDiv>  <TableHeading title="Number of Containers *" /></FlexibleCenteredDiv>
      <FlexibleCenteredDiv>
       <HeadingWithSelect  options={["Kgs", "Tons", "Lbs"]} heading={"Weight Per Container *"} control={control} name={`containerDetails.0.unit`} disabled={dis} />
       </FlexibleCenteredDiv>
       {containerDetails.length > 1 && <div className="w-[35px] h-full"></div>}
       </div>
       {containerDetailsFromForm.map((containerdetail,i)=><div className={`flex flex-row w-full py-[8px] items-center px-[12px] ${i%2===0?"bg-[#F3F3F3]":"bg-white border-[0.5px] border-solid border-[#D4D4D4]"}`}>
       <FlexibleCenteredDiv >
         <AgMuiSelect2 disabled={viewonly} key={generateId(containerdetail.id,i)} style={{height:"25px",width:"170px"}} rules={{ required: "Required" }}  size="small" fontSize="12px" options={typeList}  control={control} name={`containerDetails.${i}.containerType`} />
         </FlexibleCenteredDiv>
         <FlexibleCenteredDiv >
           <Quantity disabled={viewonly}  key={generateId(containerdetail.id,i)}  control={control} callback={(e)=>{setValue(`containerDetails.${i}.numContainers`,e)}} name={`containerDetails.${i}.numContainers`} rules={{ required: "Required", validate: validateGreaterThanZero }} />
         </FlexibleCenteredDiv>
         <FlexibleCenteredDiv>
           <TextWithSelect  key={generateId(containerdetail.id,i)} options={["Kgs", "Tons", "Lbs"]} control={control} name={`containerDetails.${i}.wtPerContainer`} unit={weightPerContainer} disabled={viewonly} />
         </FlexibleCenteredDiv>
         { !viewonly && containerDetails.length > 1 && 
          <div onClick={()=>{
           removeContainer(i);
          }} className="w-[35px] h-full flex items-center justify-center cursor-pointer">
<img src={deleteicon}/>
          </div>
               }
       </div>)}
    </div>
    {!viewonly &&
    <button onClick={()=>{
       let newContainer = new Container();
       newContainer.unit = weightPerContainer;
       appendContainer({ ...newContainer })
  }} type="button" className="bg-[#F7F7F7] border-[1px] border-solid border-[#E8E8E8] h-[35px] w-[110px] rounded-[6px]">
      <div className="flex h-full w-full flex-row gap-[5px] justify-center items-center font-bold">
          <Add sx={{fontSize:"22px",color:"#2C358A"}}/>
          <p className="text-grey">Add Row</p>
      </div>
  </button>
    }
    
  </div>
}