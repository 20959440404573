import { Box, Typography } from "@mui/material";
import { ContentBox } from "./ContentBox";
import { FileViewComponent } from "./FileComponent";
import { KeyDescription } from "./KeyDescription";
let server = process.env.REACT_APP_FILE_SERVER
export function Documents({task}){
     return  <Box sx={{display:"flex",flexDirection:"column",gap:2,height:"100%"}}>
         <Typography sx={{fontFamily:"Figtree",color:"black",fontWeight:"bold",fontSize:"18px"}}>Documents</Typography>
       
         {task.files.map((file)=><Box sx={{display:"flex",flexDirection:"row",gap:2,alignItems:"center"}}>
            <KeyDescription title={file.label}/>
            <ContentBox>
            { file.filename?.length > 0 && <FileViewComponent documentTitle={file.filename} documentUrl={server + '/api/v1/filestore/getfile/' + task.bookingNum + '/' + file.filename} />}
            </ContentBox>
        </Box>)}
     </Box>
}