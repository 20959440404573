function Row({isGrey,title,value,isFirst,isLast}){
  let borderDesign = "border-solid border-[#D4D4D4] border-b-0";
  if(isLast){
    borderDesign = "border-solid border-[#D4D4D4]";
  }
  
  return <div className={`flex flex-row items-center px-5 justify-between h-[40px] w-[617.5px] ${borderDesign} ${isGrey?"bg-[#F3F3F3]":"bg-white"}`}>
    <p className="text-[15px] text-blue-dark font-semibold">{title} :</p>
    <p className="text-grey">{value}</p>
  </div>
}

export function TableView({rows}){
    let isGrey = true;
    return <div className="flex flex-row flex-wrap justify-between">
        {rows.map((row,i)=>{
           if((i+1)%2!==0){
            isGrey=!isGrey;
           }
        return <Row isFirst={i===0} isLast={i===rows.length-1 || i===(((rows.length)/2)+1)} title={row.title} value={row.value} isGrey={isGrey} />
})}
        </div>
}