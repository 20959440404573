import { Avatar, Box, Button, CardActions, CardContent, CardMedia, Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel, IconButton, ImageList, ImageListItem, Input, InputAdornment, Modal, Stack, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import EntityApi from '../state/entity-api'
import BranchApi from '../state/branch-api'
import { EntityV2Store, branchStore, configStore, loginStore } from '../state/CommonState'
import { Card } from 'reactstrap'
import EastIcon from '@mui/icons-material/East';
import { makeStyles, styled } from '@mui/styles'
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { BranchType } from '../state/Types'
import { useForm } from 'react-hook-form'
import LabeledTextField from './LabeledTextField'
import SelectField from './SelectField'
import { City, Country, State } from 'country-state-city'
import LabeledSelectField from './LabeledSelectField'
import { BRANCH_STATUS } from '../util/constants'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import Api from '../state/Api'
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import { Image  } from '@mui/icons-material'
import TaskIcon from '@mui/icons-material/Task';
import Visibility from '@mui/icons-material/Visibility';
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault';
import EntityUtils from '../util/entityUtils';
import { kMaxLength } from 'buffer';
import LabeledTextField2 from './LabeledTextFiled2';
import RoleUtils from '../util/RoleUtils'
import UsersApis from '../state/users-api'
// import { TextField } from "@mui/material";



const BranchList = () => {
  const [branches, setbranches] = useState([])
  const [BranchMode, setBranchMode] = useState('list')
  const [BranchData, setBranchData] = useState({})
  const [states , setstates]= useState([])
  const [defaultValue , setdefaultValue]= useState({})
  const [cities, setcities] = useState([])
  const [uplDocStatus, setuplDocStatus] = useState(true)
  const [loading, setloading] = useState(false)
  const [scopeofbusiness , setscopeofbusiness] = useState([]) ;
  const [userRoleType , setuserRoleType] = useState({})
  const [agusersName , setAgusersName] = useState({
    relationShipManager : '',
    customerService : ''
  })

  let Entity = EntityV2Store.EntityRec

  async function setStates () {
    const EntityCountryy = await Entity?.entityCountry
    const BranchCountry = Country.getAllCountries().filter(
      (r) => r.name === Entity?.entityCountry
    )
    const Statess = State.getStatesOfCountry(BranchCountry[0]?.isoCode)
    setstates(Statess)
  
  }

  useEffect(()=> {
    let userRec = loginStore.userRec.aguserRec
    UsersApis.viewUser(userRec.email , (data)=>  {
    let entityUserDataMainlist = data?.mainEntityList?.filter((x,i) => x.entityId === Entity.entityId) 
    let entityUserData = data?.entityList?.filter((x,i) => x.entityId === Entity.entityId) 
    if(entityUserDataMainlist?.length > 0){
      setuserRoleType(entityUserDataMainlist[0])
    }else if(entityUserData?.length > 0) {
      setuserRoleType(entityUserData[0])
    }
    } )
    
  },[])

  useEffect(() => {
    BranchApi.getAllBranches(Entity.entityId, (data) => {
      setbranches(data)
      // getUserName(data[0])
    })
    setStates()
    if(BranchMode ==='new'){
       let data = new BranchType()
        data.scopeOfBusiness = ['Cross Border']
        data.branchName = Entity.entityName
        setdefaultValue(data)
          
    }
   

   
  }, [BranchMode])

  const defaultForm = useForm({
    defaultValues: {...defaultValue, pincode : ''}
});

const { control, handleSubmit , register  , unregister, reset , setValue, clearErrors , setError , resetField ,   formState: { errors }, getValues,watch } = defaultForm
  const watchFields = watch()
  
  const showBranchForm = (r , x) => {
    reset()
    setBranchMode(r)
    if(r === 'edit'){
      setdefaultValue(x)
      setBranchData(x)
      reset(x)
      setCitiesFn('edit', x)
      setscopeofbusiness(x.scopeOfBusiness)
      getUserName(x)
    } 
    

  }

  const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });
  

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: '#f0f0f0',
    border: '2px solid grey.500',
    boxShadow: 24,
    borderRadius: '16px',
    pt: 2,
    px: 4,
    pb: 3,
  };

  const useStyles = makeStyles((theme) => ({
    root: {
      background: "#fafafa"
    }
  }));

  const addNewBranch = () => {
    branchStore.BranchRec.set(new BranchType());
    setBranchData(new BranchType())
    reset(new BranchType())
    showBranchForm('new')
  }

  const branchAddressline = [
    {
      address: "",
      city: "",
      pincode: "",
      zipcode: "",
    },
  ]

  async function  getUserName (Data) {
    let relationshipManagerId =  Data.crossBorder.relationshipManager
    let customerServiceId =  Data.crossBorder.customerService
  // await Promise.all(await UsersApis.viewAgragaUser(id, (data)=> {
  //   name = `${data.data.firstName}  ${data.data.lastName} ` 
  //   setValue('')
  //   // console.log(data);
  //  }))
  //  return name
 
  let datas = {
    relationshipManager: '',
    customerService : ''
  }

  if ( relationshipManagerId?.length > 0 ){
 UsersApis.viewAgragaUser(relationshipManagerId, (data)=> {
  datas.relationShipManager = `${data.data.firstName}  ${data.data.lastName} `
   })
  }
  if( customerServiceId?.length>0 ){
   UsersApis.viewAgragaUser(customerServiceId, (data)=> {
    datas.customerService = `${data.data.firstName}  ${data.data.lastName} `
    setAgusersName(datas)
  })
 }
}

  function change(files, filetype, i, filekey) {
    const formData = new FormData()
    formData.append("myFile", files[0])
    formData.append("label", filetype)
    formData.append("key", i)
    fetch(`${process.env.REACT_APP_FILE_SERVER}/api/v1/filestore/storefile`, {
      method: "POST",
      headers: Api.H1(),
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          if (data.error) {
            configStore.setModalMessage(data.error)
          }
        } else {
          const documentObj = defaultValue.documents.filter(
            (r) => r.name === filetype
          )
          if(filetype === 'gstCert'){
            setValue('documents', defaultValue.documents)
            setValue('documents[1].pkey', data.key.pkey)
            setValue('documents[1].fileLink', data.key.filename)
            let obj = {...BranchData}
            obj.documents[1].pkey = data.key.pkey
            obj.documents[1].fileLink = data.key.filename
            setBranchData({...obj})
            setuplDocStatus(true)

          }else {
            setValue('documents', defaultValue.documents)
            setValue('documents[0].pkey', data.key.pkey)
            setValue('documents[0].fileLink', data.key.filename)
            let obj = {...BranchData}
            obj.documents[0].pkey = data.key.pkey
            obj.documents[0].fileLink = data.key.filename
            setBranchData({...obj})
            setuplDocStatus(true)
          }
         
        }
      })
      .catch((error) => {
        console.error(error)
      })
  }
  
 function setCitiesFn  (e , r)  {
  console.log(e);
  let value
  if(e ==='edit'){
    value = r.state.split("/")[0]
  }else {
    value = e.target.value.split("/")[0]
  }
  const BranchCountry = Country.getAllCountries().filter(
    (r) => r.name === Entity?.entityCountry
  )
  const Statecode = states.filter((r) => r.name === value)
  const cities = City.getCitiesOfState(
    BranchCountry[0]?.isoCode,
    Statecode[0]?.isoCode
  )

  setcities(cities)
  console.log(defaultValue);
  if(e === 'edit'){
    r.state = value
    setdefaultValue({...r})
  }else {
    defaultValue.state = value
    setdefaultValue({...defaultValue})
  }


 }

  const addNewBranchAddress = () => {
    const array = [...BranchData.branchAddresses]
    array.push(branchAddressline[0])
    BranchData.branchAddresses = array
    setBranchData({ ...BranchData })
    defaultValue.branchAddresses.push(branchAddressline[0])
    setdefaultValue({...defaultValue})
  }

  const deleteDoc = (x) => {
    if(x=== 'gst'){
      let obj = { ...BranchData }
      obj.documents[1].pkey = ''
      obj.documents[1].fileLink = ''
      setBranchData({ ...obj })
      setValue('documents[1].pkey', '')
      setValue('documents[1].fileLink', '')

    }else if (x==='uid'){
      let obj = { ...BranchData }
      obj.documents[0].pkey = ''
      obj.documents[0].fileLink = ''
      setBranchData({ ...obj })
      setValue('documents[0].pkey', '')
      setValue('documents[0].fileLink', '')
    }

  }

  const submitHandler = async(data) => {
   console.log('data',data);
  //  const regexGSTPattern =
  // /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/
  // if (!regexGSTPattern.test(data.gst.trim())) {
  //  return setError('gst', {  message: 'Invalid GST format. Eg: 22AAAAA0000A1Z5 or 29ABCDE1234F1ZK' });
  // }else if (regexGSTPattern.test(data.gst.trim())) {
  //   clearErrors("gst") 
  // }
   if(BranchMode === 'new'){
    let uploaded = await docUploaded()
    if(!uploaded  ){
      setuplDocStatus(false)
  return  configStore.setModalMessage('please upload the documents')
    }
    if(EntityUtils.isIndia(Entity)){
      const extractedPan = EntityUtils.extractPanFromGST(data.gst)
      if(extractedPan !== Entity.panNum){
        setError(`gst` , { message :'Pan and Gst Mismatch'})
        return 
      }
    }
    data.country = Entity.entityCountry
    data.branchcountryCode = Entity.entityCountryCode
    data.status = BRANCH_STATUS.PENDING
    data.entityId = Entity.entityId
    data.crossBorder = Entity.customer.crossBorder
    data.domesticTransport = Entity.customer?.domesticTransport
    data.scopeOfBusiness = ['Cross Border']
    data.branchName = Entity.entityName
    data.mandatoryCheck= true
    data.timeline.push({
      date: Date.now(),
      event: "Created",
      user: loginStore.email,
      role: RoleUtils.getUserRole()
    })
    BranchApi.createBranch(setloading ,data , (data)=> {
       if (data){
         setloading(false)
         configStore.setModalMessage("Branch Created Successfully");
         setBranchMode('list')
       }
    })
   } else if (BranchMode === 'edit'){
    if(data.status === BRANCH_STATUS.REJECTED){
      const uploaded = await docUploaded()
      if(!uploaded ){
        setuplDocStatus(false)
    return  configStore.setModalMessage('Please upload the documents')
      }
      data.timeline.push({
        date: Date.now(),
        event: "Resubmited",
        user: loginStore.email,
        role: RoleUtils.getUserRole()
      })
      data.status = BRANCH_STATUS.PENDING
    }
 
   await BranchApi.updateBranch(data.branchId ,data.gst , setloading , (data)=> {
      if (data){
        setloading(false)
        configStore.setModalMessage("Branch Updated Successfully");
        setBranchMode('list')
      }
   }, data )

   }
}

function statusColorList (r){
  let colorObj = {}
  if(r.status === BRANCH_STATUS.PENDING){
   colorObj.outLineColor = '#a37531'
   colorObj.backGroundColor = '#ffe0b3'
  }else if (r.status === BRANCH_STATUS.VERIFIED){
   colorObj.outLineColor = '#319953'
   colorObj.backGroundColor = '#b3ffcc'
  }else if (r.status === BRANCH_STATUS.REJECTED){
   colorObj.outLineColor = '#ab3c3c'
   colorObj.backGroundColor = '#ffb3b3'
  }
  return colorObj
}

function isUserEntityAdmin () {
  if( userRoleType.role === "Entity Admin"){
    return true
  }
  return false
}


 function isPendingStatus () {
  const status = getValues("status")
  return  status === BRANCH_STATUS.PENDING
 }

 function isVerifiedStatus () {
  const status = getValues("status")
  return  status === BRANCH_STATUS.VERIFIED
 }

 function isRejectedStatus () {
  const status = getValues("status")
  return  status === BRANCH_STATUS.REJECTED
 }

 const handleChange = (e,r) => {
  console.log(e.target.value);
 }

const handleChangeGst = async (value) => {
  console.log('gst', value );
  
  if (value.length === 15) {
    await BranchApi.checkGst(value.toUpperCase(), (response) => {
   
     console.log(response); 
     if(response.status === true){
      setError('gst', {  message: 'GST already registered' });

     }else {
      clearErrors("gst") 
     }
    
    })
  } else {
    // clearErrors("gst") 
  }
  setValue('gst', value.toUpperCase())
}

function docUploaded (){
  let value = getValues('documents[0].fileLink')
  let value1 = getValues('documents[1].fileLink')
  if(value1.length === 0 && EntityUtils.isIndia(Entity) ){
    return false
  }
  return true
}

function statusColor (){
   let colorObj = {}
   if(BranchData.status === BRANCH_STATUS.PENDING){
    colorObj.outLineColor = 'warning'
    colorObj.backGroundColor = '#ffe0b3'
   }else if (BranchData.status === BRANCH_STATUS.VERIFIED){
    colorObj.outLineColor = 'success'
    colorObj.backGroundColor = '#b3ffcc'
   }else if (BranchData.status === BRANCH_STATUS.REJECTED){
    colorObj.outLineColor = 'error'
    colorObj.backGroundColor = '#ffb3b3'
   }
   return colorObj
}

  const removeBranchAddressLine = (i) => {
    const array = [...BranchData.branchAddresses]
    if (array.length > 1) {
      array.splice(i, 1)
    }
    BranchData.branchAddresses = array
    setBranchData({ ...BranchData })

    let arrayNew = [...defaultValue.branchAddresses]
    if (arrayNew.length > 1) {
      arrayNew.splice(i, 1)
    }
    defaultValue.branchAddresses = arrayNew
    setdefaultValue({...defaultValue})
    // resetField( `branchAddresses.${i}.address`)
    // resetField( `branchAddresses.${i}.city`)
    // resetField( `branchAddresses.${i}.pincode`)
    // resetField( `branchAddresses.${i}.zipcode`)
    setValue( `branchAddresses.${i}.address` , '')
    setValue( `branchAddresses.${i}.city`, '')
    setValue( `branchAddresses.${i}.pincode`, '')
    setValue( `branchAddresses.${i}.zipcode`, '')
    unregister(`branchAddresses.${i}`)
    resetField(`branchAddresses.${i}`)
  }
  let styles = useStyles()
  console.log('kmlmj', watchFields.pincode);
  return (
    <>
  
     <Box sx={{ flex: 1, backgroundColor: "white", borderRadius: "10px", display: "flex", flexDirection: "column", padding: "10px",   height: "100%", width: "100%" }}>
      <Stack direction='row' mb={2} sx={{ justifyContent: 'space-between'  }}>
        <Typography variant="h6"  sx={{ fontWeight: 600 }}>{Entity.entityName}</Typography>
        { BranchMode === 'list' && EntityUtils.isIndia(Entity) && isUserEntityAdmin() && 
          <Button variant='contained' startIcon={<AddIcon />} p={2} sx={{ float: 'right' }} onClick={() => addNewBranch()} >Add New Branch</Button>
        }
        { BranchMode !== 'list' &&
        <Typography variant="h6" sx={{ fontWeight: 600 , fontSize: '18px', width : '12%' }}>Status :  <Button variant='outlined' color={statusColor().outLineColor} style={{backgroundColor : statusColor().backGroundColor , borderRadius : '20px' , fontSize : '11px' , padding : '2%'}} >{BranchData.status}</Button></Typography>
        }

      </Stack>
      <Box sx={{
      flex: 1, backgroundColor: "white", borderRadius: "10px", display: "flex", flexDirection: "column", padding: "10px",   height: "100%", width: "100%" , overflowY: "auto", "&::-webkit-scrollbar-thumb": {
          backgroundColor: "#2C358A !important" 
      }
  }}>
   
      <Box pb={2} sx={{ display : 'flex' , flexDirection : 'column' , gap : 2 }}>
      {BranchMode === 'list' ? <>
        {branches?.map((x, i) => {
          return (

              <Card direction='row' fontFamily='figtree'  style={{display: 'flex', flexDirection: 'column', gap : 2, border: '1px solid #c2bebe'   , borderRadius : '7px', padding :'0%' , fontFamily : 'figtree'  }}  >
        
                <CardContent direction='column' fontFamily='figtree' style={{padding : '0.5% 4% 0% 4%' , height : '25px'}} sx={{ display: 'flex', justifyContent: 'space-between',padding : 0, paddingLeft: '4%', paddingRight: '4%', paddingBottom: '0%' }}>
                  {
                    EntityUtils.isIndia(Entity) && (
                      <Typography gutterBottom variant='h7' component='div' color='primary' sx={{ width: '35%' }}>
                    GST Number
                  </Typography>
                    )

                  }
                  {
                    !EntityUtils.isIndia(Entity) && (
                      <Typography gutterBottom variant='h7' component='div' color='primary' sx={{ width: '35%' }}>
                     UID Number
                  </Typography>
                    )
                  }
                  <Typography variant='h7' color='primary' sx={{ width: '35%' }}>
                    State
                  </Typography>
                  <Typography variant='h7' color='primary' sx={{ width: '25%' }}>
                    Status
                  </Typography>
                  <Typography variant='h7' color='primary' sx={{ width: '5%' }}>
                    <IconButton aria-label='send' size='medium' color='primary' m={4} onClick={()=>showBranchForm('edit', x)} >
                      <EastIcon />
                    </IconButton>
                  </Typography>

                </CardContent>
                <CardContent direction='column' style={{padding : '0% 4% 0.5% 4%'}} sx={{ display: 'flex', justifyContent: 'space-between', padding : 0, paddingLeft: '4%', paddingRight: '4%', paddingBottom: '0%', justifyContent: 'center' }}>
                  {
                    EntityUtils.isIndia(Entity) && (
                      <Typography gutterBottom variant='h7' component='div' sx={{ width: '35%', justifyContent: 'center' }}>
                    {x.gst}
                  </Typography>
                    )
                  }
                  {
                    !EntityUtils.isIndia(Entity) && (
                      <Typography gutterBottom variant='h7' component='div' sx={{ width: '35%', justifyContent: 'center' }}>
                    {x.uidRefNo}
                  </Typography>
                    )
                  }
               
                  <Typography variant='h7'  sx={{ width: '35%' }}>
                    {x.state}
                  </Typography>
                  <Typography variant='h7' color={statusColorList(x).outLineColor}  fontFamily='figtree' sx={{ width: '25%' }}>
                  <span style={{padding: '0% 2% 0% 2%',borderRadius : '20px',color : statusColorList(x).outLineColor ,  backgroundColor :  statusColorList(x).backGroundColor}}>
                    {x.status}
                    </span>
                  </Typography>
                  <Typography variant='h7' color='text.primary' sx={{ width: '5%' }}>
                  </Typography>
                </CardContent>
              </Card>
          
          )
        })}
        {
           branches?.length === 0 ? <>
           <Typography variant="h6" align='center' sx={{ fontWeight: 400 , fontSize: '16px', width : '100%'  }}>No Branches found </Typography>
           </>: null
        }

      </> : null
      }
      </Box>
      {BranchMode === 'new' && (
        <Modal
          open={BranchMode === 'new'}
          onClose={() => showBranchForm('list')}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <form onSubmit={handleSubmit(submitHandler)}   > 
          <Box sx={{ ...style, width: '75%' }}  >
            <Typography variant="h6" align='center' sx={{ fontWeight: 600 }} >Add New Branch</Typography>
            <Stack spacing={3}>
              <Stack direction='row' spacing={2} pb={0}>
                <Stack >
                  <LabeledSelectField rules={{ required: "State is required" }} labelStyle={{ color: "black", fontFamily: "Figtree", fontSize: "14px"  }} textfieldStyles={{ background: "#fafafa" , width: 300}} fontSize="14px" label="State*" control={control} name="state"  callback={(e)=>setCitiesFn(e)}  options={states || []} />
                </Stack>
                {
                  EntityUtils.isIndia(Entity) && (
                    <Stack>
                  <LabeledTextField rules={{ required: "GST is required" , pattern: {
                value: /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/,
                message: "Invalid GST format. Eg: 22AAAAA0000A1Z5 or 29ABCDE1234F1ZK"
                },
                maxLength: {
                value: 15,
                message: "GST cannot exceed 15 characters"
            } }} labelStyle={{ color: "black", fontFamily: "Figtree", fontSize: "14px"  }}  Styles={{ background: "#fafafa" , textTransform: "uppercase"  }} fontSize="14px" label="GST Number*" control={control} name="gst" callback={handleChangeGst} disabled={isPendingStatus() || isVerifiedStatus() || !isUserEntityAdmin()} />
                </Stack>
                  )
                } 
                {/* {
                  EntityUtils.isIndia(Entity) && (
                    <Stack>
                    <Typography style={{textAlign:"start",fontFamily:"Figtree", color: "black", fontFamily: "Figtree", fontSize: "14px" }}>GST Number</Typography>
                  <TextField sx={{}} style={{ fontSize : "16px" ,alignItems: 'center' }} InputProps={{ style : {background: "#fafafa",  padding : '6px 6px' , width: '300px'}}} onChange={(e)=>handleChange(e,'gst')} />
                </Stack>
                  )
                }  */}
                {
                  !EntityUtils.isIndia(Entity) && (
                    <Stack>
                  <LabeledTextField rules={{  pattern: {
                  value: /^[a-zA-Z0-9 ]{0,20}$/,
                  message: 'Invalid Format Special Characters not allowed'
                },
                maxLength: {
                  value: 20,
                  message: "UID Name cannot exceed 20 characters"
                } }} labelStyle={{ color: "black", fontFamily: "Figtree", fontSize: "14px"  }} textfieldStyles={{ background: "#fafafa" , textTransform: "uppercase" }} fontSize="14px" label="Unique Identifier Document Name" control={control} name="uidName"    />
                </Stack>
                  )
                } 
                {
                  !EntityUtils.isIndia(Entity) && (
                    <Stack>
                  <LabeledTextField rules={{ pattern: {
                  value: /^[a-zA-Z0-9 ]{0,20}$/,
                  message: 'Invalid Format Special Characters not allowed'
                },
                maxLength: {
                  value: 20,
                  message: "UID Ref num cannot exceed 20 characters"
                }  }} labelStyle={{ color: "black", fontFamily: "Figtree", fontSize: "14px"  }} textfieldStyles={{ background: "#fafafa" , textTransform: "uppercase" }} fontSize="14px" label="Unique Identifier Doc Reference number" control={control} name="uidRefNo"  />
                </Stack>
                  )
                }
                
                <Stack>
                  {EntityUtils.isIndia(Entity) && BranchData.documents[1].fileLink.length === 0 ?<>
                  { uplDocStatus && 
                    <Typography variant="h7" sx={{ fontWeight: 500 }} width={300} >Upload Gst document*</Typography>
                  }

                  {!uplDocStatus && 
                    <Typography variant="h7" sx={{ fontWeight: 500 , color : 'red'}} width={300} >Upload Gst document*</Typography>
                  }

                    <Button
                      component="label"
                      tabIndex={-1}
                      startIcon={<CloudUploadIcon />}
                      sx={{
                        border: '1px dashed blue',
                        padding : "5px 70px"   ,
                        marginLeft : '10%',
                        width : 300,
                      }}
                    >
                      Upload file
                      <VisuallyHiddenInput type="file" 
                      onChange={(e1) => {
                              change(e1.target.files, "gstCert", "gstdocs")
                              // clearErrorMessages("gst")
                            }}
                       />
                    </Button>
                    </> : null
                  }
                  {EntityUtils.isIndia(Entity) && BranchData.documents[1].fileLink.length > 0 ? <>
                    <Stack>
                    <Typography variant="h7" sx={{ fontWeight: 500 }} width={300}>Uploaded Gst document</Typography>
                        <TextField size='small' color='primary' variant="outlined" disabled='true' sx={{background: "#fafafa" }}
                          InputProps={{
                            startAdornment: <InputAdornment position="start">   <IconButton
                              aria-label="toggle password visibility"
                              edge="end"
                            >

                              <TaskIcon />
                              <Typography variant="caption"  sx={{maxWidth : 200}} > {BranchData.documents[1].fileLink}</Typography>
                             
                            </IconButton></InputAdornment>,
                             endAdornment: <InputAdornment position="end">   <IconButton
                              aria-label="toggle password visibility"
                              edge="end"
                              onClick={() => window.open(`${process.env.REACT_APP_FILE_SERVER + "/api/v1/filestore/getfile/" +  BranchData.documents[1].pkey +  "/"  + BranchData.documents[1].fileLink}?w=164&h=164&fit=crop&auto=format}`)}
                            >
                              <Visibility  />
                            </IconButton>
                            <IconButton color='error' disabled={isPendingStatus() || isVerifiedStatus() || !isUserEntityAdmin()} onClick={()=>deleteDoc('gst')}>
                          <DisabledByDefaultIcon/>
                        </IconButton></InputAdornment>,
                          }}
                        ></TextField>
                    </Stack>
                  </> : null

                  }
                </Stack>
              </Stack>
              <Stack direction='row' spacing={2} pb={3} mt={0} >
              <Stack  >
                  {!EntityUtils.isIndia(Entity) && BranchData.documents[0].fileLink.length === 0 ?<>
                  <Typography variant="h7" sx={{ fontWeight: 500 }} width={300}  >Upload UID Document</Typography>
                    <Button
                      component="label"
                      tabIndex={-1}
                      startIcon={<CloudUploadIcon />}
                  
                      sx={{
                        border: '1px dashed blue',
                        padding : "5px 70px"   ,
                        marginLeft : '10%',
                        width : 300,
                      }}
                    >
                      Upload file
                      <VisuallyHiddenInput type="file" 
                      onChange={(e1) => {
                              change(e1.target.files, "Identifer Reference Number", "identifer_refer_number")
                              // clearErrorMessages("gst")
                            }}
                       />
                    </Button>
                    </> : null
                  }
                  {!EntityUtils.isIndia(Entity) && BranchData.documents[0].fileLink.length > 0 ? <>
                    <Stack>
                    <Typography variant="h7" sx={{ fontWeight: 500 }} width={300}>Uploaded UID Document </Typography>
                        <TextField size='small' color='primary' variant="outlined" disabled='true' sx={{background: "#fafafa" }} rules={{}}
                          InputProps={{
                            startAdornment: <InputAdornment position="start"> <IconButton
                              aria-label="toggle password visibility"
                              edge="end"
                            >

                              <TaskIcon />
                              <Typography variant="caption"  sx={{maxWidth : 200}} > {BranchData.documents[0].fileLink}</Typography>
                             
                            </IconButton></InputAdornment>,
                             endAdornment: <InputAdornment position="end">   <IconButton
                              aria-label="toggle password visibility"
                              edge="end"
                              onClick={() => window.open(`${process.env.REACT_APP_FILE_SERVER + "/api/v1/filestore/getfile/" +  BranchData.documents[0].pkey +  "/"  + BranchData.documents[0].fileLink}?w=164&h=164&fit=crop&auto=format}`)}
                            >
                              <Visibility  />
                            </IconButton>
                            {
                              (isPendingStatus() || isVerifiedStatus() || !isUserEntityAdmin() ) ? <>
                              <IconButton color='error' disabled={isPendingStatus() || isVerifiedStatus() || !isUserEntityAdmin()} onClick={()=>deleteDoc('uid')}>
                          <DisabledByDefaultIcon/>
                        </IconButton>
                              </>: null
                            }
                           </InputAdornment>,
                          }}
                        ></TextField>
                    </Stack>
                  </> : null

                  }
                </Stack>
              </Stack>
            </Stack>

            <Typography variant="h7" sx={{ fontWeight: 600  }}>Branch Address : </Typography>
            <Card  style={{ border : '1px solid #c2bebe'}} sx={{border : 1  }}>
              <CardContent direction='column' pb={0} style={{ padding: "0.7% 4% 0.7% 4%"}} sx={{ display: 'flex', justifyContent: 'space-around', padding: 0, paddingBottom: 0 }} >
                <Typography variant="h8" sx={{ fontWeight: 600 }}>Address </Typography>
                <Typography variant="h8" sx={{ fontWeight: 600 }}>City </Typography>
                {
                  EntityUtils.isIndia(Entity) && (
                    <Typography variant="h8" sx={{ fontWeight: 600 }}>Pincode </Typography>
                  )
                }
                {
                  !EntityUtils.isIndia(Entity) && (
                    <Typography variant="h8" sx={{ fontWeight: 600 }}>Zipcode </Typography>
                  )
                }
               
           
              </CardContent>




            </Card>
            {BranchData.branchAddresses?.map((r, index) => {
              return <>
                <Stack direction='row' spacing={2} sx={{ paddingTop: 2 }}>
                  <LabeledTextField rules={{ required: "Address is required" }}  textfieldStyles={{ background: "#fafafa" , width:300}} fontSize="14px"  control={control} name={`branchAddresses.${index}.address`}   />
                  {
                    EntityUtils.isIndia(Entity) && (
                      <LabeledSelectField rules={{ required: "City is required" }} labelStyle={{ color: "black", fontFamily: "Figtree", fontSize: "14px"  }} textfieldStyles={{ background: "#fafafa" , width:300}} label='' fontSize="14px"  control={control} name={`branchAddresses.${index}.city`}    options={cities || []} />
                    )
                  }

                  {
                    !EntityUtils.isIndia(Entity) && (
                      <LabeledTextField rules={{ required: "City is required" }} labelStyle={{ color: "black", fontFamily: "Figtree", fontSize: "14px"  }} textfieldStyles={{ background: "#fafafa" , width:300}} fontSize="14px"  control={control} name={`branchAddresses.${index}.city`}  disabled = {isPendingStatus()}   />
                    )
                  }
                  {
                    EntityUtils.isIndia(Entity) && (
                    

                    <LabeledTextField2
                     rules={{ required: "Pincode is required" ,
                    pattern: {
                value: /^[0-9]/,
                message: "Only numbers are allowed"
                },
                maxLength: {
                value: 6,
                message: "Pincode should be 6 digits in length"
            },
            minLength: {
                value: 6,
                message: "Pincode should be 6 digits in length"
            }}}  textfieldStyles={{ background: "#fafafa" , width:300 , maxLength: 6 }} maxLength= {6}  fontSize="14px"  control={control} value={watchFields.pincode} name={`branchAddresses.${index}.pincode`}  />  
               )
                  }
                  {/* {
                    EntityUtils.isIndia(Entity) && (
                    

                      <TextField type='number' label='pincode' maxLength={6} variant="outlined" value={`branchAddresses[index].pincode`}></TextField>


                     
                    )
                  } */}
                  {
                    !EntityUtils.isIndia(Entity) && (
                      <LabeledTextField  rules={{ required: "Zipcode is required" ,
                    pattern: {
                value: /^[0-9]/,
                message: "Only numbers are allowed"
                },
                maxLength: {
                value: 10,
                message: "Zipcode should not be greater than 10 digits in length"
            },
         }} textfieldStyles={{ background: "#fafafa" , width:300}} fontSize="14px"  control={control} name={`branchAddresses.${index}.zipcode`}     />
                    )
                  }
                  {BranchData.branchAddresses.length - 1 === index ?<>
                  <IconButton aria-label='send' size='medium' color='error' m={4} onClick={() => removeBranchAddressLine(index)}>
                    <DeleteIcon />
                  </IconButton>
                  </> : null }

                </Stack>
                {BranchData.branchAddresses.length - 1 === index ?
                  <>   <Box width='100%'>
                    <Stack direction="row" spacing={2} sx={{ float: 'right' }} m={1}>
                      <Button variant='outlined' startIcon={<AddIcon />} p={2} onClick={() => addNewBranchAddress()}>Add Row</Button>
                    </Stack>
                  </Box></> : null
                }
              </>
            })
            }

            <Box width='100%' sx={{ marginTop: 10 }}>
              <Stack direction="row" spacing={2} sx={{ float: 'right' }} m={2}>
                <Button variant='outlined' p={2} onClick={() => { showBranchForm('list') ; reset()}}>Cancel</Button>
                <Button variant='contained' p={2} type="submit">Submit</Button>
              </Stack>
            </Box>
          </Box>
          </form>
        </Modal>
      )
      }

      {BranchMode === 'edit' ? <>
      <form onSubmit={handleSubmit(submitHandler)}   > 
      <Box sx={{ width: '100%' }} >
            <Stack spacing={3}>
              <Stack direction='row' spacing={2} pb={3}>
                <Stack >
                  <LabeledSelectField rules={{ required: "State is required" }} labelStyle={{ color: "black", fontFamily: "Figtree", fontSize: "14px"  }} textfieldStyles={{ background: "#fafafa" , width: 300}} fontSize="14px" label="State*" control={control} name="state"  disabled = {isPendingStatus() || isVerifiedStatus() || !isUserEntityAdmin()}  callback={(e)=>setCitiesFn(e)} options={states || []} />
                </Stack>
                {
                  EntityUtils.isIndia(Entity) && (
                    <Stack>
                  <LabeledTextField rules={{ required: "GST is required" , pattern: {
                value: /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/,
                message: "Invalid GST format. Eg: 22AAAAA0000A1Z5 or 29ABCDE1234F1ZK"
                },
                maxLength: {
                value: 15,
                message: "GST cannot exceed 15 characters"
              } }} labelStyle={{ color: "black", fontFamily: "Figtree", fontSize: "14px"  }} textfieldStyles={{ background: "#fafafa" , textTransform: "uppercase" }} fontSize="14px" label="GST Number*" control={control} name="gst" disabled = {isPendingStatus() || isVerifiedStatus() || !isUserEntityAdmin()} callback={handleChangeGst}  />
                </Stack>
                  )
                } 
                {
                  !EntityUtils.isIndia(Entity) && (
                    <Stack>
                  <LabeledTextField rules={{ pattern: {
                  value: /^[a-zA-Z0-9 ]{0,20}$/,
                 message: 'Invalid Format Special Characters not allowed',
                },
                maxLength: {
                  value: 20,
                  message: "UID Name cannot exceed 20 characters"
                }  }} labelStyle={{ color: "black", fontFamily: "Figtree", fontSize: "14px"  }} textfieldStyles={{ background: "#fafafa" , textTransform: "uppercase" }} fontSize="14px" label="Unique Identifier Document Name" control={control} name="uidName" disabled={ !isUserEntityAdmin()}   />
                </Stack>
                  )
                } 
                {
                  !EntityUtils.isIndia(Entity) && (
                    <Stack>
                  <LabeledTextField rules={{ pattern: {
                  value: /^[a-zA-Z0-9 ]{0,20}$/,
                   message: 'Invalid Format Special Characters not allowed'
                },
                maxLength: {
                  value: 20,
                  message: "UID Name cannot exceed 20 characters"
                }  }} labelStyle={{ color: "black", fontFamily: "Figtree", fontSize: "14px"  }} textfieldStyles={{ background: "#fafafa" , textTransform: "uppercase" }} fontSize="14px" label="Unique Identifier Doc Reference number" control={control} name="uidRefNo" disabled={ !isUserEntityAdmin()}   />
                </Stack>
                  )
                }
                <Stack>
                { EntityUtils.isIndia(Entity) && BranchData.documents[1].fileLink.length === 0 ?<>
                  { uplDocStatus && 
                    <Typography variant="h7" sx={{ fontWeight: 500 }} width={300} >Upload Gst document*</Typography>
                  }

                  {!uplDocStatus && 
                    <Typography variant="h7" sx={{ fontWeight: 500 , color : 'red'}} width={300} >Upload Gst document*</Typography>
                  }
                    <Button
                      component="label"
                      tabIndex={-1}
                      startIcon={<CloudUploadIcon />}
                      disabled={ !isUserEntityAdmin() || isPendingStatus() || isVerifiedStatus()}
                      sx={{
                        border: '1px dashed blue',
                        padding : "5px 70px"   ,
                        marginLeft : '10%',
                        width : 300,
                      }}
                    >
                      Upload file
                      <VisuallyHiddenInput type="file" 
                      onChange={(e1) => {
                              change(e1.target.files, "gstCert", "gstdocs")
                              // clearErrorMessages("gst")
                            }}
                       />
                    </Button>
                    </> : null
                  }
                  {EntityUtils.isIndia(Entity) && BranchData.documents[1].fileLink.length > 0 ? <>
                    <Stack>
                    <Typography variant="h7" sx={{ fontWeight: 500 }} width={300}>Uploaded Gst document</Typography>
                        <TextField size='small' color='primary' variant="outlined" disabled='true' sx={{background: "#fafafa" }}
                          InputProps={{
                            startAdornment: <InputAdornment position="start">   <IconButton
                              aria-label="toggle password visibility"
                              edge="end"
                            >

                              <TaskIcon />
                              <Typography variant="caption"  sx={{maxWidth : 200}} > {BranchData.documents[1].fileLink}</Typography>
                             
                            </IconButton></InputAdornment>,
                             endAdornment: <InputAdornment position="end">   <IconButton
                              aria-label="toggle password visibility"
                              edge="end"
                              onClick={() => window.open(`${process.env.REACT_APP_FILE_SERVER + "/api/v1/filestore/getfile/" +  BranchData.documents[1].pkey +  "/"  + BranchData.documents[1].fileLink}?w=164&h=164&fit=crop&auto=format}`)}
                            >
                              <Visibility  />
                            </IconButton>
                            { (!isPendingStatus() && !isVerifiedStatus() && isUserEntityAdmin() )  && 
                              <IconButton color='error' onClick={()=>deleteDoc('gst')}>
                          <DisabledByDefaultIcon/>
                        </IconButton>

                            }
                            </InputAdornment>,
                          }}
                        ></TextField>
                       
                    </Stack>
                  </> : null

                  }
                </Stack>
              </Stack>
            
                  {!EntityUtils.isIndia(Entity) && BranchData.documents[0].fileLink.length === 0 ?<>
                    <Stack direction='row' spacing={2} pb={3} mt={0} >
              <Stack  >
                  <Typography variant="h7" sx={{ fontWeight: 500 }} width={300}  >Upload UID Document</Typography>
                    <Button
                      component="label"
                      tabIndex={-1}
                      startIcon={<CloudUploadIcon />}
                      disabled={ !isUserEntityAdmin()}
                  
                      sx={{
                        border: '1px dashed blue',
                        padding : "5px 70px"   ,
                        marginLeft : '10%',
                        width : 300,
                      }}
                    >
                      Upload file
                      <VisuallyHiddenInput type="file" 
                      onChange={(e1) => {
                              change(e1.target.files, "Identifer Reference Number", "identifer_refer_number")
                              // clearErrorMessages("gst")
                            }}
                       />
                    </Button>
                    </Stack>
                    </Stack>
                    </> : null
                  }
                  {!EntityUtils.isIndia(Entity) && BranchData.documents[0].fileLink.length > 0 ? <>
                    <Stack direction='row' spacing={2} pb={3} mt={0} >
              <Stack  >
                    
                    <Typography variant="h7" sx={{ fontWeight: 500 }} width={300}>Uploaded UID Document </Typography>
                        <TextField size='small' color='primary' variant="outlined" disabled='true' sx={{background: "#fafafa" }} rules={{}}
                          InputProps={{
                            startAdornment: <InputAdornment position="start"> <IconButton
                              aria-label="toggle password visibility"
                              edge="end"
                            >

                              <TaskIcon />
                              <Typography variant="caption"  sx={{maxWidth : 200}} > {BranchData.documents[0].fileLink}</Typography>
                             
                            </IconButton></InputAdornment>,
                             endAdornment: <InputAdornment position="end">   <IconButton
                              aria-label="toggle password visibility"
                              edge="end"
                              onClick={() => window.open(`${process.env.REACT_APP_FILE_SERVER + "/api/v1/filestore/getfile/" +  BranchData.documents[0].pkey +  "/"  + BranchData.documents[0].fileLink}?w=164&h=164&fit=crop&auto=format}`)}
                            >
                              <Visibility  />
                            </IconButton>
                            <IconButton color='error' disabled={ !isUserEntityAdmin()} onClick={()=>deleteDoc('uid')}>
                          <DisabledByDefaultIcon/>
                        </IconButton></InputAdornment>,
                          }}
                        ></TextField>
                    </Stack>
                    </Stack>
                  </> : null

                  }
              
          
            </Stack>

            <Stack width='100%'>
            {
              scopeofbusiness &&  scopeofbusiness.length > 0 && (
                <FormControl>
                <FormLabel>Scope Of Business</FormLabel>
                 <FormGroup sx={{ display: 'flex' , flexDirection : 'row'}}>
                    <FormControlLabel label='Cross Border'   control={<Checkbox value='Cross Border' checked={scopeofbusiness.includes('Cross Border')} />} />
                    <FormControlLabel label='Domestic Transport' control={<Checkbox  value='Domestic Transport' checked={scopeofbusiness.includes('Domestic Transport')} />} />
                 </FormGroup>
            </FormControl>
              )
            }
      
        </Stack>
          {scopeofbusiness?.includes('Cross Border') && (
            <>
            <Typography variant="h7" sx={{ fontWeight: 600 }}>Agraga Cross Border - Contact Details : </Typography>
            <Stack direction='row' spacing={2} sx={{ paddingTop: 2 }}>
                  <LabeledTextField  label="Agraga Branch "  textfieldStyles={{ background: "#fafafa" , width:300}} fontSize="14px"  name={`crossBorder.agragaBranch`}  disabled control={control} />  
                  {/* <LabeledTextField  label="Relationship Manager"  textfieldStyles={{ background: "#fafafa" , width:300}} fontSize="14px"  name={`crossBorder.relationshipManager`}  disabled control={control} />   */}
                  <Box  width= "100%" >
           <Stack width= "100%">
           <Typography  variant="h8" sx={{ fontWeight: 400 , width: '100%' , fontSize : '14px' }}>Relationship Manager</Typography>
          </Stack>
          <Box style = {{fontSize : '14px' , border : "1px solid darkgrey" , borderRadius : "5px" , backGroundColor : '#a6a5a4' }} >
          <Stack width= "100%" style={{ display : 'flex' , flexDirection : 'row'}}>
          <Typography  variant="h8" sx={{ fontWeight: 600 , marginLeft: 2 }}>Name : </Typography>
           <Typography  variant="h8" sx={{ fontWeight: 400 , marginLeft: 2 , opacity : 0.6 }}>{agusersName.relationShipManager}</Typography>
           </Stack>
          <Stack width= "100%" style={{ display : 'flex' , flexDirection : 'row'}}>
          <Typography  variant="h8" sx={{ fontWeight: 600 , marginLeft: 2 }}>Email : </Typography>
           <Typography  variant="h8" sx={{ fontWeight: 400 , marginLeft: 2 , opacity : 0.6}}>{getValues('crossBorder.relationshipManager')}</Typography>
           </Stack>
          
           </Box>
           {/* <TextField  sx={{ fontWeight: 500 , marginLeft : 1  , width : '100%' }} control={control} name="customer.crossBorder.customerService">{aguserStore.getName4email(getValues('customer.crossBorder.relationshipManager'))} </TextField> */}
           </Box>
                  {/* <LabeledTextField  label="Customer Service"  textfieldStyles={{ background: "#fafafa" , width:300}} fontSize="14px"  name={`crossBorder.customerService`}  disabled control={control} />   */}
                  <Box  width= "100%" >
           <Stack width= "100%">
           <Typography  variant="h8" sx={{ fontWeight: 400 , width: '100%' , fontSize : '14px' }}>Customer Service</Typography>
          </Stack>
          <Box style = {{fontSize : '14px' , border : "1px solid darkgrey" , borderRadius : "5px" , backGroundColor : '#a6a5a4' }} >
          
            <Stack width= "100%" style={{ display : 'flex' , flexDirection : 'row'}}>
            <Typography  variant="h8" sx={{ fontWeight: 600 , marginLeft: 2 }}>Name : </Typography>
           <Typography  variant="h8" sx={{ fontWeight: 400 , marginLeft: 2  , opacity : 0.6}}>{agusersName.customerService  }</Typography>
           </Stack>
           <Stack width= "100%" style={{ display : 'flex' , flexDirection : 'row'}}>
           <Typography  variant="h8" sx={{ fontWeight: 600 , marginLeft: 2 }}>Email : </Typography>
           <Typography  variant="h8" sx={{ fontWeight: 400 , marginLeft: 2 , opacity : 0.6}}>{getValues('crossBorder.customerService')}</Typography>
           </Stack>
          
           </Box>
           {/* <TextField  sx={{ fontWeight: 500 , marginLeft : 1  , width : '100%' }} control={control} name="customer.crossBorder.customerService">{aguserStore.getName4email(getValues('customer.crossBorder.relationshipManager'))} </TextField> */}
           </Box>
                </Stack>
            </>
          )
            
          }
          {scopeofbusiness?.includes('Domestic Transport') && (
            <>
            <Typography variant="h7" sx={{ fontWeight: 600 }}>Agraga Domestic Transport - Contact Details : </Typography>
            <Stack direction='row' spacing={2} sx={{ paddingTop: 2 }}>
                  <LabeledTextField  label="Agraga Branch "  textfieldStyles={{ background: "#fafafa" , width:300}} fontSize="14px"  name={`domesticTransport.agragaBranch`}  disabled control={control} />  
                  <LabeledTextField  label="Relationship Branch"  textfieldStyles={{ background: "#fafafa" , width:300}} fontSize="14px"  name={`domesticTransport.relationshipManager`}  disabled control={control} />  
                  <LabeledTextField  label="Customer Service"  textfieldStyles={{ background: "#fafafa" , width:300}} fontSize="14px"  name={`domesticTransport.customerService`}  disabled control={control} />  
                </Stack>
            </>
          )
            
          }


            <Typography variant="h7" style={{marginBottom : '5%'}}  sx={{ fontWeight: 600  }}>Branch Address : </Typography>
            <Card  style={{ border : '1px solid #c2bebe'}} sx={{border : 1 , paddingBottom : 0 , width: '80%'}}>
              <CardContent direction='column'  style={{ padding: "0.5% 4% 0.5% 4%"}} sx={{ display: 'flex', justifyContent: 'space-between', background : '#f7f5f5' }} >
                <Typography variant="h8" sx={{ fontWeight: 600 }}>Address </Typography>
                <Typography variant="h8" sx={{ fontWeight: 600 }}>City </Typography>
                {
                  EntityUtils.isIndia(Entity) && (
                    <Typography variant="h8" sx={{ fontWeight: 600 , width: '25%' }}>Pincode </Typography>
                  )
                }
                {
                  !EntityUtils.isIndia(Entity) && (
                    <Typography variant="h8" sx={{ fontWeight: 600 }}>Zipcode </Typography>
                  )
                }
              </CardContent>
            </Card>
            {BranchData.branchAddresses?.map((r, index) => {
              return <>
                <Stack direction='row' spacing={2} sx={{ paddingTop: 2 }}>
                  <LabeledTextField rules={{ required: "Address is required" }}  textfieldStyles={{ background: "#fafafa" , width:300}} fontSize="14px"  control={control} name={`branchAddresses.${index}.address`}  disabled = {isPendingStatus() || !isUserEntityAdmin()}  />
                  {
                    EntityUtils.isIndia(Entity) && (
                      <LabeledSelectField rules={{ required: "City is required" }} labelStyle={{ color: "black", fontFamily: "Figtree", fontSize: "14px"  }} label='' textfieldStyles={{ background: "#fafafa" , width:300}} fontSize="14px"  control={control} name={`branchAddresses.${index}.city`}  disabled = {isPendingStatus() || !isUserEntityAdmin()}  options={cities || []} />
                    )
                  }
                  {
                    !EntityUtils.isIndia(Entity) && (
                      <LabeledTextField rules={{ required: "City is required" }} labelStyle={{ color: "black", fontFamily: "Figtree", fontSize: "14px"  }} textfieldStyles={{ background: "#fafafa" , width:300}} fontSize="14px"  control={control} name={`branchAddresses.${index}.city`}  disabled = {isPendingStatus() || !isUserEntityAdmin()}   />
                    )
                  }

                  {
                    EntityUtils.isIndia(Entity) && (
                    <LabeledTextField rules={{ required: "Pincode is required" , pattern: {
                value: /^[0-9]/,
                message: "Only numbers are allowed"
                },
                maxLength: {
                value: 6,
                message: "Pincode should be 6 digits in length"
            },
            minLength: {
                value: 6,
                message: "Pincode should be 6 digits in length"
            } }}  textfieldStyles={{ background: "#fafafa" , width:300}} fontSize="14px"  control={control} name={`branchAddresses.${index}.pincode`}  disabled = {isPendingStatus() || !isUserEntityAdmin() }   />
                    )
                  }
                  {
                    !EntityUtils.isIndia(Entity) && (
                      <LabeledTextField rules={{ required: "Zipcode is required" , pattern: {
                value: /^[0-9]/,
                message: "Only numbers are allowed"
                },
                maxLength: {
                value: 10,
                message: "Zipcode should not be greater than 10 digits in length"
            },
            }}  textfieldStyles={{ background: "#fafafa" , width:300}} fontSize="14px"  control={control} name={`branchAddresses.${index}.zipcode`}  disabled = {isPendingStatus() || !isUserEntityAdmin()}   />
                    )
                  }
                  {BranchData.branchAddresses.length - 1 === index && !isPendingStatus() &&  isUserEntityAdmin() ? (
                    <IconButton aria-label='send' size='medium' color='error' m={4} onClick={() => removeBranchAddressLine(index)}>
                    <DeleteIcon />
                  </IconButton> 
                  
                  ) : <IconButton  disabled>  <DeleteIcon /></IconButton>
                    }
                   

                </Stack>
                {BranchData.branchAddresses.length - 1 === index && !isPendingStatus() && isUserEntityAdmin() ?
                  <>   <Box width='100%'>
                    <Stack direction="row" spacing={2} sx={{ float: 'right' }} m={1}>
                      <Button variant='outlined' startIcon={<AddIcon />} p={2} onClick={() => addNewBranchAddress()}>Add Row</Button>
                    </Stack>
                  </Box></> : null
                }
              </>
            })
            }

            {
              isRejectedStatus() && (
                <>
                <Box sx={{display : 'flex' , alignItems : 'center' , marginTop : 4}}>
          <Typography variant="h6" sx={{ fontWeight: 600 }} >Rejected Remarks :</Typography>
          <Typography variant="h8" sx={{ fontWeight: 500 , marginLeft : 1 }}>{getValues('remarks')} </Typography>
          </Box>
                </>
              )
            }

           
            <Box width='100%' sx={{ marginTop: 10 }}>
              <Stack direction="row" spacing={2} sx={{ float: 'right' }} m={2}>
                <Button variant='outlined' p={2} onClick={() => showBranchForm('list')}>Cancel</Button>
                <Button variant='contained' p={2} type="submit" disabled={!isUserEntityAdmin() || isPendingStatus()}>Submit</Button>
              </Stack>
            </Box>
          </Box>
          </form>
      

      </> : null
      }
      </Box>
      </Box>
     
    </>
  )
}

export default BranchList
