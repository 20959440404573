import Api from "./Api"
import {
  configStore,
  routerStore,
  cususerStore,
  aguserStore,
  loginStore,
  AgmodalStore,
} from "./CommonState"
import { CustomeruserType } from "./Types"


const USERSAPI = {
  POST: `${process.env.REACT_APP_API_SERVER}/api/v1/stakeholder/adduser`,
  SENDINVITE: `${process.env.REACT_APP_API_SERVER}/api/v1/stakeholder/senduserinvite`,
  GETUSER: (emailId,entityId) =>
   `${process.env.REACT_APP_API_SERVER}/api/v1/stakeholder/checkuser/${emailId}/${entityId}`,
   GETPROFILE: (emailId) =>
   `${process.env.REACT_APP_API_SERVER}/api/v1/stakeholder/getuserprofile/${emailId}`,
   USERLOGIN: (data) =>
   `${process.env.REACT_APP_API_SERVER}/api/v1/stakeholder/loginuser`,
   VIEWUSER: (emailId) =>
   `${process.env.REACT_APP_API_SERVER}/api/v1/stakeholder/viewuser/${emailId}`,
   UPDATEUSERSTATUS: (data) =>
   `${process.env.REACT_APP_API_SERVER}/api/v1/stakeholder/updateuserstatus/${data.email}`,
   GETUSERBYMOBILE: (mobile) =>
    `${process.env.REACT_APP_API_SERVER}/api/v1/stakeholder/getuserprofilebymobile/${mobile}`,
}
export default class UsersApis {
    static async createUser(data,callback,userData ,type) {
        console.log("about to call api.post")
        let DATA
        if(type === 'cusCreation'){
         DATA = userData
        }else {
           DATA = {...cususerStore.cususerRec , ...data}
        }

        console.log('usersdata',DATA)
        Api.post(USERSAPI.POST, DATA, (data) => {
          if(data?.data?.status === 200){
            configStore.setModalMessage("User Created & Invite Send your Mail Successful")
          }else{
            configStore.setModalMessage("User Created Successful")
          }
          cususerStore.cususerRec.set( CustomeruserType)
          routerStore.entityUserManageView = "list"
          if(callback){
            callback(data)  
          }
        })
      }

      static async checkUser(email , entityId, setUserdata) {
        console.log("about to call api.post")
        Api.fetch1(USERSAPI.GETUSER(email, entityId) , (data) => {
          console.log('lklklk' , data);
        if(data.status=== 200){
          setUserdata(data)
        }else if(data.status === 201){
          configStore.setModalMessage(data.message)
          // setUserdata(new CustomeruserType())
          cususerStore.cususerRec.set(new CustomeruserType())
        }else if (data.status === 404){
          setUserdata(data)
        }
        })
      }

      static async sendInviteMail(data,user , setUserStatusfn , callback) {
        console.log("about to call api.post")
        Api.post(USERSAPI.SENDINVITE , data, (datas) => {
        if(datas){
          if(datas.status === 402){
            configStore.setModalMessage("Invite cannot be sent")
          }else {
            configStore.setModalMessage("Invite sent succesfully")
            user.inviteSend= true
            routerStore.entityUserManageView = "list"        
            setUserStatusfn && setUserStatusfn()     
          }
          if(callback){
            callback(datas)
          }
        }
        })
      }

      static async viewUser(email, callback) {
        console.log("about to call api.post")
        Api.fetch1(USERSAPI.VIEWUSER(email) , (data) => {
        if(data){
          cususerStore.cususerRec.set(data.data)
          AgmodalStore.pageVisible = true;
          AgmodalStore.modalPage = 'ViewAguser'
          if(callback){
            callback(data.data)
          }
        }
        })
      }
   
      static async viewAgragaUser(email , callback) {
        console.log("about to call api.post")
        Api.fetch1(USERSAPI.VIEWUSER(email) , (data) => {
        if(data){
          callback(data)
        }
        })
      }

      static async getProfile(email , setUserProfile) {
        console.log("about to call api.post")
        Api.fetch1(USERSAPI.GETPROFILE(email) , (data) => {
        if(data){
          setUserProfile(data.data)
        }
        })
      }

      static async getProfileByMobile(mobile, datacallback = (f) => f) {
        console.log("about to call api.post");
        try {
          await Api.fetch1(USERSAPI.GETUSERBYMOBILE(mobile), (data) => {
            if(data){
              datacallback(data)
            }
          });
          
        } catch (error) {
          console.error("Error fetching profile by mobile", error);
          throw error; 
        }
      }

      static async loginUser(data , datacallback = (f) => f) {
        console.log("about to call api.post")
        Api.post(USERSAPI.USERLOGIN(data) , data , (dataa) => {
          if(!dataa.userFound){
          configStore.setModalMessage(dataa.message)
          if(datacallback)
            datacallback(dataa)
          }
       else  if(dataa.userFound){
          loginStore.userRec = dataa;
          loginStore.email = dataa.email;
          datacallback(dataa)
        }
        })
      }

      static async deactivateUser(data , setremarksPopup) {
        console.log("about to call api.post")
        Api.put(USERSAPI.UPDATEUSERSTATUS(data) , data , (datas) => {
        if(datas){
          // datacallback(data)
          cususerStore.cususerRec.set(new CustomeruserType())
          routerStore.entityUserManageView = "list"
          setremarksPopup(false)
        }
        })
      }
}
