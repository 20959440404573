import { useState } from "react";
import { useSnapshot } from "valtio";
import { bookingsStore, configStore } from "../state/CommonState.js";
import { bookingCancellationReasonList } from "../StaticData.js";

const warnMsg = `IMPORTANT: Cancellation charges might be Applicable. If you still want to proceed with Cancellation, Team Agraga will check and confirm cancellation charges, if any.`;
export default function AgBookingRequestCancellationv3({ ..._props }) {
  useSnapshot(bookingsStore);
  const [hasToShowTextBox, setHasToShowTextBox] = useState(false);
  return (
    <>
      <div
        class={
          bookingsStore.hasToShowRequestCancellation
            ? "modal is-active"
            : "modal"
        }
      >
        <div class="modal-background"></div>
        <div class="modal-content">
          <article class="message">
            <div class="message-body">
            <p>Reason:</p>
              <div className="card mb-5">
                <div className="card-content">
                  <div className="container mb-3">
                    {bookingCancellationReasonList.map((reason) => (
                      <>
                        <div className="field">
                          <label className="radio control">
                            <input
                              type="radio"
                              name="cancellationReason"
                              value={reason}
                              style={{ marginRight: "7px" }}
                              onChange={(e) => {
                                if (e.target.value === "Others") {
                                  bookingsStore.requestCancellationReason =
                                    null;
                                  setHasToShowTextBox((p) => !p);
                                } else {
                                  setHasToShowTextBox(false);
                                  bookingsStore.requestCancellationReason =
                                    e.target.value;
                                }
                              }}
                              checked={
                                bookingsStore.requestCancellationReason ===
                                reason
                              }
                            />
                            {`${reason}.`}
                          </label>
                        </div>
                      </>
                    ))}
                  </div>
                  {hasToShowTextBox && (
                    <>
                      <div class="field">
                        <label class="label">Cancellation Reason</label>
                        <div class="control">
                          <textarea
                            class="textarea is-warning"
                            placeholder="Cancellation Reason"
                            name={`requestCancellationReason`}
                            onChange={(e) => {
                              bookingsStore.requestCancellationReason =
                                e.target.value;
                            }}
                          ></textarea>
                        </div>
                      </div>
                    </>
                  )}
                </div>
                <p className="is-size-7 is-italic has-text-danger px-4 pb-4">
                  {warnMsg}
                </p>
              </div>
              <div
                className="buttons"
                style={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <button
                  type="button"
                  className="button is-danger is-small"
                  onClick={() => {
                    bookingsStore.hasToShowRequestCancellation = false;
                    bookingsStore.requestCancellationReason = null;
                    setHasToShowTextBox(false);
                  }}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="button is-warning is-small"
                  onClick={() => {
                    bookingsStore.hasToShowRequestCancellation = false;
                    configStore.hasToShowAgWarn = true;
                  }}
                >
                  Submit
                </button>
              </div>
            </div>
          </article>
        </div>
        <button
          class="modal-close is-large"
          aria-label="close"
          onClick={() => {
            bookingsStore.hasToShowRequestCancellation = false;
            bookingsStore.requestCancellationReason = null;
            setHasToShowTextBox(false);
          }}
        >
          Cancel
        </button>
      </div>
    </>
  );
}
