import ListQuote from './ListQuote'
import QuoteMain from './QuoteMain'
import MainAguser from './agusers/main-agusers'
import Tbd from './tbd.'
import MainEntity from './entity/main-entity'
import MainEntityV2 from './entity-v2/Entities/main-entity-v2'
import MainVehicle from './vehicle/main-vehicle'
import MainBranch from './entity-v2/Branches/main-branch'
import MainDrivers from './drivers/main-drivers'
import EditAguser from './agusers/edit-aguser'
import QuoteListClient from './schedules/QuoteListClient'
import QuoteListBPO from './schedules/QuoteListBPO'
import QuoteClientMain from './schedules/QuoteClientMain'
import Dashboard from './components/dashboard'
import BookingList from './mybooks/booking-list'
import ListEnquiry from './enquiry/list-enquiry'
import HomeScreen from './enquiry/home-screen'
import HomeMain from './enquiry/home-main'
import ListEnquiryMain from './enquiry/list-enquiry-main'
import ListBookingsMain from './mybooks/booking-list-main'
import MainMyactions from './my-actions/main-myactions'
import CustomerMyactions from './my-actions/customer-myactions'
import EnquiryHome from './enquiry/enquiry_home'
import TaskList from './mytasks/task-list'
import { C1 } from './charts/c1'
import { ChartHome } from './charts/chart.home'
import { ChartNewHome } from './charts/chart.new.home'
import Uploads from './uploads/uploads'
import PricingCalc from './pricing/pricing.calc'
import AuditAgusers from './agusers/audit-agusers'
import PricingScreen from './pricing/pricing.screen'
import Report from './Report'
import Home2 from './Home2'
import DtQuoteMain from './dt/DtQuoteMain'
import DtBookingMain from './dt/DtBookingMain'
import DtContractMain from './dt/DtcontractMain'
import Devices from './components/devices'
import SiteInformation from './siteinformation/siteinformation'
import PricingScreen2 from './pricing/pricing.screen.v2'
import Dtactionslist from './dtactions/dtactions-list'
import PricingConfig from './pricing/pricing-config'
import QuoteMainv2 from './quotes/quote-main-v2'
import Operationscb from './Operationscb'
import CustomersUsers from './CustomersUsers'
import Salescb from './Salescb'
import HomeMainv2 from './enquiryv2/home-main-v2'
import ListBookingsMainv2 from './mybooksv2/booking-list-main-v2'
import Spotv3 from './spotv3/spot.v3'
import SpotBookingv3 from './spotv3/spot.booking.v3'
import { ROLES } from "./util/constants";
import MainEntityApproval from "./entity-approvals/main-entityApproval";
import Fclpricing from './fclpricing'
import { configStore } from './state/CommonState'
import TrackMyBooking from './trackmybookings/TrackMyBookings'
import EnquiryHomeV2 from './enquiryv2/enquiry_home-v2'
import Schedules from './port-to-port-schedules/schedules'
import ListEnquiryMainv3 from './enquiryv3/list-enquiry-main-v3'
import QuoteMainv3 from './quotesv3/quote-main-v3'
import HomeMainv3 from './enquiryv3/home-main-v3'
import ListBookingsMainv3 from './mybooksv3/booking-list-main-v3'
import QuickQuotev3 from './quick-quotev3/quick-quote-v3'
const excludedRolesForReportsAuditAccess = [
    ROLES.CENTRAL.BPO,
    "OPERATIONS",
    "CLIENT USER",
];

const oldExcludedRoles = [ROLES.CENTRAL.BPO, "OPERATIONS", "CLIENT USER"];

const menuList = [
    // {
    //     name: 'Home',
    //     key: 'Home',
    //     href: '/',
    //     component: <ChartNewHome />,
    //     isRoleBased: true,
    //     classname: "home"
    // },
    {
        name: 'Cross-Border',
        key: '',
        classname: "crossborder",
        isRoleBased: true,
        children: [
            // {
            //     name: 'Enquiries Received',
            //     key: 'Enquiries',
            //     href: '/',
            //     component: <ListEnquiryMain />,
            //     isRoleBased: true,
            //     classname: "enquiries"
            // },
            {
                name: 'Enquiries Received',
                key: 'Enquiriesv3',
                href: '/',
                component: <ListEnquiryMainv3 />,
                isRoleBased: true,
                classname: "enquiries"
            },

            // {
            //     name: 'Quotations',
            //     key: 'Quotation',
            //     href: '/',
            //     component: <QuoteMain />,
            //     classname: "crossborderquotes"
            // },
            // {
            //     name: 'Quotations',
            //     key: 'Quotationv2',
            //     href: '/',
            //     component: <QuoteMainv2 />,
            //     isRoleBased: true,
            //     classname: "crossborderquotes"
            // },
            {
                name: 'Quotations',
                key: 'Quotationv3',
                href: '/',
                component: <QuoteMainv3 />,
                isRoleBased: true,
                classname: "crossborderquotes"
            },
            // {
            //     name: 'Upload Schedules',
            //     key: 'Upload Schedules',
            //     href: '/',
            //     component: <HomeMain />,
            //     classname: "uploadschedules",
            // },
            // {
            //     name: 'Upload Schedules',
            //     key: 'Upload Schedulesv2',
            //     href: '/',
            //     component: <HomeMainv2 />,
            //     isRoleBased: true,
            //     classname: "uploadschedulesv2",
            // },
            {
                name: 'Upload Schedules',
                key: 'Upload Schedulesv3',
                href: '/',
                component: <HomeMainv3 />,
                isRoleBased: true,
                classname: "uploadschedulesv3",
            },
            /*{
                name: 'Spot Booking - LCL',
                key: 'Spot Booking - LCL',
                href: '/',
                component: <PricingScreen2 />,
                isRoleBased: true,
                classname: "pricingcalculator",
            },*/
            // {
            //     name: 'Quick Quote',
            //     key: 'Spot Booking WIP',
            //     href: '/',
            //     component: <Spotv3 />,
            //     isRoleBased: true,
            //     classname: "spotv3",
            // },
            {
                name: 'Quick Quote',
                key: 'Spot Booking WIP',
                href: '/',
                component: <QuickQuotev3 />,
                isRoleBased: true,
                classname: "spotv3",
            },
            // {
            //     name: 'Bookings',
            //     key: 'Bookings',
            //     href: '/',
            //     component: <ListBookingsMain />,
            //     classname: "crossborder_bookings",
            // },
            // {
            //     name: 'Bookings',
            //     key: 'Bookingsv2',
            //     href: '/',
            //     component: <ListBookingsMainv2 />,
            //     isRoleBased: true,
            //     classname: "crossborder_bookingsv2",
            // },
            {
                name: 'Bookings',
                key: 'Bookingsv3',
                href: '/',
                component: <ListBookingsMainv3 />,
                isRoleBased: true,
                classname: "crossborder_bookingsv2",
            },
            {
                name: 'Visibility',
                key: 'Visibility',
                href: '/',
                component: <CustomerMyactions />,
                isRoleBased: true,
                classname: "visibility",
            },
            {
                name: 'My Actions',
                key: 'My Actions',
                href: '/',
                component: <TaskList />,
                isRoleBased: true,
                classname: "myactions",
            },
        ],
    },
    {
        name: 'Domestic Transport',
        key: '',
        isRoleBased: true,
        classname: "domestictransport",
        children: [
            {
                name: 'Quotations',
                key: 'Domestic Quotations',
                href: '/',
                component: <DtQuoteMain />,
                isRoleBased: true,
                classname: "domesticquotes",
            },
            {
                name: 'Bookings',
                key: 'Domestic Transport Booking',
                href: '/',
                component: <DtBookingMain />,
                isRoleBased: true,
                classname: "domestictransport_bookings",
            },
            {
                name: 'My Actions',
                key: 'Domestic Transport Actions',
                href: '/',
                component: <Dtactionslist />,
                isRoleBased: true,
                classname: "dtactionslist",
            },
        ],
    },
    // {
    //     name: 'Analytics',
    //     key: '',
    //     classname: "analytics",
    //     isRoleBased: true,
    //     children: [
    //         {
    //             name: 'Operations CB',
    //             key: 'Operations CB',
    //             href: '/',
    //             component: <Operationscb />,
    //             isRoleBased: true,
    //             classname: "operations_cb",
    //         },
    //         {
    //             name: 'Sales CB',
    //             key: 'Sales CB',
    //             href: '/',
    //             component: <Salescb />,
    //             classname: "sales_cb",
    //             isRoleBased: true,
    //         },
    //         {
    //             name: 'FCL Pricing',
    //             key: 'FCL Pricing',
    //             href: '/',
    //             component: <Fclpricing />,
    //             isRoleBased: true,
    //             classname: "fclpricing",
    //         },
    //     ],
    // },
    {
        name: 'Config',
        key: 'Config',
        href: '/',
        component: <PricingConfig />,
        classname: "config",
        isRoleBased: true,
    },
    {
        name: 'Schedules',
        key: 'Schedules',
        href: '/',
        component: <Schedules />,
        classname: "config",
        isRoleBased: true,
    },
    {
        name: 'Uploads',
        key: 'Uploads',
        href: '/',
        component: <Uploads />,
        classname: "uploads",
        isRoleBased: true,
    },
    // {
    //     name: 'Entities',
    //     key: 'Entities',
    //     href: '/',
    //     component: <MainEntity />,
    //     isRoleBased: true,
    //     classname: "entities",
    // },
    {
        name: 'Entities',
        key: 'Entitiesv2',
        href: '/',
        isRoleBased: true,
        component: <MainEntityV2 />
    },
    {
        name: 'Approvals',
        key: 'Approvals',
        href: '/',
        component: <MainEntityApproval />,
        isRoleBased: true,
    },
    {
        name: 'Vehicles',
        key: 'Vehicle List',
        href: '/',
        isRoleBased: true,
        component: < MainVehicle />
    },
    {   
        name: 'Drivers',
        key: 'Drivers List',
        href: '/',
        isRoleBased: true,
        component: < MainDrivers />
    },
    {
        name: 'Customers',
        key: 'Customers',
        href: '/',
        component: <CustomersUsers />,
        isRoleBased: true,
        classname: "customers_users",
    },
    {
        name: 'Users',
        key: 'Users',
        href: '/',
        isRoleBased: true,
        component: <MainAguser />,
        classname: "users",
    },
    {
        name: 'Audit',
        key: 'Audit',
        href: '/',
        isRoleBased: true,
        component: <AuditAgusers />,
        classname: "audit",
    },
    {
        name: 'Reports',
        key: 'Reports',
        href: '/',
        component: <Report />,
        exclude: excludedRolesForReportsAuditAccess,
        classname: "reports",
    },
    {
        name: 'Access Control',
        key: 'Devices',
        href: '/',
        component: <Devices />,
        exclude: excludedRolesForReportsAuditAccess,
        classname: "devices",
    },
    // {
    //     name: 'Site-Information',
    //     key: 'Site-Information',
    //     href: '/',
    //     isRoleBased: true,
    //     component: <SiteInformation />,
    //     classname: "siteinformation",
    // }
]

console.log(configStore?.currentSelectedEntity, "select");
let currentEntity = localStorage.getItem('ag_current_entity')
currentEntity = JSON.parse(currentEntity)
console.log(currentEntity, "air");
const clientMenuList = [
    // {
    //     name: 'Dashboard',
    //     href: '/',
    //     component: <Dashboard/>
    // },
    {
        name: 'Home',
        key: 'Home',
        href: '/',
        component: <ChartNewHome />,
        classname: "home",
    },
    {
        name: 'Cross-Border',
        key: '',
        classname: "crossborder",
        children: [
            {
                name: 'Enquiries',
                key: 'Enquiries',
                href: '/',
                component: <EnquiryHome />,
                classname: "enquiries"
            },
            {
                name: 'Spot',
                key: 'Spot Booking - LCL',
                href: '/',
                component: <PricingScreen2 />,
                classname: "pricingcalculator",
            },
            {
                name: 'Spot - WIP',
                key: 'Spot Booking WIP',
                href: '/',
                component: <Spotv3 />,
                isRoleBased: true,
                classname: "spotv3",
            },
            // {
            //     name: 'Create Booking',
            //     key: 'Create Booking',
            //     href: '/',
            //     component: <HomeMain />,
            //     classname: "crossborder_bookings",
            // },
            {
                name: 'Create Booking',
                key: 'Create Bookingv2',
                href: '/',
                component: <HomeMainv2 />,
                classname: "crossborder_bookings",
            },
            // {
            //     name: 'My Bookings',
            //     key: 'Bookings',
            //     href: '/',
            //     component: <ListBookingsMain />,
            //     classname: "crossborder_bookings",
            // },
            {
                name: 'My Bookings',
                key: 'Bookingsv2',
                href: '/',
                component: <ListBookingsMainv2 />,
                classname: "crossborder_bookings",
            },
            {
                name: 'Visibility',
                key: 'Visibility',
                href: '/',
                component: <CustomerMyactions />,
                classname: "visibility",
            },
            {
                name: 'My Actions',
                key: 'My Actions',
                href: '/',
                component: <TaskList />,
                classname: "myactions",
            },

        ],
    },
    {
        name: 'Domestic Transport',
        key: '',
        classname: "domestictransport",
        children: [
            {
                name: 'Contracts',
                key: 'Domestic Transport',
                href: '/',
                component: <DtContractMain />,
                classname: "domesticcontracts",
            },
            {
                name: 'Bookings',
                key: 'Domestic Transport Booking',
                href: '/',
                component: <DtBookingMain />,
                classname: "domestictransport_bookings",
            },
        ],
    },
    {
        name: 'Access Control',
        key: 'Devices',
        href: '/',
        component: <Devices />,
        classname: "devices",
    },
    {
        name: 'Track My Bookings',
        key: 'Track My Bookings',
        href: '/',
        component: <TrackMyBooking />,
        classname: "Track My Bookings",
        excludeMenu: true,
    }

]

export default menuList
export {
    clientMenuList
}