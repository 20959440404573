export const commodityType = {
  Machinery: "COM01",

  "Spare Parts": "COM12",

  Antennas: "COM35",

  Equipment: "COM01",

  Plastic: "COM22",

  "Containerized general mechandise": "COM10",
  "Medical Equipment": "",
  "Automotive Spare Parts & Components": "COM12",
  Rubber: "COM31",
  Timber: "",
  "Metal - Steels, Zinc, Lead, Aluminum, Copper Sheets, or Ingots or Coils, High-value electronic scrap":
    "COM21",

  "Packaged Food Items (Canned, Dry Fruits & Nuts, Dressings, Cinnamon, Spices, Rice, Sugar of any kind excluding only raw sugar, Coffee/ Tea, Milk Powder)":
    "COM25",
  "Leather & Finished Leather Products": "COM16",
  "Textiles/ Fabrics/ Apparel/ Accessories/ Footwear": "COM13",
  Toys: "COM54",
  "Wheels and Tyres": "COM31",
  "Casino Machines": "COM01",
  "Raw materials in general classified as high risk": "COM06",
  "Raw or bulk polypropylene, polyethylene and latex": "COM29",
  "Non-Hazardous Chemicals (including agrochemicals, fungicides, insecticides, fertilizers":
    "COM19",
  "Wood and wooden articles": "COM33",
  "Fragile items such as tableware, ceramics, glass, among other": "COM27",
  "Cigarettes, tobacco, spirits, beers, wines, and similar products": "",
  "Business personal computers or Laptops, computer parts such as displays, hard drives, modems, electronic":
    "COM35",
  "Printers and their parts": "COM35",
  "Sound equipment": "COM35",
  "Video games and consoles": "COM35",
  "Video, TV or Photography cameras": "COM35",
  "Televisions - LED's, LCD's, Flat screens, Plasmas or the like": "COM71",
  "Appliances such as refrigerators, washing machines, portable air conditioners, dryers, blenders, kitchen":
    "COM71",
  "Articles related to COVID (medical implements, masks, tests, antibacterial, among others":
    "",
};

export let commodityType1={
  Machinery:  "New Machinery or Mechanical/ Electrical/ Electronic Equipment for Industrial use",
  "Spare Parts":	"New Machinery spare parts/ Automobile parts/ Hardware tools",
  Antennas:	"New electronic goods and their spares (Computers and their peripherals/ tablets/ mobile phones/ cables/ chargers etc)",
  Equipment:	"New Machinery or Mechanical/ Electrical/ Electronic Equipment for Industrial use",
  Plastic:	"New Articles of plastic/plastic made items",
  "Containerized general mechandise":	"All types of Non-Refrigerated Container",
    
  "Automotive Spare Parts & Components":	"New Machinery spare parts/ Automobile parts/ Hardware tools",
  Rubber:	"Articles made of rubber (tyres, tubes etc)",
    
  "Metal - Steels, Zinc, Lead, Aluminum, Copper Sheets, or Ingots or Coils, High-value electronic scrap":	"New Metal rods/Sheets/Coils/Pipes/Cables/Wires/Billets/Ignots/Cathodes (Excluding precious metals and metal scrap)",
  "Packaged Food Items (Canned, Dry Fruits & Nuts, Dressings, Cinnamon, Spices, Rice, Sugar of any kind excluding only raw sugar, Coffee/ Tea, Milk Powder)":	"Processed food/edible items (Confectionery like chocolates and candies/ baked goods/ biscuits and other snacks/ canned goods/ pickles and jams/ ready to eat,meals/ pastas/ noodles etc)",
  "Leather & Finished Leather Products":	"Articles of leather/Saddlery and harness/travel bags, baggage, handbags and similar",
  "Textiles/ Fabrics/ Apparel/ Accessories/ Footwear":	"Readymade Garments/Clothes/Footwear",
  Toys:	"Toys and other children's games with or without electrical or mechanical parts",
  "Wheels and Tyres":	"Articles made of rubber (tyres, tubes etc)",
  "Casino Machines":	"New Machinery or Mechanical/ Electrical/ Electronic Equipment for Industrial use",
  "Raw materials in general classified as high risk":	"Minerals (Mercuric Sulphide/ Dolomite/ Bentonite/ Bleaching Earth/ Kaolin/ Attapulgite/ Soda Ash/ Aluminium Silicate/ Potassium sulphate/ Limestone/ Clay/ Sand/ Gravel/ Diatomite/ Silica/ Barite/ Gypsum/ Talc/ Natural graphite)",
  "Raw or bulk polypropylene, polyethylene and latex":	"Raw plastic/ plastic granules",
  "Non-Hazardous Chemicals (including agrochemicals, fungicides, insecticides, fertilizers":	"Liquid chemicals/Paints/Dyes/Intermediates",
  "Wood and wooden articles":	"New furniture, modular kitchen sets and the like made of metal/ wood/ glass",
  "Fragile items such as tableware, ceramics, glass, among other":  "Finished fragile goods made of ceramic/ glass/ marble/ granite/ stone (Like tiles, sanitaryware, lighting equipment, handicrafts etc)",
    
  "Business personal computers or Laptops, computer parts such as displays, hard drives, modems, electronic":  "New electronic goods and their spares (Computers and their peripherals/ tablets/ mobile phones/ cables/ chargers etc)",
  "Printers and their parts":	"New electronic goods and their spares (Computers and their peripherals/ tablets/ mobile phones/ cables/ chargers etc)",
  "Sound equipment":	"New electronic goods and their spares (Computers and their peripherals/ tablets/ mobile phones/ cables/ chargers etc)",
  "Video games and consoles":	"New electronic goods and their spares (Computers and their peripherals/ tablets/ mobile phones/ cables/ chargers etc)",
  "Video, TV or Photography cameras":	"New electronic goods and their spares (Computers and their peripherals/ tablets/ mobile phones/ cables/ chargers etc)",
  "Televisions - LED's, LCD's, Flat screens, Plasmas or the like":	"White Goods/ Large Appliance - AC, Fridge, LED, washing machine, Microwave, Dishwasher",
  "Appliances such as refrigerators, washing machines, portable air conditioners, dryers, blenders, kitchen":	"White Goods/ Large Appliance - AC, Fridge, LED, washing machine, Microwave, Dishwasher"
    
}

