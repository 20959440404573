import { useState } from "react";
import { AntSwitch } from "../mui-components/AntSwitch";
export function StuffingTypeForFcl({title,onChange,disabled,value}){
    return <div className="flex flex-row gap-2.5 items-center">
        <p className="text-sm text-[#4BA7DD] font-bold">{title}</p>
        <p className="text-sm text-grey">Factory</p>
        <AntSwitch disabled={disabled} checked={value=='Dock'} onChange={(e)=>{
            if(onChange){
                onChange(e);
            }
        }}/>
         <p className="text-sm text-grey">Cfs</p>
    </div>
}