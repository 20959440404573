import { DetailsSelect2 } from "../mui-components/DetailsSelect";
import { DetailsText2, DetailsText3 } from "../mui-components/DetailsText";
import { ControlledDetailSwitch } from "./AgControlledDetailSwitch";
import { StuffingTypeForFcl } from "./AgStuffingTypeForFcl";
import { useEffect, useState } from "react";
import { Cargo, Container } from "../enquiry/enquiry-data";
import { getHazardousClassList, packingGroupList } from "../StaticData";
import { useSnapshot } from "valtio";
import { configStore, quoteDataRec } from "../state/CommonState";

export function CargoTypes({form,viewonly}){
    useSnapshot(quoteDataRec)
    useSnapshot(configStore)
    const { control, register, formState: { errors }, handleSubmit, resetField, watch, setValue, reset , getValues} = form;
    let hazardous = watch("hazardous");
    let Scope = watch("scope")
    let mode = watch("mode")
    let shipmentType = watch("shipmentType")
    let nonstackable = watch("nonStackable");
    let temperatureControlled = watch("temperatureControlled");
    let minTemperature = watch("minTemperature");
    let maxTemperature = watch("maxTemperature");
    const stuffingType = watch("stuffingType")
    const destuffingType = watch("destuffingType")
    const multidest = getValues("multidest")
    useEffect(()=>{
        if(mode=="FCL"){
            let l = `FCL (${stuffingType}/${destuffingType})`
            if(shipmentType!=l){
                setValue("shipmentType",l)
            }
        }else{
            if(shipmentType!=mode){
                setValue("shipmentType",mode)
            }
        }
        if(stuffingType==="Dock" && destuffingType === "Dock"){
            setValue("temperatureControlled",false)
        }
        if(quoteDataRec.quotationNum == "" && configStore.disableShipmentType == "" && !viewonly){
            if(multidest!=undefined){
                let l = [
                    {
                        destination:multidest[0]['destination'],
                        cargoDetails:[new Cargo()],
                    }
                ]
                if(JSON.stringify(l)!=JSON.stringify(multidest)){
                    setValue("multidest",l)
                }
                setValue("containerDetails",[new Container()])
            }else{
                let l = [
                    {
                        destination:"",
                        cargoDetails:[new Cargo()],
                    }
                ]
                if(JSON.stringify(l)!=JSON.stringify(multidest)){
                    setValue("multidest",l)
                }
                setValue("containerDetails",[new Container()])
            }
        }
    },[stuffingType,destuffingType])
    useEffect(()=>{
        if(quoteDataRec.quotationNum == "" && configStore.disableShipmentType == "" && !viewonly){
            resetField("hazardous",false);
            resetField("nonStackable",false);
            resetField("temperatureControlled",false);
            resetField("minTemperature","");
            resetField("maxTemperature","")
            resetField("stuffingType","Factory")
            resetField("destuffingType","Factory")
        }
    },[])
     useEffect(() => {
         if(!hazardous){
             resetField("quoteClass")  
             resetField("packingGroup") 
             resetField("unnumber")
         }
     }, [hazardous])
 
     useEffect(() => {
     }, [nonstackable])
 
     useEffect(() => {
         if(!temperatureControlled){
              if(mode.toLowerCase() === 'fcl'){
                 resetField("cargoValue");
                 resetField("temperature");
              }else{
                 resetField("minTemperature");
                 resetField("maxTemperature");
              }
         }
     }, [temperatureControlled])

    return  <div className="w-full flex flex-row justify-between flex-wrap gap-2.5">
    {!temperatureControlled && <ControlledDetailSwitch control={control} isChecked={hazardous} register={register} formKey={"hazardous"} title={"Hazardous"} disable={viewonly}/>}
    {(mode.toLowerCase() === "lcl" || mode.toLowerCase() === 'air')  &&  <ControlledDetailSwitch control={control} isChecked={nonstackable} register={register} formKey={"nonStackable"} title={"Non-Stackable"}  disable={viewonly}/>}
    {(mode.toLowerCase() === "fcl" || mode.toLowerCase() === 'air') && !hazardous &&  ( stuffingType!=="Dock" || destuffingType !== "Dock" )  && <ControlledDetailSwitch control={control} isChecked={temperatureControlled} disable={viewonly} onChange={(value)=>{
           setValue("containerDetails",[new Container()])
    }} register={register} formKey={"temperatureControlled"} title={"Temperature Controlled"} />}
    {hazardous && <DetailsSelect2 disabled={viewonly} control={control} name={"quoteClass"} label="Class" options={getHazardousClassList(mode.toUpperCase())} />}
    {hazardous && <DetailsSelect2 disabled={viewonly} control={control} name={"packingGroup"} label="Packing Group" options={packingGroupList} />}
    {hazardous && <DetailsText3 disabled={viewonly}
        label={"UN Number"} 
            fontSize="12px" size="small" fullwidth control={control} name={"unnumber"} rules={{
                validate:(value)=> {
                    if(value?.length!=4){
                        return 'UN number must be 4 numbers'
                    }
                }
            }}  />
}
  {temperatureControlled && !hazardous && mode.toLowerCase() === 'fcl' &&
        <>
                <DetailsText2 disabled={viewonly} label="Temperature" fontSize="12px" size="small" fullwidth control={control} name={"temperature"}
                    rules={{
                        pattern: {
                            value: /^-3[0-5]$|^-?[0-2]?[0-9]$|^30$/,
                            message: 'Temperature must be between -35 to 30'
                        },
                    }}
                    allowNegative={true} />

                <DetailsText2 disabled={viewonly} label="Cargo Value(USD)" fontSize="12px" size="small" fullwidth control={control} name={"cargoValue"}
                    rules={{
                        validate:(value)=> {
                            if(value<=0){
                                return "Invalid value"
                            }
                            if(value?.length!=5){
                                return 'Cargo Value must be 5 numbers'
                            }
                        }
                    }} />
        </>
    }
     {temperatureControlled && !hazardous && mode.toLowerCase() === 'air' &&
            <>
               
                    <DetailsText2 disabled={viewonly} label="Minimum Temperature" fontSize="12px" size="small" fullwidth control={control} name={"minTemperature"} rules={{
                        pattern: {
                            value: /^(\+|-)?([0-9]|[1-2][0-9]|3[0-5])$/,
                            message: 'Temperature must be between -35 to 35'
                        },
                        validate: (value) => {
                            if (value <= maxTemperature) {
                                return true
                            } else {
                                return "Minimum temp should not be greater than Maximum temperature"
                            }
                        }

                    }} allowNegative={true} />
                    <DetailsText2 disabled={viewonly} label="Maximum Temperature" fontSize="12px" size="small" fullwidth control={control} name={"maxTemperature"}
                        rules={{
                            pattern: {
                                value: /^(\+|-)?([0-9]|[1-2][0-9]|3[0-5])$/,
                                message: 'Temperature must be between -35 to 35'
                            },
                            validate: (value) => {
                                if (value >= minTemperature) {
                                    return true
                                } else {
                                    return "Maximum temp should not be less than Minimum temperature"
                                }
                            }

                        }} allowNegative={true} />
            </>
        }
          {mode.toLowerCase() === 'fcl' && <>
           <StuffingTypeForFcl key={"stuffingType"} disabled={quoteDataRec.quotationNum !== "" || configStore.disableShipmentType !== ""?true:viewonly} title="Stuffing Type?" value={stuffingType} onChange={(e)=>{
                if(stuffingType=='Factory'){
                    setValue("stuffingType","Dock");
                }else{
                    setValue("stuffingType","Factory");
                }
           }} />
           <StuffingTypeForFcl key={"destuffingType"} disabled={quoteDataRec.quotationNum !== "" || configStore.disableShipmentType !== ""?true:viewonly} title="De-Stuffing Type?" value={destuffingType} onChange={(e)=>{
            if(destuffingType=='Factory'){
                setValue("destuffingType","Dock");
            }else{
                setValue("destuffingType","Factory");
            }
           }} />
        </>
        }
        </div>
}