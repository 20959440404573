import { subscribe, useSnapshot } from "valtio"
import { useEffect, useState } from "react"
import { AgmodalStore, configStore, loginStore } from "../state/CommonState";
import TaskDatetime from "./task-datetime";
import Taskfileapproval, { validateFileApproval } from "./task-file-approval";
import Taskfiledisplay from "./task-file-display";
import Taskfiletable from "./task-file-table";
import { taskStore } from "./task-store";
import Taskapi from "./tasks-api";
import Billchecklist from "./Bill-check-list";

export default function Sbchecklistapproval(props) {
    const [checkBtnk, setcheckBtnk] = useState(false)
    useSnapshot(taskStore)
    useSnapshot(configStore)
    useSnapshot(AgmodalStore)
    let task = taskStore.current
    let stage = task.stage

    const [approval,setApproval] = useState(false)
    useEffect(()=>{
        let l = taskStore.current
        if(l['files'] != undefined && l['files'].length>0){
            let f = true
            for (let i = 0; i < l['files'].length; i++) {
                if(l['files'][i]['approved']=="Reject"){
                    f=false
                    i = l['files'].length+1
                }
            }
            if(approval!=f){
                setApproval(f)
            }
        }else{
            if(approval){
                setApproval(false)
            }
        }
    },[taskStore.current])

    if (stage === 1) {
        return (
            <div className="box">
                <p>
                    Please Review and Approve the attached document
                </p>
                <hr />
                <p>
                    {
                        (task.remarks.length > 0) ?
                            <>
                                <strong>Remarks</strong> {task.remarks}
                            </> : ''
                    }
                </p>
                <hr />
                <Taskfileapproval />
                <p>
                    <span>
                        <button style={{ float: 'left' }} className="button islink"
                            onClick={async (e) => {
                                let task = taskStore.current
                                let ret = validateFileApproval(task)
                                if (ret.errors.length > 0) {
                                    configStore.setModalMessage(ret.errors.join(','))
                                    return
                                }
                                if (ret.rejected)
                                    [task.stage, task.pendingWith] = [2, 'AGRAGA']
                                else
                                    [task.stage, task.pendingWith] = [3, 'AGRAGA']
                                taskStore.setCustomerComplete()
                                await Taskapi.updateTask(taskStore.current)
                                taskStore.triggerActionVisible = false
                                taskStore.myActionVisible = false
                                if(AgmodalStore.modalPage === "TaskContainer" || loginStore.isClient()){
                                    AgmodalStore.pageVisible = false
                                    AgmodalStore.apiCall = true
                                }
                            }}>Submit</button>
                    </span>
                </p>
            </div>
        )
    }
    if (stage === 2) {
        return (
            <>
                <p>The document was rejected. Please see reason below.</p>
                <hr />
                <Taskfiletable showRejectReason={true}/>
                <p>
                    <span><strong>Remarks</strong></span>
                    <br />
                    <span><textarea cols='50'
                        value={taskStore.current.remarks} onChange={(e) => {
                            taskStore.current.remarks = e.target.value
                        }} /></span>
                </p>
                <p>
                    <span>
                        <button style={{ float: 'left' }} className="button islink"
                            onClick={async (e) => {
                                taskStore.current.stage = 1
                                taskStore.current.pendingWith = 'CUSTOMER'
                                taskStore.current.customerStatus = 'PENDING'
                                await Taskapi.updateTask(taskStore.current)
                                taskStore.triggerActionVisible = false
                                taskStore.myActionVisible = false
                                if(AgmodalStore.modalPage === "TaskContainer" || loginStore.isClient()){
                                    AgmodalStore.pageVisible = false
                                    AgmodalStore.apiCall = true
                                }
                            }}>Submit</button>
                    </span>
                </p>
            </>
        )
    }
    if (stage >= 3) {
        return (
            <>
                {approval && <p>{taskStore.current?.actionName=="Shipping Bill Checklist Approval"?"The Shipping Bill Checklist Document has been approved":"The Document has been approved"}</p>}
                <hr />
                {(taskStore.current?.actionName !== "Shipping Bill Checklist Approval") || loginStore.isClient()?<Taskfiledisplay />:<></>}
                {/* {stage >= 3 && (task.customerStatus === "COMPLETED" || task.status === "COMPLETED")?<Taskfiledisplay />:<></>} */}
                {taskStore.current?.actionName!="BL Draft Approval" && stage === 3?<Billchecklist type="sbc" callback={(e)=> setcheckBtnk(e)}/>:null}
                {(checkBtnk || taskStore.current?.actionName=="BL Draft Approval") && <p>
                    <span>
                        {
                            (stage === 3) ?
                                <button style={{ float: 'left' }} className="button islink"
                                    onClick={async (e) => {
                                        taskStore.current.stage = 4
                                        taskStore.current.pendingWith = 'AGRAGA'
                                        taskStore.setComplete()
                                        await Taskapi.updateTask(taskStore.current)
                                        taskStore.triggerActionVisible = false
                                        taskStore.myActionVisible = false
                                        if(AgmodalStore.modalPage === "TaskContainer" || loginStore.isClient()){
                                            AgmodalStore.pageVisible = false
                                            AgmodalStore.apiCall = true
                                        }
                                    }}>Acknowledge</button>
                                : <></>
                        }
                    </span>
                </p>}
            </>
        )
    }
}
export function SbchecklistapprovalTrigger(props) {
    useSnapshot(taskStore)
    const [disableButton, setDisableButton] = useState(true)
    useEffect(() => {
        let unsubscribe = subscribe(taskStore.current, () => {
            let disable = false
            if (taskStore.current.dueDate.length == 0) {
                disable = true
            }
            if(!disable){
                let checkfile = []
                taskStore.current.files.map((e) =>{
                    if(checkfile.indexOf(e.label) == -1){
                        if(taskStore.current.original_file.indexOf(e.label) != -1 && e.filename.length==0){
                            disable = true
                        }else{
                            if(taskStore.current.original_file.indexOf(e.label) != -1){
                                checkfile.push(e.label)
                            }
                        }
                    }
                })
            }
            setDisableButton(disable)
        })
        return unsubscribe
    }, [taskStore.current])
    return (
        <>
            <TaskDatetime />
            <hr/>
            <Taskfiletable />
            <p>
                <span>
                    <button style={{ float: 'left' }} className="button islink"
                        disabled={disableButton}
                        onClick={async (e) => {
                            taskStore.current.stage = 1
                            taskStore.current.pendingWith = 'CUSTOMER'
                            await Taskapi.saveTask(taskStore.current)
                            taskStore.triggerActionVisible = false
                            taskStore.myActionVisible = false
                        }}>Trigger Action</button>
                </span>
            </p>
        </>
    )
}