import { useEffect, useState, useRef } from "react"
import {
  AgmodalStore,
  EntityV2Store,
  configStore,
  creditformStore,
  loginStore,
  routerStore,
} from "../../state/CommonState"
import { Buffer } from 'buffer'
import { Col3, Col4, Columns } from "../../Controls"
import { ReactComponent as Loader } from "../../assets/loader.svg"
import { BiPlus } from "react-icons/bi"
import { MdDelete } from "react-icons/md"
import { commodityList, currencyList } from "../../StaticData"
import { useSnapshot } from "valtio"
import CreditsApis from "../../state/credit-api"
import { AiFillEdit } from "react-icons/ai"
import { MdDownload } from "react-icons/md"
import { TbEditOff } from "react-icons/tb"
import { FaRupeeSign } from "react-icons/fa"
import { CREDIT_STATUS,PERMISSIONS } from "../../util/constants"
import Api from "../../state/Api"
import RoleUtils from "../../util/RoleUtils"
import EntityUtils from "../../util/entityUtils"
import PhoneField from "../../mui-components/PhoneField"

export default function EditCredit(props) {
  let Entity = EntityV2Store.EntityRec
  let title = `${Entity.entityId}/Credit Facility`
  let status = "Not Availed"
  let CreditForm = creditformStore.CreditformRec
  const finArray = [
    {
      customercontact: "",
      department: "",
      contactnum: "",
      email: "",
      countryCode : "",
    },
  ]

  const [loading, setloading] = useState(false)
  const [subBtnstatus, setsubBtnstatus] = useState(false)
  const [cursor, setCursor] = useState(0)
  const [formObject, setformObject] = useState({})
  const [editMode, setEditMode] = useState(false)
  const [errors, setErrors] = useState({})
  const [saveBtn, setSavebtn] = useState(false)
  const ref = useRef(null)
  useSnapshot(creditformStore)
  useSnapshot(AgmodalStore)

  useEffect(() => {
    const input = ref.current
  }, [ref, cursor])



  useEffect(() => {
    if (props.mode === "new") {
      CreditsApis.getGstList(Entity.entityId)

      setEditMode(true)
      setsubBtnstatus(false)
      setStatusColor()
    } else if (props.mode === "edit") {
      let form = JSON.parse(JSON.stringify(CreditForm))
      setformObject(form)
      setsubBtnstatus(true)
      checkSubBtnStatus()
      setStatusColor()
    }
  }, [])

  const addNewFinancerow = () => {
    const array = [...CreditForm.finance]
    array.push(finArray[0])
    CreditForm.finance = array
    if (errors.finance) {
      errors.finance = null
    }
  }

  const setStatusColor = () => {
    let status = CreditForm.status
    if (status === CREDIT_STATUS.VERIFIED) {
      document.getElementById("statusbtn_id").style.border = "1px solid green"
      document.getElementById("statusbtn_id").style.color = "green"
    } else if (status === CREDIT_STATUS.REJECTED) {
      document.getElementById("statusbtn_id").style.border = "1px solid red"
      document.getElementById("statusbtn_id").style.color = "red"
    } else {
      document.getElementById("statusbtn_id").style.border = "1px solid #e68a00"
      document.getElementById("statusbtn_id").style.color = "#e68a00"
    }
  }

  const addNewFinanceEsarow = () => {
    const array = [...CreditForm.financeescalation]
    array.push(finArray[0])
    CreditForm.financeescalation = array
    if (errors.financeescalation) {
      errors.financeescalation = null
    }
  }

  const removeFinancerow = (i) => {
    const array = [...CreditForm.finance]
    if (array.length > 1) {
      array.splice(i, 1)
    }else if (array.length === 1){
      array[0].contactnum = ""
      array[0].department = ""
      array[0].customercontact = ""
      array[0].email = ""
      array[0].countryCode = ""
    }
    CreditForm.finance = array
    if (errors.finance) {
      errors.finance = null
    }
  }
  function checkSubBtnStatus() {
    if (props.mode === "edit") {
      if (JSON.stringify(CreditForm) !== JSON.stringify(formObject)) {
        setsubBtnstatus(false)
      } else {
        setsubBtnstatus(true)
      }
    }
  }

  const removeFinanceEsarow = (i) => {
    const array = [...CreditForm.financeescalation]
    console.log(i, array)
    if (array.length > 1) {
      array.splice(i, 1)
    }else if (array.length === 1){
      array[0].contactnum = ""
      array[0].department = ""
      array[0].customercontact = ""
      array[0].email = ""
      array[0].countryCode = ""
    }
    CreditForm.financeescalation = array
    if (errors.financeescalation) {
      errors.financeescalation = null
    }
  }

  const handleChange = (e, r, z, a, b) => {
    setCursor(e.target.selectionStart)
    let value = e.target.value
    errors[r] = ""
    setErrors(errors)
    if ( r !== 'natureofcargo' &&  e.target.value.length > 100) {
      return null
    }
    if (r === "turnover") {
      if (z === "value") {
        errors.turnovervalue = ""
        setErrors(errors)
      }
      return (CreditForm[r][z] = e.target.value)
    }
    if (r === "financialsummary") {
      errors[b] = ""
      setErrors(errors)
      return (CreditForm[r][z][a] = e.target.value)
    }
    if (r === "duration") {
      if ((value <= 90 && value > 0) || value === "") {
        return (CreditForm[r] = e.target.value)
      } else {
        return null
      }
    }
    if (r === "creditlimit") {
      if ((value <= 50000000 && value > 0) || value === "") {
        return (CreditForm[r] = e.target.value)
      } else {
        return null
      }
    }
    CreditForm[r] = e.target.value
    errors[r] = ""
    setErrors(errors)
  }

  const handleChange1 = (e, i, r) => {
    setCursor(e.target.selectionStart)
    let re = new RegExp("^([A-Za-z0-9- ]{0,100})$")
    if (r !== "email" && !re.test(e.target.value)) {
      return null
    }
    if (r === "contactnum" && e.target.value.length > 15 ) {
      return null
    }
    if (e.target.value.length > 100) {
      return null
    }
    CreditForm.finance[i][r] = e.target.value
    if (errors.finance?.obj?.length > 0) {
      errors.finance.obj[i][r] = ""
      errors.finance.error = false
      setErrors(errors)
    }
  }

  function clearErrors  (i,r , x)  {
    if (errors[x]?.obj?.length > 0) {
      errors[x].obj[i][r] = ""
      errors[x].error = false
      setErrors(errors)
    }
  }

  const handleChange2 = (e, i, r) => {
    setCursor(e.target.selectionStart)
    let re = new RegExp("^([A-Za-z0-9- ]{0,100})$")
    if (r !== "email" && !re.test(e.target.value)) {
      return null
    }
    if (r === "contactnum" && e.target.value.length > 15) {
      return null
    }
    if (e.target.value.length > 100) {
      return null
    }
    CreditForm.financeescalation[i][r] = e.target.value
    if (errors.financeescalation?.obj?.length > 0) {
      errors.financeescalation.obj[i][r] = ""
      errors.financeescalation.error = false
      setErrors(errors)
    }
  }

  function isVerifiedStatus() {
    return CreditForm.status === CREDIT_STATUS.VERIFIED
  }
  function isRejectedStatus() {
    return CreditForm.status.includes("Rejected")
  }

  function isNotAvailedStatus() {
    return CreditForm.status === CREDIT_STATUS.NOTAVAILED
  }
  const handleSubmit = async (e) => {
    e.preventDefault()
    const fieldsCheck = await dovalidationcheck()
    // console.log(fieldsCheck);
    if (fieldsCheck.isError === false) {
      if (isVerifiedStatus() || isRejectedStatus()) {
        if ((JSON.stringify(CreditForm) == JSON.stringify(formObject))) {
          return configStore.setModalMessage("Please Change some values to push the credit form for sales approval")
        }

        let Timeline = [...CreditForm.timeline]
        Timeline.push({
          date: Date.now(),
          event: "Credit Application Re-Initiated",
          user: loginStore.email,
          role: RoleUtils.getUserRole(),
        })
        CreditForm.documents[0].fileLink = ""
        CreditForm.documents[0].pkey = ""
        CreditForm.creditscore = ""
        CreditForm.creditagency = ""
        CreditForm.overallcreditscore = ""
        CreditForm.timeline = Timeline
        CreditForm.remarks = ""
        CreditForm.status = CREDIT_STATUS.APPPENSALES
        console.log(CreditForm)
        await CreditsApis.createCaf()
      } else {
        CreditForm.timeline = [
          {
            date: Date.now(),
            event: "Credit Application Initiated",
            user: loginStore.email,
            role: RoleUtils.getUserRole(),
          },
        ]
        CreditForm.status = CREDIT_STATUS.APPPENSALES
        console.log(CreditForm)
        await CreditsApis.createCaf()
        AgmodalStore.apiCall = true
      }
    }
  }

  // function RejectedRole(){
  //   return CreditForm.status.replace("Credit Application- Rejected- ", "")
  // }

  const handleSubmitSave = async (e) => {
    e.preventDefault()
    const fieldsCheck = await dovalidationcheck()
    if (fieldsCheck.isError === false) {
      await CreditsApis.updateCaf()
    }
  }

  useEffect(() => {
    if (props.mode === "edit") {
      let formCreditnew = {
        duration: CreditForm.duration,
        creditlimit: CreditForm.creditlimit,
        financialsummary: CreditForm.financialsummary,
        finance: CreditForm.finance,
      }
      let formCreditnewnonman = {
        natureofbusiness: CreditForm.natureofbusiness,
        natureofcargo: CreditForm.natureofcargo,
        turnover: CreditForm.turnover,
        financeescalation: CreditForm.financeescalation,
      }

      let formCreditOld = {
        duration: formObject.duration,
        creditlimit: formObject.creditlimit,
        financialsummary: formObject.financialsummary,
        finance: formObject.finance,
      }

      let formCreditOldnonman = {
        natureofbusiness: formObject.natureofbusiness,
        natureofcargo: formObject.natureofcargo,
        turnover: formObject.turnover,
        financeescalation: formObject.financeescalation,
      }
      if (JSON.stringify(formCreditnewnonman) !== JSON.stringify(formCreditOldnonman)) {
        if (JSON.stringify(formCreditnew) === JSON.stringify(formCreditOld)) {
          setSavebtn(true)
        } else {
          setSavebtn(false)
        }
      } else {
        setSavebtn(false)
      }
      if (JSON.stringify(formCreditnew) !== JSON.stringify(formCreditOld)) {
        setsubBtnstatus(false)
      } else {
        setsubBtnstatus(true)
      }

    }
  }, [handleChange, handleChange1, handleChange2])
  // useEffect(()=> {
  //  console.log("errors set");
  // },[errors])

  const dovalidationcheck = async () => {
    function isValid(value) {
      return (
        (!Array.isArray(value) &&
          value &&
          value.trim().length > 0 &&
          value !== "default") ||
        (Array.isArray(value) &&
          value.some((item) => item.trim() !== "default"))
      )
    }
    let errors = {}
    let fieldsCheck = { isError: false }
    // let ErrorsSubmit = {}
    let fieldsToSubmit = [
      { title: "Duration", field: "duration", required: true, maxValue: 90 },
      {
        title: "Amount",
        field: "creditlimit",
        required: true,
        maxValue: 50000000,
      },
      // {
      //   title: "NatureofBusiness",
      //   field: "natureofbusiness",
      //   required: true,
      //   dropdown: true,
      // },
      // {
      //   title: "NatureofCargo",
      //   field: "natureofcargo",
      //   required: true,
      //   dropdown: true,
      // },
      // { title: "Turnover", field: "currency", required: true },
      // { title: "Turnover", field: "turnovervalue", required: true },
      // { title: "Duration", field: "duration", required: true },
      // { title: "Duration", field: "duration", required: true },
    ]
    fieldsToSubmit.forEach(({ title, field, required, dropdown, maxValue }) => {
      let value = CreditForm[field]
      if (title === "Turnover" && field === "turnovervalue") {
        value = CreditForm.turnover["value"]
        if (required && !isValid(value)) {
          errors[field] = `${title} is required.`
          fieldsCheck.isError = true
        }
      } else {
        if (required && !isValid(value)) {
          errors[field] = `${title} is required.`
          fieldsCheck.isError = true
        }
        if (required && value > maxValue) {
          errors[field] = `${title} value should be between 0 and ${maxValue}`
          fieldsCheck.isError = true
        }

        if (dropdown && value === "default") {
          errors[field] = `Please select a valid ${title}.`
          fieldsCheck.isError = true
        }
      }
    })
    let obj = [
      {
        field: "finsum_int_tp",
        value: CreditForm.financialsummary.International.totalpotential,
      },
      {
        field: "finsum_int_mr",
        value: CreditForm.financialsummary.International.monthlyrevenue,
      },
      {
        field: "finsum_dom_tp",
        value: CreditForm.financialsummary.Domestic.totalpotential,
      },
      {
        field: "finsum_dom_mr",
        value: CreditForm.financialsummary.Domestic.monthlyrevenue,
      },
    ]
    obj.forEach(({ field, value }) => {
      if (!isValid(value)) {
        errors[field] = `Please enter all values`
        fieldsCheck.isError = true
      }
    })

    errors.finance = {
      obj: [],
      error: false,
    }
    errors.financeescalation = {
      obj: [],
      error: false,
    }
    const emailRegexPattern =
    /^[A-Za-z0-9_!#$%&'*+\/=?`{|}~^.-]+@[A-Za-z0-9-]+(\.[A-Za-z0-9-]+)+$/
    CreditForm.finance.forEach((r, i) => {
      errors.finance.obj.push({
        customercontact: "",
        department: "",
        contactnum: "",
        email: "",
      })
      if (!isValid(r.customercontact)) {
        errors.finance.obj[i].customercontact = `Please enter all values`
        fieldsCheck.isError = true
        errors.finance.error = true
      }
      if (!isValid(r.department)) {
        errors.finance.obj[i].department = `Please enter all values`
        fieldsCheck.isError = true
        errors.finance.error = true
      }
      if (!isValid(r.contactnum)) {
        errors.finance.obj[i].contactnum = `Please enter all values`
        fieldsCheck.isError = true
        errors.finance.error = true
      }
      if (!isValid(r.email)) {
        errors.finance.obj[i].email = `Please enter all values`
        fieldsCheck.isError = true
        errors.finance.error = true
      }
      if (isValid(r.contactnum) && (r.contactnum.length > 15 || r.contactnum.length < 7)) {
        errors.finance.obj[
          i
        ].contactnum = `Enter Valid Phone Number (7-15 Digits)`
        fieldsCheck.isError = true
        errors.finance.error = true
        errors.finance.contactNumber = `Enter Valid Phone Number (7-15 Digits)`
      }
      if (isValid(r.email) && !emailRegexPattern.test(r.email.trim())) {
        errors.finance.obj[i].email = `Invalid email pattern`
        fieldsCheck.isError = true
        errors.finance.error = true
        errors.finance.email = `Invalid email`
      }
    })
    let esclationEntered = false
    CreditForm.financeescalation.forEach((r, i) => {
      if (r.customercontact || r.department || r.contactnum || r.email) {
        esclationEntered = true
      }
    })
    if (esclationEntered === true) {
      CreditForm.financeescalation.forEach((r, i) => {
        errors.financeescalation.obj.push({
          customercontact: "",
          department: "",
          contactnum: "",
          email: "",
        })
        if (!isValid(r.customercontact)) {
          errors.financeescalation.obj[
            i
          ].customercontact = `Please enter all values`
          fieldsCheck.isError = true
          errors.financeescalation.error = true
        }
        if (!isValid(r.department)) {
          errors.financeescalation.obj[
            i
          ].department = `Please enter all values`
          fieldsCheck.isError = true
          errors.financeescalation.error = true
        }
        if (!isValid(r.contactnum)) {
          errors.financeescalation.obj[
            i
          ].contactnum = `Please enter all values`
          fieldsCheck.isError = true
          errors.financeescalation.error = true
        }
        if (!isValid(r.email)) {
          errors.financeescalation.obj[i].email = `Please enter all values`
          fieldsCheck.isError = true
          errors.financeescalation.error = true
        }
        if (isValid(r.contactnum) && (r.contactnum.length > 15 || r.contactnum.length < 7)) {
          errors.financeescalation.obj[
            i
          ].contactnum = `Enter Valid Phone Number (7-15 Digits)`
          fieldsCheck.isError = true
          errors.financeescalation.error = true
          errors.financeescalation.contactNumber = `Enter Valid Phone Number (7-15 Digits)`
        }
        if (isValid(r.email) && !emailRegexPattern.test(r.email.trim())) {
          errors.financeescalation.obj[i].email = `Invalid email pattern`
          fieldsCheck.isError = true
          errors.financeescalation.error = true
          errors.financeescalation.email = `Invalid email`
        }
      })
    }

    setErrors(errors)
    return fieldsCheck
  }

  return (
    <div className="container is-fullheight">
      <h1 className="title is-flex is-align-items-center p-1">
        <span className="credit_title_spn">{title}</span>
        <span className="btn_status_span_credit">
          <button className="statusbtn " id="statusbtn_id">
            {/* {CreditForm.status.replace("Credit Application-", "")}
           */}
            {CreditForm.status}
          </button>
        </span>
        <button
          onClick={async (e) => {
            e.preventDefault()
            props.fn.fn(false)
          }}
          className="delete is-large is-info"
          style={{ backgroundColor: "#485fc7", marginLeft: "18%" }}
        ></button>
      </h1>
      {isRejectedStatus() && (
        <>
          <table className="table_rej table_caf" style={{ width: "35%" }}>
            <thead>
              <td>Date</td>
              <td>User Role</td>
              <td>Rejected Remarks</td>
            </thead>
            <tbody>
              <td>{new Date(CreditForm.updatedAt).toLocaleDateString()}</td>
              <td>{CreditForm.rejectedrole}</td>
              <td>{CreditForm.remarks}</td>
            </tbody>
          </table>

          {/* <h2 className="subtitle has-text-weight-bold">Remarks</h2>
          <p>{CreditForm.remarks}</p> */}
        </>
      )}
      {isVerifiedStatus() ? (
        <>
          <Columns>
            <Col4>
              {
                <div className="is-flex px-3 is-flex-direction-column has-background-light">
                  <span className="is-size-6 has-text-weight-bold">
                    Credit Rating Agency
                  </span>
                  <span>{CreditForm.creditagency}</span>
                </div>
              }
            </Col4>
            <Col4>
              <div className="is-flex px-3 is-flex-direction-column has-background-light">
                <span className="is-size-6 has-text-weight-bold">
                  Credit Score
                </span>
                <span>{CreditForm.creditscore}</span>
              </div>
            </Col4>
            <Col4>
              <div className="is-flex px-3 is-flex-direction-column has-background-light">
                <span className="is-size-6 has-text-weight-bold">
                  Overall Credit Score
                </span>
                <span>{CreditForm.overallcreditscore}</span>
              </div>
            </Col4>
          </Columns>
          <p>
            <a href={
              process.env.REACT_APP_FILE_SERVER +
              "/api/v1/filestore/getfile/" +
              CreditForm?.documents[0]?.pkey +
              "/" +
              CreditForm?.documents[0]?.fileLink
            }
              target="_blank"
              rel="noreferrer"
              alt="">Customer Acknowledge Credit Application</a>
          </p>
        </>
      ) : null}
      <hr></hr>
      <h1
        className="is-flex subtitle has-text-weight-bold"
        style={{ display: "flex" }}
      >
        <span>Credit Application</span>
        {(!isVerifiedStatus() && !isRejectedStatus() && !isNotAvailedStatus()) && (
          <span
            className="is-clickable"
            style={{ float: "right", fontSize: "1.3em", marginLeft: "79%" }}
          >
            <a onClick={async () => {
              let res = await Api.getCafFormDownload(CreditForm.entityId)
              const buffer = Buffer.from(res)
              const blob = new Blob([buffer])
              const fileURL = window.URL.createObjectURL(blob)
              let alink = document.createElement('a')
              alink.href = fileURL
              alink.download = `${CreditForm.entityId}.pdf`
              alink.click()
            }}><MdDownload />
            </a>
          </span>
        )

        }
        {(isVerifiedStatus() || isRejectedStatus()) && !editMode && (
          <>
            <span
              className="is-clickable"
              style={{ float: "right", marginLeft: "78%", fontSize: "1.3em" }}
            >
              <AiFillEdit onClick={() => setEditMode(true)} />
            </span>
            {isRejectedStatus() && (
              <span
                className="is-clickable"
                style={{ float: "right", fontSize: "1.3em", marginLeft: "1%" }}
              >
                <a onClick={async () => {
                  let res = await Api.getCafFormDownload(CreditForm.entityId)
                  const buffer = Buffer.from(res)
                  const blob = new Blob([buffer])
                  const fileURL = window.URL.createObjectURL(blob)
                  let alink = document.createElement('a')
                  alink.href = fileURL
                  alink.download = `${CreditForm.entityId}.pdf`
                  alink.click()
                }}><MdDownload />
                </a>
              </span>
            )

            }

          </>
        )}
        {(isVerifiedStatus() || isRejectedStatus()) && editMode && (
          <>
            <span
              className="is-clickable"
              style={{ float: "right", marginLeft: "81%", fontSize: "1.3em" }}
            >
              <TbEditOff onClick={() => setEditMode(false)} />
            </span>
          </>
        )}
      </h1>
      <div className="px-4">
        <Columns className="py-0">
          <Col4>
            <div className="is-flex px-3 is-flex-direction-column has-background-light">
              <span className="is-size-6 has-text-weight-bold">
                Entity Name
              </span>
              <span>{CreditForm.entityName}</span>
            </div>
          </Col4>
          <Col4></Col4>
          {EntityUtils.isIndia(Entity) ? 
            <Col4>
            <div className="is-flex px-3 is-flex-direction-column has-background-light">
              <span className="is-size-6 has-text-weight-bold">PAN Number</span>
              <span>{CreditForm.panNum}</span>
            </div>
          </Col4> : 
          <Col4>
            <div className="is-flex px-3 is-flex-direction-column has-background-light">
              <span className="is-size-6 has-text-weight-bold">TIN Number</span>
              <span>{CreditForm.tinNum}</span>
            </div>
          </Col4>

          }
          {/* <Col4>
            <div className="is-flex px-3 is-flex-direction-column has-background-light">
              <span className="is-size-6 has-text-weight-bold">PAN Number</span>
              <span>{CreditForm.panNum}</span>
            </div>
          </Col4> */}
        </Columns>
        {EntityUtils.isIndia(Entity) ? <>
          <p className="is-size-6 px-3 has-text-weight-bold">GST Numbers</p>
        <p className="pb-2">
          {CreditForm.gstList?.map((r, i) => {
            return <button className="pd-2 button-caf-gst">{r.gst}</button>
          })}
        </p>

        </> : null

        }
      
        { CreditForm.customer.crossBorder.relationshipManager.length > 3 && <>
        <p className="is-size-6 has-text-weight-bold">Scope of Business - Cross Border</p>
        <Columns>
          <Col4>
            <div className="is-flex px-3 is-flex-direction-column has-background-light">
              <span className="is-size-6 has-text-weight-bold">
                Agraga Branch
              </span>
              <span>{CreditForm.customer.crossBorder.agragaBranch}</span>
            </div>
          </Col4>

          <Col4>
            <div className="is-flex px-3 is-flex-direction-column has-background-light">
              <span className="is-size-6 has-text-weight-bold">
                Relationship Manager
              </span>
              <span>{CreditForm.customer.crossBorder.relationshipManager}</span>
            </div>
          </Col4>
          <Col4>
            <div className="is-flex px-3 is-flex-direction-column has-background-light">
              <span className="is-size-6 has-text-weight-bold">
                Customer Service
              </span>
              <span>{CreditForm.customer.crossBorder.customerService}</span>
            </div>
          </Col4>
        </Columns>
        </>
}
{CreditForm.customer.domesticTransport.relationshipManager.length > 3 && <>
        <p className="is-size-6 has-text-weight-bold">Scope of Business - Domestic Transport</p>
        <Columns>
          <Col4>
            <div className="is-flex px-3 is-flex-direction-column has-background-light">
              <span className="is-size-6 has-text-weight-bold">
                Agraga Branch
              </span>
              <span>{CreditForm.customer.domesticTransport.agragaBranch}</span>
            </div>
          </Col4>

          <Col4>
            <div className="is-flex px-3 is-flex-direction-column has-background-light">
              <span className="is-size-6 has-text-weight-bold">
                Relationship Manager
              </span>
              <span>{CreditForm.customer.domesticTransport.relationshipManager}</span>
            </div>
          </Col4>
          <Col4>
            <div className="is-flex px-3 is-flex-direction-column has-background-light">
              <span className="is-size-6 has-text-weight-bold">
                Customer Service
              </span>
              <span>{CreditForm.customer.domesticTransport.customerService}</span>
            </div>
          </Col4>
          
        </Columns>
        <Columns>
        <Col4>
            <div className="is-flex px-3 is-flex-direction-column has-background-light">
              <span className="is-size-6 has-text-weight-bold">
                Branch Operation Manager
              </span>
              <span>{CreditForm.customer.domesticTransport.branchOperationManager}</span>
            </div>
          </Col4>
        </Columns>
        </>
}
        <hr></hr>
        {/* <form className="p-2" onSubmit={handleSubmit}> */}
        <div>
          <p className="is-size-5 has-text-weight-bold">Credit Details</p>
          <Columns>
            <Col4>
              <div className="is-flex  is-flex-direction-column is-one-third">
                <label className="has-text-weight-semibold is-flex  is-flex-direction-column is-one-third">
                  Duration*
                  <input
                    type="number"
                    className="py-1 px-2"
                    placeholder="please enter duration in days"
                    name="duration"
                    value={CreditForm.duration}
                    ref={ref}
                    onChange={(e) => handleChange(e, "duration")}
                    disabled={!editMode}
                    max="90"
                    min="1"
                    maxLength={3}
                    onKeyDown={(e) =>
                      ["e", "E", "+", "-", "."].includes(e.key) &&
                      e.preventDefault()
                    }
                  ></input>
                </label>
                {errors.duration && (
                  <p className="help is-input-danger-message">
                    {errors.duration}
                  </p>
                )}
              </div>
            </Col4>
            <Col4></Col4>
            <Col4>
              <div className="is-flex  is-flex-direction-column is-one-third">
                <label className="has-text-weight-semibold is-flex  is-flex-direction-column is-one-third">
                  Amount*
                  <input
                    type="number"
                    className="py-1 px-2"
                    placeholder="Value"
                    name="creditlimit"
                    value={CreditForm.creditlimit}
                    disabled={!editMode}
                    ref={ref}
                    onKeyDown={(e) =>
                      ["e", "E", "+", "-", "."].includes(e.key) &&
                      e.preventDefault()
                    }
                    min={0}
                    onChange={(e) => handleChange(e, "creditlimit")}
                  ></input>
                </label>
                {errors.creditlimit && (
                  <p className="help is-input-danger-message">
                    {errors.creditlimit}
                  </p>
                )}
              </div>
            </Col4>
          </Columns>
          <Columns>
            <Col4>
              <div className="is-flex  is-flex-direction-column is-one-third">
                <label className="has-text-weight-semibold is-flex  is-flex-direction-column is-one-third">
                  Nature of Business
                  <select
                    className="py-1"
                    name="natureofbusiness"
                    ref={ref}
                    value={CreditForm.natureofbusiness}
                    onChange={(e) => handleChange(e, "natureofbusiness")}
                    disabled={!editMode}
                  >
                    <option value="">Select type</option>
                    <option value="Manufacturing">Manufacturing</option>
                    <option value="Trading">Trading</option>
                  </select>
                </label>
                {errors.natureofbusiness && (
                  <p className="help is-input-danger-message">
                    {errors.natureofbusiness}
                  </p>
                )}
              </div>
            </Col4>
            <Col4></Col4>
            <Col4>
              <div className="is-flex  is-flex-direction-column is-one-third">
                <label className="has-text-weight-semibold">
                  Nature of Cargo
                  <select
                    className="py-1 wd_100"
                    name="natureofcargo"
                    value={CreditForm.natureofcargo}
                    ref={ref}
                    onChange={(e) => handleChange(e, "natureofcargo")}
                    disabled={!editMode}
                  >
                    <option className="wd_100" value="">
                      Select type
                    </option>
                    {commodityList.map((r, i) => {
                      return (
                        <>
                          <option className="wd_100">{r}</option>
                        </>
                      )
                    })}
                  </select>
                  {errors.natureofcargo && (
                    <p className="help is-input-danger-message">
                      {errors.natureofcargo}
                    </p>
                  )}
                </label>
              </div>
            </Col4>
          </Columns>
          <Columns>
            <Col4>
              <div className="is-flex  is-flex-direction-column is-one-third">
                <label className="is-flex  is-flex-direction-column is-one-third">
                  Turnover
                  <div>
                    <select
                      className="py-1 px-2 wd_20"
                      value={CreditForm.turnover.currency}
                      ref={ref}
                      onChange={(e) => handleChange(e, "turnover", "currency")}
                      disabled={!editMode}
                    >
                      {currencyList.map((r, i) => {
                        return (
                          <option key={i} value={r}>
                            {r}
                          </option>
                        )
                      })}
                    </select>
                    <input
                      type="number"
                      className="py-1 px-2 wd_80"
                      placeholder="Value"
                      name="turnover"
                      ref={ref}
                      value={CreditForm.turnover.value}
                      onChange={(e) => handleChange(e, "turnover", "value")}
                      disabled={!editMode}
                      onKeyDown={(e) =>
                        ["e", "E", "+", "-", "."].includes(e.key) &&
                        e.preventDefault()
                      }
                      min={1}
                    ></input>
                  </div>
                </label>
                {errors.turnovervalue && (
                  <p className="help is-input-danger-message">
                    {errors.turnovervalue}
                  </p>
                )}
              </div>
            </Col4>
          </Columns>
          <hr></hr>
          <p className="is-size-5 has-text-weight-bold">Financial Summary*</p>
          <table className="table_caf wd_80">
            <thead>
              <td>Product</td>
              <td>Total Potential</td>
              <td>Monthly Revenue Projected</td>
            </thead>
            <tbody>
              <tr>
                <td className="px-2">International</td>

                <td>
                  <label>
                    <input
                      type="number"
                      // className="inp_table_caf"
                      className={`inp_table_caf ${errors.finsum_int_tp ? "input-danger" : ""
                        }`}
                      name="totalpotential"
                      value={
                        CreditForm.financialsummary.International.totalpotential
                      }
                      ref={ref}
                      disabled={!editMode}
                      onChange={(e) =>
                        handleChange(
                          e,
                          "financialsummary",
                          "International",
                          "totalpotential",
                          "finsum_int_tp"
                        )
                      }
                      min={0}
                      onKeyDown={(e) =>
                        ["e", "E", "+", "-", "."].includes(e.key) &&
                        e.preventDefault()
                      }
                    ></input>
                  </label>
                </td>

                <td>
                  <label>
                    <input
                      type="number"
                      className={`inp_table_caf ${errors.finsum_int_mr ? "input-danger" : ""
                        }`}
                      name="monthlyrevenue"
                      value={
                        CreditForm.financialsummary.International.monthlyrevenue
                      }
                      disabled={!editMode}
                      ref={ref}
                      onChange={(e) =>
                        handleChange(
                          e,
                          "financialsummary",
                          "International",
                          "monthlyrevenue",
                          "finsum_int_mr"
                        )
                      }
                      min={0}
                      onKeyDown={(e) =>
                        ["e", "E", "+", "-", "."].includes(e.key) &&
                        e.preventDefault()
                      }
                    ></input>
                  </label>
                </td>
              </tr>
              <tr>
                <td className="px-2">Domestic Transport</td>

                <td>
                  <label>
                    <input
                      type="number"
                      className={`inp_table_caf  ${errors.finsum_dom_tp ? "input-danger" : ""
                        }`}
                      name="totalpotential"
                      ref={ref}
                      value={
                        CreditForm.financialsummary.Domestic.totalpotential
                      }
                      disabled={!editMode}
                      onChange={(e) =>
                        handleChange(
                          e,
                          "financialsummary",
                          "Domestic",
                          "totalpotential",
                          "finsum_dom_tp"
                        )
                      }
                      onKeyDown={(e) =>
                        ["e", "E", "+", "-", "."].includes(e.key) &&
                        e.preventDefault()
                      }
                      min={0}
                    ></input>
                  </label>
                </td>

                <td>
                  <label>
                    <input
                      type="number"
                      className={`inp_table_caf ${errors.finsum_dom_mr ? "input-danger" : ""
                        }`}
                      name="monthlyrevenue"
                      value={
                        CreditForm.financialsummary.Domestic.monthlyrevenue
                      }
                      ref={ref}
                      disabled={!editMode}
                      onChange={(e) =>
                        handleChange(
                          e,
                          "financialsummary",
                          "Domestic",
                          "monthlyrevenue",
                          "finsum_dom_mr"
                        )
                      }
                      min={0}
                      onKeyDown={(e) =>
                        ["e", "E", "+", "-", "."].includes(e.key) &&
                        e.preventDefault()
                      }
                    ></input>
                  </label>
                </td>
              </tr>
            </tbody>
          </table>
          {(errors.finsum_int_tp ||
            errors.finsum_int_mr ||
            errors.finsum_dom_tp ||
            errors.finsum_dom_mr) && (
              <p className="help is-input-danger-message">
                Please enter all values
              </p>
            )}
          <p className="is-size-5 has-text-weight-bold mt-4">Finance*</p>
          <div className="is-flex ">
            <table className="table_caf_fin wd_80">
              <thead>
                <td>Customer Contact Person</td>
                <td>Description/Department</td>
                <td>Contact Number</td>
                <td>Email</td>
                {editMode && (
                  <td></td>
                )
                }
              </thead>
              <tbody>
                {CreditForm.finance?.map((r, i) => {
                  return (
                    <tr>
                      <td>
                        <input
                          // className="inp_table_caf"
                          className={`inp_table_caf is-size-6 ${errors.finance?.obj[i]?.customercontact
                              ? "input-danger"
                              : ""
                            }`}
                          value={CreditForm.finance[i].customercontact}
                          ref={ref}
                          onChange={(e) =>
                            handleChange1(e, i, "customercontact")
                          }
                          disabled={!editMode}
                        ></input>
                      </td>
                      <td>
                        <input
                          className={`inp_table_caf is-size-6 ${errors.finance?.obj[i]?.department
                              ? "input-danger"
                              : ""
                            }`}
                          ref={ref}
                          value={CreditForm.finance[i].department}
                          onChange={(e) => handleChange1(e, i, "department")}
                          disabled={!editMode}
                        ></input>
                      </td>
                      <td className={`is-flex wd_100 is-size-6 ${errors.finance?.obj[i]?.contactnum
                          ? "input-danger"
                          : ""
                        }`}>
                        {/* <span className="contNumcodespn mt-2 mx-1"></span>
                        <input
                          type="number"
                          className={`inp_table_caf  out_none_inp is-size-6 px-1`}
                          ref={ref}
                          value={CreditForm.finance[i].contactnum}
                          onChange={(e) => handleChange1(e, i, "contactnum")}
                          disabled={!editMode}
                          onKeyDown={(e) =>
                            ["e", "E", "+", "-", "."].includes(e.key) &&
                            e.preventDefault()
                          }
                          min={0}
                        ></input> */}
                        <PhoneField callback={(e)=> {
            // setPhonePrefix(e?.code)
            // setPhoneNumber(e?.number)
            CreditForm.finance[i].countryCode =e?.code
            CreditForm.finance[i].contactnum =e?.number
            clearErrors(i,'contactnum' , 'finance')
        }} 
        countryCode={CreditForm.finance[i].countryCode || "+91"} phone={CreditForm.finance[i].contactnum} 
        creditFlag={true}  labelReq={false} errorMsgReg={false} disabled = {!editMode} ></PhoneField>
                      </td>
                      <td>
                        <input
                          className={`inp_table_caf is-size-6  ${errors.finance?.obj[i]?.email ? "input-danger" : ""
                            }`}
                          value={CreditForm.finance[i].email}
                          ref={ref}
                          onChange={(e) => handleChange1(e, i, "email")}
                          disabled={!editMode}
                        ></input>
                      </td>
                      {editMode ? (
                        <>
                          <td>
                            {CreditForm.finance.length === (Math.abs(i + 1)) ? (
                              <span className=" icon is-medium">
                                <BiPlus
                                  className="is-clickable fas fa-lg"
                                  style={{ color: "red" }}
                                  onClick={addNewFinancerow}
                                  disabled={!editMode}
                                />
                              </span>
                            ) : <span className=" icon is-medium"></span>
                            }
                            <span className=" icon is-medium">
                              <MdDelete
                                className="is-clickable fas fa-lg"
                                onClick={() => removeFinancerow(i)}
                                disabled={!editMode}
                              />
                            </span>
                          </td>
                        </>
                      ) : null}
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
          {errors.finance?.error && (
            <p className="help is-input-danger-message">
              {errors.finance.contactNumber || errors.finance.email
                ? errors.finance.contactNumber || errors.finance.email
                : "Please enter all details"}
            </p>
          )}
          <p className="is-size-5 has-text-weight-bold mt-4">
            Finance Escalation
          </p>
          <table className="table_caf_fin wd_80 mb-4">
            <thead>
              <td>Customer Contact Person</td>
              <td>Description/Department</td>
              <td>Contact Number</td>
              <td>Email</td>
              {editMode && (
                <td></td>
              )
              }
            </thead>
            <tbody>
              {CreditForm.financeescalation?.map((r, i) => {
                return (
                  <tr>
                    <td>
                      <input
                        className={`inp_table_caf is-size-6  ${errors.financeescalation?.obj[i]?.customercontact
                            ? "input-danger"
                            : ""
                          }`}
                        value={CreditForm.financeescalation[i].customercontact}
                        ref={ref}
                        onChange={(e) => handleChange2(e, i, "customercontact")}
                        disabled={!editMode}
                      ></input>
                    </td>
                    <td>
                      <input
                        className={`inp_table_caf is-size-6 ${errors.financeescalation?.obj[i]?.department
                            ? "input-danger"
                            : ""
                          }`}
                        value={CreditForm.financeescalation[i].department}
                        ref={ref}
                        onChange={(e) => handleChange2(e, i, "department")}
                        disabled={!editMode}
                      ></input>
                    </td>
                    <td className={`is-flex wd_100 is-size-6 ${errors.financeescalation?.obj[i]?.contactnum
                        ? "input-danger"
                        : ""
                      }`}>
                      {/* <span className="contNumcodespn mt-2 mx-1"></span>
                      <input
                        type="number"
                        className={`inp_table_caf out_none_inp is-size-6 px-1`}
                        value={CreditForm.financeescalation[i].contactnum}
                        ref={ref}
                        onChange={(e) => handleChange2(e, i, "contactnum")}
                        disabled={!editMode}
                        onKeyDown={(e) =>
                          ["e", "E", "+", "-", "."].includes(e.key) &&
                          e.preventDefault()
                        }
                        min={0}
                      ></input> */}
                      <PhoneField callback={(e)=> {
            // setPhonePrefix(e?.code)
            // setPhoneNumber(e?.number)
            CreditForm.financeescalation[i].countryCode =e?.code
            CreditForm.financeescalation[i].contactnum =e?.number
            clearErrors(i,'contactnum', 'financeescalation')
        }} 
        countryCode={CreditForm.financeescalation[i].countryCode} phone={CreditForm.financeescalation[i].contactnum} 
        creditFlag={true}  labelReq={false} errorMsgReg={false} disabled = {!editMode} ></PhoneField>
                    </td>
                    <td>
                      <input
                        className={`inp_table_caf out_none_inp is-size-6  ${errors.financeescalation?.obj[i]?.email
                            ? "input-danger"
                            : ""
                          }`}
                        value={CreditForm.financeescalation[i].email}
                        ref={ref}
                        onChange={(e) => handleChange2(e, i, "email")}
                        disabled={!editMode}
                      ></input>
                    </td>
                    {editMode ? (
                      <>
                        <td className="add_del_iconrow">
                          {CreditForm.financeescalation.length === (Math.abs(i + 1)) ? (
                            <span className=" icon is-medium">
                              <BiPlus
                                className="is-clickable fas fa-lg"
                                style={{ color: "red" }}
                                onClick={addNewFinanceEsarow}
                              />

                            </span>
                          ) : <span className=" icon is-medium"></span>
                          }
                          <span className=" icon is-medium">
                            <MdDelete
                              className="is-clickable fas fa-lg"
                              onClick={() => removeFinanceEsarow(i)}
                              disabled={true}
                            />
                          </span>
                        </td>
                      </>
                    ) : null}
                  </tr>
                )
              })}
            </tbody>
          </table>
          {errors.financeescalation?.error && (
            <p className="help is-input-danger-message">
              {errors.financeescalation.contactNumber || errors.financeescalation.email
                ? errors.financeescalation.contactNumber || errors.financeescalation.email
                : "Please enter all details"}
            </p>
          )}
          {editMode && (
            <div>
              <span>
                <button
                  className="button_cancel"
                  onClick={async (e) => {
                    e.preventDefault()
                    props.fn.fn(false)
                    AgmodalStore.pageVisible = false
                    AgmodalStore.apiCall = true
                  }}
                >
                  CANCEL
                </button>
                {saveBtn && (
                  <button
                    className="button_submit"
                    type="submit"
                    onClick={handleSubmitSave}
                  >
                    {!loading ? "SAVE" : <Loader className="spinner" />}
                  </button>
                )
                }{(!subBtnstatus && props.mode === "edit") && (
                  <button
                    className="button_submit"
                    id="approvebtnid"
                    type="submit"
                    onClick={handleSubmit}
                    style={{
                      ...(subBtnstatus ? { opacity: "0.6" } : null)
                    }}
                  // disabled={subBtnstatus}
                  >
                    {!loading ? "RESUBMIT" : <Loader className="spinner" />}
                  </button>
                )
                }
                {(props.mode === "new" && RoleUtils.isUserAuthorized(PERMISSIONS.CREATE.MANAGE_CREDIT)) && (
                  <button
                    className="button_submit"
                    id="approvebtnid"
                    type="submit"
                    onClick={handleSubmit}
                    style={{
                      ...(subBtnstatus ? { opacity: "0.6" } : null)
                    }}
                  // disabled={subBtnstatus}
                  >
                    {!loading ? "SUBMIT" : <Loader className="spinner" />}
                  </button>
                )
                }
              </span>
            </div>
          )}
        </div>
        {/* </form> */}
      </div>
    </div>
  )
}
