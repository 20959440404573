import { useEffect, useState } from "react"
import MonApi from "../state/mongo-api"
import Utils from "../util/Utils"
import { taskStore } from "../mytasks/task-store"
import { configStore } from "../state/CommonState"
import { useSnapshot } from "valtio"
import { Typography } from "@mui/material"

export function DocumentCenter(props) {
   
    useSnapshot(taskStore)
    useSnapshot(configStore)
    const [fileList, setFileList] = useState([])
    let task = taskStore.current
    let stage = task.stage
    let docs = task.files
    if (!docs) docs = {}
    console.log('*************', task)
    let server = process.env.REACT_APP_FILE_SERVER
    let dsrRec = props.dsrRec
    useEffect(() => {
        let f = async () => {
            let ret = await MonApi.find('Filestore', { '_id.pkey': props.dsrRec._id }, { 'content': 0 })
            let actions = await MonApi.find('Myactions', { bookingNum: props.dsrRec._id })
            let files = []
            for (let i = 0; i < actions.length; i++) {
                let a = actions[i]
                if(a.actionName.indexOf('Additional Document Request')>=0){
                    // if(a.customerStatus ==='COMPLETED'){
                        a.documentRequest.map(e => (e.uploadedDocument.length > 0&&e.status==='Approve') ? files.push(e.uploadedDocument) : 1)
                        ret.filter((e) => files.includes(e._id.filename) || e?.label === "template")
                    // }
                }
                if(a.actionName.indexOf('Shipping Instructions') == -1){                    
                    if (a.status === 'COMPLETED') {
                        if(a.files!=undefined){
                            a.files.map(e => (e.filename.length > 0) ? files.push(e.filename) : 1)
                        }
                        continue
                    }
                    if (a.customerStatus === 'COMPLETED') {
                        if(a.files!=undefined){
                            a.files.map(e => (e.filename.length > 0 && e.approved === 'Approve') ? files.push(e.filename) : 1)
                        }
                    }
                }else{
                    if (a.status === 'COMPLETED') {
                        if(a.files!=undefined){
                            if(a.files.length > 1  && a.files[1].filename.length > 0){
                                files.push(a.files[1].filename)
                            }else{
                                 a.files.map(e => (e.filename.length > 0) ? files.push(e.filename) : 1)
                            }
                        }
                        continue
                    }
                    if (a.customerStatus === 'COMPLETED') {
                        if(a.files!=undefined){
                            if(a.files.length > 1  && a.files[1].filename.length > 0){
                                files.push(a.files[1].filename)
                            }else{
                                 a.files.map(e => (e.filename.length > 0) ? files.push(e.filename) : 1)
                            }
                        }
                    }
                }
                
            }
            
            ret = ret.filter(e => files.indexOf(e._id.filename) >= 0)

            console.log('RESULT OF DOCUMNET CENTER ACTIONS=', ret)
            setFileList(ret)
        }
        f()
    }, [])
    //return <h1>{props.dsrRec._id}</h1>
    return (
        <div>
            {props.dsrRec.booking.commercialInvoice_filename_list?.length === 0 && props.dsrRec.booking.commercialInvoice_filename_list?.length === 0 && props.dsrRec.booking.msds_filename.length === 0 && props.dsrRec.booking.nondgdecl_filename.length === 0 && props.dsrRec.booking.dgdecl_filename.length === 0 && props.dsrRec.booking.iipcert_filename.length === 0 && fileList.length ===0 ? <Typography sx={{textAlign:"center", fontWeight:"bold", fontFamily:"Figtree"}}>No Documents Found </Typography> :
            <>
            <table className="table is-narrow is-small is-fullwidth">
                <thead>
                    <tr>
                        <th>Document Name</th>
                        <th>Created Date</th>
                        <th>Created By</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        (dsrRec.booking.commercialInvoice_filename_list?.length > 0) ?
                            <>
                                {
                                    dsrRec.booking.commercialInvoice_filename_list.map(e => {
                                        return <tr>
                                            <td>
                                                <a target='_blank'
                                                    href={server + '/clientfiles/' + e}>Commercial Invoice</a>
                                            </td>
                                            <td>
                                                {Utils.formatToDateTime1(dsrRec.booking.bookingDate, '')}
                                            </td>
                                            <td>
                                                {dsrRec.booking.tsUpdatedBy}
                                            </td>
                                        </tr>
                                    })
                                }
                            </>
                            :
                            <></>
                    }
                    {
                        (dsrRec.booking.commercialInvoice_filename_list?.length > 0) ?
                            <>
                                {
                                    dsrRec.booking.packingList_filename_list.map(e => {
                                        return <tr>
                                            <td>
                                                <a target='_blank'
                                                    href={server + '/clientfiles/' + e}>Packing List</a>
                                            </td>
                                            <td>
                                                {Utils.formatToDateTime1(dsrRec.booking.bookingDate, '')}
                                            </td>
                                            <td>
                                                {dsrRec.booking.tsUpdatedBy}
                                            </td>
                                        </tr>
                                    })
                                }
                            </>
                            :
                            <></>
                    }
                    {
                        (dsrRec.booking.msds_filename.length > 0) ?
                            <tr>
                                <td>
                                    <a target='_blank'
                                        href={server + '/clientfiles/' + dsrRec.booking.msds_filename}>MSDS</a>
                                </td>
                                <td>
                                    {Utils.formatToDateTime1(dsrRec.booking.bookingDate, '')}
                                </td>
                                <td>
                                    {dsrRec.booking.tsUpdatedBy}
                                </td>
                            </tr>
                            : <></>
                    }
                    {
                        (dsrRec.booking.nondgdecl_filename.length > 0) ?
                            <tr>
                                <td>
                                    <a target='_blank'
                                        href={server + '/clientfiles/' + dsrRec.booking.nondgdecl_filename}>Non-DG Declaration</a>
                                </td>
                                <td>
                                    {Utils.formatToDateTime1(dsrRec.booking.bookingDate, '')}
                                </td>
                                <td>
                                    {dsrRec.booking.tsUpdatedBy}
                                </td>
                            </tr>
                            : <></>
                    }
                    {
                        (dsrRec.booking.dgdecl_filename.length > 0) ?
                            <tr>
                                <td>
                                    <a target='_blank'
                                        href={server + '/clientfiles/' + dsrRec.booking.dgdecl_filename}>DG Declaration</a>
                                </td>
                                <td>
                                    {Utils.formatToDateTime1(dsrRec.booking.bookingDate, '')}
                                </td>
                                <td>
                                    {dsrRec.booking.tsUpdatedBy}
                                </td>
                            </tr>
                            : <></>
                    }
                    {
                        (dsrRec.booking.iipcert_filename.length > 0) ?
                            <tr>
                                <td>
                                    <a target='_blank'
                                        href={server + '/clientfiles/' + dsrRec.booking.iipcert_filename}>IIP Certificate</a>
                                </td>
                                <td>
                                    {Utils.formatToDateTime1(dsrRec.booking.bookingDate, '')}
                                </td>
                                <td>
                                    {dsrRec.booking.tsUpdatedBy}
                                </td>
                            </tr>
                            : <></>
                    }

                    <tr>

                    </tr>
                    {
                        fileList.map(e => <tr>
                            <td >
                                <a target='_blank'
                                    href={server + '/api/v1/filestore/getfile/' + e._id.pkey + '/' + e._id.filename}>
                                    {e.label}
                                </a>
                            </td>
                            <td>{Utils.formatToDateTime(e.approvedOn ? e.approvedOn : e.createdOn)}</td>
                            <td>{e.createdBy}</td>
                        </tr>
                        )
                    }                    
                </tbody>
            </table>
            <hr />
            </>
            }
            {/*<p>
                <span>
                    <button className="button is-danger"
                        onClick={async (e) => {
                            taskStore.myActionVisible = false
                        }}>Cancel</button>
                    <button style={{ float: 'right' }} className="button islink"
                        onClick={async (e) => {
                            let keys = Object.keys(taskStore.current.files)
                            let rejected = false
                            if (!keys || !keys.length) { } else {
                                for (let i = 0; i < keys.length; i++) {
                                    let key = keys[i]
                                    let f = taskStore.current.files[key]
                                    if (f.approved === 'Reject' && !f.rejectReason) {
                                        configStore.setModalMessage('Rejection reason is mandatory')
                                        return
                                    }
                                    if (f.approved === 'Reject') rejected = true
                                }
                                if (rejected) taskStore.current.stage = 1
                                else {
                                    taskStore.current.stage = 3
                                    taskStore.setComplete()
                                }
                                await Taskapi.updateTask(taskStore.current)
                                taskStore.myActionVisible = false
                            }
                        }}>Submit</button>
                </span>
                    </p>*/}
                
        </div>
    )
}