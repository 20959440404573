export const QuoteDetails = (props) => {
    let keys = Object.keys(props.rec);
    let rows = [];
    for (let i = 0; i < keys.length; i++) {
        if((i+1)<keys.length){
            rows.push(
                <tr key={keys[i]}>
                    <td style={{border:"solid 0.5px #D4D4D4", width:"49%", padding:"5px", backgroundColor:(i%2)==1?"#F3F3F3":"none"}}>
                        <div style={{display:"flex", width:"100%"}}>
                            <div style={{width:"30%", textAlign:"left", color:"#2C358A", fontWeight:"900"}}>
                                {keys[i]}:
                            </div>
                            <div style={{width:"70%", textAlign:"right", color:"#555555"}}>
                                {props.rec[keys[i]]}
                            </div>
                        </div>
                    </td>
                    <td style={{width:"2%"}}></td>
                    <td style={{border:"solid 0.5px #D4D4D4", width:"49%", padding:"5px", backgroundColor:(i%2)==1?"#F3F3F3":"none"}}>
                        <div style={{display:"flex", width:"100%"}}>
                            <div style={{width:"30%", textAlign:"left", color:"#2C358A", fontWeight:"900"}}>
                                {keys[i+1]}:
                            </div>
                            <div style={{width:"70%", textAlign:"right", color:"#555555"}}>
                                {props.rec[keys[i+1]]}
                            </div>
                        </div>
                    </td>
                </tr>
            );
            i = i+1
        }else{
            rows.push(
                <tr key={keys[i]}>
                    <td style={{border:"solid 0.5px #D4D4D4", width:"49%", padding:"5px", backgroundColor:(i%2)==1?"#F3F3F3":"none"}}>
                        <div style={{display:"flex", width:"100%"}}>
                            <div style={{width:"30%", textAlign:"left", color:"#2C358A", fontWeight:"900"}}>
                                {keys[i]}:
                            </div>
                            <div style={{width:"70%", textAlign:"right", color:"#555555"}}>
                                {props.rec[keys[i]]}
                            </div>
                        </div>
                    </td>
                    <td style={{width:"2%"}}></td>
                    <td style={{border:"solid 0.5px #D4D4D4", width:"49%", padding:"5px", backgroundColor:(i%2)==1?"#F3F3F3":"none"}}></td>
                </tr>
            );
        }
    }
    return <>{rows}</>;
};